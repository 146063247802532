import { Button, Grid } from '@material-ui/core';
import React from 'react';
import { isMobile } from 'react-device-detect';
import Text from '../../components/Text';

interface IProps {
    classes: any;
    onClose: any;
}

class Gift extends React.Component<IProps> {
    public render() {
        const { classes, onClose } = this.props;
        const padding = isMobile ? {} : { paddingLeft: 140, paddingRight: 140 };

        return (
            <Grid container={true} justifyContent="center" alignItems="center" className={classes.pageHeader} style={padding}>
                <Grid container={true} item={true} xs={12}>
                    <Grid
                        style={{
                            width: '100%',
                            height: isMobile ? 340 : 400,
                            backgroundImage: 'url(/images/phone-gift-explainer.png)',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            backgroundSize: 'contain',
                        }}
                    />
                </Grid>
                <Grid container={true} item={true} xs={12} style={{ marginTop: 35 }} justifyContent="center" wrap="nowrap">
                    <img alt="gift" src="/images/gift.png" style={{ width: 30, height: 30 }} />
                    <Text bold={true} fontSize={26} style={{ paddingLeft: 20 }} noWrap={true}>
                        Gifts
                    </Text>
                </Grid>
                <Grid item={true} xs={12} style={{ marginTop: 12 }}>
                    <Text light={true} fontSize={20} align="center">
                        Students earn points that can be redeemed for local and online discounts
                    </Text>
                </Grid>
                <Grid container={true} direction="column" justifyContent="center" alignItems="center" className={classes.buttonLoaderWrapper}>
                    <Button onClick={onClose} variant="contained" color="primary" className={classes.primaryButton}>
                        Got it
                    </Button>
                </Grid>
            </Grid>
        );
    }
}

export default Gift;
