import { FormControl, Grid, Input, InputLabel, Link, Typography } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { Formik } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import AlertDialog from '../../components/Alert';
import Submit from '../../components/Submit';
import * as routes from '../../utils/routes';
import { ForgotSchema } from '../../utils/validations';
import appStyle from '../App.style';

interface IProps {
    classes: any;
    user: any;
}

interface IState {
    alert: any;
    loginRedirect: boolean;
}

class Forgot extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            alert: {
                showing: false,
            },
            loginRedirect: false,
        };
    }

    public render() {
        const { classes } = this.props;
        const { alert } = this.state;

        return (
            <Grid container={true}>
                <Grid item={true} xs={12} className={classes.pageHeader}>
                    <Grid container={true} justifyContent="center" alignItems="center">
                        <Grid item={true} sm={6} xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                            <Typography component="h1" className={classes.pageTitle}>
                                Forgot Password
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item={true} xs={12} className={classes.pageBody} style={{ backgroundColor: 'white', borderTopWidth: 0 }}>
                    <Grid container={true} justifyContent="center" alignItems="center">
                        <Grid item={true} sm={6} xs={12} className={classes.authContainer}>
                            <AlertDialog alert={alert} onClose={this.alertClosed} />
                            <Formik
                                initialValues={{
                                    email: '',
                                }}
                                validationSchema={ForgotSchema}
                                onSubmit={this.onSubmit}
                            >
                                {({ values, errors, touched, handleChange, handleSubmit, isSubmitting }) => (
                                    <form onSubmit={handleSubmit} noValidate={true}>
                                        <FormControl margin="normal" required={true} fullWidth={true} error={!!errors.email && !!touched.email}>
                                            <InputLabel htmlFor="email">Email</InputLabel>
                                            <Input id="email" name="email" autoFocus={true} value={values.email} onChange={handleChange} />
                                        </FormControl>
                                        <Submit loading={isSubmitting}>Reset Password</Submit>
                                    </form>
                                )}
                            </Formik>
                            <Typography component="p" variant="body1" className={classes.authLink} align="center">
                                <Link color="primary" component={RouterLink} to={routes.login} underline="none">
                                    Back to login
                                </Link>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    private onSubmit = (values, { resetForm, setSubmitting }) => {
        const { actions } = this.props.user;
        const { email } = values;

        actions
            .forgot(email)
            .then(() => {
                resetForm();
                this.setState({
                    alert: {
                        showing: true,
                        title: 'Success',
                        message: 'Check your email to reset your password',
                    },
                });
            })
            .catch((error: Error) => {
                setSubmitting(false);
                this.setState({
                    alert: {
                        showing: true,
                        title: 'Sorry',
                        message: error.message,
                    },
                });
            });
    };

    private alertClosed = () => {
        this.setState({ alert: { showing: false } });
    };
}

const mapStoreToProps = (state: any) => {
    return {
        user: state.user,
    };
};

export default connect(
    mapStoreToProps,
    null
)(withStyles(appStyle)(Forgot));
