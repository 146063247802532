import { Store } from 'redux';
import { createAction, handleActions } from 'redux-actions';
import firebase from 'firebase/compat/app'
class IncentiveProgress {
    public actions = {
        getIncentiveProgress: () => {
            const groupKeys = Object.keys(this.store.getState().groups.data);
            const incentivesArray: any[] = [];
            this.store.dispatch(this.requestIncentiveProgress());

            groupKeys.forEach((key) => {
                incentivesArray.push(
                    this.db
                        .collection('incentiveProgress')
                        .where('classroomId', '==', key)
                        .get()
                        .then((query) => {
                            const incentives = {};

                            query.docs.forEach((doc) => {
                                incentives[doc.id] = {
                                    completed: [],
                                    ...doc.data(),
                                };
                            });

                            if (incentives && Object.keys(incentives).length !== 0) {
                                return incentives;
                            } else {
                                return;
                            }
                        })
                );
            });

            return Promise.all(incentivesArray).then((results) => {
                const progress = results.reduce((collector, result) => {
                    return { ...collector, ...result };
                }, {});

                this.store.dispatch(this.setIncentiveProgress(progress));
            });
        },
        markIncentiveReceived: (
            id: string,
            date: string,
            checked: boolean,
            incentiveId: string,
            incentiveName: string,
            classroomId: string,
            classroomName: string
        ) => {
            const data = {
                received: {
                    [date]: checked,
                },
            };

            return this.db
                .collection('incentiveProgress')
                .doc(id)
                .set(data, { merge: true })
                .then(() => {
                    this.store
                        .getState()
                        .mixpanel.actions.markedAsReceived(checked, date, incentiveId, incentiveName, classroomId, classroomName, 'Individual');
                })
                .catch((error) => {
                    console.info(`reactions:incentiveProgress:markIncentiveReceived:error: ${error}`);
                });
        },
        markAllReceived: (completed: any) => {
            const batch = this.db.batch();

            completed.slice(0, 500).forEach((item) => {
                const ref = this.db.collection('incentiveProgress').doc(item.id);
                const data = {
                    received: {
                        [item.receipt.date]: true,
                    },
                };

                batch.set(ref, data, { merge: true });
            });

            return batch
                .commit()
                .then(() => {
                    completed.forEach((item) => {
                        this.store
                            .getState()
                            .mixpanel.actions.markedAsReceived(
                                true,
                                item.receipt.date,
                                item.incentiveId,
                                item.incentiveName,
                                item.classroomId,
                                item.classroomName,
                                'Individual'
                            );
                    });

                    this.store.getState().mixpanel.actions.markedAllAsReceived(completed.slice(0, 500).length, 'Individual');
                })
                .then(() => {
                    if (completed.length > 500) {
                        this.store.getState().incentiveProgress.actions.markAllReceived(completed.slice(500));
                    } else {
                        this.store.getState().incentiveProgress.actions.getIncentiveProgress();
                    }
                })
                .catch((error) => {
                    console.info(`reactions:incentiveProgress:markAllReceived:error: ${error}`);
                });
        },
        clearIncentiveProgress: () => {
            Object.keys(this.activeListeners).forEach((key) => {
                this.activeListeners[key]();
                delete this.activeListeners[key];
            });

            this.store.dispatch(this.clearIncentiveProgress());
        },
    };

    public initialState = {
        actions: this.actions,
        data: null,
        isFetching: false,
    };

    public reducer = handleActions<any>(
        {
            CLEAR_INCENTIVE_PROGRESS: () => {
                return this.initialState;
            },
            SET_INCENTIVE_PROGRESS: (state, action) => {
                return {
                    ...state,
                    data: action.payload,
                    isFetching: false,
                };
            },
            REQUEST_INCENTIVE_PROGRESS: (state, action) => {
                return {
                    ...state,
                    isFetching: true,
                };
            },
        },
        this.initialState
    );

    private clearIncentiveProgress = createAction('CLEAR_INCENTIVE_PROGRESS');
    private setIncentiveProgress = createAction('SET_INCENTIVE_PROGRESS');
    private requestIncentiveProgress = createAction('REQUEST_INCENTIVE_PROGRESS');

    private db: any;
    private store: Store;
    private activeListeners: any = {};

    constructor(/**firebase: any*/) { 
        this.db = firebase.firestore();
    }

    public setStore = (store) => {
        this.store = store;
    };
}

export default IncentiveProgress;
