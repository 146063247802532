import moment from 'moment';
import { handleActions } from 'redux-actions';
import firebase from 'firebase/compat/app'

export default class Dashboard {
    public actions = {
        progressEarned: ({ date, offset, classroomId }) => {
            const startTime = moment(date)
                .utcOffset(offset)
                .startOf('day')
                .toDate();
            const endTime = moment(date)
                .utcOffset(offset)
                .endOf('day')
                .toDate();
            const progressStart = this.firestore.Timestamp.fromDate(startTime);
            const progressEnd = this.firestore.Timestamp.fromDate(endTime);

            return this.db
                .collection('events/all/Earned')
                .where('classrooms_progressed', 'array-contains', classroomId)
                .where('end', '>=', progressStart)
                .where('end', '<=', progressEnd)
                .get()
                .then((querySnapshot) => {
                    const students = {};

                    querySnapshot.forEach((doc) => {
                        const { user_id, start, end } = doc.data();

                        if (students[user_id.toString()] && students[user_id.toString()].sessions) {
                            students[user_id.toString()].sessions.push({
                                start: start.seconds,
                                end: end.seconds,
                            });
                        } else {
                            students[user_id.toString()] = {
                                sessions: [
                                    {
                                        start: start.seconds,
                                        end: end.seconds,
                                    },
                                ],
                            };
                        }
                    });

                    return {
                        start: date,
                        end: date,
                        [classroomId]: {
                            students,
                        },
                    };
                })
                .catch((error) => {
                    console.info(`reactions:dashboard:progressEarned:error: ${error}`);
                });
        },
    };

    public initialState = {
        actions: this.actions,
        data: null,
    };

    public reducer = handleActions<any>({}, this.initialState);

    private db: any;
    private firestore: any;

    constructor(/**firebase: any*/) {
        this.db = firebase.firestore();
        this.firestore = firebase.firestore;
    }
}
