import { createTheme } from '@material-ui/core';

const theme = createTheme({
    typography: {
        fontFamily: ['Montserrat', 'sans-serif'].join(','),
    },
    palette: {
        primary: {
            main: '#00D793',
        },
        secondary: {
            main: '#FFFFFF',
        },
    },
});

export default theme;
