import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

const InvitePrint = (props) => {
    return (
        <SvgIcon viewBox="0 0 24 22" {...props}>
            <g id="School-View" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Invite" transform="translate(-616.000000, -614.000000)">
                    <g id="Group-6" transform="translate(410.000000, 203.000000)">
                        <g id="Group-5">
                            <g id="Group-4">
                                <g id="invite-print" transform="translate(204.000000, 407.000000)">
                                    <path
                                        d="M22.4,10.1111111 L5.6,10.1111111 C3.608,10.1111111 2,11.7488889 2,13.7777778 L2,21.1111111 L6.8,21.1111111 L6.8,26 L21.2,26 L21.2,21.1111111 L26,21.1111111 L26,13.7777778 C26,11.7488889 24.392,10.1111111 22.4,10.1111111 Z M18.8,23.5555556 L9.2,23.5555556 L9.2,17.4444444 L18.8,17.4444444 L18.8,23.5555556 Z M22.4,15 C21.74,15 21.2,14.45 21.2,13.7777778 C21.2,13.1055556 21.74,12.5555556 22.4,12.5555556 C23.06,12.5555556 23.6,13.1055556 23.6,13.7777778 C23.6,14.45 23.06,15 22.4,15 Z M21.2,4 L6.8,4 L6.8,8.88888889 L21.2,8.88888889 L21.2,4 Z"
                                        id="Shape"
                                        fill="#248969"
                                        fillRule="nonzero"
                                    />
                                    <polygon id="Shape" points="0 0 29 0 29 29 0 29" />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
};

export default InvitePrint;
