import { Store } from 'redux';
import { createAction, handleActions } from 'redux-actions';
import { bugsnagClient } from '../libraries/bugsnag';
import { TIMEOUT } from '../utils/constants';
import firebase from 'firebase/compat/app'
declare let ga: any;

interface ISignUp {
    displayName: string;
    email: string;
    password: string;
    location: any;
}

interface ISetup {
    teacherName: string;
    school: any;
    referrer: string;
}

interface ILogin {
    email: string;
    password: string;
}

interface IUpdateAccount {
    displayName: string;
    teacherName: string;
    school: {
        id: number;
    };
}

class User {
    public actions = {
        signUpEmail: ({ displayName, email, password, location }: ISignUp) => {
            return this.firebase
                .auth()
                .createUserWithEmailAndPassword(email, password)
                .then((userCredential: any) => {
                    const user = userCredential.user;
                    console.info(`reactions:user:signUpEmail:userCredential: ${password}`);
                    const uid = user.uid;
                    const modalStates = {
                        showOnboarding: true,
                    };

                    return this.firebase
                        .database()
                        .ref('/portalUsers/' + uid)
                        .set({ teacherName: displayName, displayName, modalStates, email })
                        .then(() => {
                            console.info(`reactions:user:portalUsers:uid: ${uid}`);
                            this.store.getState().mixpanel.actions.signUp(uid, displayName, location, 'Email');// call the mix panel method signUp

                            ga('send', {
                                hitType: 'event',
                                eventCategory: 'Teacher Rewards',
                                eventAction: 'signup',
                            });
                        });
                });
        },
        signInWithGoogle: () => {
            const provider = new firebase.auth.GoogleAuthProvider();

            provider.setCustomParameters({
                prompt: 'select_account',
            });

            return this.firebase.auth().signInWithRedirect(provider);
        },
        setupUser: (uid, { teacherName, school, referrer }: ISetup) => {
            return this.firebase
                .database()
                .ref('/portalUsers/' + uid)
                .update({ teacherName, school })
                .then(() => {
                    const userInfo = {
                        ...this.store.getState().user.data.userInfo,
                        teacherName,
                        school,
                    };

                    this.store.dispatch(this.setUserInfo(userInfo));

                    this.store.getState().mixpanel.actions.sendEventWithProps('Complete Account', {
                        distinct_id: uid,
                        teacherName,
                        'School Name': school.id,
                        'School Id': school.name,
                        Referrer: referrer,
                    });
                });
        },
        getGoogleRedirect: (location) => {
            return this.firebase
                .auth()
                .getRedirectResult()
                .then((result) => {
                    const { additionalUserInfo, user } = result;

                    if (user && additionalUserInfo.isNewUser) {
                        this.store.getState().mixpanel.actions.signUp(user.uid, user.displayName, location, 'Google');
                    }

                    if (user && !additionalUserInfo.isNewUser) {
                        this.store.getState().mixpanel.actions.login(user.uid, 'Google');
                    }
                })
                .catch((error) => {
                    console.info(`reactions:user:getGoogleRedirect:error: ${error}`);
                });
        },
        loginEmail: ({ email, password }: ILogin) => {
            return this.firebase
                .auth()
                .signInWithEmailAndPassword(email, password)
                .then((userCredential) => {
                    const user = userCredential.user;
                    const uid = user.uid;
                    this.store.getState().mixpanel.actions.login(uid);
                });
        },
        forgot: (email: string) => {
            return this.firebase.auth().sendPasswordResetEmail(email);
        },
        logout: () => {
            this.store.getState().mixpanel.actions.logout();
            this.firebase.auth().signOut();
        },
        updateEmail: ({ currentPassword, email }) => {
            const { authUser } = this.store.getState().user.data;
            const { currentUser } = this.firebase.auth();
            const credential = this.firebase.auth.EmailAuthProvider.credential(currentUser.email, currentPassword);
            const oldEmail = authUser.email;
            const newUserInfo = {
                ...this.store.getState().user.data.userInfo,
                email,
            };

            return currentUser.reauthenticateWithCredential (credential).then(() => {
                return this.firebase
                    .auth()
                    .currentUser.updateEmail(email)
                    .then(() => {
                        this.firebase
                            .database()
                            .ref('/portalUsers/' + authUser.uid)
                            .set(newUserInfo)
                            .then(() => {
                                this.store.getState().mixpanel.actions.updatedEmail(email, oldEmail);
                            });
                    });
            });
        },
        updatePassword: ({ currentPassword, newPassword }) => {
            const { currentUser } = this.firebase.auth();
            const credential = this.firebase.auth.EmailAuthProvider.credential(currentUser.email, currentPassword);

            return currentUser.reauthenticateWithCredential (credential).then(() => {
                return this.firebase
                    .auth()
                    .currentUser.updatePassword(newPassword)
                    .then(() => {
                        this.store.getState().mixpanel.actions.updatedPassword();
                    });
            });
        },
        updateAccount: (newInfo: IUpdateAccount) => {
            return new Promise<void>((resolve, reject) => {
                const {
                    authUser: { email, uid },
                    userInfo,
                } = this.store.getState().user.data;
                const newUserInfo = {
                    ...this.store.getState().user.data.userInfo,
                    ...newInfo,
                };

                const classroomQuery = this.db
                    .collection('classrooms')
                    .where('portalUsers', 'array-contains', uid)
                    .get();
                const updateUserInfo = this.firebase
                    .database()
                    .ref('/portalUsers/' + uid)
                    .set(newUserInfo);
                const institutionQuery = this.db
                    .collection('institutions')
                    .where('pp.schools.id', '==', newUserInfo.school.id)
                    .get()
                    .then((results) => {
                        if (results.docs.length > 0) {
                            return results.docs[0].id;
                        }

                        return null;
                    });

                return Promise.all([classroomQuery, updateUserInfo, institutionQuery])
                    .then((results) => {
                        newUserInfo.institutionId = results[2];

                        this.store
                            .getState()
                            .mixpanel.actions.updatedAccount(userInfo, newUserInfo.displayName, newUserInfo.teacherName, newUserInfo.school, email);
                        this.store.dispatch(this.setUserInfo(newUserInfo));

                        const updates: Array<Promise<void>> = [];
                        results[0].docs.forEach((doc) => {
                            const attributes = doc.data().attributes;
                            const update = this.db
                                .collection('classrooms')
                                .doc(doc.id)
                                .set(
                                    {
                                        attributes: {
                                            ...attributes,
                                            teacherName: newUserInfo.teacherName,
                                            schoolId: newUserInfo.school.id,
                                        },
                                    },
                                    { merge: true }
                                );
                            updates.push(update);
                        });

                        return Promise.all(updates)
                            .then(() => {
                                this.store.getState().groups.actions.getGroups(uid);
                                resolve();
                            })
                            .catch((error) => reject(error));
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        updateHelperModals: (modalStates) => {
            const { uid } = this.store.getState().user.data.authUser;
            const userInfo = {
                ...this.store.getState().user.data.userInfo,
                modalStates: {
                    ...this.store.getState().user.data.userInfo.modalStates,
                    ...modalStates,
                },
            };

            this.firebase
                .database()
                .ref('/portalUsers/' + uid)
                .set(userInfo)
                .then(() => {
                    this.store.dispatch(this.setUserInfo(userInfo));
                });
        },
        updatePreferences: (preferences) => {
            const { uid } = this.store.getState().user.data.authUser;
            const userInfo = {
                ...this.store.getState().user.data.userInfo,
                preferences: {
                    ...this.store.getState().user.data.userInfo.preferences,
                    ...preferences,
                },
            };

            return firebase
                .database()
                .ref(`/portalUsers/${uid}`)
                .set(userInfo)
                .then(() => {
                    this.store.dispatch(this.setUserInfo(userInfo));
                });
        },
    };

    public initialState = {
        actions: this.actions,
        data: {
            loaded: false,
            authUser: null,
            userInfo: null,
        },
    };

    public reducer = handleActions<any>(
        {
            SET_USER: (state, action) => {
                return {
                    ...state,
                    data: action.payload,
                };
            },
            SET_USER_INFO: (state, action) => {
                return {
                    ...state,
                    data: {
                        ...state.data,
                        userInfo: action.payload,
                    },
                };
            },
        },
        this.initialState
    );

    private setUser = createAction('SET_USER');
    private setUserInfo = createAction('SET_USER_INFO');

    private db: any;
    private firebase: any;
    private store: Store;

    constructor(/**firebase: any*/) {
        // OLD IMPLEMENTATION -- FAILED JULY 2022
        this.firebase = firebase;
        this.db = firebase.firestore();
    }

    public setStore = (store) => {
        this.store = store;
    };

    public init = () => {
        return new Promise<void>((resolve) => {
            firebase.auth().onAuthStateChanged((user: any) => {
                // console.info(`reactions:user:init:userObject: ${user}`);
                const timeout = setTimeout(() => {
                    // console.info(`reactions:user:setTimeout:logging out - notauthed: ${user.uid}`);
                    return this.notAuthed().then(() => resolve());
                }, TIMEOUT);
                // console.info(`reactions:user:init:userObjectUID: ${user.uid}`);
                const uid = user ? user.uid : undefined;
                let notAuthed = false;

                if (user && uid) { // get data before calling notAuthed function
                    console.info(`reactions:user:before  cloud function call: .....${uid}`);
                    return firebase
                        .database()
                        .ref(`/portalUsers/${uid}`)
                        .once('value')
                        .then((snapshot: any) => {
                            const getPortalUser = firebase.functions().httpsCallable('getPortalUser')
                            
                            const portalUser = snapshot.val();
                            const { displayName, email } = user;
                            const modalStates = {
                                showOnboarding: true,
                            };
                            const preSetupData = { teacherName: displayName, displayName, modalStates, email };

                            // if we have the user, we're done
                            if (portalUser) {
                                return portalUser;
                            }

                            // if uid is a number, then we have a portal user, if string and no portalUser record, we need to finish setup.
                            if (isNaN(uid)) {
                                return firebase
                                    .database()
                                    .ref('/portalUsers/' + uid)
                                    .set(preSetupData)
                                    .then(() => preSetupData);
                            } else {
                                return getPortalUser({ id: uid }).then(({ data }) => {
                                    const portalUserData = {
                                        ...data,
                                        modalStates: {
                                            showOnboarding: true,
                                        },
                                    };

                                    return firebase
                                        .database()
                                        .ref(`/portalUsers/${uid}`)
                                        .set(portalUserData)
                                        .then(() => portalUserData);
                                });
                            }
                        })
                        .then((data) => {
                            if (data.school) {
                                return this.db
                                    .collection('institutions')
                                    .where('pp.schools.id', '==', data.school.id)
                                    .get()
                                    .then((results) => {
                                        if (results.docs.length > 0) {
                                            return { ...data, institutionId: results.docs[0].id };
                                        }

                                        return data;
                                    });
                            }
                            return data;
                        })
                        .then((data) => {
                            if (data.earningWindowId) {
                                return this.store
                                    .getState()
                                    .earningWindow.actions.getEarningWindow(data.earningWindowId)
                                    .then(() => {
                                        return data;
                                    });
                            }

                            return data;
                        })
                        .then((data) => {
                            return this.store
                                .getState()
                                .groups.actions.getGroups(uid)
                                .then((groupData) => {
                                    this.store.getState().mixpanel.actions.setUser(uid, data, user, groupData);
                                    this.store.dispatch(
                                        this.setUser({
                                            loaded: true,
                                            authUser: user,
                                            userInfo: data,
                                        })
                                    );

                                    bugsnagClient.setUser(
                                        user.uid,
                                        data.displayName,
                                         user.email,
                                        // 'School Name': data.school.name,
                                        // 'School ID': data.school.id,
                                    );

                                    window.Intercom('boot', {
                                        app_id: 'u5s3lhma',
                                        email: user.email,
                                        user_id: user.uid,
                                        name: data.displayName,
                                        // 'School Name': data.school.name,
                                        // 'School ID': data.school.id,
                                    });

                                    clearTimeout(timeout);
                                    resolve(user);
                                });
                        })
                        .catch(() => {
                            notAuthed = true;
                        });
                } else {
                    notAuthed = true;
                }

                if (notAuthed) {
                    clearTimeout(timeout);
                    return this.notAuthed().then(() => resolve());
                }
            });
        }).catch(() => {
            console.info('error on auth');
        });
    };

    private notAuthed = () => {
        return Promise.resolve().then(() => {
            let userAuthed = false;

            if (this.store.getState().user.data.authUser) {
                userAuthed = true;
            }
            this.store.dispatch(
                this.setUser({
                    loaded: true,
                    authUser: null,
                    userInfo: null,
                })
            );
            if (userAuthed) {
                this.store.getState().groups.actions.clearGroups();
                this.store.getState().incentiveProgress.actions.clearIncentiveProgress();
                this.store.getState().earningWindow.actions.clearEarningWindow();
            }
            bugsnagClient.setUser(
                null,
                null,
                 null,
                // 'School Name': data.school.name,
                // 'School ID': data.school.id,
            );
            window.Intercom('shutdown');
            window.Intercom('boot', { app_id: 'u5s3lhma' });
        });
    };
}

export default User;