// import moment from 'moment';
import { Store } from 'redux';
import { createAction, handleActions } from 'redux-actions';

export default class Teachers {
    public actions = {
        getTeacherList: (schoolId, reinit = false) => {
            const getTeacherList = this.firebase.functions().httpsCallable('getTeacherList');

            if (reinit) {
                this.store.dispatch(this.reinitTeachers());
            }

            return getTeacherList({ schoolId })
                .then((results: any) => {
                    if (results.data && !results.data.error) {
                        this.store.dispatch(this.setTeachers(results.data));
                    }
                })
                .catch((error: Error) => {
                    console.info(`reactions:schools:getTeacherList:error: ${error}`);
                });
        },
    };

    public initialState = {
        actions: this.actions,
        data: null,
        initted: false,
    };

    public reducer = handleActions<any>(
        {
            REINIT_TEACHERS: (state) => {
                return {
                    ...state,
                    data: null,
                    initted: false,
                };
            },
            SET_TEACHERS: (state, action) => {
                return {
                    ...state,
                    data: action.payload,
                    initted: true,
                };
            },
        },
        this.initialState
    );

    // private database: any;
    // private firestore: any;
    private store: Store;

    private setTeachers = createAction('SET_TEACHERS');
    private reinitTeachers = createAction('REINIT_TEACHERS');

    constructor(private firebase: any) {}

    public setStore = (store) => {
        this.store = store;
    };
}
