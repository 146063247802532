import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    FormControl,
    FormHelperText,
    Grid,
    Input,
    InputLabel,
    Typography,
    withMobileDialog,
} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { ExpandMore } from '@material-ui/icons';
import { Formik } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import CloseButton from '../../components/CloseButton';
import InstitutionMap from '../../components/InstitutionMap';
import SchoolPicker from '../../components/SchoolPicker';
import { AccountEmailSchema, AccountPasswordSchema, AccountSchema } from '../../utils/validations';
import appStyle from '../App.style';

interface IProps {
    classes?: any;
    user?: any;
    onClose: any;
    showing: boolean;
    fullScreen?: boolean;
    width?: any;
}

interface IState {
    expandEmail: boolean;
    expandPassword: boolean;
    updateError: {
        account?: string;
        email?: string;
        password?: string;
    };
    updateSuccess: {
        account?: string;
        email?: string;
        password?: string;
    };
}

class EditAccount extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            expandEmail: false,
            expandPassword: false,
            updateError: {},
            updateSuccess: {},
        };
    }

    public render() {
        const {
            showing,
            classes,
            fullScreen,
            user: {
                data: { authUser, userInfo },
            },
        } = this.props;
        const { expandEmail, expandPassword, updateError, updateSuccess } = this.state;
        const schoolId = userInfo && userInfo.school ? userInfo.school.id : undefined;

        if (!authUser || !userInfo) {
            return null;
        }

        return (
            <div>
                <Dialog fullScreen={fullScreen} open={showing} onClose={this.onClose} maxWidth="sm" fullWidth={true}>
                    <Formik
                        initialValues={{
                            displayName: userInfo.displayName,
                            teacherName: userInfo.teacherName,
                            email: authUser.email,
                            school: userInfo.school,
                        }}
                        validationSchema={AccountSchema}
                        onSubmit={this.onSubmit}
                    >
                        {({ values, errors, touched, handleChange, handleSubmit, isSubmitting, setFieldValue }) => (
                            <form className={classes.form} onSubmit={handleSubmit} noValidate={true}>
                                <DialogContent style={{ paddingBottom: 0, position: 'relative' }}>
                                    <CloseButton onClick={this.onClose} />
                                </DialogContent>
                                <DialogTitle id="alert-dialog-title" style={{ fontWeight: 600 }}>
                                    Edit Account
                                </DialogTitle>
                                <DialogContent style={{ overflowY: 'unset', position: 'relative' }}>
                                    <FormControl margin="normal" required={true} fullWidth={true} error={!!errors.displayName && !!touched.displayName}>
                                        <InputLabel shrink={true} htmlFor="displayName">
                                            Name
                                        </InputLabel>
                                        <Input
                                            placeholder="First and Last Name"
                                            id="displayName"
                                            name="displayName"
                                            value={values.displayName}
                                            onChange={handleChange}
                                        />
                                    </FormControl>
                                    <FormControl margin="normal" required={true} fullWidth={true} error={!!errors.teacherName && !!touched.teacherName}>
                                        <InputLabel shrink={true} htmlFor="teacherName">
                                            What do students call you?
                                        </InputLabel>
                                        <Input
                                            placeholder="e.g Mrs. Smith, Coach Robertson, etc."
                                            id="teacherName"
                                            name="teacherName"
                                            value={values.teacherName}
                                            onChange={handleChange}
                                        />
                                    </FormControl>
                                    <SchoolPicker error={!!errors.school} school={values.school} onChange={(school) => setFieldValue('school', school)} />
                                    <DialogActions>
                                        <div className={classes.buttonLoaderWrapper}>
                                            <Button onClick={this.onClose} color="primary">
                                                Cancel
                                            </Button>
                                        </div>
                                        <div className={classes.buttonLoaderWrapper}>
                                            <Button type="submit" disabled={isSubmitting} color="primary">
                                                Save
                                            </Button>
                                            {isSubmitting && <CircularProgress size={20} className={classes.buttonLoaderInline} />}
                                        </div>
                                    </DialogActions>
                                    {schoolId && <InstitutionMap schoolId={schoolId} hideAddress={true} hideHeading={true} creatorID="edit-account" />}
                                    {updateError && updateError.account && (
                                        <DialogContentText id="alert-dialog-description" style={{ color: 'red', paddingTop: 15 }}>
                                            {updateError.account}
                                        </DialogContentText>
                                    )}
                                </DialogContent>
                            </form>
                        )}
                    </Formik>
                    <Formik
                        initialValues={{
                            currentPassword: '',
                            email: authUser.email,
                        }}
                        validationSchema={AccountEmailSchema}
                        onSubmit={this.onUpdateEmail}
                        enableReinitialize={true}
                    >
                        {({ errors, touched, values, handleChange, handleSubmit, isSubmitting }) => (
                            <form onSubmit={handleSubmit} noValidate={true}>
                                <DialogContent style={{ paddingTop: 0, position: 'relative' }}>
                                    <ExpansionPanel expanded={expandEmail} onChange={this.toggleExpandEmail}>
                                        <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                                            <Typography className={classes.heading}>Update Email</Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            <Grid container={true}>
                                                <FormControl
                                                    margin="normal"
                                                    required={true}
                                                    fullWidth={true}
                                                    error={!!errors.currentPassword && !!touched.currentPassword}
                                                >
                                                    <Input
                                                        placeholder="Current Password"
                                                        name="currentPassword"
                                                        type="password"
                                                        value={values.currentPassword}
                                                        onChange={handleChange}
                                                    />
                                                </FormControl>
                                                <FormControl margin="normal" required={true} fullWidth={true} error={!!errors.email && !!touched.email}>
                                                    <Input
                                                        placeholder="Email"
                                                        name="email"
                                                        type="text"
                                                        id="email"
                                                        value={values.email}
                                                        onChange={handleChange}
                                                    />
                                                </FormControl>
                                                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                                    <div className={classes.buttonLoaderWrapper}>
                                                        <Button disabled={isSubmitting} type="submit" color="primary">
                                                            Update Email
                                                        </Button>
                                                        {isSubmitting && <CircularProgress size={20} className={classes.buttonLoaderInline} />}
                                                    </div>
                                                </div>
                                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                                    {updateError && updateError.email && (
                                                        <DialogContentText id="alert-dialog-description" style={{ color: 'red', paddingTop: 15 }}>
                                                            {updateError.email}
                                                        </DialogContentText>
                                                    )}
                                                    {updateSuccess && updateSuccess.email && (
                                                        <DialogContentText id="alert-dialog-description" color="primary" style={{ paddingTop: 15 }}>
                                                            {updateSuccess.email}
                                                        </DialogContentText>
                                                    )}
                                                </div>
                                            </Grid>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                </DialogContent>
                            </form>
                        )}
                    </Formik>
                    <Formik
                        initialValues={{
                            currentPassword: '',
                            newPassword: '',
                        }}
                        validationSchema={AccountPasswordSchema}
                        onSubmit={this.onUpdatePassword}
                    >
                        {({ errors, touched, values, handleChange, handleSubmit, isSubmitting }) => (
                            <form onSubmit={handleSubmit} noValidate={true}>
                                <DialogContent style={{ paddingTop: 0, position: 'relative' }}>
                                    <ExpansionPanel expanded={expandPassword} onChange={this.toggleExpandPassword}>
                                        <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                                            <Typography className={classes.heading}>Update Password</Typography>
                                        </ExpansionPanelSummary>
                                        <ExpansionPanelDetails>
                                            <Grid container={true}>
                                                <FormControl
                                                    margin="normal"
                                                    required={true}
                                                    fullWidth={true}
                                                    error={!!errors.currentPassword && !!touched.currentPassword}
                                                >
                                                    <Input
                                                        placeholder="Current Password"
                                                        name="currentPassword"
                                                        type="password"
                                                        value={values.currentPassword}
                                                        onChange={handleChange}
                                                    />
                                                </FormControl>
                                                <FormControl
                                                    margin="normal"
                                                    required={true}
                                                    fullWidth={true}
                                                    error={!!errors.newPassword && !!touched.newPassword}
                                                >
                                                    <Input
                                                        placeholder="New Password"
                                                        name="newPassword"
                                                        type="password"
                                                        id="newPassword"
                                                        value={values.newPassword}
                                                        onChange={handleChange}
                                                    />
                                                    <FormHelperText style={{ opacity: 0.3 }}>(min length of 6 characters)</FormHelperText>
                                                </FormControl>
                                                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                                                    <div className={classes.buttonLoaderWrapper}>
                                                        <Button disabled={isSubmitting} type="submit" color="primary">
                                                            Update Password
                                                        </Button>
                                                        {isSubmitting && <CircularProgress size={20} className={classes.buttonLoaderInline} />}
                                                    </div>
                                                </div>
                                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                                    {updateError && updateError.password && (
                                                        <DialogContentText id="alert-dialog-description" style={{ color: 'red', paddingTop: 15 }}>
                                                            {updateError.password}
                                                        </DialogContentText>
                                                    )}
                                                    {updateSuccess && updateSuccess.password && (
                                                        <DialogContentText id="alert-dialog-description" color="primary" style={{ paddingTop: 15 }}>
                                                            {updateSuccess.password}
                                                        </DialogContentText>
                                                    )}
                                                </div>
                                            </Grid>
                                        </ExpansionPanelDetails>
                                    </ExpansionPanel>
                                </DialogContent>
                            </form>
                        )}
                    </Formik>
                </Dialog>
            </div>
        );
    }

    private onSubmit = (values, { setSubmitting }) => {
        const { user } = this.props;
        const { teacherName, displayName, school } = values;

        user.actions
            .updateAccount({ teacherName, displayName, school })
            .then(() => {
                setSubmitting(false);
                this.onClose();
            })
            .catch((error: Error) => {
                setSubmitting(false);
                this.setState({ updateError: { account: error.message }, updateSuccess: {} });
            });
    };

    private onUpdateEmail = (values, { resetForm }) => {
        const { user } = this.props;

        user.actions
            .updateEmail(values)
            .then(() => {
                resetForm();
                this.setState({ updateError: {}, updateSuccess: { email: 'Successfully updated email' } });
            })
            .catch((error: Error) => {
                resetForm();
                this.setState({ updateError: { email: error.message }, updateSuccess: {} });
            });
    };

    private onUpdatePassword = (values, { resetForm }) => {
        const { user } = this.props;

        user.actions
            .updatePassword(values)
            .then(() => {
                resetForm();
                this.setState({ updateError: {}, updateSuccess: { password: 'Successfully updated password' } });
            })
            .catch((error: Error) => {
                resetForm();
                this.setState({ updateError: { password: error.message }, updateSuccess: {} });
            });
    };

    private toggleExpandEmail = () => {
        this.setState({ expandEmail: !this.state.expandEmail, expandPassword: false });
    };

    private toggleExpandPassword = () => {
        this.setState({ expandEmail: false, expandPassword: !this.state.expandPassword });
    };

    private onClose = () => {
        this.props.onClose();
        this.setState({ expandEmail: false, expandPassword: false, updateError: {}, updateSuccess: {} });
    };
}

const mapStoreToProps = (state: any) => {
    return {
        user: state.user,
    };
};

export default connect(
    mapStoreToProps,
    null
)(withMobileDialog<IProps>()(withStyles(appStyle)(EditAccount)));
