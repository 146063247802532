import moment from 'moment';
import queryString from 'query-string';
import { browserName, browserVersion, getUA, isMobile, mobileModel, mobileVendor, osName, osVersion } from 'react-device-detect';
import { Store } from 'redux';
import EarningWindow from 'shared-library-js';
// import ip from 'ip';

import MP from 'mixpanel';
// import MP from 'mixpanel-browser';
import { handleActions } from 'redux-actions';
import { EARNING_WINDOW_TYPES, WEEKDAY_MASK } from '../utils/constants';
// const mp = MP.init(`${process.env.REACT_APP_MIXPANEL_TOKEN}`);
// const mp = MP.init("442a141438536be5a4daad5a97284ab1", {debug: true, ignore_dnt: true /**opt_out_tracking_by_default: true*/});

const mp = MP.init('0a8022d636a95efd15995b4037cf6df1', {debug:true, protocol:'http', test:true});


class Mixpanel {
    public actions = {
        setUser: (uid: string, userInfo: any, authUser: any, groupData: any) => {
            const now = moment().toISOString();
            mp.people.set_once(uid, 'First Session', now);
            mp.people.set(uid, 'Last Session', now);
            mp.people.increment(uid, '# of Session', 1);

            const superProps = this.getUserSuperProps(uid, userInfo, authUser, groupData);
            // mp.track('Session', {...superProps, time: moment().subtract(5, 'seconds').toDate()});
            mp.track('Session', superProps);
            mp.people.set(authUser.uid, superProps);

            // console.info("Mixpanel Event: '" + 'Session' + "' with properties: ", superProps);
        },
        signUp: (uid: any, displayName: string, location: any, type: 'Email' | 'Google') => {
            const params = location ? queryString.parse(location.search) : undefined;
            console.info("Mixpanel userUID : '" + uid );
            this.abstractEvent(
                'Sign Up',
                {
                    distinct_id: uid,
                    displayName,
                    Channel: params ? params.channel : '',
                    Campaign: params ? params.campaign : '',
                    'Auth Type': type,
                },
                false
            );
        },
        login: (uid: string, type: 'Email' | 'Google') => {
            this.abstractEvent(
                'Log In',
                {
                    distinct_id: uid,
                    'Auth Type': type,
                },
                false
            );
        },
        logout: () => {
            this.abstractEvent('Log Out');
        },
        clickedShared: () => {
            this.abstractEvent('Clicked Shared');
        },
        clickedFamilyShare: () => {
            this.abstractEvent('Clicked Family Share');
        },
        shared: (Channel) => {
            this.abstractEvent('Shared', {
                Channel,
            });
        },
        sentGeofenceFeedback: () => {
            this.abstractEvent('Sent Geofence Feedback');
        },
        viewedClasses: () => {
            this.abstractEvent('Viewed Classes');
        },
        viewedDashboard: () => {
            this.abstractEvent('Viewed Dashboard');
        },
        dashboardFiltered: (input: any) => {
            const filterArr: string[] = [];
            if (input.groupFilter !== null) {
                filterArr.push('Class');
            }
            if (input.incentiveFilter !== null) {
                filterArr.push('Reward');
            }
            if (input.userFilter !== null) {
                filterArr.push('User');
            }

            if (filterArr.length > 0) {
                this.abstractEvent('Filtered Dashboard', {
                    'Filter Type': filterArr,
                });
            }
        },
        blockStudentsFromClass: (studentsBlocked: any[], classroomId: string) => {
            const properties: any = {
                'Class ID': classroomId,
            };

            properties[`Student Names`] = studentsBlocked.map((student) => student.name);
            properties[`Student IDs`] = studentsBlocked.map((student) => student.id);

            this.abstractEvent('Removed Students from Class', properties);
        },
        restoreStudentsFromClass: (studentsRestored: any[], classroomId: string) => {
            const properties: any = {
                'Class ID': classroomId,
            };

            properties[`Student Names`] = studentsRestored.map((student) => student.name);
            properties[`Student IDs`] = studentsRestored.map((student) => student.id);

            this.abstractEvent('Restored Students to Class', properties);
        },
        createdClass: (classroom: any, classroomId: string) => {
            const {
                attributes: { inviteCode, name, window, windowType, groupSize },
            } = classroom;
            const earningWindow = new EarningWindow(EarningWindow.standardWindow(), classroom);
            const startTime = moment(earningWindow.getStandardTimeString('start'), 'HH:mm');
            const endTime = moment(earningWindow.getStandardTimeString('end'), 'HH:mm');
            const properties: any = {
                'Class Name': name,
                'Class ID': classroomId,
                'Schedule Type': `${windowType.charAt(0).toUpperCase()}${windowType.slice(1)}`,
                'Invite Code': inviteCode,
                'Total Class Size': groupSize ? groupSize : 'NOT SET',
            };

            if (windowType === EARNING_WINDOW_TYPES.standard) {
                properties['Start Time'] = startTime.format('hh:mm A');
                properties['End Time'] = endTime.format('hh:mm A');
                properties['Days Of The Week'] = Object.keys(WEEKDAY_MASK).filter((key) => {
                    // tslint:disable-next-line:no-bitwise
                    return (window.dow & WEEKDAY_MASK[key]) !== 0;
                });
                properties['Class Minutes'] = moment.duration(endTime.diff(startTime)).asMinutes();
            }

            if (windowType === EARNING_WINDOW_TYPES.weekly || windowType === EARNING_WINDOW_TYPES.rotating) {
                window.rotation.slots.forEach((slot, index) => {
                    const key = `Day ${index + 1}`;
                    const start = moment(slot.start, 'HH:mm');
                    const end = moment(slot.end, 'HH:mm');

                    properties[`${key} Start Time`] = start.format('hh:mm A');
                    properties[`${key} End Time`] = end.format('hh:mm A');
                    properties[`${key} Minutes`] = moment.duration(end.diff(start)).asMinutes();
                });
            }

            if (windowType === EARNING_WINDOW_TYPES.rotating) {
                properties['Days in Rotation'] = window.rotation.numSlots;
                properties['Next School Day'] = moment(window.rotation.reference.date, 'YYYY-MM-DD').format('MM/DD/YYYY');
                properties['Rotation Start Day'] = window.rotation.reference.slot + 1;
            }

            this.abstractEvent('Created Class', properties);
        },
        editedClass: (oldClassroom: any, newClassroom: any, classroomId: string) => {
            const newAttributes = newClassroom.attributes;
            const oldAttributes = oldClassroom.attributes;
            const newGroupSize = newAttributes && newAttributes.groupSize ? newAttributes.groupSize : 'NOT SET';

            const editedFields: string[] = [];
            const properties: any = {
                'Class Name': newAttributes.name,
                'Class ID': classroomId,
                'Total Class Size': newGroupSize,
            };
            const newEarningWindow = new EarningWindow(EarningWindow.standardWindow(), { attributes: { ...newAttributes } });
            const oldEarningWindow = new EarningWindow(EarningWindow.standardWindow(), { attributes: { ...oldAttributes } });

            if (oldAttributes.windowType === 'days') {
                oldAttributes.windowType = 'standard';
            }

            if (oldAttributes.windowType === 'times') {
                oldAttributes.windowType = 'weekly';
            }

            if (oldAttributes.windowType === 'rotation') {
                oldAttributes.windowType = 'rotating';
            }

            if (newAttributes.name !== oldAttributes.name) {
                editedFields.push('Class Name');
                properties['Class Name'] = newAttributes.name;
                properties['Class Name - Old'] = oldAttributes.name;
            }

            const oldGroupSize = oldAttributes && oldAttributes.groupSize ? oldAttributes.groupSize : 'NOT SET';
            if (oldGroupSize !== newGroupSize) {
                editedFields.push('Total Class Size');
                properties['Total Class Size'] = newGroupSize;
                properties['Total Class Size - Old'] = oldGroupSize;
            }

            if (newAttributes.windowType !== oldAttributes.windowType) {
                editedFields.push('Schedule Type');
                properties['Schedule Type'] = `${newAttributes.windowType.charAt(0).toUpperCase()}${newAttributes.windowType.slice(1)}`;
                properties['Schedule Type - Old'] = `${oldAttributes.windowType.charAt(0).toUpperCase()}${oldAttributes.windowType.slice(1)}`;
            }

            if (newAttributes.windowType !== EARNING_WINDOW_TYPES.standard && oldAttributes.windowType !== EARNING_WINDOW_TYPES.standard) {
                if (newAttributes.windowType === EARNING_WINDOW_TYPES.rotating) {
                    if (newAttributes.window.rotation.numSlots !== oldAttributes.window.rotation.numSlots) {
                        properties['Days in Rotation'] = newAttributes.window.rotation.numSlots;
                        properties['Days in Rotation - Old'] = oldAttributes.window.rotation.numSlots;
                    }

                    if (newAttributes.window.rotation.reference.date !== oldAttributes.window.rotation.reference.date) {
                        properties['Next School Day'] = newAttributes.window.rotation.reference.date;
                        properties['Next School Day - Old'] = oldAttributes.window.rotation.reference.date;
                    }

                    if (newAttributes.window.rotation.reference.slot !== oldAttributes.window.rotation.reference.slot) {
                        properties['Rotation Start Day'] = newAttributes.window.rotation.reference.slot;
                        properties['Rotation Start Day - Old'] = oldAttributes.window.rotation.reference.slot;
                    }
                }

                newAttributes.window.rotation.slots.forEach((slot, index) => {
                    const key = `Day ${index + 1}`;
                    const start = moment(slot.start, 'HH:mm');
                    const end = moment(slot.end, 'HH:mm');

                    if (oldAttributes.window.rotation.slots[index] && oldAttributes.window.rotation.slots[index].start !== slot.start) {
                        properties[`${key} Start Time`] = start.format('hh:mm A');
                        properties[`${key} Start Time - Old`] = moment(oldAttributes.window.rotation.slots[index].start, 'HH:mm').format('hh:mm A');
                    }

                    if (oldAttributes.window.rotation.slots[index] && oldAttributes.window.rotation.slots[index].end !== slot.end) {
                        properties[`${key} End Time`] = end.format('hh:mm A');
                        properties[`${key} End Time - Old`] = moment(oldAttributes.window.rotation.slots[index].end, 'HH:mm').format('hh:mm A');
                    }
                });

                oldAttributes.window.rotation.slots.forEach((slot, index) => {
                    const key = `Day ${index + 1}`;
                    const start = moment(slot.start, 'HH:mm');
                    const end = moment(slot.end, 'HH:mm');

                    if (!newAttributes.window.rotation.slots[index]) {
                        properties[`${key} Start Time - Old`] = start.format('hh:mm A');
                        properties[`${key} End Time - Old`] = end.format('hh:mm A');
                    }
                });
            }

            if (newAttributes.windowType !== EARNING_WINDOW_TYPES.standard && oldAttributes.windowType === EARNING_WINDOW_TYPES.standard) {
                newAttributes.window.rotation.slots.forEach((slot, index) => {
                    const key = `Day ${index + 1}`;
                    const start = moment(slot.start, 'HH:mm');
                    const end = moment(slot.end, 'HH:mm');

                    properties[`${key} Start Time`] = start.format('hh:mm A');
                    properties[`${key} End Time`] = end.format('hh:mm A');
                    properties[`${key} Minutes`] = moment.duration(end.diff(start)).asMinutes();
                });

                if (newAttributes.windowType === EARNING_WINDOW_TYPES.rotating) {
                    properties['Days in Rotation'] = newAttributes.window.rotation.numSlots;
                    properties['Next School Day'] = moment(newAttributes.window.rotation.reference.date, 'YYYY-MM-DD').format('MM/DD/YYYY');
                    properties['Rotation Start Day'] = newAttributes.window.rotation.reference.slot + 1;
                }

                properties['Start Time - Old'] = moment(oldEarningWindow.getStandardTimeString('start'), 'HH:mm').format('hh:mm A');
                properties['End Time - Old'] = moment(oldEarningWindow.getStandardTimeString('end'), 'HH:mm').format('hh:mm A');
                properties['Days Of The Week - Old'] = Object.keys(WEEKDAY_MASK).filter((key) => {
                    // tslint:disable-next-line:no-bitwise
                    return (oldAttributes.window.dow & WEEKDAY_MASK[key]) !== 0;
                });
                properties['Class Minutes - Old'] = moment
                    .duration(
                        moment(oldEarningWindow.getStandardTimeString('end'), 'HH:mm').diff(moment(oldEarningWindow.getStandardTimeString('start'), 'HH:mm'))
                    )
                    .asMinutes();
            }

            if (newAttributes.windowType === EARNING_WINDOW_TYPES.standard && oldAttributes.windowType !== EARNING_WINDOW_TYPES.standard) {
                oldAttributes.window.rotation.slots.forEach((slot, index) => {
                    const key = `Day ${index + 1}`;
                    const start = moment(slot.start, 'HH:mm');
                    const end = moment(slot.end, 'HH:mm');

                    properties[`${key} Start Time - Old`] = start.format('hh:mm A');
                    properties[`${key} End Time - Old`] = end.format('hh:mm A');
                    properties[`${key} Minutes - Old`] = moment.duration(end.diff(start)).asMinutes();
                });

                if (oldAttributes.windowType === EARNING_WINDOW_TYPES.rotating) {
                    properties['Days in Rotation - Old'] = oldAttributes.window.rotation.numSlots;
                    properties['Next School Day - Old'] = moment(oldAttributes.window.rotation.reference.date, 'YYYY-MM-DD').format('MM/DD/YYYY');
                    properties['Rotation Start Day - Old'] = oldAttributes.window.rotation.reference.slot + 1;
                }

                properties['Start Time'] = moment(newEarningWindow.getStandardTimeString('start'), 'HH:mm').format('hh:mm A');
                properties['End Time'] = moment(newEarningWindow.getStandardTimeString('end'), 'HH:mm').format('hh:mm A');
                properties['Days Of The Week'] = Object.keys(WEEKDAY_MASK).filter((key) => {
                    // tslint:disable-next-line:no-bitwise
                    return (newAttributes.window.dow & WEEKDAY_MASK[key]) !== 0;
                });
                properties['Class Minutes'] = moment
                    .duration(
                        moment(newEarningWindow.getStandardTimeString('end'), 'HH:mm').diff(moment(newEarningWindow.getStandardTimeString('start'), 'HH:mm'))
                    )
                    .asMinutes();
            }

            if (newAttributes.windowType === EARNING_WINDOW_TYPES.standard && oldAttributes.windowType === EARNING_WINDOW_TYPES.standard) {
                if (newEarningWindow.getStandardTimeString('start') !== oldEarningWindow.getStandardTimeString('start')) {
                    properties['Start Time'] = moment(newEarningWindow.getStandardTimeString('start'), 'HH:mm').format('hh:mm A');
                    properties['Start Time - Old'] = moment(oldEarningWindow.getStandardTimeString('start'), 'HH:mm').format('hh:mm A');
                }

                if (newEarningWindow.getStandardTimeString('end') !== oldEarningWindow.getStandardTimeString('end')) {
                    properties['End Time'] = moment(newEarningWindow.getStandardTimeString('end'), 'HH:mm').format('hh:mm A');
                    properties['End Time - Old'] = moment(oldEarningWindow.getStandardTimeString('end'), 'HH:mm').format('hh:mm A');
                }

                if (newAttributes.window.dow !== oldAttributes.window.dow) {
                    properties['Days Of The Week'] = Object.keys(WEEKDAY_MASK).filter((key) => {
                        // tslint:disable-next-line:no-bitwise
                        return (newAttributes.window.dow & WEEKDAY_MASK[key]) !== 0;
                    });
                    properties['Days Of The Week - Old'] = Object.keys(WEEKDAY_MASK).filter((key) => {
                        // tslint:disable-next-line:no-bitwise
                        return (oldAttributes.window.dow & WEEKDAY_MASK[key]) !== 0;
                    });
                }

                if (
                    newEarningWindow.getStandardTimeString('start') !== oldEarningWindow.getStandardTimeString('start') ||
                    newEarningWindow.getStandardTimeString('end') !== oldEarningWindow.getStandardTimeString('end')
                ) {
                    properties['Class Minutes'] = moment
                        .duration(
                            moment(newEarningWindow.getStandardTimeString('end'), 'HH:mm').diff(
                                moment(newEarningWindow.getStandardTimeString('start'), 'HH:mm')
                            )
                        )
                        .asMinutes();
                    properties['Class Minutes - Old'] = moment
                        .duration(
                            moment(oldEarningWindow.getStandardTimeString('end'), 'HH:mm').diff(
                                moment(oldEarningWindow.getStandardTimeString('start'), 'HH:mm')
                            )
                        )
                        .asMinutes();
                }
            }

            this.abstractEvent('Edited Class', properties);
        },
        viewedClass: (classroom: any, classroomId: string) => {
            const earningWindow = new EarningWindow(EarningWindow.standardWindow(), classroom);
            let numIncentives = 0;
            let numStudents = 0;

            if (!classroom) {
                return;
            }
            if (classroom.incentives) {
                numIncentives = Object.keys(classroom.incentives).length;
            }
            if (classroom.students) {
                numStudents = Object.keys(classroom.students).length;
            }

            const startTime = moment(earningWindow.getStandardTimeString('start'), 'HH:mm');
            const endTime = moment(earningWindow.getStandardTimeString('end'), 'HH:mm');

            this.abstractEvent('Viewed Class', {
                'Class Name': classroom.attributes.name,
                'Class ID': classroomId,
                'Number of Rewards': numIncentives,
                'Number of Students': numStudents,
                'Class Minutes': moment.duration(endTime.diff(startTime)).asMinutes(),
            });
        },
        deletedClass: (classroom: any, classroomId: string) => {
            const earningWindow = new EarningWindow(EarningWindow.standardWindow(), classroom);
            let numIncentives = 0;
            let numStudents = 0;

            if (classroom.incentives) {
                numIncentives = Object.keys(classroom.incentives).length;
            }
            if (classroom.students) {
                numStudents = Object.keys(classroom.students).length;
            }

            const startTime = moment(earningWindow.getStandardTimeString('start'), 'HH:mm');
            const endTime = moment(earningWindow.getStandardTimeString('end'), 'HH:mm');

            this.abstractEvent('Deleted Class', {
                'Class Name': classroom.attributes.name,
                'Class ID': classroomId,
                'Number of Rewards': numIncentives,
                'Number of Students': numStudents,
                'Class Minutes': moment.duration(endTime.diff(startTime)).asMinutes(),
            });
        },
        createdReward: (reward: any, rewardId: string, classroomId: string, copied: boolean) => {
            const groupData = this.store.getState().groups.data;
            const classroom = groupData[classroomId];

            const properties = {
                'Reward Name': reward.name,
                'Reward ID': rewardId,
                'Class Name': classroom.attributes.name,
                'Class ID': classroomId,
                'Seconds Needed': parseInt(reward.duration),
                'Minutes Neeed': parseFloat(reward.duration) / 60.0,
                'Hours Needed': parseFloat(reward.duration) / 3600.0,
                'Reward Type': 'Individual',
            };

            let hasAdvancedSettings = false;
            if (reward.details) {
                hasAdvancedSettings = true;
                properties['Reward Details'] = reward.details;
            }

            if (reward.limit) {
                hasAdvancedSettings = true;
                properties['Usage Limit'] = parseInt(reward.limit);
            }

            if (reward.expiration) {
                const expirationSplit = reward.expiration.split(' ');
                const expirationDate = expirationSplit[0];
                const expirationTime = expirationSplit[1];
                hasAdvancedSettings = true;
                properties['Expiration'] = reward.expiration;
                properties['Expiration Date'] = expirationDate;
                properties['Expiration Time'] = expirationTime;
            }

            this.abstractEvent('Created Reward', {
                ...properties,
                'Has advanced settings': hasAdvancedSettings,
                Copied: copied,
            });
        },
        createdClassWideReward: (reward: any, rewardId: string, classroomId: string, copied: boolean) => {
            const groupData = this.store.getState().groups.data;
            const classroom = groupData[classroomId];

            const properties = {
                'Reward Name': reward.name,
                'Reward ID': rewardId,
                'Class Name': classroom.attributes.name,
                'Class ID': classroomId,
                'Class Percent': `${((reward.required / classroom.attributes.groupSize) * 100) / 100}%`,
                'Reward Times': reward.iterations.map((iteration) => {
                    return {
                        'Duration Seconds': iteration.duration,
                        'Duration Minutes': Math.round(iteration.duration / 60),
                        'Duration Hours': Math.round(iteration.duration / 3600),
                        'Start Date': moment.unix(iteration.start).toISOString(),
                        'End Date': moment.unix(iteration.expiration).toISOString(),
                    };
                }),
                'Reward Type': 'Class Wide',
            };

            if (reward.details) {
                properties['Reward Details'] = reward.details;
            }

            this.abstractEvent('Created Reward', {
                ...properties,
            });
        },
        editedReward: (oldReward: any, newReward: any, rewardId: string, classroomId: string) => {
            const groupData = this.store.getState().groups.data;
            const classroom = groupData[classroomId];

            const properties = {
                'Reward Name': newReward.name,
                'Reward ID': rewardId,
                'Class Name': classroom.attributes.name,
                'Class ID': classroomId,
                'Seconds Needed': parseInt(newReward.duration),
                'Minutes Neeed': parseFloat(newReward.duration) / 60.0,
                'Hours Needed': parseFloat(newReward.duration) / 3600.0,
            };

            const editedFields: string[] = [];
            if (oldReward.name !== newReward.name) {
                editedFields.push('Reward Name');
                properties['Reward Name - Old'] = oldReward.name;
            }
            if (oldReward.duration !== newReward.duration) {
                editedFields.push('Duration');
                properties['Seconds Needed - Old'] = parseInt(oldReward.duration);;
                properties['Minutes Needed - Old'] = parseFloat(oldReward.duration) / 60.0;
                properties['Hours Needed - Old'] = parseFloat(oldReward.duration) / 3600.0;
            }
            if (oldReward.details !== newReward.details) {
                editedFields.push('Reward Details');
                properties['Reward Details - Old'] = oldReward.details;
            }
            if (oldReward.limit !== newReward.limit) {
                editedFields.push('Usage Limit');
                properties['Usage Limit - Old'] = parseInt(oldReward.limit);
            }
            if (oldReward.expiration !== newReward.expiration) {
                editedFields.push('Expiration');
            }

            let oldHasAdvancedSettings = false;
            if (oldReward.details) {
                oldHasAdvancedSettings = true;
            }
            if (oldReward.limit) {
                oldHasAdvancedSettings = true;
            }
            if (oldReward.expiration) {
                oldHasAdvancedSettings = true;
            }
            if (oldReward.expiration && editedFields.includes('Expiration')) {
                const expirationSplit = oldReward.expiration.split(' ');
                const expirationDate = expirationSplit[0];
                const expirationTime = expirationSplit[1];
                properties['Expiration - Old'] = oldReward.expiration;
                properties['Expiration Date - Old'] = expirationDate;
                properties['Expiration Time - Old'] = expirationTime;
            }

            let newHasAdvancedSettings = false;
            if (newReward.details) {
                newHasAdvancedSettings = true;
                properties['Reward Details'] = newReward.details;
            }

            if (newReward.limit) {
                newHasAdvancedSettings = true;
                properties['Usage Limit'] = parseInt(newReward.limit);
            }

            if (newReward.expiration) {
                const expirationSplit = newReward.expiration.split(' ');
                const expirationDate = expirationSplit[0];
                const expirationTime = expirationSplit[1];
                newHasAdvancedSettings = true;
                properties['Expiration'] = newReward.expiration;
                properties['Expiration Date'] = expirationDate;
                properties['Expiration Time'] = expirationTime;
            }

            if (oldHasAdvancedSettings !== newHasAdvancedSettings) {
                editedFields.push('Has advanced Settings');
                properties['Has advanced settings - Old'] = oldHasAdvancedSettings;
            }

            this.abstractEvent('Edited Reward', {
                ...properties,
                'Has advanced settings': newHasAdvancedSettings,
                'Edited Fields': editedFields,
            });
        },
        deletedReward: (reward: any, rewardId: string, classroomId: string) => {
            const groupData = this.store.getState().groups.data;
            const classroom = groupData[classroomId];

            this.abstractEvent('Deleted Reward', {
                'Reward Name': reward.name,
                'Reward ID': rewardId,
                'Class Name': classroom.attributes.name,
                'Class ID': classroomId,
            });
        },
        toggledDashboardTab: (tab) => {
            const captalized = tab.replace(/^./, (letter) => letter.toUpperCase());

            this.abstractEvent(`Clicked In ${captalized} Tab`);
        },
        updatedAccount: (oldUser: any, displayName: string, teacherName: string, school: any, email: string) => {
            const editedFields: string[] = [];
            const properties = {
                'Display Name': displayName,
                'Teacher Name': teacherName,
                'School ID': school.id,
                Email: email,
            };

            if (oldUser.displayName !== displayName) {
                editedFields.push('Display Name');
                properties['Display Name - Old'] = oldUser.displayName;
            }

            if (oldUser.teacherName !== teacherName) {
                editedFields.push('Teacher Name');
                properties['Teacher Name - Old'] = oldUser.teacherName;
            }

            if (oldUser.school.id !== school.id) {
                editedFields.push('School ID');
                properties['School ID - Old'] = oldUser.school.id;
            }

            this.abstractEvent('Edited Account Information', {
                ...properties,
                'Edited Fields': editedFields,
            });
        },
        updatedEmail: (email: string, oldEmail: string) => {
            this.abstractEvent('Edited Email', {
                Email: email,
                'Email - Old': oldEmail,
            });
        },
        updatedPassword: () => {
            this.abstractEvent('Edited Password');
        },
        clickedTooltip: (id: string, page: string, text: string) => {
            this.abstractEvent('Clicked Tooltip', {
                'Tooltip ID': id,
                Page: page,
                'Tooltip Copy': text,
            });
        },
        clickedStudents: () => {
            this.abstractEvent('Clicked Students');
        },
        clickedLearnMore: () => {
            this.abstractEvent('Invite Code - Learn More Clicked');
        },
        clickedInviteStudent: () => {
            this.abstractEvent('Invite Student Clicked');
        },
        clickedGoogleClassroomShare: () => {
            this.abstractEvent('Clicked Google Classroom Share');
        },
        clickedGoogleClassroomShareForHome: () => {
            this.abstractEvent('Clicked Google Classroom Share', {
                'Via': 'Home'
            });
        },
        sharedGoogleClassroomsForHome: () => {
            this.abstractEvent('Shared to Google Classroom', {
                'Via': 'Home'
            });
        },
        sharedGoogleClassrooms: () => {
            this.abstractEvent('Shared to Google Classroom');
        },
        sharedToRemind: () => {
            this.abstractEvent('Shared to Remind');
        },
        clickedHelpCenter: () => {
            this.abstractEvent('Viewed Help Cener');
        },
        copiedRewards: (num: number) => {
            this.abstractEvent('Copied Rewards', {
                'Number of Rewards': num,
            });
        },
        clickedPrintHandout: () => {
            this.abstractEvent('Clicked Print Student Handout');
        },
        markedAsReceived: (
            checked: boolean,
            date: string,
            incentiveId: string,
            incentiveName: string,
            classroomId: string,
            classroomName: string,
            type: string
        ) => {
            this.abstractEvent('Marked Completed Receipt', {
                'Incentive ID': incentiveId,
                'Incentive Name': incentiveName,
                'Classroom ID': classroomId,
                'Classroom Name': classroomName,
                'Date Completed': date,
                'Incentive Type': type,
                Marked: checked,
            });
        },
        markedAllAsReceived: (numReceipts: number, type: string) => {
            this.abstractEvent('Marked All Completed Receipts', {
                'Number Marked': numReceipts,
                'Incentive Type': type,
            });
        },
        viewedClassDetails: () => {
            this.abstractEvent('Viewed Class Details');
        },
        changedDate: () => {
            this.abstractEvent('Changed Date');
        },

        classRosterEntered: () => {
            this.abstractEvent('Class Roster Entered');
        },
        viewedClasswideReminder: () => {
            this.abstractEvent('Viewed Class Wide Reminder');
        },
        classwideReminderSent: (type: string) => {
            this.abstractEvent(`Class Wide Reminder Sent - ${type}`);
        },
        // generic funcs
        sendEventWithProps: (event, props) => {
            this.abstractEvent(event, { ...props });
        },
        sendEvent: (event) => {
            this.abstractEvent(event);
        },
        sendEventWithChangedData: (event, changedData) => {
            const editedFields = Object.keys(changedData).filter((key) => {
                return changedData[key].new !== changedData[key].old;
            });
            const props = { 'Edited Fields': editedFields };

            editedFields.forEach((key) => {
                props[key] = changedData[key].new;
                props[`${key} - Old`] = changedData[key].old;
            });

            this.abstractEvent(event, { ...props });
        },
    };

    public initialState = {
        actions: this.actions,
    };

    public reducer = handleActions<any>({}, this.initialState);

    private store: Store;

    public setStore = (store) => {
        this.store = store;
    };

    private getUserSuperProps = (uid: string, userInfo: any, authUser: any, groupData: any) => {
        const earningWindow = this.store.getState().earningWindow.data;
        let city = 'NA';
        let state = 'NA';
        if (userInfo.school && userInfo.school.cityState) {
            city = userInfo.school.cityState
                .split(', ')
                .slice(0, -1)
                .join(', ');
            state = userInfo.school.cityState.split(', ').slice(-1)[0];
        }
        let groupCount = 0;
        let incentiveCount = 0;

        Object.keys(groupData).forEach((groupId) => {
            groupCount++;
            const incentives = groupData[groupId].incentives;
            if (incentives) {
                Object.keys(incentives).forEach(() => {
                    incentiveCount++;
                });
            }
        });

        const props = {
            distinct_id: uid,
            $email: authUser.email,
            $name: userInfo.displayName,
            'Teacher Name': userInfo.teacherName,
            Mobile: isMobile,
            'Browser Name': browserName,
            'Browser Version': browserVersion,
            'Mobile Vendor': mobileVendor,
            'Mobile Model': mobileModel,
            'OS Version': osVersion,
            'OS Name': osName,
            'User Agent': getUA,
            'Total Classes': groupCount,
            'Total Rewards': incentiveCount,
            'Native': false,
        };

        if (userInfo.school) {
            props['School Name'] = userInfo.school.name;
            props['School ID'] = userInfo.school.id;
            props['School City'] = city;
            props['School State'] = state;
        }

        if (earningWindow) {
            props['Schedule Type'] = earningWindow.type;
        }

        if (process.env.REACT_APP_ENV === 'beta') {
            props['Beta'] = true;
        }

        return props;
    };

    private abstractEvent = (name: string, properties: any = {}, hasUser: boolean = true) => {
        const appVersion = window.appVersion ? window.appVersion : 'unknown';
        console.info("Mixpanel user boolean : '" + hasUser );

        let superProps = {
            // $ip: ip.address(),
            'Day of Week': moment().format('dddd'),
        };

        if (hasUser) {
            const { userInfo, authUser } = this.store.getState().user.data;
            const groupData = this.store.getState().groups.data;
            console.info("Mixpanel hasUser : '" + userInfo );
            superProps = { ...superProps, ...this.getUserSuperProps(authUser.uid, userInfo, authUser, groupData) };
            mp.people.set(authUser.uid, superProps);
        }

        const mpProps = { ...superProps, ...properties, 'App Version': appVersion };
        mp.track(name, mpProps);
        console.info("Mixpanel Event: '" + name + "' with properties: ", mpProps);
    };
}

export default Mixpanel;
