import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

const Admin = (props) => {
    return (
        <SvgIcon viewBox="0 0 69 47" {...props}>
            <g id="School-View" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="school-view-" transform="translate(-685.000000, -1001.000000)" fillRule="nonzero">
                    <g id="Group-3-Copy" transform="translate(621.000000, 956.000000)">
                        <g id="admin" transform="translate(64.000000, 45.000000)">
                            <path
                                d="M5.81284775,46.85 L0.309559939,43.25 C-0.103186646,42.95 -0.103186646,42.2 0.309559939,41.9 L5.81284775,38.3 C6.08801214,38 6.36317653,38 6.50075873,38 L63.8725341,38 C66.2114315,38 68,39.95 68,42.5 C68,45.05 66.2114315,47 63.8725341,47 L6.50075873,47 C6.36317653,47 6.08801214,47 5.81284775,46.85 Z"
                                id="Shape"
                                fill="#D9DCE1"
                            />
                            <path
                                d="M64.845991,38 L62,38 L62,47 L64.9815144,47 C67.4209352,47 69.4537859,44.45 68.9116924,41.6 C68.6406457,39.5 66.8788417,38 64.845991,38 Z"
                                id="Shape"
                                fill="#00895E"
                            />
                            <path
                                d="M22,26.5700935 L20.4827586,19.4205607 C20.3448276,18.8598131 19.6551724,18.8598131 19.5172414,19.4205607 L18,26.5700935 L18.4137931,34 L21.5862069,33.8598131 L22,26.5700935 Z"
                                id="Shape"
                                fill="#00895E"
                            />
                            <path
                                d="M12.6779661,16 C12.2711864,16 12,15.7310924 12,15.3277311 C12.2711864,13.3109244 12.9491525,6.05042017 13.0847458,5.64705882 C13.7627119,2.42016807 16.6101695,0 20,0 C23.5254237,0 26.5084746,2.55462185 27.0508475,5.91596639 C27.0508475,6.18487395 27.7288136,13.3109244 28,15.3277311 C28,15.7310924 27.7288136,16 27.3220339,16 L12.6779661,16 Z"
                                id="Shape"
                                fill="#03D794"
                            />
                            <path
                                d="M25,8.62886598 C25,12.814433 22.6025641,17 19.5,17 C16.3974359,17 14,12.814433 14,8.62886598 C14,5.59793814 16.3974359,3 19.5,3 C22.6025641,3 25,5.45360825 25,8.62886598 Z"
                                id="Shape"
                                fill="#D9DCE1"
                            />
                            <path
                                d="M26.6018402,7.17994544 C24.896456,8.9312484 21.2014569,7.55522465 18.7854959,6.05410783 C16.3695349,4.55299101 16.7958809,2.92678113 17.7906884,1.80094352 C18.7854959,0.550012838 21.4856876,0.800198974 23.9016486,2.30131579 C26.3176095,3.80243261 27.7387631,6.05410783 26.6018402,7.17994544 Z"
                                id="Shape"
                                fill="#03D794"
                            />
                            <path
                                d="M18.7343434,5.12 C18.7343434,5.12 15.359259,5.72 14.3748594,8 C13.3904598,7.16 14.6561164,4.52 14.6561164,4.52 C14.6561164,4.52 16.6249156,2 17.3280582,2 C18.0312008,2 20,2.24 20,2.24 L18.7343434,5.12 Z"
                                id="Shape"
                                fill="#03D794"
                            />
                            <path
                                d="M33,38 C33,35.6870748 33,36.0952381 32.7216495,33.3741497 C32.5824742,30.7891156 32.3041237,28.3401361 31.7474227,25.6190476 C31.4690722,24.2585034 31.0515464,22.3537415 30.3556701,20.8571429 C30.3556701,20.7210884 30.2164948,20.7210884 30.2164948,20.585034 C29.7989691,19.7687075 29.242268,19.0884354 28.5463918,18.6802721 L22.1443299,18 L23.1185567,24.3945578 L19.3608247,27.9319728 L15.6030928,24.3945578 L16.5773196,18 L10.314433,18.6802721 C9.47938144,19.0884354 8.78350515,19.9047619 8.50515464,20.8571429 C8.50515464,20.8571429 8.50515464,20.8571429 8.50515464,20.9931973 C7.80927835,22.4897959 7.53092784,24.2585034 7.25257732,25.6190476 C6.69587629,28.2040816 6.41752577,30.7891156 6.27835052,33.3741497 C6.13917526,36.0952381 6,35.6870748 6,38 L33,38 Z"
                                id="Shape"
                                fill="#03D794"
                            />
                            <path
                                d="M50,24.5 L48.4827586,17.4166667 C48.3448276,16.8611111 47.6551724,16.8611111 47.5172414,17.4166667 L46,24.5 L46.4137931,32 L49.5862069,31.8611111 L50,24.5 Z"
                                id="Shape"
                                fill="#00895E"
                            />
                            <path
                                d="M53,6.57142857 C53,10.7142857 50.4615385,15 47.5,15 C44.5384615,15 42,10.8571429 42,6.57142857 C42,3.42857143 44.5384615,1 47.5,1 C50.4615385,1 53,3.57142857 53,6.57142857 Z"
                                id="Shape"
                                fill="#D9DCE1"
                            />
                            <path
                                d="M62,38 C62,34.975 62,35.3875 61.7171717,32.3625 C61.5757576,29.75 61.2929293,27.1375 60.7272727,24.525 C60.4444444,23.15 60.020202,21.0875 59.3131313,19.575 C59.3131313,19.4375 59.1717172,19.4375 59.1717172,19.3 C58.7474747,18.475 58.1818182,17.7875 57.4747475,17.375 L52.8080808,16 L50.1212121,25.2125 L48,31.95 L45.8787879,25.2125 L43.1919192,16 L38.5252525,17.375 C37.6767677,17.7875 36.969697,18.6125 36.6868687,19.575 C36.6868687,19.575 36.6868687,19.575 36.6868687,19.7125 C35.979798,21.225 35.6969697,23.0125 35.2727273,24.3875 C34.7070707,27.1375 34.4242424,29.6125 34.2828283,32.225 C34,35.3875 34,34.975 34,38 L62,38 Z"
                                id="Shape"
                                fill="#03D794"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
};

export default Admin;
