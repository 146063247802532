import { Avatar, Grid, Typography } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { Line } from 'rc-progress';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import BlankAvatar from '../../../components/BlankAvatar';
import appStyle from '../../App.style';

interface IProps {
    classes: any;
    name: string;
    incentiveName: string;
    pic: string;
    progress: any;
    groups: any;
    incentiveProgress: any;
}

class InProgressIncentiveCell extends React.Component<IProps> {
    public render() {
        const { classes, name, incentiveName, progress, pic } = this.props;

        return (
            <Grid item={true} sm={12} xs={12} className={classes.pageRow}>
                <div className={classes.inProgressIncentiveCellContainer}>
                    <Grid container={true}>
                        <Grid item={true} xs={isMobile ? 2 : 1} className={classes.profilePicContainer}>
                            {this.renderAvatar(name, pic)}
                        </Grid>
                        <Grid item={true} xs={isMobile ? 5 : 7} style={{ paddingLeft: 16 }}>
                            <Grid container={true} direction='column'>
                                <Grid item={true}>
                                    <Typography component="span" className={classes.completedIncentiveCellTitle}>
                                        {name}
                                    </Typography>
                                </Grid>
                                <Grid item={true}>
                                    <Typography component="span" className={classes.completedIncentiveCellIncentiveName}>
                                        {incentiveName}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item={true} xs={isMobile ? 5 : 4} className={classes.inProgressIncentiveCellTime} style={{ paddingRight: 16 }}>
                            <Typography component="p" className={classes.inProgressIncentiveCellClassroomName}>
                                {progress.classroomName}
                            </Typography>
                            <Typography component="p" className={classes.completedIncentiveCellDate}>
                                {this.getIncentiveProgressString(progress)}
                            </Typography>
                        </Grid>
                        <Grid item={true} xs={12} style={{ marginBottom: -4, paddingTop: 18 }}>
                            <Line percent={this.getIncentiveProgressPercentage(progress) * 100} strokeWidth={0.75} strokeColor="#00D793" strokeLinecap="butt" />
                        </Grid>
                    </Grid>
                </div>
            </Grid>
        );
    }

    private renderAvatar(name, imageUrl) {
        const style: any = { border: '1px solid #ececec' };

        if (imageUrl) {
            return <Avatar alt="Remy Sharp" src={imageUrl} style={style} />;
        }

        return <BlankAvatar name={name} style={style} />;
    }

    private getIncentiveProgressString = (progress: any) => {
        const { data } = this.props.groups;
        const { classroomId, incentiveId } = progress;
        const incentiveDuration = data[classroomId].incentives[incentiveId].duration;
        const progressDuration = progress.current;
        const progressDurationMinutes = Math.floor(progressDuration / 60);
        const incentiveDurationMinutes = Math.floor(incentiveDuration / 60);
        const progressDurationHours = Math.floor(progressDuration / 3600);
        const incentiveDurationHours = Math.floor(incentiveDuration / 3600);

        if (incentiveDurationMinutes < 120) {
            return `${progressDurationMinutes} / ${incentiveDurationMinutes} MIN${incentiveDurationMinutes > 1 ? 'S' : ''}`;
        } else {
            return `${progressDurationHours} / ${incentiveDurationHours} HOUR${incentiveDurationHours > 1 ? 'S' : ''}`;
        }
    };

    private getIncentiveProgressPercentage = (progress: any) => {
        const { data } = this.props.groups;
        const { classroomId, incentiveId } = progress;
        const incentiveDurationHours = data[classroomId].incentives[incentiveId].duration;
        const progressHours = progress.current;

        return progressHours / incentiveDurationHours;
    };
}

const mapStoreToProps = (state: any) => {
    return {
        groups: state.groups,
        incentiveProgress: state.incentiveProgress,
    };
};

export default connect(
    mapStoreToProps,
    null
)(withStyles(appStyle)(InProgressIncentiveCell));
