import { Button, Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { CheckBoxOutlineBlank, CheckBoxRounded } from '@material-ui/icons';
import moment from 'moment';
import React, { PureComponent } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import Text from '../../../components/Text';
import { IGroupIncentive, IIteration } from '../../../utils/types';
import appStyle from '../../App.style';

interface IProps {
    classes: any;
    actions: {
        groupProgresses: any;
    };
    data: {
        groupProgresses: any;
    };
    groupIncentive: IGroupIncentive;
    iteration: IIteration;
    toggleClasswideModal: (shouldShow: boolean, modalIteration: IIteration | undefined, modalGroupIncentive: IGroupIncentive | undefined) => void;
}

class GroupIncentiveReceipts extends PureComponent<IProps> {
    public render() {
        const { classes, toggleClasswideModal, groupIncentive } = this.props;
        const currentIteration = this.props.iteration;
        const { groupProgresses } = this.props.data;

        if (groupIncentive && groupIncentive.iterations) {
            return groupIncentive.iterations
                .map((iteration: any, index: number) => {
                    const { groupProgressesId } = iteration;
                    const groupIncentiveId = iteration.id;
                    const { received, completion, classroomId } = groupProgresses[groupProgressesId] ? groupProgresses[groupProgressesId] : false;
                    const dateFormat = isMobile ? 'MM/DD/YY h:mmA' : 'ddd, MMM D, YYYY h:mmA';
                    const isCurrent = iteration === currentIteration;
                    const mostRecent = iteration === this.getMostRecentIteration(this.props.groupIncentive.iterations);

                    if (completion || mostRecent) {
                        return (
                            <Grid
                                key={index}
                                container={true}
                                item={true}
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                className={classes.dashboardCompletedGroupReceiptClasswide}
                            >
                                <Grid item={true}>
                                    <Button
                                        onClick={() => toggleClasswideModal(true, iteration, groupIncentive)}
                                        variant="text"
                                        size="small"
                                        style={{ textTransform: 'none', padding: '16px 32px 16px 32px' }}
                                    >
                                        <Text
                                            fontSize={12}
                                            light={completion ? true : false}
                                            semiBold={isCurrent ? true : false}
                                            style={{ color: completion ? '#000' : '#00D793' }}
                                        >
                                            {completion ? moment.unix(completion).format(dateFormat) : 'Reward in Progress'}
                                        </Text>
                                    </Button>
                                </Grid>
                                <Grid item={true} style={{ paddingRight: 16 }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={!!received}
                                                onChange={(event, checked) =>
                                                    this.handleReceiptReceived({ received, groupProgressesId, groupIncentiveId, classroomId }, 'classwide')
                                                }
                                                color={received ? 'primary' : 'default'}
                                                checkedIcon={<CheckBoxRounded />}
                                                icon={<CheckBoxOutlineBlank />}
                                                style={{ opacity: received ? 1 : 0.35 }}
                                                disabled={!completion}
                                            />
                                        }
                                        label={received ? 'reward received' : completion ? 'not received' : 'not completed'}
                                        labelPlacement="start"
                                        style={{ paddingRight: 8 }}
                                        classes={{ label: received ? classes.receivedChecked : classes.receivedNotChecked }}
                                        className={classes.dashboardCompletedGroupCheckboxHover}
                                    />
                                </Grid>
                            </Grid>
                        );
                    }

                    return undefined;
                })
                .reverse();
        }

        return undefined;
    }

    private handleReceiptReceived = (item: any, type = null) => {
        const {
            actions: { groupProgresses },
        } = this.props;

        if (type === 'classwide') {
            groupProgresses.markGroupIncentivesReceived([
                { received: item.received, id: item.groupProgressesId, groupIncentiveId: item.groupIncentiveId, classroomId: item.classroomId },
            ]);
        }
    };

    private getMostRecentIteration = (iterations: IIteration[]): IIteration => {
        const currentDate = moment();

        if (iterations.length < 1) {
            return undefined;
        }

        let iteration: IIteration = iterations.find((iter) => {
            return currentDate > moment.unix(iter.start) && currentDate < moment.unix(iter.expiration);
        });

        if (iteration === undefined) {
            iteration = iterations[0];
            iterations.forEach((iter, index) => {
                if (moment.unix(iter.expiration) < currentDate) {
                    iteration = iterations[index];
                }
            });
        }

        return iteration;
    };
}

const mapStoreToProps = (state: any) => {
    return {
        actions: {
            groupProgresses: state.groupProgresses.actions,
        },
        data: {
            groupProgresses: state.groupProgresses.data,
        },
    };
};

export default connect(
    mapStoreToProps,
    null
)(withStyles(appStyle)(GroupIncentiveReceipts));
