import { Dialog, DialogContent, Grid, List, ListItem, withStyles } from '@material-ui/core';
import React from 'react';
import { isMobile } from 'react-device-detect';
import CloseButton from '../../components/CloseButton';
import Text from '../../components/Text';
import appStyle from '../App.style';

interface IProps {
    classes: any;
    onClose: any;
    teacher: any;
}

interface IState {
    show: boolean;
}

class Teacher extends React.Component<IProps, IState> {
    public render() {
        const { classes, onClose, teacher } = this.props;
        const time = teacher.time.split(':');

        return (
            <Dialog open={true} fullScreen={isMobile} maxWidth="sm" fullWidth={true}>
                <DialogContent style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <Grid container={true} direction="column" style={{ paddingLeft: 24, paddingRight: 24 }}>
                        <Grid item={true}>
                            <CloseButton onClick={onClose} />
                        </Grid>
                    </Grid>
                    <Grid
                        container={true}
                        direction="column"
                        className={classes.pageHeader}
                        style={isMobile ? { paddingLeft: 32, paddingRight: 32 } : undefined}
                    >
                        <Grid container={true} item={true} justifyContent="space-between" alignItems="center">
                            <Text gutterBottom={true} isTitle={true}>
                                {teacher.displayName}
                            </Text>
                            <Text light={true} fontSize={14}>
                                {`#${teacher.index + 1} out of ${teacher.length}`}
                            </Text>
                        </Grid>
                        <Text light={true} fontSize={14} gutterBottom={true} style={{ marginTop: 32 }}>
                            Student phone-free time
                        </Text>
                        <Text color="primary" fontSize={24}>
                            {`${time[0]} hrs ${time[1]} mins ${time[2]} secs`}
                        </Text>
                    </Grid>
                    <Grid container={true} direction="column" className={classes.pageBody} style={{ paddingTop: 32 }}>
                        {this.renderClassrooms()}
                    </Grid>
                </DialogContent>
            </Dialog>
        );
    }

    private renderClassrooms = () => {
        const { classes, teacher } = this.props;

        return Object.keys(teacher.classroomsDetail).map((classroomKey, classroomIndex) => {
            const classroom = teacher.classroomsDetail[classroomKey];

            return (
                <List
                    key={classroomIndex}
                    subheader={
                        <ListItem className={classes.classroomIncentiveHeader}>
                            <Text fontSize={14} bold={true}>
                                {classroom.name}
                            </Text>
                            <Text fontSize={10} style={{ textTransform: 'uppercase' }}>{`${classroom.students} students`}</Text>
                        </ListItem>
                    }
                    className={classes.classroomList}
                >
                    {Object.keys(classroom.incentives).map((incentiveKey, incentiveIndex) => {
                        const incentive = classroom.incentives[incentiveKey];
                        const hours = Math.floor(incentive.duration / 3600);
                        const minutes = Math.floor((incentive.duration - hours * 3600) / 60);
                        const hoursText = hours > 0 ? `${hours} HOUR${hours > 1 ? 'S' : ''}` : '';
                        const minutesText = minutes > 0 ? `${hours > 0 ? ', ' : ''}${minutes} MIN${minutes > 1 ? 'S' : ''}` : '';

                        return (
                            <ListItem key={incentiveIndex} className={classes.classroomIncentiveCell}>
                                <Text  fontSize={12}>
                                    {incentive.name}
                                </Text>
                                <Text  light={true} fontSize={10}>
                                    {`${hoursText}${minutesText}`}
                                </Text>
                            </ListItem>
                        );
                    })}
                </List>
            );
        });
    };
}

export default withStyles(appStyle)(Teacher);
