import { Button, FormControl, Grid, Input, InputLabel, Typography } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { Form, Formik } from 'formik';
import React from 'react';
import EarningWindow, { IEarningWindow } from 'shared-library-js';
import Submit from '../../components/Submit';
import Text from '../../components/Text';
import Time from '../../components/Time';
import Tooltip from '../../components/Tooltip';
import { ROTATION_TYPES } from '../../utils/constants';
import { ConfirmRotatingFormSchema } from '../../utils/validations';
import appStyle from '../App.style';

interface IProps {
    classes: any;
    earningWindow: IEarningWindow;
    group: any;
    groupId: string;
    onClose: any;
    onSubmit: any;
}

class RotatingForm extends React.Component<IProps> {
    public render() {
        const { classes, onClose } = this.props;

        return (
            <Formik enableReinitialize={true} initialValues={this.getInitialValues()} validationSchema={ConfirmRotatingFormSchema} onSubmit={this.handleSubmit}>
                {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                    <Form noValidate={true}>
                        <Grid container={true} justifyContent="center" alignItems="center">
                            <Grid className={classes.authContainer} style={{ paddingTop: 32 }}>
                                <FormControl margin="normal" fullWidth={true} error={!!errors.name && !!touched.name}>
                                    <InputLabel shrink={true} htmlFor="name">
                                        Class Name
                                    </InputLabel>
                                    <Input
                                        inputProps={{ 'data-hj-whitelist': true }}
                                        placeholder="e.g. First Period, Spanish 2, 3rd Period English"
                                        id="name"
                                        autoFocus={false}
                                        name="name"
                                        value={values.name}
                                        readOnly={true}
                                    />
                                    <Tooltip isInput={true} page="Confirm Rotating Class" id="className">
                                        This is what your class will be labeled in the app. Name your class someting that your students will easily recognize.
                                    </Tooltip>
                                </FormControl>
                                {this.renderTimes(values, errors, setFieldValue)}
                            </Grid>
                            <Grid container={true} item={true} xs={12} style={{ paddingTop: 24 }} justifyContent="center">
                                <Submit loading={isSubmitting} id="submit-button" className={classes.saveButton}>
                                    Save
                                </Submit>
                            </Grid>
                            <Grid container={true} item={true} xs={12} style={{ paddingTop: 32 }} justifyContent="center">
                                <Text color="primary" fontSize={14} bold={true} onClick={onClose}>
                                    Back to schedule settings
                                </Text>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        );
    }

    private renderTimes = (values, errors, setFieldValue) => {
        const { group } = this.props;
        const earningWindow = new EarningWindow(values.earningWindow);
        const { labelType, slots } = values.earningWindow;
        const title = group ? 'Edit Class' : 'Create Class';

        return slots.map((slot, index) => {
            const label = labelType === ROTATION_TYPES.numeric ? `${index + 1}` : String.fromCharCode(index + 65);
            const error =
                errors && errors.earningWindow && errors.earningWindow.slots && errors.earningWindow.slots[index]
                    ? errors.earningWindow.slots[index].start
                    : undefined;

            return (
                <FormControl key={index} margin="normal" fullWidth={true} style={{ paddingTop: 24 }} error={!!error}>
                    <Grid container={true} justifyContent="space-between" alignItems="center" wrap="nowrap">
                        <Typography style={{ fontWeight: 500 }}>Day {label}</Typography>
                        <Button
                            color="primary"
                            size="small"
                            style={{ textTransform: 'none', fontWeight: 500 }}
                            disableRipple={true}
                            onClick={() => this.handleToggleEnabled(index, errors, earningWindow, setFieldValue)}
                        >
                            {slot.enabled ? 'No class?' : 'Add class time'}
                        </Button>
                    </Grid>
                    <Grid container={true} justifyContent="center" alignItems={slot.enabled ? 'center' : 'flex-start'} wrap="nowrap" style={{ height: '100%' }}>
                        {slot.enabled && (
                            <Time
                                startKey="start"
                                startLabel="Class start"
                                startTime={earningWindow.timeToString(slot.start)}
                                endKey="end"
                                endLabel="Class end"
                                endTime={earningWindow.timeToString(slot.end)}
                                handleTimeChanged={({ id, value }) => this.handleChangeTime(id, value, index, earningWindow, setFieldValue)}
                                page={title}
                                noPadding={true}
                                hideTooltip={true}
                                error={!!error}
                                errorMessage={error}
                                startId={`time-${index * 2}`}
                                endId={`time-${index * 2 + 1}`}
                            />
                        )}
                        {!slot.enabled && <Text light={true}>No class on this day in rotation</Text>}
                    </Grid>
                </FormControl>
            );
        });
    };

    private getInitialValues = () => {
        const { group } = this.props;
        const earningWindow = new EarningWindow(this.props.earningWindow, group);

        return {
            name: group ? group.attributes.name : '',
            earningWindow: earningWindow.window,
        };
    };

    private handleChangeTime = (id, time, index, earningWindow, setFieldValue: any) => {
        const window = earningWindow.setSlotTime(id, time, index);

        setFieldValue('earningWindow', window);
    };

    private handleToggleEnabled = (slot: number, errors, earningWindow, setFieldValue: any) => {
        if (!(errors && errors.earningWindow && errors.earningWindow.slots && errors.earningWindow.slots[slot])) {
            setFieldValue('earningWindow', earningWindow.toggleSlotEnabled(slot));
        }
    };

    private handleSubmit = (values, options) => {
        const { groupId, onSubmit } = this.props;

        onSubmit(groupId, values, options);
    };
}

export default withStyles(appStyle)(RotatingForm);
