import React from 'react';
import { connect } from 'react-redux';
import { Route, RouteComponentProps, Switch, withRouter } from 'react-router-dom';
import * as routes from '../utils/routes';
import Forgot from './Auth/Forgot';
import Login from './Auth/Login';
import NoMatch from './Auth/NoMatch';
import Signup from './Auth/SignUp';
import Check from './Check';
import Dashboard from './Dashboard';
import Groups from './Groups';
import Group from './Groups/Group';
import School from './School';
import Privacy from './Misc/Privacy';

interface IProps extends RouteComponentProps {
    user: any;
    history: any;
}

declare let ga: any;

class Routes extends React.Component<IProps> {
    private unlisten: any = null;

    public UNSAFE_componentWillMount() {
        let currentLocation = this.props.history.location;
        console.info(`routes:renderAuthRoutes:authuserLocation: `,location.pathname);
        this.unlisten = this.props.history.listen((location, action) => {
            if (location.pathname !== currentLocation.pathname) {
                if (window && window.Intercom) {
                    window.Intercom('update');
                }

                ga('set', 'page', location.pathname);
                ga('send', 'pageview');
                currentLocation = location;
            }
        });
    }
    public componentWillUnmount() {
        this.unlisten();
    }

    public render() {
        const { user } = this.props;

        if (!user.data.loaded) {
            console.info(`routes:renderNonAuthRoutes:no user authed: `);
            return <div />;
        }

        if (user.data.authUser) {
            console.info(`routes:renderAuthRoutes:authuser: `, user.data.authUser);
            return this.renderAuthRoutes();
            
        }
        console.info(`routes:renderNonAuthRoutes:success: `);
        return this.renderNonAuthRoutes();
       
    }

    private renderAuthRoutes() {
        const {
            user: {
                data: { userInfo },
            },
        } = this.props;
        const showOnboarding = userInfo.modalStates.showOnboarding;

        if (showOnboarding) {
            return (
                <Switch>
                    <Route path={routes.groups} component={Groups} />
                    <Route path={routes.check} component={Check} />
                    <Route exact={true} path={routes.privacy} component={Privacy} />
                    <Route component={NoMatch} />
                </Switch>
            );
        }

        return (
            <Switch>
                <Route path={routes.dashboard} component={Dashboard} />
                <Route path={routes.groups} component={Groups} />
                <Route path={routes.group} component={Group} />
                <Route path={routes.school} component={School} />
                <Route path={routes.check} component={Check} />
                <Route exact={true} path={routes.privacy} component={Privacy} />
                <Route component={NoMatch} />
            </Switch>
        );
    }

    private renderNonAuthRoutes() {
        return (
            <Switch>
                <Route exact={true} path={routes.signUp} component={Signup} />
                <Route exact={true} path={routes.login} component={Login} />
                <Route exact={true} path={routes.forgot} component={Forgot} />
                <Route exact={true} path={routes.privacy} component={Privacy} />
                <Route path={routes.check} component={Check} />
                <Route component={NoMatch} />
            </Switch>
        );
    }
}

const mapStoreToProps = (state: any) => {
    return {
        user: state.user,
    };
};

export default withRouter(
    connect(
        mapStoreToProps,
        null
    )(Routes)
);
