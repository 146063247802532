import { Button, ButtonBase, FormControl, Grid, GridList, GridListTile } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { DatePicker } from '@material-ui/pickers';
import moment, { Moment } from 'moment-timezone';
import React from 'react';
import EarningWindow, { IEarningWindow, TEarningWindowLabelType } from 'shared-library-js';
import Text from '../../components/Text';
import Tooltip from '../../components/Tooltip';
import { ROTATION_TYPES } from '../../utils/constants';
import appStyle from '../App.style';

interface IActive {
    color: 'primary';
    variant: 'outlined';
}

interface IProps {
    classes: any;
    edit: boolean;
    setValues: any;
    values: IEarningWindow;
}

class RotatingForm extends React.PureComponent<IProps> {
    public render() {
        const { edit } = this.props;

        if (edit) {
            return this.renderEdit();
        }

        return this.renderCreate();
    }

    private renderCreate = () => {
        return (
            <Grid container={true} justifyContent="center" alignItems="center">
                <Grid container={true} item={true} justifyContent="space-between" wrap="nowrap" style={{ paddingTop: 24 }}>
                    <Text fontSize={14} light={true}>
                        How many days are in your complete schedule rotation?
                    </Text>
                    <Tooltip page="Onboarding" id="daysTip">
                        Include all days of your school schedule rotation, even if this class does not meet every day.
                    </Tooltip>
                </Grid>
                <Grid container={true} item={true} justifyContent="space-between" wrap="nowrap" style={{ paddingTop: 16 }}>
                    <Text fontSize={14} extraLight={true}>
                        e.g. A/B would be a 2 day rotation
                    </Text>
                </Grid>
                {this.renderTypes()}
                {this.renderDays()}
                <Grid container={true} item={true} justifyContent="space-between" wrap="nowrap" style={{ paddingTop: 16 }}>
                    <Text fontSize={14} extraLight={true}>
                        Example Schedule
                    </Text>
                </Grid>
                {this.renderExample()}
                <Grid container={true} item={true} justifyContent="space-between" wrap="nowrap" style={{ paddingTop: 40 }}>
                    <Text fontSize={16}>Set your next school day to start your rotation</Text>
                    <Tooltip page="Onboarding" id="daysTip">
                        Choose the next day you have school. This ensures that your rotation will start on the right day.
                    </Tooltip>
                </Grid>
                {this.renderStart()}
                <Grid container={true} item={true} justifyContent="space-between" wrap="nowrap" style={{ paddingTop: 40 }}>
                    <Text fontSize={14} light={true}>
                        Which day in your rotation will land on that day?
                    </Text>
                </Grid>
                {this.renderStartSlots()}
            </Grid>
        );
    };

    private renderEdit = () => {
        return (
            <Grid container={true} justifyContent="center" alignItems="center" style={{ paddingBottom: 24 }}>
                {this.renderSchedule()}
                <Grid container={true} item={true} justifyContent="space-between" wrap="nowrap" style={{ paddingTop: 40 }}>
                    <Text fontSize={16}>Set your next school day to start your rotation</Text>
                    <Tooltip page="Onboarding" id="daysTip">
                        Choose the next day you have school. This ensures that your rotation will start on the right day.
                    </Tooltip>
                </Grid>
                {this.renderStart()}
                <Grid container={true} item={true} justifyContent="space-between" wrap="nowrap" style={{ paddingTop: 40 }}>
                    <Text fontSize={14} light={true}>
                        Which day in your rotation will land on that day?
                    </Text>
                </Grid>
                {this.renderStartSlots()}
                <Grid container={true} item={true} justifyContent="space-between" wrap="nowrap" style={{ paddingTop: 24 }}>
                    <Text fontSize={14} light={true}>
                        How many days are in your complete schedule rotation?
                    </Text>
                    <Tooltip page="Onboarding" id="daysTip">
                        Include all days of your school schedule rotation, even if this class does not meet every day.
                    </Tooltip>
                </Grid>
                <Grid container={true} item={true} justifyContent="space-between" wrap="nowrap" style={{ paddingTop: 16 }}>
                    <Text fontSize={14} extraLight={true}>
                        e.g. A/B would be a 2 day rotation
                    </Text>
                </Grid>
                {this.renderTypes()}
                {this.renderDays()}
            </Grid>
        );
    };

    private renderTypes = () => {
        const { values } = this.props;
        const active: IActive = { color: 'primary', variant: 'outlined' };
        const numericProps = values.labelType === ROTATION_TYPES.numeric ? active : null;
        const alphabetProps = values.labelType === ROTATION_TYPES.alphabet ? active : null;

        return (
            <FormControl margin="normal" fullWidth={true} style={{ paddingTop: 24 }}>
                <Grid container={true} wrap="nowrap" alignItems="center" style={{ height: 32 }}>
                    <Grid item={true}>
                        <Button {...numericProps} size="small" disableRipple={true} onClick={() => this.handleChangeLabelType('numeric', values)}>
                            123
                        </Button>
                    </Grid>
                    <Grid item={true}>
                        <Button {...alphabetProps} size="small" disableRipple={true} onClick={() => this.handleChangeLabelType('alphabet', values)}>
                            abc
                        </Button>
                    </Grid>
                </Grid>
            </FormControl>
        );
    };

    private renderDays = () => {
        const { classes, values } = this.props;
        const { slots } = values;
        const days = [2, 3, 4, 5, 6, 7, 8, 9, 10];

        return (
            <FormControl margin="normal" fullWidth={true} style={{ paddingTop: 12 }}>
                <Grid container={true} justifyContent="center" alignItems="center">
                    <Grid item={true} xs={12}>
                        {days.map((day, index) => {
                            const selected = slots.length === day ? classes.selectedDayOfWeekButton : classes.unselectedDayOfWeekButton;

                            return (
                                <ButtonBase key={index} onClick={() => this.handleChangeSlots(day, values)} className={selected} disableRipple={true}>
                                    {`${day}`}
                                </ButtonBase>
                            );
                        })}
                    </Grid>
                </Grid>
            </FormControl>
        );
    };

    private renderExample = () => {
        const { classes, values } = this.props;
        const week = ['M', 'T', 'W', 'TH', 'F'];
        const days = values.slots.length < 5 ? week : [...week, ...week];

        return (
            <GridList cols={5} style={{ width: '100%', paddingTop: 12 }} cellHeight="auto" spacing={0}>
                {days.map((day: any, index: number) => {
                    const charIndex = index % values.slots.length;
                    const label = values.labelType === ROTATION_TYPES.numeric ? `${charIndex + 1}` : String.fromCharCode(charIndex + 65);
                    const cellClasses = `${classes.exampleScheduleCell} ${index % 5 === 0 ? classes.exampleScheduleCellFirst : ''}`;

                    return (
                        <GridListTile key={index}>
                            <Grid container={true} item={true} style={{ height: 40 }} justifyContent="center" alignContent="center">
                                <Text fontSize={11} light={true}>
                                    {day}
                                </Text>
                            </Grid>
                            <Grid container={true} item={true} className={cellClasses} justifyContent="center" alignContent="center">
                                <Text fontSize={12} light={true}>
                                    {`DAY ${label}`}
                                </Text>
                            </Grid>
                        </GridListTile>
                    );
                })}
            </GridList>
        );
    };

    private renderSchedule = () => {
        const { classes, values } = this.props;
        const earningWindow = new EarningWindow(values);
        const startAt = moment.unix(earningWindow.window.startAt);
        const today = moment().startOf('day');
        const startDate = startAt.isSameOrAfter(today) ? moment(startAt).subtract(1, 'day') : moment(today).subtract(2, 'day');
        const days = Array(5)
            .fill(0)
            .map(() => {
                const date = moment(startDate.add(1, 'day'));

                return { date, slot: earningWindow.getSlot(date.unix()) };
            });
        const current = startAt.isSameOrAfter(today) ? 0 : 1;

        return (
            <GridList cols={5} style={{ width: '100%', paddingTop: 12 }} cellHeight="auto" spacing={0}>
                {days.map((day, index) => {
                    const label = values.labelType === ROTATION_TYPES.numeric ? `${day.slot + 1}` : String.fromCharCode(day.slot + 65);
                    const cellStyle = { borderLeftWidth: index === 0 ? 1 : 0, borderRightWidth: 1 };

                    cellStyle.borderRightWidth = index === 0 && current === 1 ? 0 : 1;
                    cellStyle.borderLeftWidth = index === 1 && current === 1 ? 1 : cellStyle.borderLeftWidth;

                    return (
                        <GridListTile key={index}>
                            <Grid container={true} item={true} style={{ height: 40 }} direction="column" justifyContent="flex-start" alignContent="center">
                                <Text fontSize={11} light={current !== index} style={{ textAlign: 'center' }}>
                                    {day.date.format('ddd')}
                                </Text>
                                <Text fontSize={11} light={current !== index}>
                                    {day.date.format('M/D')}
                                </Text>
                            </Grid>
                            <Grid
                                container={true}
                                item={true}
                                className={current === index ? classes.scheduleCellCurrent : classes.scheduleCell}
                                style={cellStyle}
                                justifyContent="center"
                                alignContent="center"
                            >
                                <Text fontSize={12} light={current !== index}>
                                    {day.slot > -1 ? `DAY ${label}` : 'No Class'}
                                </Text>
                            </Grid>
                        </GridListTile>
                    );
                })}
            </GridList>
        );
    };

    private renderStart = () => {
        const { values } = this.props;

        return (
            <FormControl margin="normal" fullWidth={true} style={{ paddingTop: 12 }}>
                <DatePicker
                    autoOk={true}
                    allowKeyboardControl={false}
                    emptyLabel="When is your next school day?"
                    format="MM/DD/YYYY"
                    value={moment.unix(values.startAt)}
                    onChange={(date) => this.handleChangeStartAt(date, values)}
                    shouldDisableDate={this.handleDisableDates}
                />
            </FormControl>
        );
    };

    private renderStartSlots = () => {
        const { classes, values } = this.props;

        return (
            <FormControl margin="normal" fullWidth={true} style={{ paddingTop: 12 }}>
                <Grid container={true} item={true} xs={12} alignItems="center">
                    {values.slots.map((_, index) => {
                        const selectedClass = values.startSlot === index ? classes.selectedDayOfWeekButton : classes.unselectedDayOfWeekButton;
                        const label = values.labelType === ROTATION_TYPES.numeric ? `${index + 1}` : String.fromCharCode(index + 65);

                        return (
                            <ButtonBase key={index} onClick={() => this.handleChangeStartSlot(index, values)} className={selectedClass} disableRipple={true}>
                                {`DAY ${label}`}
                            </ButtonBase>
                        );
                    })}
                </Grid>
            </FormControl>
        );
    };

    private handleDisableDates = (date) => {
        const dow = date.isoWeekday();

        return dow > 5 || date.isBefore(moment().startOf('day'));
    };

    private handleChangeSlots = (length: number, values: IEarningWindow) => {
        const { setValues } = this.props;
        const earningWindow = new EarningWindow(values);
        const window = earningWindow.setRotatingSlotsLength(length);

        setValues(window);
    };

    private handleChangeLabelType = (labelType: TEarningWindowLabelType, values: IEarningWindow) => {
        const { setValues } = this.props;
        const earningWindow = new EarningWindow(values);
        const window = earningWindow.setRotatingLabelType(labelType);

        setValues(window);
    };

    private handleChangeStartAt = (date: Moment, values: IEarningWindow) => {
        const { setValues } = this.props;
        const earningWindow = new EarningWindow(values);
        const window = earningWindow.setRotatingStartAt(date.unix());

        setValues(window);
    };

    private handleChangeStartSlot = (slot: number, values: IEarningWindow) => {
        const { setValues } = this.props;
        const earningWindow = new EarningWindow(values);
        const window = earningWindow.setRotatingStartSlot(slot);

        setValues(window);
    };
}

export default withStyles(appStyle)(RotatingForm);
