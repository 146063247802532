import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    MenuItem,
    Paper,
    Popper,
    Typography,
} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import CloseIcon from '@material-ui/icons/Close';
import Downshift from 'downshift';
import { Formik } from 'formik';
import Fuse from 'fuse.js';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import appStyle from '../app/App.style';
import { NewSchoolSchema } from '../utils/validations';
import AddressPredictionList from './AddressPredictionList';
import Submit from './Submit';

interface IProps {
    classes: any;
    error: boolean;
    actions: any;
    location: any;
    onChange: any;
    school?: any;
    schools: any;
    style?: any;
    user: any;
}

interface IState {
    autoFocus: boolean;
    editLocation: boolean;
    selectedSchool: any;
    showNewSchoolRequest: boolean;
    showThankYou: boolean;
    zipCode: string;
    zipCodeError: boolean;
}

class SchoolPicker extends React.Component<IProps, IState> {
    private schoolInputRef;
    private zipCodeInputRef;

    constructor(props: IProps) {
        super(props);

        this.state = {
            autoFocus: false,
            editLocation: false,
            selectedSchool: props.school ? this.getSchoolPropData(props.school) : null,
            showNewSchoolRequest: false,
            showThankYou: false,
            zipCode: '',
            zipCodeError: false,
        };
    }

    public componentDidMount() {
        const { actions } = this.props;

        actions.searches.getLocation();
    }

    public render() {
        const { classes, error, location } = this.props;
        const { autoFocus, editLocation, selectedSchool, zipCode, zipCodeError } = this.state;

        return (
            <FormControl
                margin="normal"
                required={true}
                fullWidth={true}
                error={error}
                style={{ minHeight: 120, justifyContent: 'center' }}
                className="schoolPickerShadow"
            >
                <InputLabel htmlFor="schoolpicker" shrink={true} style={{ fontSize: 22 }}>
                    School Name
                </InputLabel>

                <Typography component="span" style={{ marginBottom: 20, fontSize: 12, height: 24 }} onClick={this.toggleEditLocation}>
                    Searching near
                    {!location && '...'}
                    {location && (
                        <Typography component="span" style={{ display: 'inline', fontWeight: 'bold', fontSize: 12, height: 24 }}>
                            {` ${location.city}, ${location.state} ${location.zipCode} - `}
                            <Typography
                                component="span"
                                color="primary"
                                style={{ display: 'inline', fontWeight: 'bold', fontSize: 12, height: 24, cursor: 'pointer' }}
                            >
                                {editLocation ? 'Cancel' : 'Edit Location'}
                            </Typography>
                        </Typography>
                    )}
                </Typography>
                {!editLocation && (
                    <Downshift onSelect={this.onSelectSchool} itemToString={(item) => (item ? item.name : '')} initialSelectedItem={selectedSchool}>
                        {({ getInputProps, getItemProps, getMenuProps, isOpen, inputValue, highlightedIndex, selectedItem, clearSelection }) => (
                            <div className={classes.container}>
                                <Input
                                    autoComplete="off"
                                    inputProps={{ 'data-hj-whitelist': true }}
                                    autoFocus={autoFocus}
                                    placeholder="Search by school name"
                                    id="schoolpicker"
                                    name="schoolpicker"
                                    fullWidth={true}
                                    endAdornment={this.renderClearAdornment(inputValue, clearSelection)}
                                    {...getInputProps()}
                                />
                                <div ref={(ref) => (this.schoolInputRef = ref)} />
                                <Popper
                                    open={isOpen}
                                    anchorEl={this.schoolInputRef}
                                    placement="bottom"
                                    disablePortal={true}
                                    modifiers={{
                                        flip: {
                                            enabled: false,
                                        },
                                        preventOverflow: {
                                            enabled: false,
                                        },
                                        hide: {
                                            enabled: false,
                                        },
                                    }}
                                    style={{ zIndex: 100 }}
                                >
                                    <div {...(isOpen ? getMenuProps({}, { suppressRefError: true }) : {})}>
                                        <Paper
                                            square={true}
                                            style={{
                                                width: this.schoolInputRef ? this.schoolInputRef.clientWidth : null,
                                                maxHeight: 210,
                                                overflowY: 'scroll',
                                                zIndex: 100,
                                            }}
                                        >
                                            {/* schools list render */}
                                            <ul>
                                            {this.renderSuggestions(inputValue, getItemProps, highlightedIndex, selectedItem, this.getSuggestions(inputValue))}
                                            </ul>
                                           {/* end schools list render */}
                                            <Button onClick={this.onOpenNewSchoolRequest} className={classes.newSchoolRequestButton} fullWidth={true}>
                                                Don't see your school?
                                            </Button>
                                        </Paper>
                                    </div>
                                </Popper>
                            </div>
                        )}
                    </Downshift>
                )}
                {editLocation && (
                    <Input
                        autoComplete="off"
                        inputProps={{ 'data-hj-whitelist': true }}
                        autoFocus={autoFocus}
                        placeholder="Enter zip code"
                        id="schoolpicker"
                        name="schoolpicker"
                        value={zipCode}
                        onChange={this.setZipcode}
                        onKeyPress={this.onZipcodeEnter}
                        inputRef={(ref) => (this.zipCodeInputRef = ref)}
                        endAdornment={this.renderZipcodeAdornment()}
                        error={zipCodeError}
                    />
                )}
                {this.renderNewSchoolRequest()}
                {this.renderThankYou()}
            </FormControl>
        );
    }

    private renderZipcodeAdornment = () => {
        const { zipCode } = this.state;
        const valid = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zipCode);

        return (
            <InputAdornment position="end">
                <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    style={{ borderRadius: 20, textTransform: 'none', marginBottom: 15 }}
                    onClick={this.setLocation}
                    disabled={!valid}
                >
                    Update
                </Button>
            </InputAdornment>
        );
    };

    private renderClearAdornment = (inputValue, clearSelection) => {
        if (!inputValue || inputValue.length === 0) {
            return null;
        }

        return (
            <InputAdornment position="end">
                <IconButton component="span" onClick={clearSelection}>
                    <CloseIcon style={{ fontSize: 18 }} />
                </IconButton>
            </InputAdornment>
        );
    };

    private renderSuggestions = (inputValue, getItemProps, highlightedIndex, selectedItem, suggestions: any) => {
        if (suggestions.length === 0) {
            return (
                <MenuItem disabled={true} style={{ justifyContent: 'center' }}>
                    <Typography align="center" style={{ fontStyle: 'italic' }}>
                        No results found
                    </Typography>
                </MenuItem>
            );
        }

        return suggestions.map((suggestion, index) => {
            const isHighlighted = highlightedIndex === index;
            const isSelected = selectedItem ? suggestion === selectedItem.name : false;
            console.log('reactions:schoolpicker:renderNewSchoolRequest:suggestion:', JSON.stringify(suggestion));

            return (
                <MenuItem
                    {...getItemProps({ item: suggestion })}
                    key={index}
                    selected={isHighlighted}
                    component="div"
                    style={{ fontWeight: isSelected ? 500 : 400 }}
                >
                    <Typography align="center" style={{ fontStyle: 'italic' }}>
                    {suggestion.id} {suggestion.name}
                    </Typography>
                </MenuItem>
            );
        });
    };

    private renderNewSchoolRequest = () => {
        const { classes } = this.props;
        const { showNewSchoolRequest } = this.state;

        return (
            <Dialog
                open={showNewSchoolRequest}
                onClose={() => this.setState({ showNewSchoolRequest: false })}
                PaperProps={{ style: { alignItems: 'center' } }}
                maxWidth="sm"
                fullWidth={true}
                fullScreen={isMobile}
            >
                <Grid item={true} style={{ alignSelf: 'stretch', paddingLeft: 16, paddingTop: 16 }}>
                    <CloseIcon onClick={() => this.setState({ showNewSchoolRequest: false })} style={{ cursor: 'pointer' }} />
                </Grid>
                <DialogContent>
                    <DialogTitle style={{ textAlign: 'center', fontWeight: 800 }}>Enter your school details</DialogTitle>
                    <Formik
                        initialValues={{
                            newSchoolName: '',
                            newSchoolAddress: '',
                        }}
                        validationSchema={NewSchoolSchema}
                        onSubmit={this.onNewSchoolRequest}
                    >
                        {({ values, errors, touched, handleChange, handleSubmit, isSubmitting, setFieldValue }) => (
                            <form
                                onSubmit={(event) => {
                                    this.handleOnSubmitEvent(event, handleSubmit);
                                }}
                                noValidate={true}
                                autoComplete="off"
                            >
                                <FormControl margin="normal" required={true} fullWidth={true} error={!!errors.newSchoolName && !!touched.newSchoolName}>
                                    <InputLabel htmlFor="newSchoolName">School Name</InputLabel>
                                    <Input
                                        autoComplete="off"
                                        placeholder="Full school name (no abbreviations)"
                                        id="newSchoolName"
                                        name="newSchoolName"
                                        value={values.newSchoolName}
                                        onChange={handleChange}
                                    />
                                </FormControl>
                                <FormControl
                                    margin="normal"
                                    required={true}
                                    fullWidth={true}
                                    error={!!errors.newSchoolAddress && !!touched.newSchoolAddress}
                                    style={{ marginBottom: '150px' }}
                                >
                                    <Downshift
                                        itemToString={(item) => (item ? item.description : '')}
                                        onChange={(selectedItem) => this.onSelectSchoolAddress(selectedItem, setFieldValue)}
                                    >
                                        {({
                                            getInputProps,
                                            getItemProps,
                                            getMenuProps,
                                            isOpen,
                                            inputValue,
                                            highlightedIndex,
                                            selectedItem,
                                            clearSelection,
                                        }) => (
                                            <div className={classes.container}>
                                                <InputLabel htmlFor="newSchoolAddress">School Address</InputLabel>
                                                <Input
                                                    autoComplete="off"
                                                    inputProps={{ 'data-hj-whitelist': true }}
                                                    placeholder="Search school street address"
                                                    id="newSchoolAddress"
                                                    name="newSchoolAddress"
                                                    fullWidth={true}
                                                    endAdornment={this.renderClearAdornment(inputValue, clearSelection)}
                                                    {...getInputProps()}
                                                />
                                                <div ref={(ref) => (this.schoolInputRef = ref)} />
                                                <Popper
                                                    open={isOpen}
                                                    anchorEl={this.schoolInputRef}
                                                    placement="bottom"
                                                    disablePortal={true}
                                                    modifiers={{
                                                        flip: {
                                                            enabled: false,
                                                        },
                                                        preventOverflow: {
                                                            enabled: false,
                                                        },
                                                        hide: {
                                                            enabled: false,
                                                        },
                                                    }}
                                                    style={{ zIndex: 100 }}
                                                >
                                                    <div {...(isOpen ? getMenuProps({}, { suppressRefError: true }) : {})}>
                                                        <Paper
                                                            square={true}
                                                            style={{
                                                                width: this.schoolInputRef ? this.schoolInputRef.clientWidth : null,
                                                                maxHeight: 280,
                                                                overflowY: 'scroll',
                                                                zIndex: 100,
                                                            }}
                                                        >
                                                            <AddressPredictionList
                                                                inputValue={inputValue}
                                                                getItemProps={getItemProps}
                                                                highlightedIndex={highlightedIndex}
                                                                selectedItem={selectedItem}
                                                            />
                                                        </Paper>
                                                    </div>
                                                </Popper>
                                            </div>
                                        )}
                                    </Downshift>
                                </FormControl>
                                <Submit loading={isSubmitting} style={{ paddingBottom: 40 }}>
                                    Submit
                                </Submit>
                            </form>
                        )}
                    </Formik>
                </DialogContent>
            </Dialog>
        );
    };

    private renderThankYou = () => {
        const { classes } = this.props;
        const { showThankYou } = this.state;

        return (
            <Dialog
                open={showThankYou}
                onClose={() => this.setState({ showThankYou: false })}
                PaperProps={{ style: { alignItems: 'center' } }}
                maxWidth="sm"
                fullWidth={true}
                fullScreen={isMobile}
            >
                <Grid item={true} style={{ alignSelf: 'stretch', paddingLeft: 16, paddingTop: 16 }}>
                    <CloseIcon onClick={() => this.setState({ showThankYou: false })} style={{ cursor: 'pointer' }} />
                </Grid>
                <DialogTitle style={{ textAlign: 'center' }}>
                    Thank you! Your school will be live in 3-5 business days. We will notify you via email.
                </DialogTitle>
                <DialogContent>
                    <div className={classes.buttonLoaderWrapper}>
                        <Button onClick={() => this.setState({ showThankYou: false })} variant="contained" color="primary" className={classes.primaryButton}>
                            Done
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        );
    };

    private getSuggestions = (inputValue) => {
        const { schools } = this.props;
        const inputs = inputValue
            ? inputValue
                  .replace(/\s\s+/g, '')
                  .trim()
                  .toLowerCase()
                  .split(' ')
            : [];

        const nearbySchools = schools
            .filter((school) => {
                const schoolName = school.name.toLowerCase();
                const found = school.searchWords.filter((word) => {
                    return inputs.find((input) => word.startsWith(input));
                });

                return found.length > 0 || schoolName.includes(inputValue.trim().toLowerCase());
            })
            .sort((schoolA, schoolB) => {
                return schoolA.distance < schoolB.distance ? -1 : 1;
            })
            .slice(0, 600);

        const fuse = new Fuse(nearbySchools, { keys: ['searchWords', 'name'], shouldSort: true });

        return fuse.search(inputValue).slice(0, 40);
    };

    private toggleEditLocation = () => {
        const { editLocation } = this.state;

        this.setState({ editLocation: !editLocation, zipCode: '', zipCodeError: false }, () => {
            if (this.state.editLocation) {
                this.zipCodeInputRef.focus();
            } else {
                this.schoolInputRef.focus();
            }
        });
    };

    private onSelectSchool = (selectedSchool) => {
        const { onChange } = this.props;

        this.setState({ selectedSchool }, () => {
            if (selectedSchool) {
                onChange({
                    cityState: `${selectedSchool.city}, ${selectedSchool.state}`,
                    highSchool: selectedSchool.highSchool,
                    id: selectedSchool.id,
                    name: selectedSchool.name,
                    timeZone: selectedSchool.timeZone,
                });
            } else {
                onChange(null);
            }
        });
    };

    private onZipcodeEnter = (event) => {
        const {
            key,
            target: { value },
        } = event;
        const valid = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value);

        if (key === 'Enter') {
            event.preventDefault();

            if (valid) {
                this.setState({ zipCode: value }, () => this.setLocation());
            }
        }
    };

    private setZipcode = (event) => {
        const {
            target: { value },
        } = event;

        this.setState({ zipCode: value });
    };

    private setLocation = () => {
        const { actions } = this.props;
        const { zipCode } = this.state;

        actions.searches
            .setLocation(zipCode)
            .then(() => this.setState({ editLocation: false, selectedSchool: null }))
            .catch(() => this.setState({ zipCodeError: true }));
    };

    private onNewSchoolRequest = (values, { setSubmitting }) => {
        const { actions } = this.props;
        const { newSchoolName, newSchoolAddress } = values;

        this.setState({ showNewSchoolRequest: false, showThankYou: true }, () => {
            setSubmitting(false);
            actions.institutions.createNewSchoolRequest({ newSchoolName, newSchoolAddress });
        });
    };

    private onOpenNewSchoolRequest = () => {
        this.setState({ showNewSchoolRequest: true });
    };

    private getSchoolPropData = (school) => {
        const cityStateArray = school.cityState ? school.cityState.split(', ') : [];

        return {
            city: cityStateArray.length === 2 ? cityStateArray[0] : '',
            state: cityStateArray.length === 2 ? cityStateArray[1] : '',
            id: school.id,
            name: school.name,
            timeZone: school.timeZone,
        };
    };

    private onSelectSchoolAddress = (selectedItem: any, setFieldValue: any) => {
        if (selectedItem && selectedItem.description) {
            setFieldValue('newSchoolAddress', selectedItem.description, true);
        }
    };

    private handleOnSubmitEvent = (event: any, handleSubmit: any) => {
        event.stopPropagation();
        handleSubmit(event);
    };
}

const mapStoreToProps = (state: any) => {
    return {
        actions: {
            institutions: state.institutions.actions,
            searches: state.searches.actions,
            user: state.user.actions,
        },
        location: state.searches.data.location,
        schools: state.searches.data.schools,
        user: state.user,
    };
};

export default compose<IProps, any>(
    connect(
        mapStoreToProps,
        null
    ),
    withStyles(appStyle)
)(SchoolPicker);
