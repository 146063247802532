import {legacy_createStore as createStore, applyMiddleware} from 'redux';

import logger from 'redux-logger';
import Reactions from './reactions';

const configureStore = () => {
    const reactions = new Reactions();
    let store;

    if(window.env === 'prod') {
        store = createStore(reactions.getReducers(),  applyMiddleware(logger));
    }
    else {
        store = createStore(reactions.getReducers(), applyMiddleware(logger));
    }

    reactions.setStore(store);

    reactions.init();

    return { reactions, store };
};

export default configureStore;