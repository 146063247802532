import { Avatar, Button, Checkbox, CircularProgress, FormControlLabel, Grid, withStyles } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import { ExportToCsv } from 'export-to-csv';
import moment from 'moment-timezone';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import BlankAvatar from '../../components/BlankAvatar';
import Text from '../../components/Text';
import { IGroupIncentive, IIteration } from '../../utils/types';
import appStyle from '../App.style';
import NoInProgressCell from './Cells/NoInProgressCell';
import Filters from './Filters';
import ClasswideModal from './Modals/ClasswideModal';

interface IProps {
    classes: any;
    filteredData: any;
    handleMarkAllReceived: any;
    handleMarkOneItem: any;
    onFilterChanged: any;
    filterInputs: any;
    actions: any;
    loading: boolean;
    data: any;
    hideReceived: boolean;
    toggleHideReceived: any;
}

interface IState {
    showClasswideModal: boolean;
    modalIteration: IIteration;
    modalUniqueCompleted: any;
    modalGroupIncentive: IGroupIncentive;
}

class CompletedTab extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            showClasswideModal: false,
            modalIteration: undefined,
            modalUniqueCompleted: undefined,
            modalGroupIncentive: undefined,
        };
    }

    public render() {
        const { classes, filteredData, filterInputs, onFilterChanged, loading, hideReceived, toggleHideReceived } = this.props;
        const { showClasswideModal, modalIteration, modalGroupIncentive } = this.state;
        const { completed } = filteredData;
        const checkedItems = completed.filter((item: any) => item.received);
        const disabled = checkedItems.length === completed.length;
        const { groupProgresses } = this.props.data;
        const groupDataReady = (groupProgresses !== null && groupProgresses.data !== null);

        let atLeastOneGroupCompleted = false;
        let atLeastOneGroupNotReceived = false;
        if (groupProgresses) {
            Object.keys(groupProgresses).forEach((key) => {
                if (groupProgresses[key].completion) {
                    atLeastOneGroupCompleted = true;
                    if (groupProgresses[key].received === null) {
                        atLeastOneGroupNotReceived = true;
                    }
                }
            });
        }

        return (
            <Grid container={true} direction="column">
                {showClasswideModal && (
                    <ClasswideModal
                        open={showClasswideModal}
                        toggleClasswideModal={this.toggleClasswideModal}
                        iteration={modalIteration}
                        groupIncentive={modalGroupIncentive}
                    />
                )}
                <Grid container={true} justifyContent="flex-end" style={{ paddingLeft: 6, paddingRight: 6, marginBottom: 6 }}>
                    <Filters filterInputs={filterInputs} onFilterChanged={onFilterChanged} />
                    {(completed.length > 0 || atLeastOneGroupCompleted) && (
                        <Grid container={true} direction="row" justifyContent="space-between" alignItems="center">
                            <Grid>
                                <Button
                                    disableFocusRipple={true}
                                    disableRipple={true}
                                    variant="text"
                                    size="small"
                                    style={{ textTransform: 'none', fontSize: 14 }}
                                    color="primary"
                                    onClick={() => this.downloadCsv(completed)}
                                >
                                    <Icons.GridOn color="primary" fontSize="small" style={{ paddingRight: 5 }} />
                                    Download Spreadsheet
                                </Button>
                            </Grid>
                            <Grid>
                                <Button
                                    onClick={toggleHideReceived}
                                    variant="text"
                                    size="small"
                                    style={{ textTransform: 'none', fontSize: 14 }}
                                    color="primary"
                                >
                                    {hideReceived ? 'Show Received' : 'Hide Received'}
                                </Button>
                                <Button
                                    onClick={this.handleMarkAllReceived}
                                    variant="text"
                                    size="small"
                                    style={{ textTransform: 'none', fontSize: 14 }}
                                    disabled={disabled && !atLeastOneGroupNotReceived}
                                >
                                    Mark all received
                                    {loading && <CircularProgress size={24} className={classes.buttonLoader} style={{ top: 'unset' }} />}
                                </Button>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
                <Grid container={true} item={true} className={isMobile ? undefined : classes.dashboardPageSubBody} alignContent="flex-start">
                    {groupDataReady && this.renderCompletedClasswide()}
                    {this.renderCompletedUsers()}
                </Grid>
            </Grid>
        );
    }

    private renderCompletedUsers = () => {
        const { classes } = this.props;
        const users = this.getCompletedData();
        const { groupProgresses } = this.props.data;

        let atLeastOneCompleted = false;
        if (groupProgresses) {
            Object.keys(groupProgresses).forEach((key) => {
                if (groupProgresses[key].completion) {
                    atLeastOneCompleted = true;
                }
            });
        }

        if (users.length > 0) {
            return users.map((user: any, index) => {
                const { classrooms } = user;
                const classroomName = classrooms.length === 1 ? classrooms[0].classroomName : '';

                return (
                    <Grid container={true} key={index} className={classes.dashboardCompletedGroup}>
                        <Grid container={true} direction="row" className={classes.dashboardCompletedGroupHeader} wrap="nowrap">
                            <Grid container={true} item={true} xs={6} direction="row" justifyContent="flex-start" wrap="nowrap">
                                {this.renderAvatar(user.userName, user.userPic)}
                                <Text style={{ paddingLeft: 10 }} noWrap={true}>
                                    {user.userName}
                                </Text>
                            </Grid>
                            <Grid container={!isMobile} item={true} xs={6} wrap="nowrap" style={{ paddingTop: isMobile ? 7 : 0 }}>
                                <Text light={true} style={{ width: '100%', textAlign: 'right' }} fontSize={12} noWrap={true}>
                                    {classroomName}
                                </Text>
                            </Grid>
                        </Grid>
                        {this.renderCompletedClassrooms(classrooms)}
                    </Grid>
                );
            });
        } else if (atLeastOneCompleted) {
            return '';
        } else {
            return (
                <NoInProgressCell
                    title="You do not have any completed rewards yet"
                    body="Check the progress on your rewards to make sure they are not too difficult to complete"
                />
            );
        }
    };

    private renderCompletedClasswide = () => {
        const {
            classes,
            filteredData: { groupsFiltered },
        } = this.props;

        if (groupsFiltered) {
            return Object.keys(groupsFiltered).map((groupKey: string, index: number) => {
                const group = groupsFiltered[groupKey];
                const { groupIncentives } = group;

                if (groupIncentives && Object.keys(groupIncentives).length > 0) {
                    let shouldShowHeader = false;
                    Object.keys(groupIncentives).forEach((incentiveKey: string) => {
                        shouldShowHeader = this.shouldShowHeader(groupIncentives[incentiveKey]) ? true : shouldShowHeader;
                    });

                    if (shouldShowHeader) {
                        const groupName = group && group.attributes ? group.attributes.name : '';

                        return (
                            <Grid container={true} key={index} className={classes.dashboardCompletedGroup}>
                                <Grid container={true} direction="row" className={classes.dashboardCompletedGroupHeader} wrap="nowrap">
                                    <Grid container={true} item={true} xs={6} direction="row" justifyContent="flex-start" wrap="nowrap">
                                        <img src="/images/people-outline.png" alt="people icon" style={{ width: 18, height: 14, marginTop: '2px' }} />
                                        <Text style={{ paddingLeft: 10 }} noWrap={true}>
                                            Class Wide Reward
                                        </Text>
                                    </Grid>
                                    <Grid container={true} item={true} xs={6} direction="row" justifyContent="flex-end" alignItems="center">
                                        <Text light={true} style={{ width: '100%', textAlign: 'right' }} fontSize={12} noWrap={true}>
                                            {groupName}
                                        </Text>
                                    </Grid>
                                </Grid>
                                <Grid container={true}>{groupIncentives ? this.renderCompletedClasswideIncentives(groupIncentives) : ''}</Grid>
                            </Grid>
                        );
                    }
                }

                return undefined;
            });
        }
        return '';
    };

    private renderCompletedClasswideIncentives = (groupIncentives: any) => {
        const { classes } = this.props;

        return Object.keys(groupIncentives).map((incentiveKey: string, index: number) => {
            const incentive = groupIncentives[incentiveKey];

            if (this.shouldShowHeader(incentive)) {
                return (
                    <Grid container={true} key={index}>
                        <Grid
                            container={true}
                            item={true}
                            direction="row"
                            className={classes.dashboardCompletedGroupIncentiveClasswide}
                            onClick={() => this.toggleClasswideModal(true, undefined, incentive)}
                        >
                            <Text fontSize={12}>{incentive.name}</Text>
                        </Grid>
                        <Grid container={true} item={true} direction="row">
                            {this.renderCompletedClasswideReceipts(incentive)}
                        </Grid>
                        {isMobile ? (
                            <Grid container={true} item={true} direction="row" justifyContent="center" alignItems="center" style={{ borderTop: '1px solid #ececec' }}>
                                <Button onClick={() => this.toggleClasswideModal(true, undefined, incentive)} fullWidth={true}>
                                    <Grid container={true} style={{ padding: '8px' }} justifyContent="center" alignItems="center">
                                        <Text fontSize={11} semiBold={true}>
                                            View Student Progress
                                            <img
                                                src="/images/right-arrow.png"
                                                alt="right arrow"
                                                style={{ width: 6, height: 10, marginLeft: 4, marginTop: 3 }}
                                            />
                                        </Text>
                                    </Grid>
                                </Button>
                            </Grid>
                        ) : (
                            ''
                        )}
                    </Grid>
                );
            }
            return '';
        });
    };

    private shouldShowHeader = (incentive) => {
        const { groupProgresses } = this.props.data;
        let atLeastOneCompleted = false;
        let atLeastOneNotReceived = false;

        if (incentive && incentive.iterations) {
            incentive.iterations.forEach((iteration) => {
                const { groupProgressesId } = iteration;
                const groupProgress = groupProgresses[groupProgressesId];

                if (groupProgress && groupProgress.completion) {
                    atLeastOneCompleted = true;
                    if (groupProgress && !groupProgress.received) {
                        atLeastOneNotReceived = true;
                    }
                }
            });
        }

        return atLeastOneCompleted && (atLeastOneNotReceived || !this.props.hideReceived);
    };

    private renderCompletedClasswideReceipts = (incentive: any) => {
        const { classes, hideReceived } = this.props;
        const { groupProgresses } = this.props.data;

        if (incentive && incentive.iterations) {
            return incentive.iterations
                .map((iteration: any, index: number) => {
                    const { groupProgressesId } = iteration;
                    const groupIncentiveId = iteration.id;
                    const { received, completion, classroomId } = groupProgresses[groupProgressesId] ? groupProgresses[groupProgressesId] : false;
                    const dateFormat = isMobile ? 'MM/DD/YY h:mmA' : 'ddd, MMM D, YYYY h:mmA';

                    if (completion && (!hideReceived || !received)) {
                        return (
                            <Grid
                                key={index}
                                container={true}
                                item={true}
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                                className={classes.dashboardCompletedGroupReceiptClasswide}
                            >
                                <Grid item={true}>
                                    <Button
                                        onClick={() => this.toggleClasswideModal(true, iteration, incentive)}
                                        variant="text"
                                        size="small"
                                        style={{
                                            textTransform: 'none',
                                            padding: isMobile ? '4px 16px 8px 8px' : '20px 28px 20px 16px',
                                            opacity: 0.5,
                                            fontWeight: 400,
                                        }}
                                    >
                                        {moment.unix(completion).format(dateFormat)}
                                    </Button>
                                </Grid>
                                <Grid item={true}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={!!received}
                                                onChange={(event, checked) =>
                                                    this.handleReceiptReceived({ received, groupProgressesId, groupIncentiveId, classroomId }, 'classwide')
                                                }
                                                color={received ? 'primary' : 'default'}
                                                checkedIcon={<Icons.CheckBoxRounded />}
                                                icon={<Icons.CheckBoxOutlineBlank />}
                                                style={{ opacity: received ? 1 : 0.35 }}
                                            />
                                        }
                                        label={received ? 'reward received' : 'not received'}
                                        labelPlacement="start"
                                        style={{ paddingRight: 8 }}
                                        classes={{ label: received ? classes.receivedChecked : classes.receivedNotChecked }}
                                        className={classes.dashboardCompletedGroupCheckboxHover}
                                    />
                                </Grid>
                            </Grid>
                        );
                    }

                    return undefined;
                })
                .reverse();
        }
        return '';
    };

    private renderCompletedClassrooms = (classrooms: any, type: string = undefined) => {
        return classrooms.map((classroom, index) => {
            const { incentives } = classroom;
            const classroomName = classrooms.length > 1 ? classroom.classroomName : undefined;
            const timeZone = classrooms.length > 1 ? classroom.timeZone : undefined;

            return <React.Fragment key={index}>{this.renderCompletedIncentives(incentives, classroomName, timeZone)}</React.Fragment>;
        });
    };

    private renderCompletedIncentives = (incentives: any, classroomName, timeZone) => {
        const { classes } = this.props;

        return incentives.map((incentive, index) => {
            return (
                <React.Fragment key={index}>
                    <Grid container={true} item={true} direction="row" className={classes.dashboardCompletedGroupIncentive}>
                        <Text fontSize={12} fontWeight={500}>
                            {incentive.incentiveName}
                        </Text>
                        {classroomName && (
                            <Text fontSize={12} fontWeight={400} light={true} style={{ paddingLeft: 3 }}>
                                {`- ${classroomName}`}
                            </Text>
                        )}
                    </Grid>
                    {this.renderCompletedReceipts(incentive.receipts, timeZone)}
                </React.Fragment>
            );
        });
    };

    private renderCompletedReceipts = (receipts, timeZone) => {
        const { classes } = this.props;

        return receipts.map((item: any, index) => {
            const { receipt } = item;

            const dateFormat = isMobile ? 'MM/DD/YY h:mmA' : 'ddd, MMM D, YYYY h:mmA';

            return (
                <Grid
                    key={index}
                    container={true}
                    item={true}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    className={classes.dashboardCompletedGroupReceipt}
                >
                    <Text light={true} fontSize={12}>
                        {moment.tz(receipt.date, timeZone)
                            .local()
                            .format(dateFormat)}
                    </Text>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={item.received}
                                onChange={() => this.handleReceiptReceived(item)}
                                color={item.received ? 'primary' : 'default'}
                                checkedIcon={<Icons.CheckBoxRounded />}
                                icon={<Icons.CheckBoxOutlineBlank />}
                                style={{ opacity: item.received ? 1 : 0.35 }}
                            />
                        }
                        label={item.received ? 'reward received' : 'not received'}
                        labelPlacement="start"
                        style={{ paddingRight: 8 }}
                        classes={{ label: item.received ? classes.receivedChecked : classes.receivedNotChecked }}
                    />
                </Grid>
            );
        });
    };

    private renderAvatar(name, imageUrl) {
        const style = { border: '1px solid #ececec', width: 30, height: 30, fontSize: 16 };

        if (imageUrl) {
            return <Avatar alt="Remy Sharp" src={imageUrl} style={style} />;
        }

        return <BlankAvatar name={name} style={style} />;
    }

    private getCompletedData = () => {
        const { filteredData, hideReceived } = this.props;
        const completed = filteredData.completed.filter((progress) => (hideReceived ? !progress.received : true));

        const users = completed
            .reduce((accumulator: any[], progress: any) => {
                const { userId, userName, userPic } = progress;
                const found = accumulator.find((item) => item.userId === userId);

                return found ? accumulator : [...accumulator, { userId, userName, userPic }];
            }, [])
            .sort((a, b) => (a.userName > b.userName ? 1 : -1))
            .map((user) => {
                user.classrooms = completed
                    .filter((item) => item.userId === user.userId)
                    .reduce((accumulator: any[], progress: any) => {
                        const { classroomId, classroomName, timeZone } = progress;
                        const found = accumulator.find((item) => item.classroomId === classroomId);

                        return found ? accumulator : [...accumulator, { classroomId, classroomName, timeZone }];
                    }, [])
                    .sort((a, b) => (a.classroomName > b.classroomName ? 1 : -1))
                    .map((classroom) => {
                        classroom.incentives = completed
                            .filter((item) => item.userId === user.userId && item.classroomId === classroom.classroomId)
                            .reduce((accumulator: any[], progress: any) => {
                                const { incentiveId, incentiveName } = progress;
                                const found = accumulator.find((item) => item.incentiveId === incentiveId);

                                return found ? accumulator : [...accumulator, { incentiveId, incentiveName }];
                            }, [])
                            .sort((a, b) => (a.incentiveName > b.incentiveName ? 1 : -1))
                            .map((incentive) => {
                                incentive.receipts = completed
                                    .filter(
                                        (item) =>
                                            item.userId === user.userId &&
                                            item.classroomId === classroom.classroomId &&
                                            item.incentiveId === incentive.incentiveId
                                    )
                                    .sort((a, b) => (moment(a.receipt.date).isAfter(moment(b.receipt.date)) ? -1 : 1));

                                return incentive;
                            });

                        return classroom;
                    });

                return user;
            });

        return users;
    };

    private handleMarkAllReceived = () => {
        const { handleMarkAllReceived } = this.props;

        handleMarkAllReceived();
    };

    private handleReceiptReceived = (item: any, type = undefined) => {
        const {
            handleMarkOneItem,
            actions: { groupProgresses },
        } = this.props;

        if (type === 'classwide') {
            groupProgresses.markGroupIncentivesReceived([
                { received: item.received, id: item.groupProgressesId, groupIncentiveId: item.groupIncentiveId, classroomId: item.classroomId },
            ]);
        } else {
            handleMarkOneItem(item);
        }
    };

    private downloadCsv = (items) => {
        const { actions } = this.props;
        const data = items.map((item) => {
            const { classroomName, incentiveName, receipt, received, userName } = item;
            const minutes = (receipt.duration / 60) % 60;
            const hours = Math.floor(receipt.duration / 60 / 60);
            const status = received ? 'Received' : 'Not Received';
            const earned = moment
                .utc(receipt.date)
                .local()
                .format('MM/DD/YYYY, h:mm:ss A');
            const timeOff = `${hours > 0 ? hours + ' hour' : ''}${hours > 1 ? 's' : ''}${hours > 0 && minutes > 0 ? ', ' : ''}${
                minutes > 0 ? minutes + ' minute' : ''
            }${minutes > 1 ? 's' : ''}`;

            return {
                userName,
                classroomName,
                incentiveName,
                status,
                earned,
                timeOff,
            };
        });
        const endRange = moment().startOf('day');
        const beginRange = items.reduce((date, item) => {
            const { receipt } = item;
            const earned = moment.utc(receipt.date).local();

            return earned.isBefore(date) ? earned : date;
        }, endRange);
        const range = endRange.isSame(beginRange) ? endRange.format('_M_D_YYYY') : `${beginRange.format('_M_D_YYYY')}${endRange.format('_M_D_YYYY')}`;
        const csvExporter = new ExportToCsv({
            filename: `PocketPoints-CompletedRewards${range}`,
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            useTextFile: false,
            headers: ['Student Name', 'Class', 'Reward', 'Status', 'Date Earned', 'Time Off Phone'],
        });

        actions.mixpanel.sendEvent('Downloaded Spreadsheet');
        csvExporter.generateCsv(data);
    };

    private toggleClasswideModal = (shouldShow: boolean, modalIteration: IIteration = undefined, modalGroupIncentive: IGroupIncentive = undefined): void => {
        if (shouldShow) {
            this.setState({
                showClasswideModal: shouldShow,
                modalIteration,
                modalGroupIncentive,
            });
        } else {
            this.setState({
                showClasswideModal: false,
            });
        }
    };
}

const mapStoreToProps = (state: any) => {
    return {
        actions: {
            mixpanel: state.mixpanel.actions,
            groupProgresses: state.groupProgresses.actions,
        },
        data: {
            groupProgresses: state.groupProgresses.data,
        },
    };
};

export default connect(
    mapStoreToProps,
    null
)(withStyles(appStyle)(CompletedTab));
