import { Button, CircularProgress } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import React from 'react';
import appStyle from '../app/App.style';

interface IProps {
    classes: any;
    className?: string;
    loading?: boolean;
    style?: any;
    loadingButtonStyle?: any;
    buttonStyle?: any;
    onClick?: any;
    id?: string;
    type?: 'button' | 'submit';
    children?: any;
}

class Submit extends React.Component<IProps> {
    public render() {
        const { children, classes, className, loading, style, buttonStyle, loadingButtonStyle, type } = this.props;

        return (
            <div className={classes.buttonLoaderWrapper} style={style}>
                <Button
                    type={type ? type : 'submit'}
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    className={className ? className : classes.primaryButton}
                    style={loading ? loadingButtonStyle : buttonStyle}
                    onClick={this.handleOnClick}
                    id={this.props.id}
                >
                    {children}
                </Button>
                {loading && <CircularProgress size={24} className={classes.buttonLoader} />}
            </div>
        );
    }

    private handleOnClick = () => {
        const { onClick } = this.props;
        if (onClick) {
            onClick();
        }
    };
}

export default withStyles(appStyle)(Submit);
