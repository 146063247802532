import { Badge, Button, FormControl, Grid, Input, InputLabel, ListItem, ListItemText, Paper, Popper, Typography } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import Downshift from 'downshift';
import { Formik } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import AlertDialog from '../../../components/Alert';
import AlertTextInput from '../../../components/AlertTextInput';
import ConfirmationAlert from '../../../components/ConfirmationAlert';
import Submit from '../../../components/Submit';
import Text from '../../../components/Text';
import Tooltip from '../../../components/Tooltip';
import { IncentiveSuggestionsData } from '../../../data/suggestions';
import { IncentiveSchema } from '../../../utils/validations';
import appStyle from '../../App.style';
import IncentiveDuration from './IncentiveDuration';
import IncentiveExpiration from './IncentiveExpiration';

interface IProps {
    classes: any;
    handleClose: any;
    fullScreen: boolean;
    groupId: string;
    groups: any;
    incentiveId?: string | null;
    setLoading: any;
    showAdvanced?: boolean;
    incentiveProgress: any;
    toggleRewardType: any;
}

interface IState {
    otherLimit: number | null;
    setFieldValue: any;
    showAdvanced: boolean;
    showOtherLimit: boolean;
    alert: any;
    openPopper: boolean;
    showChoiceMessage: boolean;
    prevDuration: number;
    newDuration: number;
    confirmationAlertShowing: boolean;
    confirmationAlertTitle: string;
    formValues: any;
}

class IncentiveForm extends React.Component<IProps, IState> {
    private nameInputRef;
    private hoveringOnInputField = false;
    private hoveringOnPopper = false;

    constructor(props: IProps) {
        super(props);
        const { groupId, groups, incentiveId } = this.props;
        const incentive = groupId && incentiveId ? groups.data[groupId].incentives[incentiveId] : null;
        let showAdvanced = false;

        if ((incentive && (incentive.limit || incentive.details || incentive.expiration)) || this.props.showAdvanced) {
            showAdvanced = true;
        }

        this.state = {
            otherLimit: null,
            setFieldValue: null,
            showAdvanced,
            showOtherLimit: false,
            alert: {
                showing: false,
            },
            openPopper: false,
            showChoiceMessage: false,
            prevDuration: incentive && incentive.duration ? incentive.duration : 0,
            newDuration: incentive && incentive.duration ? incentive.duration : 0,
            confirmationAlertShowing: false,
            confirmationAlertTitle: 'Lowering the goal time may cause some rewards to be completed',
            formValues: null,
        };
    }

    public render() {
        const { classes, incentiveId } = this.props;
        const { alert, otherLimit, showAdvanced, showOtherLimit, openPopper, confirmationAlertShowing } = this.state;
        const title = incentiveId ? 'Edit Reward' : 'Create Reward';

        return (
            <React.Fragment>
                <AlertTextInput value={otherLimit} showing={showOtherLimit} onClose={this.handleOtherLimit} />
                <AlertDialog alert={alert} onClose={this.alertClosed} />
                <ConfirmationAlert
                    showing={confirmationAlertShowing}
                    onClose={this.confirmationAlertCancelled}
                    onConfirm={this.confirmationAlertConfirmed}
                    title={this.state.confirmationAlertTitle}
                    content="Are you sure you want to continue?"
                    closeText="Cancel"
                    confirmText="Continue"
                />
                <Formik initialValues={this.getInitialValues()} validationSchema={IncentiveSchema} onSubmit={this.onSubmit}>
                    {({ values, errors, touched, handleChange, handleSubmit, isSubmitting, setFieldValue, setValues }) => (
                        <form onSubmit={handleSubmit} noValidate={true}>
                            <Grid container={true} justifyContent="center" alignItems="center">
                                <Grid item={true} xs={12} className={classes.authContainer}>
                                    <Typography component="h1" className={classes.pageTitle}>
                                        {title}
                                    </Typography>
                                    {incentiveId ? '' : this.renderRewardTypeToggle()}
                                    {this.renderMessage()}
                                    <div style={{ paddingTop: 32 }} className="schoolPickerShadow">
                                        <Downshift
                                            onSelect={(value) => this.onSelectItem(value, setValues)}
                                            itemToString={(item) => (item ? item.label : '')}
                                            inputValue={values.name}
                                        >
                                            {({ getInputProps, getItemProps, getMenuProps, isOpen, inputValue, highlightedIndex, selectedItem }) => (
                                                <div className={classes.container}>
                                                    <FormControl margin="normal" required={true} fullWidth={true} error={!!errors.name && !!touched.name}>
                                                        <InputLabel shrink={true} htmlFor="name">
                                                            Reward Name
                                                        </InputLabel>
                                                        <Input
                                                            autoComplete="off"
                                                            inputProps={{ 'data-hj-whitelist': true }}
                                                            placeholder="e.g. Extra credit, Free Snacks, Class DJ"
                                                            id="name"
                                                            name="name"
                                                            multiline={true}
                                                            onFocus={this.inputOnFocus}
                                                            onBlur={this.inputOnBlur}
                                                            onChange={(e) => this.onInputChanged(e, handleChange)}
                                                            value={values.name}
                                                            onMouseOver={() => {
                                                                this.hoveringOnInputField = true;
                                                            }}
                                                            onMouseOut={() => {
                                                                this.hoveringOnInputField = false;
                                                            }}
                                                        />
                                                        <Tooltip isInput={true} page={title} id="rewardName">
                                                            What are you going to give as a reward when the time goal is reached?
                                                        </Tooltip>
                                                    </FormControl>
                                                    <div ref={(ref) => (this.nameInputRef = ref)} />
                                                    <Popper
                                                        open={openPopper}
                                                        anchorEl={this.nameInputRef}
                                                        placement="bottom"
                                                        disablePortal={true}
                                                        modifiers={{
                                                            flip: {
                                                                enabled: false,
                                                            },
                                                            preventOverflow: {
                                                                enabled: false,
                                                            },
                                                            hide: {
                                                                enabled: false,
                                                            },
                                                        }}
                                                        style={{ zIndex: 100 }}
                                                        onMouseOver={() => {
                                                            this.hoveringOnPopper = true;
                                                        }}
                                                        onMouseOut={() => {
                                                            this.hoveringOnPopper = false;
                                                        }}
                                                    >
                                                        <div {...(isOpen ? getMenuProps({}, { suppressRefError: true }) : {})}>
                                                            <Paper
                                                                square={true}
                                                                style={{
                                                                    width: this.nameInputRef ? this.nameInputRef.clientWidth : null,
                                                                    maxHeight: 210,
                                                                    overflowY: 'scroll',
                                                                    zIndex: 100,
                                                                }}
                                                            >
                                                                {this.renderSuggestions(inputValue, getItemProps, highlightedIndex, selectedItem)}
                                                            </Paper>
                                                        </div>
                                                    </Popper>
                                                </div>
                                            )}
                                        </Downshift>
                                        <IncentiveDuration
                                            duration={values.duration}
                                            handleDurationChanged={({ id, value }) => this.handleDurationChanged(setFieldValue, { id, value })}
                                            page={title}
                                        />
                                        <FormControl margin="normal" fullWidth={true} style={{ paddingTop: 16, opacity: 0.5, cursor: 'pointer' }}>
                                            <Grid container={true} style={{ cursor: 'pointer' }} onClick={this.showAdvanced}>
                                                <Grid item={true} xs={6}>
                                                    <Typography>Advanced Settings</Typography>
                                                </Grid>
                                                <Grid item={true} xs={6} style={{ textAlign: 'right' }}>
                                                    {!showAdvanced && <ExpandMore />}
                                                    {showAdvanced && <ExpandLess />}
                                                </Grid>
                                            </Grid>
                                        </FormControl>
                                        {!!showAdvanced && (
                                            <React.Fragment>
                                                <FormControl margin="normal" fullWidth={true}>
                                                    <InputLabel shrink={true} htmlFor="details">
                                                        Reward Details
                                                    </InputLabel>
                                                    <Input
                                                        inputProps={{ 'data-hj-whitelist': true }}
                                                        multiline={true}
                                                        placeholder="e.g. must be redeemed within 24 hours"
                                                        id="details"
                                                        autoFocus={!showAdvanced}
                                                        name="details"
                                                        value={values.details}
                                                        onChange={handleChange}
                                                    />
                                                    <Tooltip isInput={true} page={incentiveId ? 'Edit Reward' : 'Create Reward'} id="rewardDetails">
                                                        This is the “fine print” for your reward. Are there any special restrictions or circumstances your
                                                        students need to be aware of?
                                                    </Tooltip>
                                                </FormControl>
                                                <FormControl margin="normal" required={true} fullWidth={true} style={{ paddingTop: 16 }}>
                                                    <Grid container={true} style={{ justifyContent: 'space-between' }} wrap="nowrap">
                                                        <Grid item={true}>
                                                            <Typography style={{ opacity: 0.5 }}>How many times can this reward be used per user?</Typography>
                                                        </Grid>
                                                        <Grid item={true}>
                                                            <Tooltip page={incentiveId ? 'Edit Reward' : 'Create Reward'} id="rewardLimit">
                                                                Set a limit on how many times each student can earn this reward.
                                                            </Tooltip>
                                                        </Grid>
                                                    </Grid>
                                                    <Typography
                                                        id="unlimited"
                                                        style={{ paddingTop: 16 }}
                                                        className={!values.limit ? classes.selectedUserLimit : classes.unSelectedUserLimit}
                                                        onClick={() => setFieldValue('limit', null)}
                                                    >
                                                        Unlimited
                                                    </Typography>
                                                    <Typography
                                                        id="one_use"
                                                        className={values.limit === 1 ? classes.selectedUserLimit : classes.unSelectedUserLimit}
                                                        onClick={() => setFieldValue('limit', 1)}
                                                    >
                                                        1 use only
                                                    </Typography>
                                                    <Typography
                                                        className={values.limit && values.limit > 1 ? classes.selectedUserLimit : classes.unSelectedUserLimit}
                                                        onClick={() => this.showOtherLimit(setFieldValue)}
                                                    >
                                                        {values.limit && values.limit > 1 ? `${values.limit} total uses` : 'Other'}
                                                    </Typography>
                                                </FormControl>
                                                <IncentiveExpiration
                                                    value={values.expiration}
                                                    onChanged={(value) => setFieldValue('expiration', value)}
                                                    page={title}
                                                />
                                            </React.Fragment>
                                        )}
                                        {!incentiveId && (
                                            <Submit loading={isSubmitting} style={{ marginTop: 24 }}>
                                                Create Reward
                                            </Submit>
                                        )}
                                    </div>
                                </Grid>
                                {incentiveId && this.renderCancelSaveButtons()}
                            </Grid>
                        </form>
                    )}
                </Formik>
            </React.Fragment>
        );
    }

    private renderMessage = () => {
        const { classes } = this.props;
        const { showChoiceMessage } = this.state;
        if (showChoiceMessage) {
            return (
                <div className={classes.rewardSuggestionMessageContainer}>
                    <Typography style={{ opacity: 0.74, color: '#0F4E3A' }}>
                        <span style={{ fontWeight: 700 }}>Great Choice! </span>
                        Now just edit this to your liking and you're all set.
                    </Typography>
                </div>
            );
        }
        return null;
    };

    private renderSuggestions = (inputValue, getItemProps, highlightedIndex, selectedItem) => {
        const { classes } = this.props;

        if (inputValue.length > 0) {
            this.hoveringOnPopper = false;
            return null;
        }

        return (
            <React.Fragment>
                <div className={classes.rewardSuggestionCell}>
                    <Typography>Most Popular</Typography>
                </div>
                {IncentiveSuggestionsData.map((suggestion, index) => {
                    const isSelected = selectedItem ? suggestion === selectedItem.name : false;

                    return (
                        <ListItem
                            {...getItemProps({ item: suggestion })}
                            key={index}
                            component="div"
                            style={{ fontWeight: isSelected ? 500 : 400, cursor: 'pointer' }}
                        >
                            <ListItemText primary={suggestion.title} secondary={suggestion.subTitle} />
                        </ListItem>
                    );
                })}
            </React.Fragment>
        );
    };

    private renderCancelSaveButtons = () => {
        const { classes, handleClose } = this.props;

        return (
            <Grid container={true} style={{ textAlign: 'center', paddingTop: 24 }}>
                <Grid item={true} xs={6}>
                    <Button variant="contained" color="primary" className={classes.cancelButton} onClick={() => handleClose(false)}>
                        Cancel
                    </Button>
                </Grid>
                <Grid item={true} xs={6}>
                    <Button type="submit" variant="contained" color="primary" className={classes.saveButton}>
                        Save
                    </Button>
                </Grid>
            </Grid>
        );
    };

    private onInputChanged = (e, handleChange) => {
        handleChange(e);
        if (e.target.value.length === 0 && this.state.showChoiceMessage) {
            this.setState({
                showChoiceMessage: false,
            });
        }
    };

    private inputOnFocus = () => {
        this.setState({ openPopper: true });
    };

    private inputOnBlur = () => {
        this.setState({ openPopper: false });
    };

    private onHidePopper = () => {
        if (!this.hoveringOnInputField && !this.hoveringOnPopper) {
            this.setState({ openPopper: false });
        }
    };

    private onSelectItem = (val, setValues) => {
        setValues({
            name: val.title,
            duration: val.duration,
        });
        this.setState({
            showAdvanced: true,
            showChoiceMessage: true,
        });
    };

    private getInitialValues = () => {
        const { groupId, groups, incentiveId } = this.props;
        const incentive = groupId && incentiveId ? groups.data[groupId].incentives[incentiveId] : null;
        let values = {
            name: '',
            details: '',
            duration: 3600,
            expiration: null,
            limit: null,
        };

        if (incentive) {
            values = {
                ...values,
                ...incentive,
            };
        }

        return values;
    };

    private showAdvanced = () => {
        this.setState({ showAdvanced: !this.state.showAdvanced });
    };

    private showOtherLimit = (setFieldValue) => {
        this.setState({ setFieldValue, showOtherLimit: true });
    };

    private handleOtherLimit = (value) => {
        const { setFieldValue } = this.state;
        const limit = value > 0 ? value : null;

        setFieldValue('limit', limit);
        this.setState({
            otherLimit: limit,
            showOtherLimit: false,
        });
    };

    private onSubmit = (values, { resetForm, setSubmitting }) => {
        const {
            groupId,
            groups: { actions },
            incentiveId,
            handleClose,
        } = this.props;
        const formAction = groupId && incentiveId ? actions.updateIncentive : actions.createIncentive;

        if (incentiveId && !this.state.confirmationAlertShowing) {
            const rewardsCompleted = this.calculateRewardsCompleted();
            if (rewardsCompleted > 0) {
                this.handleConfirmationAlert(rewardsCompleted, { values, resetForm, setSubmitting });
                return;
            }
        }

        formAction({ values, groupId, incentiveId })
            .then(() => {
                resetForm();
                handleClose(true);
            })
            .catch(() => {
                setSubmitting(false);
                this.setState({
                    alert: {
                        showing: true,
                        title: 'Sorry',
                        message: 'Something went wrong. Try again',
                    },
                });
            });
    };

    private alertClosed = () => {
        this.setState({ alert: { showing: false } });
    };

    private calculateRewardsCompleted = () => {
        const { incentiveId, incentiveProgress } = this.props;
        const { newDuration } = this.state;
        let numRewards = 0;

        if (incentiveProgress) {
            Object.keys(incentiveProgress).forEach((key: any) => {
                const { current } = incentiveProgress[key];
                if (incentiveProgress[key].incentiveId === incentiveId && current >= newDuration) {
                    numRewards += Math.floor(current / newDuration);
                }
            });
        }

        return numRewards;
    };

    private handleConfirmationAlert = (rewardsCompleted: number, formValues) => {
        this.setState({
            confirmationAlertShowing: true,
            confirmationAlertTitle: `Lowering the goal time will cause ${rewardsCompleted} reward${rewardsCompleted > 1 ? 's' : ''} to be completed`,
            formValues,
        });
    };

    private confirmationAlertCancelled = () => {
        this.setState({ confirmationAlertShowing: false });
    };

    private confirmationAlertConfirmed = () => {
        const { values, resetForm, setSubmitting } = this.state.formValues;
        this.onSubmit(values, { resetForm, setSubmitting });
    };

    private handleDurationChanged = (setFieldValue, { id, value }) => {
        setFieldValue(id, value);
        this.setState({ newDuration: value });
    };

    private renderRewardTypeToggle = () => {
        const { classes, toggleRewardType } = this.props;

        return (
            <Grid item={true} xs={12} container={true} direction="row" style={{ paddingTop: 36 }}>
                <Grid item={true} xs={6} className={`${classes.rewardTypeToggle} ${classes.individualRewardSelected}`}>
                    <Text
                        style={{
                            paddingTop: 16,
                            paddingBottom: 16,
                            whiteSpace: 'pre-wrap',
                            textAlign: 'center',
                            color: '#00D793',
                        }}
                    >{`Individual Student\nReward`}</Text>
                </Grid>
                <Grid
                    item={true}
                    xs={6}
                    className={`${classes.rewardTypeToggle} ${classes.groupRewardUnselected}`}
                    onClick={toggleRewardType}
                    container={true}
                    direction="column"
                    justifyContent="center"
                    alignItems="stretch"
                >
                    <Badge
                        badgeContent={
                            <Text bold={true} fontSize={10} style={{ color: '#fff', padding: 4 }}>
                                NEW
                            </Text>
                        }
                        color="primary"
                    >
                        <Grid item={true} xs={12} style={{ paddingTop: 16, paddingBottom: 16 }}>
                            <img src={`/images/people-outline.png`} alt="people icon" style={{ width: 18, height: 14, marginBottom: '3px' }} />
                            <Text light={true} style={{ color: '#000' }}>
                                Class Wide Reward
                            </Text>
                        </Grid>
                    </Badge>
                </Grid>
            </Grid>
        );
    };
}

const mapStoreToProps = (state: any) => {
    return {
        groups: state.groups,
        incentiveProgress: state.incentiveProgress.data,
    };
};

export default connect(
    mapStoreToProps,
    null
)(withStyles(appStyle)(IncentiveForm));
