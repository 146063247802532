import { Button, Grid } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { People } from '@material-ui/icons';
import React from 'react';
import { isMobile } from 'react-device-detect';
import Text from '../../components/Text';
import appStyle from '../App.style';

interface IProps {
    classes: any;
    onClick: any;
}

class NoTeachers extends React.Component<IProps> {
    public render() {
        const { classes, onClick } = this.props;

        return (
            <Grid container={true} item={true} xs={12} justifyContent="center" alignItems="center" direction="column" style={{ paddingLeft: 32, paddingRight: 32 }}>
                <People className={classes.noResultsIcon} />
                <Text fontSize={isMobile ? 17 : 18} bold={true} gutterBottom={true} style={{ textAlign: 'center' }}>
                    No other teachers have signed up
                </Text>
                <Text fontSize={isMobile ? 17 : 18} extraLight={true} gutterBottom={true} style={{ maxWidth: 540, textAlign: 'center' }}>
                    Students are 50% more likely to stay off their phone when at least two teachers offer rewards
                </Text>
                <Button color="primary" variant="contained" className={classes.primaryButton} onClick={onClick}>
                    Invite Teachers
                </Button>
            </Grid>
        );
    }
}

export default withStyles(appStyle)(NoTeachers);
