import { Grid, Typography } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import React from 'react';
import { connect } from 'react-redux';
import appStyle from '../App.style';

interface IProps {
    classes: any;
    groups: any;
    incentiveProgress: any;
}

class Tiles extends React.Component<IProps> {
    public render() {
        const { classes } = this.props;

        return (
            <Grid container={true} className={classes.dashboardHeaderTiles} wrap="nowrap" style={{ overflowX: 'scroll' }}>
                <Grid container={true} item={true} sm={4} style={{ minWidth: 160 }} direction="column">
                    <Typography component="span" className={classes.dashboardTitle}>
                        Total Hours off Phone
                    </Typography>
                    <Typography component="span" className={classes.dashboardValue}>
                        {this.totalHoursOffPhone()}
                    </Typography>
                </Grid>
                <Grid container={true} item={true} sm={4} style={{ minWidth: 160 }} direction="column" className={classes.dashboardTile}>
                    <Typography component="span" className={classes.dashboardTitle}>
                        Total Students
                    </Typography>
                    <Typography component="span" className={classes.dashboardValue}>
                        {this.totalUsers()}
                    </Typography>
                </Grid>
                <Grid container={true} item={true} sm={4} style={{ minWidth: 220 }} direction="column" className={classes.dashboardTile}>
                    <Typography component="span" className={classes.dashboardTitle}>
                        Total Rewards Completed
                    </Typography>
                    <Typography component="span" className={classes.dashboardValue}>
                        {this.totalIncentivesCompleted()}
                    </Typography>
                </Grid>
            </Grid>
        );
    }

    private totalHoursOffPhone = () => {
        const {
            groups,
            incentiveProgress: { data },
        } = this.props;
        let inProgressSeconds = 0;

        if (data) {
            Object.keys(data)
                .filter((key) => {
                    const { classroomId, incentiveId } = data[key];

                    return groups.data[classroomId] && groups.data[classroomId].incentives[incentiveId];
                })
                .forEach((key) => {
                    inProgressSeconds += data[key].current;

                    data[key].completed.forEach((completed) => {
                        inProgressSeconds += completed.duration;
                    });
                });
        }

        return Math.floor(inProgressSeconds / 3600);
    };

    private totalUsers = () => {
        const { data } = this.props.groups;
        const uniqueUsers: [string?] = [];

        if (data) {
            Object.keys(data).forEach((key) => {
                Object.keys(data[key].students).forEach((userId: string) => {
                    if (!uniqueUsers.includes(userId)) {
                        uniqueUsers.push(userId);
                    }
                });
            });
        }
        return uniqueUsers.length;
    };

    private totalIncentivesCompleted = () => {
        const {
            groups,
            incentiveProgress: { data },
        } = this.props;
        let incentivesCompleted = 0;

        if (data) {
            Object.keys(data)
                .filter((key) => {
                    const { classroomId, incentiveId } = data[key];

                    return groups.data[classroomId] && groups.data[classroomId].incentives[incentiveId];
                })
                .forEach((key) => {
                    incentivesCompleted += data[key].completed.length;
                });
        }

        return incentivesCompleted;
    };
}

const mapStoreToProps = (state: any) => {
    return {
        groups: state.groups,
        incentiveProgress: state.incentiveProgress,
    };
};

export default connect(
    mapStoreToProps,
    null
)(withStyles(appStyle)(Tiles));
