import {
    Avatar,
    Button,
    Dialog,
    DialogContent,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    ListSubheader,
} from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { AddCircle, RemoveCircle } from '@material-ui/icons';
import React from 'react';
import { isMobile } from 'react-device-detect';
import AlertYesNo from '../../../components/AlertYesNo';
import BlankAvatar from '../../../components/BlankAvatar';
import CloseButton from '../../../components/CloseButton';
import Text from '../../../components/Text';
import appStyle from '../../App.style';

interface IProps {
    classes: any;
    blockStudents: any;
    restoreStudents: any;
    open: any;
    group: any;
    onClose: any;
}

interface IState {
    studentsToBlock: string[];
    studentsToRestore: string[];
    showBlockStudentsAlert: boolean;
}

class StudentsModal extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            studentsToBlock: [],
            studentsToRestore: [],
            showBlockStudentsAlert: false,
        };
    }

    public render() {
        const { classes, onClose, group } = this.props;
        const { studentsToBlock, studentsToRestore, showBlockStudentsAlert } = this.state;
        const showActive = Object.keys(group.students).length > 0;
        const showBlocked = group.blockedStudents != null ? Object.keys(group.blockedStudents).length > 0 : false;

        return (
            <div>
                <AlertYesNo
                    showing={showBlockStudentsAlert}
                    onClose={this.hideBlockStudentsAlert}
                    onConfirm={this.blockStudents}
                    title="Are you sure you want to block these users?"
                    content="They will be blocked and not allowed to join this classroom until you unblock them."
                    closeText="Cancel"
                    confirmText="Continue"
                />
                <Dialog fullScreen={isMobile} open={this.props.open} onClose={onClose} maxWidth="sm" fullWidth={true} scroll="paper">
                    <DialogContent style={{ padding: 0, paddingBottom: 50, position: 'relative' }}>
                        <Grid container={true} item={true} xs={12} style={{ padding: 16 }} justifyContent="space-between">
                            <CloseButton onClick={onClose} />
                        </Grid>
                        <Grid container={true} item={true} xs={12} className={classes.modalBody}>
                            <Text isTitle={true}>Manage Students</Text>
                            {showActive && (
                                <List
                                    style={{
                                        borderWidth: 1,
                                        borderStyle: 'solid',
                                        borderColor: '#EBEBEB',
                                        borderBottom: 'none',
                                        borderTopLeftRadius: 8,
                                        borderTopRightRadius: 8,
                                        marginTop: 24,
                                        width: '100%',
                                    }}
                                    disablePadding={true}
                                >
                                    <ListSubheader
                                        style={{
                                            backgroundColor: '#fcfcfc',
                                            borderBottom: '1px solid #ebebeb',
                                            borderTopLeftRadius: 8,
                                            borderTopRightRadius: 8,
                                            height: 50,
                                            display: 'flex',
                                        }}
                                    >
                                        <Grid container={true} item={true} justifyContent="space-between" alignItems="center" wrap="nowrap">
                                            <Text fontSize={16} semiBold={true}>
                                                Active Students
                                            </Text>
                                            {studentsToBlock.length > 0 && (
                                                <Button size="small" onClick={this.showBlockStudentsAlert} style={{ color: '#f44336', textTransform: 'none' }}>
                                                    Block Selected Students
                                                </Button>
                                            )}
                                        </Grid>
                                    </ListSubheader>
                                    {this.renderActiveList()}
                                </List>
                            )}
                            {showBlocked && (
                                <List
                                    style={{
                                        borderWidth: 1,
                                        borderStyle: 'solid',
                                        borderColor: '#EBEBEB',
                                        borderBottom: 'none',
                                        borderTopLeftRadius: 8,
                                        borderTopRightRadius: 8,
                                        marginTop: 24,
                                        width: '100%',
                                    }}
                                    disablePadding={true}
                                >
                                    <ListSubheader
                                        style={{
                                            backgroundColor: '#fcfcfc',
                                            borderBottom: '1px solid #ebebeb',
                                            borderTopLeftRadius: 8,
                                            borderTopRightRadius: 8,
                                            height: 50,
                                            display: 'flex',
                                        }}
                                    >
                                        <Grid container={true} item={true} justifyContent="space-between" alignItems="center" wrap="nowrap">
                                            <Text fontSize={16} semiBold={true}>
                                                Blocked Students
                                            </Text>
                                            {studentsToRestore.length > 0 && (
                                                <Button color="primary" size="small" onClick={this.restoreStudents} style={{ textTransform: 'none' }}>
                                                    Restore Selected Students
                                                </Button>
                                            )}
                                        </Grid>
                                    </ListSubheader>
                                    {this.renderBlockedList()}
                                </List>
                            )}
                        </Grid>
                    </DialogContent>
                </Dialog>
            </div>
        );
    }

    private renderActiveList = () => {
        const { group } = this.props;
        const { studentsToBlock } = this.state;

        const students = Object.keys(group.students).map((id) => {
            return {
                ...group.students[id],
                selected: studentsToBlock.includes(id),
                id,
            };
        });

        return students
            .sort((a, b) => {
                if (a.name.toUpperCase() < b.name.toUpperCase()) {
                    return -1;
                }
                if (a.name.toUpperCase() > b.name.toUpperCase()) {
                    return 1;
                }
                return 0;
            })
            .map((student, index) => {
                const iconColor = student.selected ? 'error' : 'disabled';

                return (
                    <ListItem key={index} divider={true}>
                        <ListItemAvatar>{this.renderAvatar(student)}</ListItemAvatar>
                        <ListItemText primary={student.name} />
                        <ListItemSecondaryAction>
                            <IconButton onClick={() => this.toggleStudentToBlock(student.id)}>
                                <RemoveCircle color={iconColor} />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                );
            });
    };

    private renderBlockedList = () => {
        const { group } = this.props;
        const { studentsToRestore } = this.state;
        const blockedStudents = Object.keys(group.blockedStudents).map((id) => {
            return {
                ...group.blockedStudents[id],
                selected: studentsToRestore.includes(id),
                id,
            };
        });

        return blockedStudents
            .sort((a, b) => {
                if (a.name.toUpperCase() < b.name.toUpperCase()) {
                    return -1;
                }
                if (a.name.toUpperCase() > b.name.toUpperCase()) {
                    return 1;
                }
                return 0;
            })
            .map((student, index) => {
                const iconColor = student.selected ? 'primary' : 'disabled';

                return (
                    <ListItem key={index} divider={true}>
                        <ListItemAvatar>{this.renderAvatar(student)}</ListItemAvatar>
                        <ListItemText primary={student.name} />
                        <ListItemSecondaryAction>
                            <IconButton onClick={() => this.toggleStudentToRestore(student.id)}>
                                <AddCircle color={iconColor} />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                );
            });
    };

    private renderAvatar = (student) => {
        if (student.imageUrl) {
            return <Avatar src={student.imageUrl} style={{ border: '1px solid #ececec' }} />;
        }

        return <BlankAvatar name={student.name} style={{ border: '1px solid #ececec' }} />;
    };

    private toggleStudentToBlock = (id) => {
        const { studentsToBlock } = this.state;
        const filtered = studentsToBlock.filter((studentId) => studentId !== id);

        this.setState({
            studentsToBlock: filtered.length !== studentsToBlock.length ? filtered : [...studentsToBlock, id],
        });
    };

    private toggleStudentToRestore = (id) => {
        const { studentsToRestore } = this.state;
        const filtered = studentsToRestore.filter((studentId) => studentId !== id);

        this.setState({
            studentsToRestore: filtered.length !== studentsToRestore.length ? filtered : [...studentsToRestore, id],
        });
    };

    private showBlockStudentsAlert = () => {
        this.setState({ showBlockStudentsAlert: true });
    };

    private hideBlockStudentsAlert = () => {
        this.setState({ showBlockStudentsAlert: false });
    };

    private restoreStudents = () => {
        const { restoreStudents } = this.props;
        const { studentsToRestore } = this.state;

        restoreStudents(studentsToRestore).then(() => {
            this.setState({ studentsToRestore: [] });
        });
    };

    private blockStudents = () => {
        const { blockStudents } = this.props;
        const { studentsToBlock } = this.state;

        blockStudents(studentsToBlock).then(() => {
            this.setState({ showBlockStudentsAlert: false, studentsToBlock: [] });
        });
    };
}

export default withStyles(appStyle)(withMobileDialog<IProps>()(StudentsModal));
