import { Button, Grid, withStyles } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import { Moment } from 'moment';
import React from 'react';
import appStyle from '../App.style';

interface IProps {
    classes: any;
    date: Moment;
    onSubtractDay: any;
    onOpenDate: any;
    onAddDay: any;
}

class DateSelector extends React.PureComponent<IProps> {
    public render() {
        const { classes, date, onAddDay, onOpenDate, onSubtractDay } = this.props;
        return (
            <Grid container={true} item={true} justifyContent="flex-end" className={classes.dashboardDailyHeader}>
                <Button variant="text" size="small" className={classes.dashboardDailyDateButton} onClick={() => onSubtractDay()}>
                    <Icons.ChevronLeft fontSize="small" />
                </Button>
                <Button variant="text" size="small" className={classes.dashboardDailyDateButton} onClick={() => onOpenDate()}>
                    <Icons.CalendarToday fontSize="small" className={classes.dashboardDailyDateButtonMargin} />
                    {date.format('MMM Do, YYYY')}
                </Button>
                <Button variant="text" size="small" className={classes.dashboardDailyDateButton} onClick={() => onAddDay()}>
                    <Icons.ChevronRight fontSize="small" />
                </Button>
            </Grid>
        );
    }
}

export default withStyles(appStyle)(DateSelector);
