import { Grid } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import * as Icons from '@material-ui/icons';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import Select, { components } from 'react-select';
import { FILTER_DATE_OPTIONS } from '../../utils/constants';
import appStyle from '../App.style';

interface IProps {
    classes: any;
    groups: any;
    onFilterChanged: any;
    filterInputs: any;
    mixpanel: {
        actions: any;
    };
    hideDate?: boolean;
}

class Filters extends React.Component<IProps> {
    public render() {
        const { classes, filterInputs, hideDate } = this.props;
        const groupOptions = this.getGroupOptions();
        const groupValue = groupOptions.find((option) => option.value === filterInputs.groupFilter);
        const incentiveOptions = this.getIncentiveOptions();
        const incentiveValue = incentiveOptions.find((option) => option.value === filterInputs.incentiveFilter);
        const userOptions = this.getUserOptions();
        const userValue = userOptions.find((option) => option.value === filterInputs.userFilter);
        const dateValue = FILTER_DATE_OPTIONS.find((option) => option.value === filterInputs.dateFilter);

        return (
            <Grid container={true} style={{ marginTop: 6, marginBottom: 6 }}>
                <Grid item={true} sm={3} xs={12} style={{ paddingRight: isMobile ? 0 : 10 }}>
                    <Select
                        value={userValue}
                        isClearable={true}
                        options={userOptions}
                        placeholder="Filter by Student"
                        onChange={(option) => this.onFilterChanged(option, 'userFilter')}
                        className={classes.dashboardSelect}
                        components={{ IndicatorSeparator: () => null }}
                        theme={this.selectTheme}
                    />
                </Grid>
                <Grid item={true} sm={3} xs={12} style={{ paddingRight: isMobile ? 0 : 10 }}>
                    <Select
                        value={groupValue}
                        isClearable={true}
                        options={groupOptions}
                        placeholder="Filter by Class"
                        onChange={(option) => this.onFilterChanged(option, 'groupFilter')}
                        className={classes.dashboardSelect}
                        components={{ IndicatorSeparator: () => null }}
                        theme={this.selectTheme}
                    />
                </Grid>
                <Grid item={true} sm={3} xs={12} style={{ paddingRight: isMobile || hideDate ? 0 : 10 }}>
                    <Select
                        value={incentiveValue}
                        isClearable={true}
                        options={incentiveOptions}
                        placeholder="Filter by Reward"
                        onChange={(option) => this.onFilterChanged(option, 'incentiveFilter')}
                        className={classes.dashboardSelect}
                        components={{ IndicatorSeparator: () => null }}
                        theme={this.selectTheme}
                    />
                </Grid>
                {!hideDate && (
                    <Grid container={true} item={true} sm={3} xs={12} justifyContent="flex-end">
                        <Select
                            value={dateValue}
                            options={FILTER_DATE_OPTIONS}
                            placeholder="Filter by Range"
                            onChange={(option) => this.onFilterChanged(option, 'dateFilter')}
                            className={classes.dashboardSelect}
                            components={{ IndicatorSeparator: () => null, ValueContainer: this.dateOptionValueContainer }}
                            theme={this.selectTheme}
                        />
                    </Grid>
                )}
            </Grid>
        );
    }

    private dateOptionValueContainer = (allProps) => {
        const { children, ...props } = allProps;

        return (
            <components.ValueContainer {...props} style={{ width: '100%' }}>
                <Icons.CalendarToday fontSize="small" style={{ opacity: 0.35 }} />
                <div style={{ paddingLeft: 4 }}>{children}</div>
            </components.ValueContainer>
        );
    };

    private getGroupOptions = () => {
        const { data } = this.props.groups;
        const options: any = [];

        if (data) {
            Object.keys(data).forEach((key) => {
                const option = {
                    value: data[key].attributes.name,
                    label: data[key].attributes.name,
                };
                const found = options.find((element) => element.value === option.value);

                if (!found) {
                    options.push(option);
                }
            });
        }

        options.sort((a, b) => (a.label > b.label ? 1 : -1));

        return options;
    };

    private getIncentiveOptions = () => {
        const { data } = this.props.groups;
        const options: any = [];

        if (data) {
            Object.keys(data).forEach((dataKey) => {
                if (data[dataKey].incentives) {
                    Object.keys(data[dataKey].incentives).forEach((incentiveKey) => {
                        const option = {
                            value: data[dataKey].incentives[incentiveKey].name,
                            label: data[dataKey].incentives[incentiveKey].name,
                        };
                        const found = options.find((element) => element.value === option.value);

                        if (!found) {
                            options.push(option);
                        }
                    });
                }
                if (data[dataKey].groupIncentives) {
                    Object.keys(data[dataKey].groupIncentives).forEach((groupIncentiveKey) => {
                        const option = {
                            value: data[dataKey].groupIncentives[groupIncentiveKey].name,
                            label: data[dataKey].groupIncentives[groupIncentiveKey].name,
                        };
                        const found = options.find((element) => element.value === option.value);

                        if (!found) {
                            options.push(option);
                        }
                    });
                }
            });
        }

        options.sort((a, b) => (a.label > b.label ? 1 : -1));

        return options;
    };

    private getUserOptions = () => {
        const { data } = this.props.groups;
        const options: any = [];

        if (data) {
            Object.keys(data).forEach((dataKey) => {
                if (data[dataKey].students) {
                    Object.keys(data[dataKey].students).forEach((studentKey) => {
                        const option = {
                            value: studentKey,
                            label: data[dataKey].students[studentKey].name,
                        };
                        const found = options.find((element) => element.value === option.value);

                        if (!found) {
                            options.push(option);
                        }
                    });
                }
            });
        }

        options.sort((a, b) => (a.label > b.label ? 1 : -1));

        return options;
    };

    private onFilterChanged = (option, filter) => {
        const { onFilterChanged } = this.props;

        onFilterChanged({ [filter]: option ? option.value : null });
    };

    private selectTheme = (theme) => {
        return {
            ...theme,
            colors: {
                ...theme.colors,
                primary: '#00D793',
                primary25: '#EBEBEB',
                primary50: '#00D793',
                primary75: '#00D793',
            },
        };
    };
}

const mapStoreToProps = (state: any) => {
    return {
        groups: state.groups,
        mixpanel: state.mixpanel,
    };
};

export default connect(
    mapStoreToProps,
    null
)(withStyles(appStyle)(Filters));
