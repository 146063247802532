export const signUp = '/signup';
export const login = '/login';
export const forgot = '/forgot';
export const account = '/account';
export const dashboard = '/dashboard';
export const groups = '/groups';
export const group = '/group/:id';
export const check = '/check';
export const school = '/school';
export const privacy = '/privacy';

export default {
    account,
    dashboard,
    forgot,
    group,
    groups,
    login,
    signUp,
    check,
    school,
    privacy,
};
