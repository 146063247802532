import { Button, Grid } from '@material-ui/core';
import React from 'react';
import { isMobile } from 'react-device-detect';
import Text from '../../components/Text';

interface IProps {
    classes: any;
    firstName: string;
    onClose: any;
}

class Intro extends React.Component<IProps> {
    public render() {
        const { classes, firstName, onClose } = this.props;
        const padding = isMobile ? {} : { paddingLeft: 140, paddingRight: 140 };

        return (
            <Grid container={true} justifyContent="center" alignItems="center" className={classes.pageHeader} style={padding}>
                <Grid item={true} xs={12}>
                    <Text bold={true} fontSize={24}>
                        Welcome, {firstName}!
                    </Text>
                </Grid>
                <Grid item={true} xs={12} style={{ marginTop: 12 }}>
                    <Text light={true} fontSize={20}>
                        Pocket Points offers two separate reward systems:
                    </Text>
                </Grid>
                <Grid container={true} item={true} xs={12} style={{ paddingLeft: 8, marginTop: 35 }} wrap="nowrap">
                    <img alt="gift" src="/images/gift.png" style={{ width: 30, height: 30 }} />
                    <Text bold={true} fontSize={26} style={{ paddingLeft: 20 }} noWrap={true}>
                        Gifts
                    </Text>
                </Grid>
                <Grid container={true} item={true} xs={12} style={{ paddingLeft: 8, marginTop: 5 }}>
                    <Text>
                        <Text fontSize={18} italic={true} color="primary" >
                            Purchased with points
                        </Text>
                        <Text fontSize={18} light={true} >
                            {' earned while driving, at home, or in class.'}
                        </Text>
                    </Text>
                </Grid>
                <Grid container={true} item={true} xs={12} style={{ paddingLeft: 8, marginTop: 35 }} wrap="nowrap">
                    <img alt="apple" src="/images/apple.png" style={{ width: 30, height: 30 }} />
                    <Text bold={true} fontSize={26} style={{ paddingLeft: 20 }} noWrap={true}>
                        Teacher Rewards
                    </Text>
                </Grid>
                <Grid container={true} item={true} xs={12} style={{ paddingLeft: 8, marginTop: 5 }}>
                    <Text>
                        <Text fontSize={18} italic={true} color="primary" >
                            Earned with time, not points.
                        </Text>
                        <Text fontSize={18} light={true} >
                            {' Based on phone-free time only in class.'}
                        </Text>
                    </Text>
                </Grid>
                <Grid container={true} direction="column" justifyContent="center" alignItems="center" className={classes.buttonLoaderWrapper} style={{ padding: 20 }}>
                    <Button onClick={onClose} variant="contained" color="primary" className={classes.primaryButton}>
                        Got it
                    </Button>
                </Grid>
            </Grid>
        );
    }
}

export default Intro;
