import * as yup from 'yup';

// yup.match = (key) => {
//     const message = 'Values do not match';
//     /* tslint:disable:only-arrow-functions */
//     const test = function(this: any, value) {
//         return value === this.parent[key];
//     };
//     /* tslint:enable:only-arrow-functions */

//     return yup.mixed().test('match', message, test);
// };

export const LoginSchema = yup.object().shape({
    email: yup
        .string()
        .email()
        .required(),
    password: yup.string().required(),
});

export const ForgotSchema = yup.object().shape({
    email: yup
        .string()
        .email()
        .required(),
});

export const SignUpSchema = yup.object().shape({
    displayName: yup.string().required(),
    email: yup
        .string()
        .email()
        .required(),
    password: yup
        .string()
        .min(6)
        .required(),
});

export const SetupSchema = yup.object().shape({
    teacherName: yup.string().required(),
    school: yup.object().shape({
        cityState: yup.string().required(),
        highSchool: yup.boolean(),
        id: yup
            .number()
            .integer()
            .required(),
        name: yup.string().required(),
        timeZone: yup.string().required(),
    }),
});

export const NewSchoolSchema = yup.object().shape({
    newSchoolName: yup.string().required(),
    newSchoolAddress: yup.string().required(),
});

export const StandardFormSchema = yup.object().shape({
    name: yup.string().required(),
    groupSize: yup
        .number()
        .typeError('Must be a number')
        .test('groupSize', 'Size must be greater or equal to joined students', function(this: any, value) {
            const length = parseInt(value.toString(), 10);

            return length >= this.parent.minGroupSize;
        }),
    start: yup
        .mixed()
        .required()
        .test('start', 'Start time must be less than end time', function(this: any, value) {
            const start = parseInt(value.replace(/:/gi, ''), 10);
            const end = parseInt(this.parent.end.replace(/:/gi, ''), 10);
            return start < end;
        }),
    end: yup.mixed().required(),
});

export const WeeklyFormSchema = yup.object().shape({
    name: yup.string().required(),
    groupSize: yup
        .number()
        .typeError('Must be a number')
        .test('groupSize', 'Size must be greater or equal to joined students', function(this: any, value) {
            const length = parseInt(value.toString(), 10);

            return length >= this.parent.minGroupSize;
        }),
    earningWindow: yup.object().shape({
        slots: yup.array().of(
            yup.object().shape({
                start: yup
                    .mixed()
                    .required()
                    .test('start', 'Start time must be less than end time', function(this: any, value) {
                        return value < this.parent.end;
                    }),
                end: yup.mixed().required(),
            })
        ),
    }),
});

export const RotatingFormSchema = yup.object().shape({
    name: yup.string().required(),
    groupSize: yup
        .number()
        .typeError('Must be a number')
        .test('groupSize', 'Size must be greater or equal to joined students', function(this: any, value) {
            const length = parseInt(value.toString(), 10);

            return length >= this.parent.minGroupSize;
        }),
    earningWindow: yup.object().shape({
        slots: yup.array().of(
            yup.object().shape({
                start: yup
                    .mixed()
                    .required()
                    .test('start', 'Start time must be less than end time', function(this: any, value) {
                        return value < this.parent.end;
                    }),
                end: yup.mixed().required(),
            })
        ),
    }),
});

export const ConfirmStandardFormSchema = yup.object().shape({
    start: yup
        .mixed()
        .required()
        .test('less-end', 'Start time must be less than end time', function(this: any, value) {
            const start = parseInt(value.replace(/:/gi, ''), 10);
            const end = parseInt(this.parent.end.replace(/:/gi, ''), 10);
            return start < end;
        }),
    end: yup.mixed().required(),
});

export const ConfirmWeeklyFormSchema = yup.object().shape({
    earningWindow: yup.object().shape({
        slots: yup.array().of(
            yup.object().shape({
                start: yup
                    .mixed()
                    .required()
                    .test('less-end', 'Start time must be less than end time', function(this: any, value) {
                        return value < this.parent.end;
                    }),
                end: yup.mixed().required(),
            })
        ),
    }),
});

export const ConfirmRotatingFormSchema = yup.object().shape({
    earningWindow: yup.object().shape({
        slots: yup.array().of(
            yup.object().shape({
                start: yup
                    .mixed()
                    .required()
                    .test('less-end', 'Start time must be less than end time', function(this: any, value) {
                        return value < this.parent.end;
                    }),
                end: yup.mixed().required(),
            })
        ),
    }),
});

export const StudentCountFormSchema = yup.object().shape({
    groupSize: yup
        .number()
        .typeError('Must be a number')
        .test('groupSize', 'Size must be greater or equal to joined students', function(this: any, value) {
            const length = parseInt(value.toString(), 10);

            return length >= this.parent.minGroupSize;
        }),
});

export const IncentiveSchema = yup.object().shape({
    name: yup.string().required(),
    duration: yup
        .number()
        .min(600)
        .max(180000)
        .required(),
});

export const GroupIncentiveSchema = yup.object().shape({
    name: yup.string().required(),
    iterations: yup.array().of(
        yup.object().shape({
            start: yup
                .number()
                .test('less-end', 'Start time must be less than end time', function(this: any, value) {
                    return this.parent.expiration ? value < this.parent.expiration : true;
                })
                .required('Start time is required'),
            expiration: yup.number().required('End time is required'),
        })
    ),
    isPrivate: yup.mixed().required(),
});

export const AccountSchema = yup.object().shape({
    displayName: yup.string().required(),
    teacherName: yup.string().required(),
    email: yup
        .string()
        .email()
        .required(),
    school: yup.object().shape({
        cityState: yup.string().required(),
        highSchool: yup.boolean(),
        id: yup
            .number()
            .integer()
            .required(),
        name: yup.string().required(),
        timeZone: yup.string().required(),
    }),
});

export const AccountEmailSchema = yup.object().shape({
    currentPassword: yup.string().required(),
    email: yup
        .string()
        .email()
        .required(),
});

export const AccountPasswordSchema = yup.object().shape({
    currentPassword: yup.string().required(),
    newPassword: yup
        .string()
        .min(6)
        .required(),
});
