import withStyles from '@material-ui/core/styles/withStyles';
import React from 'react';
import appStyle from '../app/App.style';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';

interface IProps {
    onClick: any;
    style?: any;
    iconStyle?: any;
    size?: 'small' | 'default' | 'large';
    padding?: 0 | 4 | 8;
    borderRadius?: number;
    light?: boolean;
}

class CloseButton extends React.PureComponent<IProps> {
    public render() {
        const { onClick, style, size, borderRadius, light, padding, iconStyle } = this.props;
        const wrapperStyle = {
            padding: padding !== undefined ? padding : 8,
            borderRadius: borderRadius !== undefined ? borderRadius : 4,
            opacity: light ? 0.5 : 1.0,
            ...style,
        };

        return (
            <IconButton style={wrapperStyle} onClick={onClick}>
                <Close fontSize={size} style={{ color: '#000', ...iconStyle }} />
            </IconButton>
        );
    }
}

export default withStyles(appStyle)(CloseButton);
