import { FormControl, Grid, Typography } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import React from 'react';
import Tooltip from '../../../components/Tooltip';
import appStyle from '../../App.style';

interface IProps {
    durationSeconds: number;
    totalSeconds: number;
    handleDurationSecondsChanged: any;
    page: string;
    handleHighPercentageAlert: any;
}

interface IState {
    initialPercentage: number;
    initialValue: number;
}

class ClasswideDurationSlider extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            initialPercentage: Math.round((this.props.durationSeconds / this.props.totalSeconds) * 100),
            initialValue: this.props.durationSeconds,
        };
    }

    public render() {
        const { durationSeconds, handleDurationSecondsChanged, totalSeconds, page, handleHighPercentageAlert } = this.props;
        const { initialPercentage, initialValue } = this.state;
        const minSeconds = Math.round((totalSeconds * 0.33) / 60) * 60;
        const durationPercent = Math.round((durationSeconds / totalSeconds) * 100);
        const avgMinutes = Math.round(durationSeconds / 60);

        return (
            <React.Fragment>
                <FormControl margin="normal" fullWidth={true} style={{ textAlign: 'center' }}>
                    <Grid container={true} wrap="nowrap">
                        <Grid item={true} style={{ flex: 1 }}>
                            <Typography style={{ opacity: 0.5 }} align="left">
                                What % of class time does each student need to be off their phone?
                            </Typography>
                        </Grid>
                        <Grid item={true}>
                            <Tooltip page={page} id="rewardDuration">
                                Set the amount of phone-free time needed to earn your reward. Be careful not to set too high of a % to account for absences or
                                approved phone use in class(Quizlet, Kahoot, etc.)
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <Slider
                        min={minSeconds}
                        max={totalSeconds}
                        step={60}
                        value={durationSeconds}
                        defaultValue={durationSeconds}
                        onChange={handleDurationSecondsChanged}
                        trackStyle={[{ height: 5, backgroundColor: '#D8D8D8' }]}
                        handleStyle={[{ height: 37, width: 37, marginTop: -15, borderColor: '#979797' }]}
                        style={{ marginTop: 25, width: '95%' }}
                        onAfterChange={() => handleHighPercentageAlert({ initialPercentage, newPercentage: durationPercent, initialValue }, 'durationSlider')}
                    />
                    <Grid container={true} wrap="nowrap" justifyContent="space-between" style={{ marginTop: 14 }}>
                        <Typography style={{ opacity: 0.5 }}>33%</Typography>
                        <Typography style={{ opacity: 0.5 }}>66%</Typography>
                        <Typography style={{ opacity: 0.5 }}>100%</Typography>
                    </Grid>
                    <Typography style={{ paddingTop: 24 }}>{`${durationPercent}% - Avg. ${avgMinutes} mins each class`}</Typography>
                </FormControl>
            </React.Fragment>
        );
    }
}

export default withStyles(appStyle)(ClasswideDurationSlider);
