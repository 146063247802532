import { Badge, CircularProgress, Grid, Typography } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import moment from 'moment';
import queryString from 'query-string';
import React, { Fragment } from 'react';
import { isMobile } from 'react-device-detect';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import ConfirmationAlert from '../../components/ConfirmationAlert';
import OnboardingBar from '../../components/OnboardingBar';
import appStyle from '../App.style';
import CompletedTab from './CompletedTab';
import DailyTab from './DailyTab';
import ProgressTab from './ProgressTab';
import Tiles from './Tiles';

interface IFilteredData {
    completed: any[];
    inProgress: any[];
    groupsFiltered: any;
}

interface IProps {
    classes: any;
    incentiveProgress: any;
    groups: any;
    mixpanel: any;
    history: any;
    groupProgresses: any;
}

interface IState {
    filteredData: IFilteredData;
    filterInputs: {
        groupFilter: null | string;
        incentiveFilter: null | string;
        userFilter: null | string;
        dateFilter: number;
    };
    alertShowing: boolean;
    selectedTabs: {
        daily: boolean;
        completed: boolean;
        progress: boolean;
    };
    markAllLoading: boolean;
    hideReceived: any;
}

class Dashboard extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            filterInputs: {
                groupFilter: null,
                incentiveFilter: null,
                userFilter: null,
                dateFilter: -1,
            },
            filteredData: {
                completed: [],
                inProgress: [],
                groupsFiltered: this.props.groups.data,
            },
            alertShowing: false,
            selectedTabs: {
                daily: true,
                completed: false,
                progress: false,
            },
            markAllLoading: false,
            hideReceived: false,
        };
    }

    public componentDidMount() {
        const {
            history: { location },
            mixpanel,
        } = this.props;
        const params = queryString.parse(location.search);

        mixpanel.actions.viewedDashboard();
        this.filterData();

        if (params && params.tab) {
            this.handleToggle(params.tab);
        }
    }

    public componentDidUpdate(prevProps: IProps) {
        if (this.props.groups.data !== prevProps.groups.data || this.props.incentiveProgress.data !== prevProps.incentiveProgress.data) {
            this.filterData();
        }
    }

    public render() {
        const {
            classes,
            incentiveProgress: { isFetching },
        } = this.props;
        const { alertShowing, filteredData, filterInputs, markAllLoading, selectedTabs } = this.state;
        const { completed } = filteredData;
        const unCheckedItems = completed.filter((item: any) => !item.received);
        const numCompletedNotReceived = unCheckedItems.length;

        return (
            <Fragment>
                <Helmet>
                    <title>Pocket Points | Dashboard</title>
                </Helmet>
                {!isMobile && <OnboardingBar mobile={false} />}

                <ConfirmationAlert
                    showing={alertShowing}
                    onClose={this.handleMarkAllAlertCancelled}
                    onConfirm={this.handleMarkAllAlertConfirmed}
                    title="Are you sure you want to mark all receipts as received?"
                    content="This could affect a large amount of receipts at one time."
                    closeText="Cancel"
                    confirmText="Continue"
                />

                <Grid container={true} style={{ overflow: 'hidden' }}>
                    <Grid item={true} xs={12} className={classes.dashboardHeader}>
                        <Grid container={true} className={classes.dashboardHeaderTitle}>
                            <Grid item={true} xs={6} style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography component="h1" className={classes.pageTitle}>
                                    Dashboard
                                </Typography>
                            </Grid>
                        </Grid>
                        {isFetching ? (
                            <div className={classes.dashboardLoading}>
                                <CircularProgress />
                            </div>
                        ) : (
                            <Tiles />
                        )}
                        {!isFetching && (
                            <Grid container={true} className={classes.dashboardTabs}>
                                <Grid
                                    id="daily"
                                    item={true}
                                    xs={isMobile ? 4 : 3}
                                    className={selectedTabs.daily ? classes.dashboardToggleSelected : classes.dashboardToggleUnselected}
                                    onClick={() => this.handleToggle('daily')}
                                    style={{ position: 'relative' }}
                                >
                                    <Typography id="daily" className={classes.dashboardToggleTitle}>
                                        {isMobile ? '' : 'Daily'} Breakdown
                                    </Typography>
                                </Grid>

                                <Grid
                                    id="completed"
                                    item={true}
                                    xs={isMobile ? 4 : 3}
                                    className={selectedTabs.completed ? classes.dashboardToggleSelected : classes.dashboardToggleUnselected}
                                    onClick={() => this.handleToggle('completed')}
                                    style={{ position: 'relative' }}
                                >
                                    <Badge
                                        badgeContent={numCompletedNotReceived}
                                        color="primary"
                                        classes={{ root: classes.badgeRoot, badge: classes.badgeBadgeTab }}
                                    >
                                        <Typography id="completed" className={classes.dashboardToggleTitle}>
                                            Completed {isMobile ? '' : 'Rewards'}
                                        </Typography>
                                    </Badge>
                                </Grid>

                                <Grid
                                    id="progress"
                                    item={true}
                                    xs={isMobile ? 4 : 3}
                                    className={selectedTabs.progress ? classes.dashboardToggleSelected : classes.dashboardToggleUnselected}
                                    onClick={() => this.handleToggle('progress')}
                                >
                                    <Typography id="progress" className={classes.dashboardToggleTitle}>
                                        {isMobile ? '' : 'Reward'} Progress
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                    {!isFetching && (
                        <Grid item={true} xs={12} className={classes.dashboardPageBody}>
                            <Grid container={true}>{this.state.selectedTabs.daily && <DailyTab />}</Grid>
                            <Grid container={true}>
                                {this.state.selectedTabs.completed && (
                                    <CompletedTab
                                        filteredData={filteredData}
                                        handleMarkAllReceived={this.handleMarkAllReceived}
                                        handleMarkOneItem={this.handleMarkOneItem}
                                        filterInputs={filterInputs}
                                        onFilterChanged={this.onFilterChanged}
                                        loading={markAllLoading}
                                        toggleHideReceived={this.toggleHideReceived}
                                        hideReceived={this.state.hideReceived}
                                    />
                                )}
                            </Grid>
                            <Grid container={true}>
                                {this.state.selectedTabs.progress && (
                                    <ProgressTab
                                        getIncentiveName={this.getIncentiveName}
                                        getUserName={this.getUserName}
                                        getUserPic={this.getUserPic}
                                        filteredData={filteredData}
                                        filterInputs={filterInputs}
                                        onFilterChanged={this.onFilterChanged}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Fragment>
        );
    }

    private handleMarkAllReceived = () => {
        this.setState({
            alertShowing: true,
        });
    };

    private handleToggle = (tab) => {
        const { mixpanel } = this.props;

        this.setState(
            {
                selectedTabs: {
                    daily: false,
                    completed: false,
                    progress: false,
                    [tab]: true,
                },
                filterInputs: {
                    ...this.state.filterInputs,
                    dateFilter: tab === 'progress' ? -1 : this.state.filterInputs.dateFilter,
                },
            },
            () => {
                mixpanel.actions.toggledDashboardTab(tab);
            }
        );
    };

    private handleMarkAllAlertConfirmed = () => {
        const { completed } = this.state.filteredData;
        const { incentiveProgress, groupProgresses } = this.props;
        const toMarkIndividual = completed.filter((item) => !item.received);
        const groupProgressData = groupProgresses.data;
        const toMarkGroup: any[] = [];

        Object.keys(groupProgressData).forEach((key) => {
            const groupProgress = groupProgressData[key];
            if (!!groupProgress.completion && !groupProgress.received) {
                toMarkGroup.push(groupProgress);
            }
        });

        this.setState(
            {
                alertShowing: false,
                markAllLoading: true,
            },
            () => {
                incentiveProgress.actions
                    .markAllReceived(toMarkIndividual)
                    .then(() => {
                        this.setState({ markAllLoading: false });
                    })
                    .catch(() => {
                        this.setState({ markAllLoading: false });
                    });
                groupProgresses.actions.markGroupIncentivesReceived(toMarkGroup);
            }
        );
    };

    private handleMarkAllAlertCancelled = () => {
        this.setState({
            alertShowing: false,
        });
    };

    private handleMarkOneItem = (item) => {
        const { completed, inProgress, groupsFiltered } = this.state.filteredData;
        const { incentiveProgress } = this.props;
        const markedCompleted = completed.map((progress) => {
            if (progress.id === item.id && progress.receipt.date === item.receipt.date) {
                progress.received = !progress.received;
            }
            return progress;
        });

        this.setState(
            {
                alertShowing: false,
                filteredData: {
                    inProgress,
                    completed: markedCompleted,
                    groupsFiltered,
                },
            },
            () => {
                incentiveProgress.actions.markIncentiveReceived(
                    item.id,
                    item.receipt.date,
                    item.received,
                    item.incentiveId,
                    item.incentiveName,
                    item.classroomId,
                    item.classroomName
                );
            }
        );
    };

    private getUserName = (receipt: any) => {
        const { data } = this.props.groups;
        const { classroomId, userId } = receipt;

        return data[classroomId].students[userId] ? data[classroomId].students[userId].name : '';
    };

    private getUserPic = (receipt: any) => {
        const { data } = this.props.groups;
        const { classroomId, userId } = receipt;

        return data[classroomId].students[userId] ? data[classroomId].students[userId].imageUrl : '';
    };

    private getIncentiveName = (receipt: any) => {
        const { data } = this.props.groups;
        const { classroomId, incentiveId } = receipt;

        return data[classroomId].incentives[incentiveId].name;
    };

    private getClassroomName = (receipt: any) => {
        const { data } = this.props.groups;
        const { classroomId } = receipt;

        return data[classroomId].attributes.name;
    };

    private getClassroomTimezone = (receipt: any) => {
        const { data } = this.props.groups;
        const { classroomId } = receipt;

        return data[classroomId].attributes.window.timeZone;
    }

    private filterData = () => {
        const {
            groups,
            incentiveProgress: { data },
        } = this.props;
        const { groupFilter, incentiveFilter, userFilter } = this.state.filterInputs;
        let inProgress: any = [];
        let completed: any = [];

        if (data) {
            Object.keys(data)
                .map((key) => {
                    return {
                        id: key,
                        incentive: data[key],
                    };
                })
                .filter((progress) => {
                    const { classroomId, incentiveId } = progress.incentive;

                    return groups.data[classroomId] && groups.data[classroomId].incentives[incentiveId];
                })
                .forEach((progress) => {
                    const id = progress.id;
                    const { incentive } = progress;
                    const { classroomId, incentiveId, userId } = incentive;

                    const userName = this.getUserName(incentive);
                    const userPic = this.getUserPic(incentive);
                    const incentiveName = this.getIncentiveName(incentive);
                    const classroomName = this.getClassroomName(incentive);
                    const timeZone = this.getClassroomTimezone(incentive);

                    const isBlocked = groups.data[classroomId].blockedStudents ? userId in groups.data[classroomId].blockedStudents : false;
                    if (incentive.current > 0 && !isBlocked ) {
                        inProgress.push({ ...incentive, classroomName, incentiveName, timeZone });
                    }

                    incentive.completed.forEach((receipt) => {
                        let received = false;
                        const date = receipt.date;
                        if (incentive.received && incentive.received[date]) {
                            received = incentive.received[date];
                        }

                        completed.push({
                            id,
                            classroomId,
                            incentiveId,
                            receipt,
                            userId,
                            received,
                            incentiveName,
                            classroomName,
                            timeZone,
                            userName,
                            userPic,
                        });
                    });
                });
        }

        inProgress = inProgress
            .filter((item) => {
                return groupFilter !== null ? item.classroomName === groupFilter : true;
            })
            .filter((item) => {
                return incentiveFilter !== null ? item.incentiveName === incentiveFilter : true;
            })
            .filter((item) => {
                return userFilter !== null ? item.userId === userFilter : true;
            })
            .sort((a, b) => {
                const aDuration = groups.data[a.classroomId].incentives[a.incentiveId].duration;
                const aProgress = a.current / aDuration;

                const bDuration = groups.data[b.classroomId].incentives[b.incentiveId].duration;
                const bProgress = b.current / bDuration;

                return aProgress > bProgress ? -1 : 1;
            });

        completed = completed
            .filter((item) => {
                return groupFilter !== null ? item.classroomName === groupFilter : true;
            })
            .filter((item) => {
                return incentiveFilter !== null ? item.incentiveName === incentiveFilter : true;
            })
            .filter((item) => {
                return userFilter !== null ? item.userId === userFilter : true;
            })
            .filter((item) => {
                const { dateFilter } = this.state.filterInputs;
                const itemDate = moment.utc(item.receipt.date).local();
                const filter = moment()
                    .startOf('day')
                    .subtract(dateFilter, 'days');

                return dateFilter > -1 ? itemDate.isSameOrAfter(filter) : true;
            })
            .sort((a, b) => {
                const aDate = moment(a.receipt.date);
                const bDate = moment(b.receipt.date);

                return aDate.isSameOrAfter(bDate) ? -1 : 1;
            });

        const groupsFiltered = this.filterGroups();
        this.setState({
            filteredData: { completed, inProgress, groupsFiltered },
        });
    };

    private filterGroups = () => {
        const { filterInputs } = this.state;
        const {
            groups: { data },
            groupProgresses,
        } = this.props;
        const { groupFilter, incentiveFilter, dateFilter, userFilter } = filterInputs;
        let groupsFiltered = data;

        if (userFilter) {
            return [{}];
        }

        if (!!data && !!groupProgresses.data && (!!groupFilter || !!incentiveFilter || dateFilter !== -1)) {
            groupsFiltered = Object.keys(data)
                .map((groupKey) => {
                    if (groupFilter === data[groupKey].attributes.name || !groupFilter) {
                        if ((!!incentiveFilter || dateFilter !== -1) && !!data[groupKey].groupIncentives) {
                            const groupIncentives = data[groupKey].groupIncentives;
                            const filteredGroupIncentives = Object.keys(groupIncentives)
                                .map((incentiveKey) => {
                                    if (incentiveFilter === groupIncentives[incentiveKey].name || !incentiveFilter) {
                                        if (dateFilter !== -1) {
                                            const { iterations } = groupIncentives[incentiveKey];
                                            const filteredIterations = iterations
                                                .map((iteration: any) => {
                                                    const item = groupProgresses.data[iteration.groupProgressesId];
                                                    const itemDate = item && item.completion ? moment.unix(item.completion) : moment.unix(0);
                                                    const filter = moment()
                                                        .startOf('day')
                                                        .subtract(dateFilter, 'days');
                                                    if (dateFilter > -1 ? itemDate.isSameOrAfter(filter) : true) {
                                                        return iteration;
                                                    }

                                                    return undefined;
                                                })
                                                .filter((element) => element);

                                            return {
                                                ...groupIncentives[incentiveKey],
                                                iterations: filteredIterations,
                                            };
                                        }
                                        return groupIncentives[incentiveKey];
                                    }

                                    return undefined;
                                })
                                .filter((element) => element);

                            return {
                                ...data[groupKey],
                                groupIncentives: filteredGroupIncentives,
                            };
                        }
                        return data[groupKey];
                    }

                    return undefined;
                })
                .filter((element) => element);
        }
        return groupsFiltered;
    };

    private onFilterChanged = (filterInputs) => {
        this.setState({ filterInputs: { ...this.state.filterInputs, ...filterInputs } }, () => {
            this.filterData();
            this.fireDashboardFilteredAnalyticEvent();
        });
    };

    private fireDashboardFilteredAnalyticEvent = () => {
        const { groupFilter, incentiveFilter, userFilter } = this.state.filterInputs;
        const { mixpanel } = this.props;
        mixpanel.actions.dashboardFiltered({ groupFilter, incentiveFilter, userFilter });
    };

    private toggleHideReceived = () => {
        this.setState({ hideReceived: !this.state.hideReceived });
    };
}

const mapStoreToProps = (state: any) => {
    return {
        groups: state.groups,
        incentiveProgress: state.incentiveProgress,
        mixpanel: state.mixpanel,
        groupProgresses: state.groupProgresses,
    };
};

export default connect(
    mapStoreToProps,
    null
)(withStyles(appStyle)(Dashboard));
