import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';

interface IProps {
    alert: {
        showing: boolean;
        title?: string;
        message?: string;
        buttonText?: string;
        confirmText?: string;
        onConfirm?: any;
        onClose?: any;
    };
    onClose?: any;
}

class AlertDialog extends React.Component<IProps> {
    public render() {
        const { showing, title, message, buttonText, onConfirm, confirmText } = this.props.alert;
        const onClose = this.props.alert.hasOwnProperty('onClose') ? this.props.alert.onClose : this.props.onClose;

        return (
            <div>
                <Dialog
                    open={showing}
                    onClose={onClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth="xs"
                    fullWidth={true}
                >
                    <DialogTitle id="alert-dialog-title">{title ? title : ''}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">{message}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose} color="primary" autoFocus={true}>
                            {buttonText ? buttonText : 'Okay'}
                        </Button>
                        {onConfirm && (
                            <Button onClick={onConfirm} color="primary" autoFocus={true}>
                                {confirmText ? confirmText : 'Yes'}
                            </Button>
                        )}
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default AlertDialog;
