import { createStyles, Theme, Typography, withStyles } from '@material-ui/core';
import { TypographyProps } from '@material-ui/core/Typography';
import React from 'react';
import { isMobile } from 'react-device-detect';

const styles = (theme: Theme) =>
    createStyles({
        divClick: {
            cursor: 'pointer',
        },
        typographyRoot: {
            marginLeft: 'unset',
            marginRight: 'unset',
            opacity: 1,
        },
        typographyBold: {
            fontWeight: 800,
        },
        typographySemiBold: {
            fontWeight: 600,
        },
        typographyLight: {
            opacity: 0.5,
        },
        typographyExtraLight: {
            opacity: 0.35,
        },
        typographyItalic: {
            fontStyle: 'italic',
        },
        typographyError: {
            color: '#D0021B',
        },
    });

interface IProps extends TypographyProps {
    classes: any;
    bold?: boolean;
    semiBold?: boolean;
    extraLight?: boolean;
    light?: boolean;
    italic?: boolean;
    fontSize?: number;
    fontWeight?: number | string;
    onClick?: any;
    isTitle?: boolean;
    justifyContent?: string;
    alignItems?: string;
    style?: any;
    error?: boolean;
}

class Text extends React.Component<IProps> {
    public render() {
        const { bold, semiBold, classes, children, color, light, extraLight, italic, isTitle, style, fontSize, fontWeight, onClick, error } = this.props;
        const rootClass = `
            ${bold ? classes.typographyBold : ''} 
            ${semiBold ? classes.typographySemiBold : ''} 
            ${light ? classes.typographyLight : ''} 
            ${extraLight ? classes.typographyExtraLight : ''} 
            ${italic ? classes.typographyItalic : ''} 
            ${onClick ? classes.divClick : ''} 
            ${error ? classes.typographyError : ''}
            ${classes.typographyRoot} 
        `;
        const componentStyle = { textTransform: 'none', ...style, fontSize, fontWeight };
        const plucked = this.pluckProps(this.props);
        const props = {
            ...plucked,
            color,
            variant: isTitle ? 'h1' : this.props.variant,
            style: isTitle ? { fontSize: isMobile ? 24 : 28, fontWeight: 800 } : componentStyle,
            classes: !isTitle ? { root: rootClass } : undefined,
            onClick,
        };

        return <Typography {...props}>{children}</Typography>;
    }

    private pluckProps = (props) => {
        return ['align', 'color', 'component', 'gutterBottom', 'headlineMapping', 'inline', 'noWrap', 'paragraph', 'variant'].reduce((results, key) => {
            return props[key] ? { ...results, [key]: props[key] } : results;
        }, {});
    };
}

export default withStyles(styles)(Text);
