import axios from 'axios';
import Geohash from 'latlon-geohash';
import { Store } from 'redux';
import { createAction, handleActions } from 'redux-actions';
import { calculateDistance } from '../utils/helper';
import firebase from 'firebase/compat/app'
class Searches {
    public actions = {
        getSchools: (geohash, zipCode, from): any => {
            const allResults: any[] = [];

            return this.db
                .collection('searches')
                .doc('schools')
                .collection('indexes')
                .where('geo.nearby', 'array-contains', geohash.slice(0, 3))
                .get()
                .then((results) => {
                    results.docs.forEach((doc) => allResults.push(doc.data()));

                    return this.db
                        .collection('searches')
                        .doc('schools')
                        .collection('indexes')
                        .where('zipCode', '==', zipCode.slice(0, 5))
                        .get()
                        .then((results) => {
                            results.docs.forEach((doc) => {
                                const data = doc.data();

                                if (!allResults.find((result) => result.id === data.id)) {
                                    allResults.push(data);
                                }
                            });
                        });
                })
                .then(() => {
                    const schools: any[] = [];

                    allResults.forEach((data) => {
                        const searchWords = data.name
                            .toLowerCase()
                            .replace(/\W/g, ' ')
                            .replace(/\s\s+/g, ' ')
                            .trim()
                            .split(' ');
                        const distance = calculateDistance(from, { lat: data.geo.point._lat, lon: data.geo.point._long });

                        if (distance <= 100) {
                            schools.push({
                                ...data,
                                distance,
                                searchWords: searchWords.reduce((words, word) => {
                                    if (!words.includes(word)) {
                                        words.push(word);
                                    }

                                    return words;
                                }, []),
                            });
                        }
                    });

                    this.store.dispatch(this.setSearchesSchools(schools));
                })
                .catch(() => null);
        },
        getLocation: () => {
            this.store.dispatch(this.setSearchesLoading(true));

            return axios
                .get('https://api.ipify.org?format=json')
                .then((ipifyResults): any => {
                    if (ipifyResults.data && ipifyResults.data.ip) {
                        return axios
                            .get(`https://api.ipdata.co/${ipifyResults.data.ip}?api-key=fbe893fa09cd1ca6c10193e1ca74380504a4459fd0e661135fb87c30`)
                            .then((ipdataResults): any => {
                                const { data } = ipdataResults;

                                if (data && data.postal) {
                                    return this.actions.setLocation(data.postal);
                                }

                                this.actions.setDefaultLocation();
                            });
                    }

                    this.actions.setDefaultLocation();
                })
                .catch(() => this.actions.setDefaultLocation())
                .then(() => {
                    this.store.dispatch(this.setSearchesLoading(false));
                });
        },
        setLocation: (zipCode) => {
            this.store.dispatch(this.setSearchesLoading(true));

            return axios
                .get(`https://maps.googleapis.com/maps/api/geocode/json?address=${zipCode}&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`)
                .then((results) => {
                    const data = results && results.data && results.data.results && results.data.results[0] ? results.data.results[0] : null;
                    let city;
                    let components;
                    let geohash;
                    let location;

                    if (data) {
                        components = data.address_components;
                        city = data.postcode_localities && data.postcode_localities[0] ? data.postcode_localities[0] : components[1].short_name;
                        location = data.geometry.location;
                        geohash = Geohash.encode(location.lat, location.lng, 9);

                        this.store.dispatch(
                            this.setSearchesLocation({
                                lat: location.lat,
                                lon: location.lng,
                                state: components[components.length - 2].short_name,
                                zipCode: components[0].short_name,
                                city,
                                geohash,
                            })
                        );

                        return this.actions.getSchools(geohash, components[0].short_name, { lat: location.lat, lon: location.lng });
                    }
                })
                .then(() => {
                    this.store.dispatch(this.setSearchesLoading(false));
                });
        },
        setDefaultLocation: () => {
            const lat = 37.77;
            const lon = -122.45;
            const geohash = Geohash.encode(lat, lon, 9);
            const zipCode = '94102';

            this.store.dispatch(
                this.setSearchesLocation({
                    city: 'San Francisco',
                    state: 'CA',
                    lat,
                    lon,
                    geohash,
                    zipCode,
                })
            );

            return this.actions.getSchools(geohash, zipCode, { lat, lon });
        },
    };

    public initialState = {
        actions: this.actions,
        data: {
            location: null,
            schools: [],
        },
        loading: false,
    };

    public reducer = handleActions<any>(
        {
            SET_SEARCHES_LOADING: (state, action) => ({
                ...state,
                loading: action.payload,
            }),
            SET_SEARCHES_LOCATION: (state, action) => {
                return {
                    ...state,
                    data: {
                        ...state.data,
                        location: action.payload,
                    },
                };
            },
            SET_SEARCHES_SCHOOLS: (state, action) => {
                return {
                    ...state,
                    data: {
                        ...state.data,
                        schools: action.payload,
                    },
                };
            },
        },
        this.initialState
    );

    private setSearchesLoading = createAction('SET_SEARCHES_LOADING');
    private setSearchesLocation = createAction('SET_SEARCHES_LOCATION');
    private setSearchesSchools = createAction('SET_SEARCHES_SCHOOLS');

    private db: any;
    private store: Store;

    constructor(/**firebase: any*/) { 
        this.db = firebase.firestore();
    }

    public setStore = (store) => {
        this.store = store;
    };
}

export default Searches;
