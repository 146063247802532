import moment from 'moment';
import { Store } from 'redux';
import { createAction, handleActions } from 'redux-actions';
import firebase from 'firebase/compat/app'

class EarningWindow {
    public actions = {
        clearEarningWindow: () => {
            this.store.dispatch(this.clearEarningWindow());
        },
        getEarningWindow: (id) => {
            return this.db
                .collection('earningWindows')
                .doc(id)
                .get()
                .then((doc) => {
                    const data = doc.data();

                    this.store.dispatch(this.setEarningWindow(data));
                })
                .catch((error) => {
                    console.info(`reactions:EarningWindow:getEarningWindow:error: ${error}`);
                });
        },
        createEarningWindow: (data) => {
            return this.db
                .collection('earningWindows')
                .add(data)
                .then((ref) => {
                    return this.store
                        .getState()
                        .user.actions.updateAccount({ earningWindowId: ref.id })
                        .then(() => {
                            const { actions } = this.store.getState().mixpanel;

                            this.store.dispatch(this.setEarningWindow(data));

                            actions.sendEventWithProps('Created Schedule', {
                                'Days in Rotation': data.slots.length,
                                'Start At': moment.unix(data.startAt).toISOString(),
                                'Start Day': data.startSlot + 1,
                                'Schedule Type': data.type,
                            });
                        });
                })
                .catch((error) => {
                    console.info(`reactions:EarningWindow:createEarningWindow:error: ${error}`);
                });
        },
        updateEarningWindow: (data) => {
            const { earningWindowId } = this.store.getState().user.data.userInfo;

            return this.db
                .collection('earningWindows')
                .doc(earningWindowId)
                .set(data)
                .then(() => {
                    const { actions } = this.store.getState().mixpanel;
                    const oldData = this.store.getState().earningWindow.data;

                    this.store.dispatch(this.setEarningWindow(data));

                    actions.sendEventWithChangedData('Edited Schedule', {
                        'Days in Rotation': {
                            new: data.slots.length,
                            old: oldData.slots.length,
                        },
                        'Start At': {
                            new: moment.unix(data.startAt).toISOString(),
                            old: moment.unix(oldData.startAt).toISOString(),
                        },
                        'Start Day': {
                            new: data.startSlot + 1,
                            old: oldData.startSlot + 1,
                        },
                        'Schedule Type': { new: data.type, old: oldData.type },
                    });
                })
                .catch((error) => {
                    console.info(`reactions:EarningWindow:updateEarningWindow:error: ${error}`);
                });
        },
    };

    public initialState = {
        actions: this.actions,
        data: null,
    };

    public reducer = handleActions<any>(
        {
            CLEAR_EARNING_WINDOW: () => {
                return this.initialState;
            },
            SET_EARNING_WINDOW: (state, action) => {
                return {
                    ...state,
                    data: action.payload,
                };
            },
        },
        this.initialState
    );

    private clearEarningWindow = createAction('CLEAR_EARNING_WINDOW');
    private setEarningWindow = createAction('SET_EARNING_WINDOW');

    private db: any;
    private store: Store;

    constructor(/**firebase: any*/) {
        this.db = firebase.firestore();
    }

    public setStore = (store) => {
        this.store = store;
    };
}

export default EarningWindow;
