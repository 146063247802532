import { Badge, Button, FormControl, FormHelperText, Grid, Input, InputLabel, TextField, Typography } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { Add, Close } from '@material-ui/icons';
import Remove from '@material-ui/icons/Remove';
import { DatePicker } from '@material-ui/pickers';
import { Form, Formik } from 'formik';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import MaskedInput from 'react-text-mask';
import EarningWindow from 'shared-library-js';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import AlertDialog from '../../../components/Alert';
import AlertTextInput from '../../../components/AlertTextInput';
import Submit from '../../../components/Submit';
import Text from '../../../components/Text';
import Tooltip from '../../../components/Tooltip';
import { IGroupIncentive } from '../../../utils/types';
import { GroupIncentiveSchema } from '../../../utils/validations';
import appStyle from '../../App.style';
import ClasswideDurationSlider from './ClasswideDurationSlider';
import ClasswideStudentsSlider from './ClasswideStudentsSlider';

interface IProps {
    classes: any;
    actions: any;
    earningWindow: any;
    handleClose: any;
    fullScreen: boolean;
    groupId: string;
    groups: any;
    setLoading: any;
    toggleRewardType: any;
    groupIncentiveId?: string | undefined;
}

interface IState {
    otherLimit: number | undefined;
    showOtherLimit: boolean;
    alert: any;
    showChoiceMessage: boolean;
    isEditing: boolean;
    initalIterationCount: number;
    durationSeconds: number;
    classTimeAlertShown: boolean;
    studentsAlertShown: boolean;
}

class ClasswideForm extends React.Component<IProps, IState> {
    private earningWindow;

    constructor(props: IProps) {
        super(props);
        const { groupId, groups, groupIncentiveId } = this.props;
        const group = groups[groupId];
        const groupIncentive: IGroupIncentive | undefined = groupId && groupIncentiveId ? group.groupIncentives[groupIncentiveId] : undefined;
        const isEditing = groupIncentiveId && groupIncentive ? true : false;
        this.earningWindow = new EarningWindow(this.props.earningWindow, group);

        const currentFutureIncentives = groupIncentive ? groupIncentive.iterations.filter((iteration) => iteration.expiration > moment().unix()) : []
        const durationSeconds = isEditing
            ? this.getInitialDurationSeconds(currentFutureIncentives.length > 0 ? currentFutureIncentives[0] : groupIncentive.iterations[0])
            : Math.round(this.earningWindow.getAverageDuration() * 0.65);

        this.state = {
            otherLimit: null,
            showOtherLimit: false,
            alert: {
                showing: false,
            },
            showChoiceMessage: false,
            isEditing,
            initalIterationCount: isEditing ? groupIncentive.iterations.length : null,
            durationSeconds,
            classTimeAlertShown: false,
            studentsAlertShown: false,
        };
    }

    public render() {
        const { classes, groups, groupId, handleClose } = this.props;
        const { alert, durationSeconds, otherLimit, showOtherLimit, isEditing } = this.state;
        const { groupSize } = groups[groupId].attributes;
        const title = isEditing ? 'Edit Reward' : 'Create Reward';
        const totalSeconds = this.earningWindow.getAverageDuration();

        return (
            <React.Fragment>
                <AlertTextInput value={otherLimit} showing={showOtherLimit} onClose={this.handleOtherLimit} />
                <AlertDialog alert={alert} />
                <Formik
                    enableReinitialize={true}
                    initialValues={this.getInitialValues()}
                    validationSchema={GroupIncentiveSchema}
                    onSubmit={this.onSubmit}
                    render={({ values, errors, touched, handleChange, isValidating, isSubmitting, setFieldValue, setFieldTouched, setValues, submitForm }) => (
                        <Form noValidate={true}> {/*  buggy - August 2022*/}
                        {/*  <form noValidate={true}>*/}
                            {/* {this.focusErrors(isSubmitting, isValidating, errors)} */}
                            <Grid container={true} justifyContent="center" alignItems="center">
                                <Grid item={true} xs={12} className={classes.authContainer}>
                                    <Typography component="h1" className={classes.pageTitle}>
                                        {title}
                                    </Typography>
                                    {isEditing ? '' : this.renderRewardTypeToggle()}
                                    {this.renderMessage()}
                                    <FormControl
                                        margin="normal"
                                        required={true}
                                        fullWidth={true}
                                        error={!!errors.name && !!touched.name}
                                        style={{ marginTop: 48, marginBottom: 8 }}
                                    >
                                        <InputLabel shrink={true} htmlFor="name">
                                            Reward Name
                                        </InputLabel>
                                        <Input
                                            autoComplete="off"
                                            inputProps={{ 'data-hj-whitelist': true }}
                                            placeholder="e.g. Extra credit, Free Snacks, Class DJ"
                                            id="name"
                                            name="name"
                                            multiline={true}
                                            onChange={handleChange}
                                            value={values.name}
                                        />
                                        <Tooltip isInput={true} page={title} id="rewardName">
                                            What are you going to give as a reward when the time goal is reached?
                                        </Tooltip>
                                    </FormControl>
                                    <Grid container={true} style={{ paddingBottom: 16 }}>
                                        <ClasswideStudentsSlider
                                            requiredStudents={values.required}
                                            totalStudents={groupSize}
                                            handleRequiredStudentsChanged={(value) => this.handleRequiredStudentsChanged(value, setFieldValue)}
                                            page={title}
                                            handleHighPercentageAlert={(sliderValues) =>
                                                this.handleHighPercentageAlert(sliderValues, undefined, setFieldValue, 'studentsSlider')
                                            }
                                        />
                                    </Grid>
                                    <Grid container={true} style={{ paddingBottom: 32 }}>
                                        <ClasswideDurationSlider
                                            durationSeconds={durationSeconds}
                                            totalSeconds={totalSeconds}
                                            handleDurationSecondsChanged={(value) => this.handleDurationSecondsChanged(value, values.iterations, setFieldValue)}
                                            page={title}
                                            handleHighPercentageAlert={(sliderValues) =>
                                                this.handleHighPercentageAlert(sliderValues, values.iterations, setFieldValue, 'durationSlider')
                                            }
                                        />
                                    </Grid>
                                    <Grid container={true} direction="row">
                                        <Grid container={true} item={true} xs={12} justifyContent="space-between" wrap="nowrap">
                                            <Grid item={true} style={{ paddingBottom: 16 }}>
                                                <Typography style={{ opacity: 0.5 }}>How many times do you want to offer this reward?</Typography>
                                            </Grid>
                                            <Grid item={true}>
                                                <Tooltip page={isEditing ? 'Edit Reward' : 'Create Reward'} id="rewardLimit">
                                                    Give your students multiple opportunities to earn your reward so they can stay engaged. Student progress
                                                    will reset to 0 after the end date is reached. Total time is based on the amount of class periods between
                                                    the start/end date and the class time % you set.
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                        <Grid container={true} direction="row" alignItems="center" item={true} xs={12} spacing={1}>
                                            <Grid item={true}>
                                                <Button
                                                    variant="outlined"
                                                    size="large"
                                                    style={{ minWidth: 50, width: 50 }}
                                                    onClick={() => this.handleIterationsCountChange(values.iterations.length + 1, values, setFieldValue)}
                                                >
                                                    <Add />
                                                </Button>
                                            </Grid>
                                            <Grid item={true}>
                                                <Button
                                                    variant="outlined"
                                                    size="large"
                                                    style={{ minWidth: 50, width: 50 }}
                                                    onClick={() => this.handleIterationsCountChange(values.iterations.length - 1, values, setFieldValue)}
                                                >
                                                    <Remove />
                                                </Button>
                                            </Grid>
                                            <Grid item={true} style={{ paddingLeft: 16 }}>
                                                <TextField
                                                    margin="normal"
                                                    fullWidth={true}
                                                    onChange={(event) => this.handleIterationsTextChange(event, values, setFieldValue)}
                                                    value={values.iterations.length}
                                                    InputProps={{ inputComponent: (props) => this.renderMaskedInput(props, values.iterations) }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container={true} direction="row" style={{ paddingBottom: 32 }}>
                                        {this.renderIterationDates(values.iterations, errors, touched, setFieldValue, setFieldTouched)}
                                    </Grid>
                                    <FormControl>
                                        <Grid container={true} style={{ justifyContent: 'space-between' }} wrap="nowrap">
                                            <Grid item={true} style={{ paddingBottom: 16 }}>
                                                <Typography style={{ opacity: 0.5 }}>
                                                    Do you want students to be able to see who is participating or not?
                                                </Typography>
                                            </Grid>
                                            <Grid item={true}>
                                                <Tooltip page={isEditing ? 'Edit Reward' : 'Create Reward'} id="rewardLimit">
                                                    Choose if you want to allow students to see each others names and their progress in the app. This can create
                                                    some friendly competition, but may not be best for all classes.
                                                </Tooltip>
                                            </Grid>
                                        </Grid>

                                        <Grid container={true} direction="column" justifyContent="center" alignItems="stretch" style={{ paddingBottom: 40 }}>
                                            {!!errors.isPrivate && !!touched.isPrivate && (
                                                <Grid item={true} xs={12} style={{ paddingBottom: 24 }}>
                                                    <FormHelperText error={true}>
                                                        Please select if you would like students to see who is participating or not
                                                    </FormHelperText>
                                                </Grid>
                                            )}
                                            <Button
                                                onClick={() => setFieldValue('isPrivate', false)}
                                                variant="text"
                                                size="large"
                                                className={
                                                    !values.isPrivate && values.isPrivate !== null
                                                        ? classes.isPrivateButtonSelected
                                                        : classes.isPrivateButtonUnselected
                                                }
                                                style={{ marginBottom: 16 }}
                                                name="isPrivate"
                                            >
                                                <Grid
                                                    container={true}
                                                    direction="column"
                                                    justifyContent="center"
                                                    alignItems="stretch"
                                                    item={true}
                                                    style={{ marginBottom: 8 }}
                                                >
                                                    <Grid item={true} xs={12} style={{ padding: '4px 16px 4px 16px' }}>
                                                        <img src="/images/participating-active.png" alt="people icon" style={{ padding: 8 }} />
                                                    </Grid>
                                                    <Grid item={true} xs={12}>
                                                        <Text style={{ color: !values.isPrivate && values.isPrivate !== null ? '#00D793' : '#000' }}>
                                                            Yes, let students see who is participating
                                                        </Text>
                                                    </Grid>
                                                </Grid>
                                            </Button>
                                            <Button
                                                onClick={() => setFieldValue('isPrivate', true)}
                                                variant="text"
                                                size="large"
                                                className={values.isPrivate ? classes.isPrivateButtonSelected : classes.isPrivateButtonUnselected}
                                            >
                                                <Grid
                                                    container={true}
                                                    direction="column"
                                                    justifyContent="center"
                                                    alignItems="stretch"
                                                    item={true}
                                                    style={{ marginBottom: 8 }}
                                                >
                                                    <Grid item={true} xs={12} style={{ padding: '4px 16px 4px 16px' }}>
                                                        <img src="/images/participating-private.png" alt="people icon" style={{ padding: 8 }} />
                                                    </Grid>
                                                    <Grid item={true} xs={12}>
                                                        <Text style={{ color: values.isPrivate ? '#00D793' : '#000' }}>
                                                            No, do not let students see who is participating
                                                        </Text>
                                                    </Grid>
                                                </Grid>
                                            </Button>
                                        </Grid>
                                    </FormControl>
                                    <FormControl margin="normal" fullWidth={true}>
                                        <InputLabel shrink={true} htmlFor="details">
                                            Reward Details (optional)
                                        </InputLabel>
                                        <Input
                                            id="details"
                                            value={values.details}
                                            placeholder="e.g. must be redeemed within 24 hours"
                                            onChange={handleChange}
                                        />
                                        <Tooltip isInput={true} page={isEditing ? 'Edit Reward' : 'Create Reward'} id="rewardDetails">
                                            This is the “fine print” for your reward. Are there any special restrictions or circumstances your students need to
                                            be aware of?
                                        </Tooltip>
                                    </FormControl>
                                    {!isEditing && (
                                        <Submit
                                            type="button"
                                            loading={isSubmitting}
                                            style={{ marginTop: 24 }}
                                            onClick={() => this.preSubmit(values, setValues).then(() => submitForm())}
                                        >
                                            Create Reward
                                        </Submit>
                                    )}
                                </Grid>
                                {isEditing && (
                                    <Grid container={true} style={{ textAlign: 'center', paddingTop: 24 }}>
                                        <Grid item={true} xs={6}>
                                            <Button variant="contained" color="primary" className={classes.cancelButton} onClick={() => handleClose(false)}>
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item={true} xs={6}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                className={classes.saveButton}
                                                onClick={() => this.preSubmit(values, setValues).then(() => submitForm())}
                                            >
                                                Save
                                            </Button>
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                        {/* </form> */}
                        </Form>
                    )}
                />
            </React.Fragment>
        );
    }

    private renderIterationDates = (iterations, errors, touched, setFieldValue, setFieldTouched) => {
        const { groupIncentiveId } = this.props;

        return iterations.map((iteration, index) => {
            const startError = !!(errors && errors.iterations && errors.iterations[index] && errors.iterations[index].start);
            const startTouched = touched && touched.iterations && touched.iterations[index] && touched.iterations[index].start;
            const expirationError = !!(errors && errors.iterations && errors.iterations[index] && errors.iterations[index].expiration);
            const expirationTouched = touched && touched.iterations && touched.iterations[index] && touched.iterations[index].expiration;
            const hasError = startError || expirationError;
            const hasValue = !!iterations[index].start || !!iterations[index].expiration || (startTouched && expirationTouched);
            const startDate = iteration.start ? moment.unix(iteration.start) : null;
            const endDate = iteration.expiration ? moment.unix(iteration.expiration) : null;
            const hours = iteration.duration ? Math.floor(iteration.duration / 3600) : undefined;
            const minutes = iteration.duration ? Math.floor((iteration.duration % 3600) / 60) : undefined;
            const hourLabel = hours && hours > 1 ? ' Hours' : ' Hour';
            const minuteLabel = minutes && minutes > 1 ? ' Minutes' : ' Minute';
            const duration =
                hours || minutes ? `${hours ? hours + hourLabel : ''}${hours && minutes ? ' and ' : ''}${minutes ? minutes + minuteLabel : ''}` : undefined;
            const disableDates = iterations[index - 1] ? !iterations[index - 1].start || !iterations[index - 1].expiration : false;
            const disableRow = groupIncentiveId && iterations[index].expiration < moment().unix();

            return (
                <Grid key={index} container={true} justifyContent="center" style={{ paddingBottom: 8, opacity: disableRow ? 0.5 : 1 }}>
                    <Grid container={true} item={true} wrap="nowrap" spacing={1}>
                        <FormControl margin="normal" fullWidth={true} style={{ paddingTop: 8 }} error={!!(startError && hasValue)}>
                            <Grid container={true} direction="row" alignItems="flex-end" style={{ height: 24 }}>
                                <Text light={true} fontSize={12}>
                                    Starts
                                </Text>
                            </Grid>
                            <DatePicker
                                disabled={disableDates || disableRow}
                                autoOk={true}
                                allowKeyboardControl={false}
                                name="iterations"
                                format="MM/DD/YYYY"
                                value={startDate}
                                disablePast={true}
                                shouldDisableDate={(date) => this.handleDisableStartDates(date, index, iterations)}
                                onChange={(date) => this.handleDateChangePrompt(date, iterations, index, 'start', setFieldValue, setFieldTouched)}
                                error={!!(startError && hasValue)}
                            />
                            {hasError && hasValue && <FormHelperText>{startError ? errors.iterations[index].start : ''}</FormHelperText>}
                        </FormControl>
                        <Text fontSize={26} light={true} style={{ width: 100, textAlign: 'center', marginTop: 42 }}>
                            -
                        </Text>
                        <FormControl margin="normal" fullWidth={true} style={{ paddingTop: 8 }} error={!!(expirationError && hasValue)}>
                            <Grid container={true} justifyContent="space-between" direction="row" alignItems="flex-end" style={{ height: 24 }}>
                                <Text light={true} fontSize={12}>
                                    Ends
                                </Text>
                                {iterations.length > 1 && !disableRow && (
                                    <Close style={{ opacity: 0.3 }} onClick={() => this.handleDeleteIteration(index, iterations, setFieldValue)} />
                                )}
                            </Grid>
                            <DatePicker
                                disabled={disableDates || disableRow}
                                autoOk={true}
                                allowKeyboardControl={false}
                                id="endDate"
                                format="MM/DD/YYYY"
                                value={endDate}
                                disablePast={!iterations[index].start}
                                shouldDisableDate={(date) => this.handleDisableEndDates(date, index, iterations)}
                                onChange={(date) => this.handleDateChangePrompt(date, iterations, index, 'expiration', setFieldValue, setFieldTouched)}
                                error={!!(expirationError && hasValue)}
                            />
                            {hasError && hasValue && <FormHelperText>{expirationError ? errors.iterations[index].expiration : ''}</FormHelperText>}
                        </FormControl>
                    </Grid>
                    {!hasError && !!iterations[index].start && !!iterations[index].expiration && (
                        <Grid container={true} item={true} alignItems="flex-end" style={{ height: 20 }}>
                            {duration && (
                                <React.Fragment>
                                    <Text fontSize={12}>{duration}</Text>
                                    <Text fontSize={12} light={true}>
                                        &nbsp;of Total Class Time Per Student
                                    </Text>
                                </React.Fragment>
                            )}
                        </Grid>
                    )}
                </Grid>
            );
        });
    };

    private renderRewardTypeToggle = () => {
        const { classes, toggleRewardType } = this.props;

        return (
            <Grid item={true} xs={12} container={true} direction="row" style={{ paddingTop: 36 }}>
                <Grid item={true} xs={6} className={`${classes.rewardTypeToggle} ${classes.individualRewardUnselected}`} onClick={toggleRewardType}>
                    <Text
                        light={true}
                        style={{
                            paddingTop: 16,
                            paddingBottom: 16,
                            whiteSpace: 'pre-wrap',
                            textAlign: 'center',
                            color: '#000',
                        }}
                    >{`Individual Student\nReward`}</Text>
                </Grid>
                <Grid
                    item={true}
                    xs={6}
                    className={`${classes.rewardTypeToggle} ${classes.groupRewardSelected}`}
                    container={true}
                    direction="column"
                    justifyContent="center"
                    alignItems="stretch"
                >
                    <Badge
                        badgeContent={
                            <Text bold={true} fontSize={10} style={{ color: '#fff', padding: 4 }}>
                                NEW
                            </Text>
                        }
                        color="primary"
                    >
                        <Grid item={true} xs={12} style={{ paddingTop: 16, paddingBottom: 16 }}>
                            <img src="/images/people-outline-green.png" alt="people icon" style={{ width: 18, height: 14, marginBottom: '3px' }} />
                            <Text style={{ color: '#00D793' }}>Class Wide Reward</Text>
                        </Grid>
                    </Badge>
                </Grid>
            </Grid>
        );
    };

    private renderMessage = () => {
        const { classes } = this.props;
        const { showChoiceMessage } = this.state;

        if (showChoiceMessage) {
            return (
                <div className={classes.rewardSuggestionMessageContainer}>
                    <Typography style={{ opacity: 0.74, color: '#0F4E3A' }}>
                        <span style={{ fontWeight: 700 }}>Great Choice! </span>
                        Now just edit this to your liking and you're all set.
                    </Typography>
                </div>
            );
        }
        return null;
    };

    private renderMaskedInput = (props, iterations) => {
        const { inputRef, ...other } = props;
        const numberMask = createNumberMask({
            prefix: '',
            suffix: iterations.length === 1 ? ' time' : ' times',
        });

        return (
            <MaskedInput
                {...other}
                ref={(ref) => inputRef(ref ? ref.inputElement : null)}
                mask={numberMask}
                guide={true}
                showMask={true}
                keepCharPositions={true}
            />
        );
    };

    private handleIterationsTextChange = (event, values, setFieldValue) => {
        const { target } = event;
        const value = target.value.replace(/_/g, '').replace(' times', '');
        let count = parseInt(value, 10);

        count = isNaN(count) ? 0 : count;

        this.handleIterationsCountChange(count, values, setFieldValue);
    };

    private handleIterationsCountChange = (count, values, setFieldValue) => {
        const iterations = [];

        if (count > 0 && count < 26) {
            for (let index = 0; index < count; index++) {
                const iteration = values.iterations[index] ? values.iterations[index] : { duration: undefined, expiration: undefined, start: undefined };

                iterations.push(iteration);
            }

            setFieldValue('iterations', iterations);
        }
    };

    private handleDeleteIteration = (index, iterations, setFieldValue) => {
        const newIterations = iterations.filter((_, iterationIndex) => index !== iterationIndex);

        setFieldValue('iterations', newIterations);
    };

    private handleDisableStartDates = (date, index, iterations) => {
        const slot = this.earningWindow.getSlotFromDayBegin(date.unix());
        const priorFound = [...iterations]
            .slice(0, index)
            .reverse()
            .find((iteration) => {
                return iteration.expiration || iteration.start + EarningWindow.day - 1;
            });
        const nextFound = iterations.slice(index + 1).find((iteration) => {
            return iteration.start || iteration.expiration;
        });
        let prior = priorFound ? priorFound.expiration || priorFound.start : undefined;
        let next = nextFound ? nextFound.expiration || nextFound.start : undefined;

        if (iterations[index - 1] && (iterations[index - 1].expiration || iterations[index - 1].start)) {
            prior = iterations[index - 1].expiration || iterations[index - 1].start + EarningWindow.day - 1;
        }

        if (iterations[index] && iterations[index].expiration) {
            next = iterations[index].expiration;
        }

        if (this.earningWindow.window.type === 'rotating' && date.isoWeekday() > 5) {
            return true;
        }

        if (this.earningWindow.window.slots[slot] && !this.earningWindow.window.slots[slot].enabled) {
            return true;
        }

        return date.unix() < prior || date.unix() > next;
    };

    private handleDisableEndDates = (date, index, iterations) => {
        const slot = this.earningWindow.getSlot(date.unix());
        const priorFound = [...iterations]
            .slice(0, index)
            .reverse()
            .find((iteration) => {
                return iteration.start || iteration.expiration;
            });
        const nextFound = iterations.slice(index + 1).find((iteration) => {
            return iteration.start || iteration.expiration;
        });
        let prior = priorFound ? priorFound.expiration || priorFound.start : undefined;
        let next = nextFound ? nextFound.expiration || nextFound.start : undefined;

        if (iterations[index] && iterations[index].start) {
            prior = iterations[index].start;
        }

        if (iterations[index + 1] && iterations[index + 1].start) {
            next = iterations[index + 1].start;
        }

        if (this.earningWindow.window.type === 'rotating' && date.isoWeekday() > 5) {
            return true;
        }

        if (this.earningWindow.window.slots[slot] && !this.earningWindow.window.slots[slot].enabled) {
            return true;
        }

        return date.unix() < prior || date.unix() >= next;
    };

    private handleDateChangePrompt = (date, iterations, index: number, type: string, setFieldValue, setFieldTouched) => {
        const { isEditing, initalIterationCount } = this.state;

        if (isEditing && initalIterationCount >= index + 1 && type === 'expiration' && iterations[index][type] > date.unix()) {
            return this.setState({
                alert: {
                    showing: true,
                    title: 'Lowering the goal time may cause some rewards to be completed',
                    message: 'Are you sure you want to continue?',
                    buttonText: 'Cancel',
                    confirmText: 'Continue',
                    onConfirm: () => this.handleDateChangeConfirm(date, iterations, index, type, setFieldValue, setFieldTouched),
                    onClose: () => this.alertClosed(),
                },
            });
        }

        if (isEditing && initalIterationCount >= index + 1 && type === 'start') {
            return this.setState({
                alert: {
                    showing: true,
                    title: 'Changing the start date will reset student progress',
                    message: 'The progress students have made so far on this reward will reset to 0 if the start date is changed.',
                    buttonText: 'Cancel',
                    confirmText: 'Continue',
                    onConfirm: () => this.handleDateChangeConfirm(date, iterations, index, type, setFieldValue, setFieldTouched),
                    onClose: () => this.alertClosed(),
                },
            });
        }

        if (date) {
            this.handleDateChangeConfirm(date, iterations, index, type, setFieldValue, setFieldTouched);
        }
    };

    private handleHighPercentageAlert = (
        values: { initialPercentage: number; newPercentage: number; initialValue: number },
        iterations: any[],
        setFieldValue: any,
        type: string
    ) => {
        const { classTimeAlertShown, studentsAlertShown } = this.state;
        const { initialPercentage, newPercentage, initialValue } = values;

        if (type === 'durationSlider' && !classTimeAlertShown && initialPercentage < 80 && newPercentage > 79) {
            this.setState({
                alert: {
                    showing: true,
                    title: 'The % of class time you set is unlikely to work',
                    message: 'Your reward will not be acheivable if students miss a day of class. We suggest setting a lower % to account for days off',
                    buttonText: 'Cancel',
                    confirmText: 'Continue',
                    onConfirm: this.alertClosed,
                    onClose: () => {
                        this.handleDurationSecondsChanged(initialValue, iterations, setFieldValue);
                        this.alertClosed();
                    },
                },
                classTimeAlertShown: true,
            });
        }

        if (type === 'studentsSlider' && !studentsAlertShown && initialPercentage < 80 && newPercentage > 79) {
            this.setState({
                alert: {
                    showing: true,
                    title: 'The number of students you set is unlikely to work',
                    message: 'There will always be a few students without phones or will not participate. We suggest setting a lower %',
                    buttonText: 'Cancel',
                    confirmText: 'Continue',
                    onConfirm: this.alertClosed,
                    onClose: () => {
                        this.handleRequiredStudentsChanged(initialValue, setFieldValue);
                        this.alertClosed();
                    },
                },
                studentsAlertShown: true,
            });
        }
    };

    private handleDateChangeConfirm = (date, iterations, index, type, setFieldValue, setFieldTouched) => {
        const { alert, durationSeconds } = this.state;
        const iteration = iterations[index];
        const value = type === 'start' ? date.startOf('day').unix() : date.endOf('day').unix();
        const start = type === 'start' ? date.startOf('day').unix() : iteration.start;
        const expiration = type === 'expiration' ? date.endOf('day').unix() : iteration.expiration;
        const days = this.earningWindow.getDaysInWindow(start, expiration);
        let duration;

        if (days > 0) {
            duration = days * durationSeconds;
        }

        setFieldValue(`iterations[${index}].duration`, duration);
        setFieldValue(`iterations[${index}].${type}`, value);
        setFieldTouched(`iterations[${index}].${type}`, true);

        if (alert.showing) {
            this.setState({ alert: { showing: false } });
        }
    };

    private getInitialValues = () => {
        const { groups, groupId, groupIncentiveId } = this.props;
        const groupIncentive = groupId && groupIncentiveId ? groups[groupId].groupIncentives[groupIncentiveId] : undefined;
        const groupSize = groups[groupId].attributes.groupSize ? groups[groupId].attributes.groupSize : 2;

        let values = {
            name: '',
            details: '',
            isPrivate: null,
            iterations: [
                { duration: undefined, expiration: undefined, start: undefined },
                { duration: undefined, expiration: undefined, start: undefined },
                { duration: undefined, expiration: undefined, start: undefined },
            ],
            required: Math.ceil(groupSize * 0.3),
            requiredType: 'students',
        };

        if (groupIncentive) {
            values = {
                ...values,
                ...groupIncentive,
            };
        }

        return values;
    };

    private handleOtherLimit = (value) => {
        const limit = value > 0 ? value : null;

        this.setState({
            otherLimit: limit,
            showOtherLimit: false,
        });
    };

    private alertClosed = () => {
        this.setState({ alert: { showing: false } });
    };

    private handleRequiredStudentsChanged = (durationStudents, setFieldValue) => {
        setFieldValue('required', durationStudents);
    };

    private handleDurationSecondsChanged = (durationSeconds, iterations, setFieldValue) => {
        const newIterations = iterations.map((iteration) => {
            const iterationCopy = { ...iteration };
            if ( iteration.expiration > moment().unix()) {
                if (!isNaN(iteration.duration)) {
                    const days = this.earningWindow.getDaysInWindow(iteration.start, iteration.expiration, true);
    
                    iterationCopy.duration = days * durationSeconds;
                }
            }
            return iterationCopy;
        });

        setFieldValue('iterations', newIterations);

        this.setState({ durationSeconds });
    };

    private getInitialDurationSeconds = (iteration): number => {
        const days = this.earningWindow.getDaysInWindow(iteration.start, iteration.expiration, true);

        return iteration.duration / days;
    };

    private preSubmit = (values, setValues) => {
        return new Promise<void>((resolve) => {
            const iterations = values.iterations.reduce((collection, iteration, index) => {
                if (index === 0 || iteration.start || iteration.expiration) {
                    collection.push(iteration);
                }
                return collection;
            }, []);

            setValues({ ...values, iterations });
            resolve();
        });
    };

    private onSubmit = (values, { resetForm, setSubmitting }) => {
        const { actions, groupId, handleClose, groupIncentiveId } = this.props;
        const action = groupIncentiveId ? actions.groups.updateGroupIncentive : actions.groups.createGroupIncentive;
        action({ values, groupId, groupIncentiveId })
            .then(() => {
                resetForm();
                handleClose(true);
            })
            .catch(() => {
                setSubmitting(false);
                this.setState({
                    alert: {
                        showing: true,
                        title: 'Sorry',
                        message: 'Something went wrong. Try again',
                    },
                });
            });
    };

    private focusErrors = (isSubmitting: boolean, isValidating: boolean, errors: any) => {
        const keys = Object.keys(errors);

        if (keys.length > 0 && isSubmitting && !isValidating) {
            const selector = `[name="${keys[0]}"]`;
            const errorElement: HTMLElement = document.querySelector(selector);

            if (errorElement) {
                errorElement.focus();
            }
        }
    };
}

const mapStoreToProps = (state: any) => {
    return {
        actions: {
            groups: state.groups.actions,
        },
        earningWindow: state.earningWindow.data,
        groups: state.groups.data,
    };
};

export default connect(
    mapStoreToProps,
    null
)(withStyles(appStyle)(ClasswideForm));
