// import React from 'react';
// import { render } from 'react-dom';
// import App from './app/App';
// import { ErrorBoundary } from './libraries/bugsnag';
// import configStore from './store';

// const { store } = configStore();

// render(
//     <ErrorBoundary>
//         <App store={store} />
//     </ErrorBoundary>,
//     document.getElementById('root')
// );

import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import { render } from 'react-dom';
import App from './app/App';
import { ErrorBoundary } from './libraries/bugsnag';
import configStore from './store';
import { Button, Grid } from '@material-ui/core';
import { People } from 'mixpanel-react-native';
import { isMobile } from 'react-device-detect';

const { store } = configStore();
const ErrorView = () =>
  <div>
   <Grid container={true} item={true} xs={12} justifyContent="center" alignItems="center" direction="column" style={{ paddingLeft: 32, paddingRight: 32 }}>
            
                <Button color="primary" variant="contained">
                   Oops! Refresh and try sign in with another method or recover password...
                </Button>
            </Grid>
  </div>

const root = ReactDOMClient.createRoot(document.getElementById('root'));
root.render(
    <ErrorBoundary FallbackComponent={ErrorView}>
        <App store={store} />
    </ErrorBoundary>
);