import moment from 'moment-timezone';

// id: classroomKey,
//                 name: attributes.name,
//                 onDate: false,
//                 students: [],
//                 totalEarned: 0,
//                 duration: 0,
//                 durationHours: 0,
//                 durationMinutes: 0,
//                 currentSlot: slot > -1 ? progressWindow.window.slots[slot] : undefined,
//                 window: progressWindow.window,

export const ClassroomData = [
    {
        example: true,
        id: '1',
        name: '*Example* - 1st Period',
        onDate: true,
        students: [
            {
                id: '1',
                name: 'Daenerys Targaryen',
                pic: '',
                sessions: [{ gap: false, start: moment('08:50', 'hh:mm').unix(), end: moment('09:30', 'hh:mm').unix(), duration: 2400 }],
                totalDuration: 2400,
            },
            {
                id: '2',
                name: 'Jon Snow',
                pic: '',
                sessions: [
                    { gap: false, start: moment('08:50', 'hh:mm').unix(), end: moment('09:00', 'hh:mm').unix(), duration: 600 },
                    { gap: true, start: moment('09:01', 'hh:mm').unix(), end: moment('09:04', 'hh:mm').unix(), duration: 180 },
                    { gap: false, start: moment('09:05', 'hh:mm').unix(), end: moment('09:20', 'hh:mm').unix(), duration: 900 },
                    { gap: true, start: moment('09:21', 'hh:mm').unix(), end: moment('09:25', 'hh:mm').unix(), duration: 240 },
                    { gap: false, start: moment('09:26', 'hh:mm').unix(), end: moment('09:30', 'hh:mm').unix(), duration: 240 },
                ],
                totalDuration: 2160,
            },
            {
                id: '3',
                name: 'Sansa Stark',
                pic: '',
                sessions: [{ gap: false, start: moment('08:50', 'hh:mm').unix(), end: moment('09:15', 'hh:mm').unix(), duration: 1500 }],
                totalDuration: 1500,
            },
        ],
        totalEarned: 6660,
        window: {
            labelType: 'numeric',
            slots: [
                { end: 34200, start: 31800, enabled: true },
                { end: 34200, start: 31800, enabled: true },
                { end: 34200, start: 31800, enabled: false },
                { end: 34200, start: 31800, enabled: false },
                { end: 34200, start: 31800, enabled: true },
                { end: 34200, start: 31800, enabled: false },
                { end: 34200, start: 31800, enabled: true },
            ],
            startAt: 1564383600,
            startSlot: 0,
            timeZone: 'America/Los_Angeles',
            type: 'standard',
        },
        currentSlot: { end: 34200, start: 31800, enabled: true },
        duration: 2400,
        durationHours: 0,
        durationMinutes: 40,
        endTime: 1565973000,
        startTime: 1565970600,
    },
    {
        example: true,
        id: '2',
        name: '*Example* - 2nd Period',
        onDate: true,
        students: [
            {
                id: '1',
                name: 'Cersei Lannister',
                pic: '',
                sessions: [
                    { gap: false, start: moment('09:50', 'hh:mm').unix(), end: moment('10:00', 'hh:mm').unix(), duration: 600 },
                    { gap: true, start: moment('10:01', 'hh:mm').unix(), end: moment('10:04', 'hh:mm').unix(), duration: 180 },
                    { gap: false, start: moment('10:05', 'hh:mm').unix(), end: moment('10:20', 'hh:mm').unix(), duration: 900 },
                    { gap: true, start: moment('10:21', 'hh:mm').unix(), end: moment('10:25', 'hh:mm').unix(), duration: 240 },
                    { gap: false, start: moment('10:26', 'hh:mm').unix(), end: moment('10:30', 'hh:mm').unix(), duration: 240 },
                ],
                totalDuration: 2160,
            },
            {
                id: '2',
                name: 'Tyrion Lannister',
                pic: '',
                sessions: [
                    { gap: false, start: moment('09:50', 'hh:mm').unix(), end: moment('10:10', 'hh:mm').unix(), duration: 1200 },
                    { gap: true, start: moment('10:11', 'hh:mm').unix(), end: moment('10:13', 'hh:mm').unix(), duration: 120 },
                    { gap: false, start: moment('10:14', 'hh:mm').unix(), end: moment('10:20', 'hh:mm').unix(), duration: 360 },
                    { gap: true, start: moment('10:21', 'hh:mm').unix(), end: moment('10:30', 'hh:mm').unix(), duration: 540 },
                ],
                totalDuration: 1560,
            },
            {
                id: '3',
                name: 'Jaime Lanister',
                pic: '',
                sessions: [{ gap: false, start: moment('08:50', 'hh:mm').unix(), end: moment('09:15', 'hh:mm').unix(), duration: 1500 }],
                totalDuration: 1500,
            },
        ],
        totalEarned: 5220,
        window: {
            labelType: 'numeric',
            slots: [
                { end: 37800, start: 35400, enabled: true },
                { end: 37800, start: 35400, enabled: true },
                { end: 37800, start: 35400, enabled: false },
                { end: 37800, start: 35400, enabled: false },
                { end: 37800, start: 35400, enabled: true },
                { end: 37800, start: 35400, enabled: false },
                { end: 37800, start: 35400, enabled: true },
            ],
            length: 7,

            startAt: 1564383600,
            startSlot: 0,
            timeZone: 'America/Los_Angeles',
            type: 'standard',
        },
        currentSlot: { end: 37800, start: 35400, enabled: true },
        duration: 2400,
        durationHours: 0,
        durationMinutes: 40,
        endTime: 1565976600,
        startTime: 1565974200,
    },
];
