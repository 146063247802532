import { FormControl, Grid, Input, InputLabel, ListItem, ListItemText, Paper, Popper } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import Downshift from 'downshift';
import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import appStyle from '../app/App.style';

interface IProps {
    classes: any;
    value: string;
    onChange: any;
}

interface IState {
    openPopper: boolean;
}

class Referrer extends React.Component<IProps, IState> {
    private nameInputRef: any;
    private hoveringOnInputField = false;
    private hoveringOnPopper = false;

    constructor(props: IProps) {
        super(props);

        this.state = {
            openPopper: false,
        };
    }

    public componentDidMount() {
        const signupContainer = document.getElementById('signupContainer');
        if (signupContainer) {
            signupContainer.addEventListener('click', (e: Event) => this.onHidePopper());
        }
    }

    public render() {
        const { value, onChange } = this.props;
        const { openPopper } = this.state;

        return (
            <Grid container={true} item={true} xs={12} style={{ marginTop: -20 }}>
                <Downshift onSelect={onChange} itemToString={(item) => (item ? item.label : '')} inputValue={value}>
                    {({ getInputProps, getItemProps, getMenuProps, isOpen, inputValue, highlightedIndex, selectedItem }) => (
                        <div style={{ width: '100%' }}>
                            <FormControl margin="normal" required={false} fullWidth={true}>
                                <InputLabel htmlFor="referrer">How did you hear about Pocket Points?</InputLabel>
                                <Input
                                    autoComplete="off"
                                    inputProps={{ 'data-hj-whitelist': true }}
                                    placeholder="Please select one or type your own"
                                    id="referrer"
                                    name="referrer"
                                    onFocus={this.inputOnFocus}
                                    // onChange={handleChange}
                                    onChange={(e) => this.onInputChanged(e, onChange)}
                                    value={value}
                                    onMouseOver={() => {
                                        this.hoveringOnInputField = true;
                                    }}
                                    onMouseOut={() => {
                                        this.hoveringOnInputField = false;
                                    }}
                                />
                            </FormControl>

                            <div ref={(ref) => (this.nameInputRef = ref)} />
                            <Popper
                                open={openPopper}
                                anchorEl={this.nameInputRef}
                                placement="bottom"
                                disablePortal={true}
                                modifiers={{
                                    flip: {
                                        enabled: false,
                                    },
                                    preventOverflow: {
                                        enabled: false,
                                    },
                                    hide: {
                                        enabled: false,
                                    },
                                }}
                                style={{ zIndex: 100 }}
                                onMouseOver={() => {
                                    this.hoveringOnPopper = true;
                                }}
                                onMouseOut={() => {
                                    this.hoveringOnPopper = false;
                                }}
                            >
                                <div {...(isOpen ? getMenuProps({}, { suppressRefError: true }) : {})}>
                                    <Paper
                                        square={true}
                                        style={{
                                            width: this.nameInputRef ? this.nameInputRef.clientWidth : null,
                                            maxHeight: 210,
                                            overflowY: 'scroll',
                                            zIndex: 100,
                                        }}
                                    >
                                        {this.renderSuggestions(inputValue, getItemProps, highlightedIndex, selectedItem)}
                                    </Paper>
                                </div>
                            </Popper>
                        </div>
                    )}
                </Downshift>
            </Grid>
        );
    }

    private onInputChanged = (e, handleChange) => {
        handleChange(e.target.value);
    };

    private inputOnFocus = () => {
        this.setState({ openPopper: true });
    };

    private onHidePopper = () => {
        if (!this.hoveringOnInputField && !this.hoveringOnPopper) {
            this.setState({ openPopper: false });
        }
    };

    private renderSuggestions = (inputValue, getItemProps, highlightedIndex, selectedItem) => {
        const suggestions = this.getSuggestions();

        if (inputValue.length > 0) {
            this.hoveringOnPopper = false;
            return null;
        }

        const renderedList = suggestions.map((suggestion, index) => {
            const isHighlighted = highlightedIndex === index;
            const isSelected = selectedItem ? suggestion === selectedItem.name : false;

            return (
                <ListItem
                    {...getItemProps({ item: suggestion })}
                    key={index}
                    selected={isHighlighted}
                    component="div"
                    style={{ fontWeight: isSelected ? 500 : 400, cursor: 'pointer' }}
                >
                    <ListItemText primary={suggestion} />
                </ListItem>
            );
        });

        return <React.Fragment>{renderedList}</React.Fragment>;
    };

    private getSuggestions = () => {
        return [
            'Admin/Principal',
            'Other Teacher',
            'Technology Coordinator',
            'Students',
            'Friend/Family',
            'Email from Pocket Points',
            'Social Media',
            'Conference',
        ];
    };
}

const mapStoreToProps = (state: any) => {
    return {
        actions: state.searches.actions,
        location: state.searches.data.location,
        schools: state.searches.data.schools,
        user: state.user,
    };
};

export default compose<IProps, any>(
    connect(
        mapStoreToProps,
        null
    ),
    withStyles(appStyle)
)(Referrer);
