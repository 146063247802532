import { Button, Grid } from '@material-ui/core';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import Text from '../../components/Text';

interface IProps {
    classes: any;
    onClose: any;
    onLearnMore: any;
    actions: any;
}

class Reward extends React.Component<IProps> {
    public render() {
        const { classes, onClose, onLearnMore } = this.props;
        const padding = isMobile ? {} : { paddingLeft: 140, paddingRight: 140 };

        return (
            <Grid container={true} justifyContent="center" alignItems="center" className={classes.pageHeader} style={padding}>
                <Grid container={true} item={true} xs={12}>
                    <Grid
                        style={{
                            width: '100%',
                            height: isMobile ? 340 : 400,
                            backgroundImage: 'url(/images/phone-reward-explainer.png)',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            backgroundSize: 'contain',
                        }}
                    />
                </Grid>
                <Grid container={true} item={true} xs={12} style={{ marginTop: 35 }} justifyContent="center" wrap="nowrap">
                    <img alt="apple" src="/images/apple.png" style={{ width: 30, height: 30 }} />
                    <Text bold={true} fontSize={26} style={{ paddingLeft: 20 }} noWrap={true}>
                        Teacher Rewards
                    </Text>
                </Grid>
                <Grid item={true} xs={12} style={{ marginTop: 12 }}>
                    <Text light={true} fontSize={20} align="center">
                        You will be creating teacher rewards based on phone-free time in your classes
                    </Text>
                </Grid>
                <Grid container={true} direction="column" justifyContent="center" alignItems="center" className={classes.buttonLoaderWrapper}>
                    <Button onClick={onClose} variant="contained" color="primary" className={classes.primaryButton}>
                        Continue
                    </Button>
                    <Button
                        variant="text"
                        color="primary"
                        target="_blank"
                        href="https://intercom.help/portal-c4c52318881b/en/articles/2893520-teacher-rewards-gifts"
                        style={{ textTransform: 'none', marginTop: 8 }}
                        onClick={onLearnMore}
                    >
                        Learn More About Rewards and Gifts
                    </Button>
                </Grid>
            </Grid>
        );
    }
}

const mapStoreToProps = (state: any) => {
    return {
        actions: {
            mixpanel: state.mixpanel.actions,
        },
    };
};

export default connect(
    mapStoreToProps,
    null
)(Reward);
