import { Grid } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import CloseButton from '../../../components/CloseButton';
import Submit from '../../../components/Submit';
import Text from '../../../components/Text';
import appStyle from '../../App.style';

declare let gapi: any;
declare let remind: any;

interface IProps {
    classes: any;
    actions: any;
    handleShowClasswide: () => void;
    rewardName: string;
    inviteCode: string;
}

interface IState {
    glowActive: boolean;
}

export class SendReminderModal extends Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            glowActive: false,
        };
    }

    public componentDidMount() {
        const { rewardName, inviteCode } = this.props;

        this.renderReminderGoogleClassroom(rewardName, inviteCode);
    }

    public render() {
        const { classes, handleShowClasswide, inviteCode, rewardName } = this.props;
        const body = `Join the class on Pocket Points to earn: ${
            rewardName.length > 25 ? `${rewardName.slice(0, 23)}..` : rewardName.slice(0, 26)
        }. Download Pocket Points and use invite code ${inviteCode}`;

        return (
            <React.Fragment>
                <Grid container={true} style={{ padding: 16 }}>
                    <Grid container={true} justifyContent="space-between" alignItems="center">
                        <Grid item={true} style={{ padding: '6px 6px 2px 6px' }} className={classes.buttonHover}>
                            <CloseButton onClick={handleShowClasswide} />
                        </Grid>
                    </Grid>
                    <Grid container={true} justifyContent="center" alignItems="center" style={{ paddingBottom: 48 }}>
                        <Grid item={true}>
                            <Text fontSize={30} bold={true}>
                                Send Reminder
                            </Text>
                        </Grid>
                    </Grid>
                    <Grid container={true} className={classes.inviteStudentsContainer} direction="column" style={{ paddingBottom: 48 }}>
                        <Grid item={true} style={{ paddingBottom: 32 }} onClick={() => this.glowOnce()}>
                            <Grid container={true} alignItems="center" justifyContent="center">
                                <Grid item={true} style={{ height: 46, width: 46, ...this.glowStyle() }}>
                                    <div id="gShareWidget" />
                                </Grid>
                                <Grid item={true}>
                                    <Text style={{ fontSize: 16, fontWeight: 300, marginLeft: 20, width: 216 }}>Share on Google Classroom</Text>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item={true} style={{ paddingBottom: 32 }} onClick={() => this.glowOnce()}>
                            <Grid container={true} alignItems="center" justifyContent="center">
                                <Grid item={true} style={{ height: 46, width: 46, ...this.glowStyle() }}>
                                    <div
                                        className="remind-composer-button"
                                        data-body={body}
                                        data-body-url="https://pckt.app.link/e2wr2RwEXT"
                                        data-text="Share on Remind"
                                        data-theme="dark"
                                        data-size="medium"
                                        data-no-text="true"
                                        data-button-type="square"
                                        style={{ cursor: 'pointer' }}
                                        onClick={this.handleRemind}
                                    />
                                </Grid>
                                <Grid item={true}>
                                    <Text style={{ fontSize: 16, fontWeight: 300, marginLeft: 20, width: 216 }}>Share on Remind</Text>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item={true}>
                            <Submit onClick={handleShowClasswide}>Done</Submit>
                        </Grid>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }

    private renderReminderGoogleClassroom = (name, inviteCode) => {
        gapi.sharetoclassroom.render('gShareWidget', {
            url: 'https://pckt.app.link/e2wr2RwEXT',
            size: 46,
            body: `Reminder to join the class on Pocket Points for the class-wide reward: ${name}. Download the Pocket Points app and use the invite code ${inviteCode}`,
            onsharecomplete: 'googleClassromOnShareComplete',
            onsharestart: 'googleClassroomOnShareStart',
        });

        remind.init({
            name: 'Teacher Rewards',
            id: '597920dc-3807-4040-b393-4053832e29e8',
        });
    };

    private handleRemind = () => {
        const {
            actions: { mixpanel },
        } = this.props;

        mixpanel.sendEvent('Clicked Remind Share');
    };

    private glowStyle = () => {
        if (this.state.glowActive) {
            return {
                transition: 'box-shadow .4s ease-in-out',
                boxShadow: '0 0 6px black',
            };
        }
        return {
            transition: 'box-shadow .4s ease-in-out',
        };
    };

    private glowOnce = () => {
        this.setState({ glowActive: true });
        setTimeout(() => this.setState({ glowActive: false }), 400);
    };
}

const mapStoreToProps = (state: any) => {
    return {
        actions: {
            mixpanel: state.mixpanel.actions,
        },
    };
};

export default connect(
    mapStoreToProps,
    null
)(withStyles(appStyle)(SendReminderModal));
