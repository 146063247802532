import { Button, createStyles, Dialog, DialogContent, FormControl, Grid, Input, Theme, withStyles } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import React from 'react';
import { isMobile } from 'react-device-detect';
import Text from '../components/Text';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { Formik, FormikErrors } from 'formik';

declare let gapi: any;
declare let remind: any;

const styles = (theme: Theme) =>
    createStyles({
        topContainer: {
            padding: 22,
        },
        sidePadding: {
            paddingLeft: 22,
            paddingRight: 22,
            [theme.breakpoints.down('xs')]: {
                paddingLeft: 0,
                paddingRight: 0,
            },
        },
        submitButton: {
            marginTop: theme.spacing(3),
            height: 50,
            width: 160,
            borderRadius: 25,
            boxShadow: 'none',
            textTransform: 'none',
            fontSize: 17,
            fontWeight: 'bold',
            color: '#ffffff',
            
        },
        shareButtonContainer: {
            width: '100%',
            paddingLeft: theme.spacing(14),
            paddingRight: theme.spacing(14),
            [theme.breakpoints.down('xs')]: {
                paddingLeft: 0,
                paddingRight: 0,
            },
        },
        iconRoot: {
            width: '100%',
            height: '100%',
            color: '#7C67CE'
        },
        cancelButton: {
            backgroundColor: '#ffffff',
            height: 50,
            width: 139,
            boxShadow: 'none',
            textTransform: 'none',
            color: '#00D793',
            fontSize: 17,
        }
    });

interface IProps {
    classes: any;
    open: boolean;
    onClose: any;
    onSharedWith: any;
    mixpanel: any;
}

interface IState {
    phoneNumber: string
 }

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const shareText = 'Hi parents! I wanted to share this tool to help reduce cell phone distractions at home. I have been using the program at school and have seen success. You can learn more here: pocketpoints.com/home';
// const remindShareText = 'Check out Pocket Points for the family! Compete and work together to earn rewards.';

class AppShareModal extends React.Component<IProps, IState> {
    public render() {
        const { classes, open, onClose } = this.props;

        return (
            <React.Fragment>
                <Helmet>
                    <script src="https://apis.google.com/js/platform.js" async={true} defer={true} />
                </Helmet>

                <Dialog
                    fullScreen={isMobile}
                    open={open}
                    onClose={onClose}
                    // onEntered={() => this.renderClassroom(inviteCode)}// old 
                    TransitionProps={{
                        onEntered: () => { this.renderClassroom()}
                    }}
                    maxWidth="lg"
                    fullWidth={true} scroll="paper"
                >
                    <DialogContent style={{ position: 'relative', padding: 0 }}>
                        {!isMobile &&
                            <Grid container={true} direction='row-reverse' justifyContent="space-around">
                                <Grid item={true} style={{paddingTop: 32}}>
                                    <img src="/images/teacher-app-preview.png" alt="download teacher app" style={{maxHeight: 500}} />
                                </Grid>
                                <Grid item={true} className={classes.topContainer} style={{display: 'flex'}}>
                                    <Grid container={true}>
                                        <Grid container={true} item={true} style={{ height: 40 }}>
                                            <Grid container={true} item={true} justifyContent="space-between" style={{ height: 40 }} wrap="nowrap">
                                                <Icons.Close
                                                    onClick={onClose}
                                                    style={{
                                                        cursor: 'pointer',
                                                        color: '#000000'
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item={true} container={true} direction='column' justifyContent='flex-start' style={{flexGrow: 1}}>
                                            <Grid item={true} style={{paddingTop: 22}} className={classes.sidePadding}>
                                                <Text fontSize={32} align="left" bold={true}>
                                                    Get the new teacher app
                                                </Text>
                                            </Grid>
                                            <Grid item={true} style={{paddingTop: 16}} className={classes.sidePadding}>
                                                <Text fontSize={20} align="left">
                                                    Manage your classroom rewards from your mobile phone
                                                </Text>
                                            </Grid>
                                            <Formik
                                                initialValues={{
                                                    phoneNumber: '',
                                                }}
                                                validate={this.validatePhoneNumber}
                                                onSubmit={this.onTextMeClick}
                                            >
                                            {({ values, errors, handleChange, handleSubmit, isSubmitting }) => (
                                                <FormControl margin="normal" required={true} fullWidth={true} error={!!errors.phoneNumber}>
                                                    <form onSubmit={handleSubmit}>
                                                    <Grid item={true} container={true} direction='row' justifyContent='space-between' alignItems='baseline'>
                                                        <Grid item={true} style={{paddingTop: 16, paddingBottom: 32}} className={classes.sidePadding}>
                                                            <Input
                                                                inputProps={{ 'data-hj-whitelist': true }}
                                                                placeholder="Enter phone number"
                                                                id="phoneNumber"
                                                                autoFocus={true}
                                                                name="phoneNumber"
                                                                style={{paddingRight: 35}}
                                                                value={values.phoneNumber}
                                                                onChange={handleChange}
                                                            />
                                                        </Grid>
                                                        <Grid item={true} style={{paddingTop: 16, paddingBottom: 32}} className={classes.sidePadding}>
                                                            <Button variant="contained" color="primary" id="submit-button" type="submit" className={classes.submitButton} disabled={isSubmitting}>
                                                                Text me a link
                                                            </Button>
                                                        </Grid>
                                                        </Grid>
                                                    </form>
                                                </FormControl>
                                            )}
                                            </Formik>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>}
                        {isMobile &&
                            <Grid container={true} direction='column' justifyContent='flex-start' alignItems='center'>
                                <Grid item={true} style={{paddingTop: 32}}>
                                    <img src="/images/teacher-app-preview.png" alt="download teacher app" style={{maxHeight: 500}} />
                                </Grid>
                                <Grid item={true} style={{paddingTop: 22}} className={classes.sidePadding}>
                                    <Text fontSize={24} align="center" bold={true}>
                                        Get the new teacher app
                                    </Text>
                                </Grid>
                                <Grid item={true} style={{paddingTop: 16}} className={classes.sidePadding}>
                                    <Text fontSize={16} align="center">
                                        Manage your classroom rewards from your mobile phone
                                    </Text>
                                </Grid>
                                <Grid item={true} style={{paddingTop: 16}} className={classes.sidePadding}>
                                    <Button variant="contained" color="primary" id="submit-button" className={classes.submitButton} onClick={this.goToApp}>
                                        View App
                                    </Button>
                                </Grid>
                                <Grid item={true} style={{paddingTop: 16, paddingBottom: 32}} className={classes.sidePadding}>
                                    <Button variant="contained" color="primary" id="cancel-button" className={classes.cancelButton} onClick={onClose}>
                                        No thanks
                                    </Button>
                                </Grid>
                            </Grid>}
                    </DialogContent>
                </Dialog>
            </React.Fragment>
        );
    }

    private renderClassroom() {
        if (gapi) {
            gapi.sharetoclassroom.render('gShareWidget', {
                url: 'https://pocketpoints.com/home?channel=rewards&campaign=gc',
                size: 46,
                body: shareText,
                onsharecomplete: 'googleClassromOnShareCompleteForHome',
                onsharestart: 'googleClassroomOnShareStartForHome',
            });
        }

        remind.init({
            name: 'Teacher Rewards',
            id: '597920dc-3807-4040-b393-4053832e29e8',
        });
    }

    private goToApp() {
        window.location.assign('https://ccqo9.app.link/um4ZxS2fT3');
    }

    private validatePhoneNumber = (values) => {
        console.info('validatePhoneNumber Called');
        console.info(values);
        const errors: FormikErrors<any> = {};
        if (!values.phoneNumber) {
            errors.phoneNumber = 'Required';
          } else if (!phoneRegExp.test(values.phoneNumber)) {
            errors.phoneNumber = 'Invalid email address';
          }
        return errors
    }

    private onTextMeClick = (values, { setSubmitting }) => {
        const {phoneNumber} = values;
        this.sendSms(phoneNumber);
        setSubmitting(false);
    };

    private sendSms = (phoneNumber) => {
        const linkData = {
            tags: [],
            channel: 'Website',
            feature: 'TextMeTheApp'
        };

        window.branch.sendSMS(phoneNumber, linkData, () => {
            this.props.onClose();
        });
    }
}

const mapStoreToProps = (state: any) => {
    return {
        mixpanel: state.mixpanel,
    };
};

export default connect(
    mapStoreToProps,
    null
)(withStyles(styles)(AppShareModal));
