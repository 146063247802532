import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

const Download = (props) => {
    return (
        <SvgIcon viewBox="0 0 10 10" {...props}>
            <g id="School-View" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="school-view-" transform="translate(-378.000000, -1200.000000)" fill="#00D793">
                    <g id="Group-3" transform="translate(376.000000, 956.000000)">
                        <g id="iconfinder_download_1_286340" transform="translate(2.000000, 244.000000)">
                            <path
                                d="M0.310039062,8.44103516 L9.68998047,8.44103516 C9.85810547,8.44103516 10,8.57376953 10,8.75105469 L10,9.68998047 C10,9.85810547 9.85810547,10 9.68998047,10 L0.310039062,10 C0.141738281,10 0,9.85810547 0,9.68998047 L0,8.75105469 C0,8.57376953 0.141738281,8.44103516 0.310039062,8.44103516 Z M6.56316406,0.310039062 L6.56316406,4.06542969 L7.47554687,4.06542969 C7.97142578,4.06542969 8.12214844,4.34005859 8.12214844,4.53490234 C8.12214844,4.85376953 7.90945312,5.11070312 7.61714844,5.40302734 L5.62425781,7.39591797 C5.46498047,7.55519531 5.2434375,7.81212891 5.00451172,7.81212891 C4.75642578,7.81212891 4.53490234,7.55521484 4.37546875,7.39591797 L2.38255859,5.40302734 C2.09023437,5.11070312 1.87769531,4.85376953 1.87769531,4.53490234 C1.87769531,4.34005859 2.02828125,4.06542969 2.52427734,4.06542969 L3.43666016,4.06542969 L3.43666016,0.310039062 C3.43667969,0.141738281 3.57826172,0 3.74654297,0 L6.25314453,0 C6.42158203,0 6.56316406,0.141738281 6.56316406,0.310039062 Z"
                                id="Shape"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
};

export default Download;
