import withStyles from '@material-ui/core/styles/withStyles';
import React from 'react';
import appStyle from '../app/App.style';

interface IProps {
    classes: any;
    name: string;
    style?: any;
    onClick?: any;
}

class BlankAvatar extends React.PureComponent<IProps> {
    public render() {
        const { classes, name, style, onClick } = this.props;
        const initials = name
            .split(' ')
            .map((text) => text.trim()[0])
            .join('');
        const fontSize = style && style.fontSize ? style.fontSize : classes.blankAvatarSpan.fontSize;

        return (
            <div style={style} className={classes.blankAvatarWrap} onClick={onClick}>
                <span className={classes.blankAvatarSpan} style={{ fontSize }}>
                    {initials}
                </span>
            </div>
        );
    }
}

export default withStyles(appStyle)(BlankAvatar);
