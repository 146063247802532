import { Grid, Typography, withStyles } from '@material-ui/core';
import { Timeline } from '@material-ui/icons';
import React from 'react';
import appStyle from '../../App.style';

interface IProps {
    classes: any;
    title: string;
    body: any;
}

class NoInProgressCell extends React.Component<IProps> {
    public render() {
        const { classes, title, body } = this.props;

        return (
            <Grid item={true} xs={12} className={classes.pageRow}>
                <div className={classes.noGroupCellContainer}>
                    <Grid container={true} justifyContent="center" alignItems="center" direction="column">
                        <Grid item={true} xs={12}>
                            <Timeline className={classes.noIncentivesIcon} />
                            <Typography component="p" className={classes.noGroupsMainText}>
                                {title}
                            </Typography>
                            <Typography component="p" className={classes.noGroupsSubText}>
                                {body}
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
        );
    }
}

export default withStyles(appStyle)(NoInProgressCell);
