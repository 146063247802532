import { Avatar, Grid } from '@material-ui/core';
import { Line } from 'rc-progress';
import React, { PureComponent } from 'react';
import BlankAvatar from '../../../components/BlankAvatar';
import Text from '../../../components/Text';
import { IGroup, IProgress } from '../../../utils/types';

interface IProps {
    group: IGroup;
    progressList: IProgress;
    renderCompleted: boolean;
    duration?: number;
}

export default class GroupProgressUserList extends PureComponent<IProps> {
    public render() {
        const { group, progressList, duration, renderCompleted } = this.props;

        const completedList = Object.keys(group.students)
            .map((userId) => {
                const completedUser = renderCompleted && !!progressList[userId] && progressList[userId].hasOwnProperty('completed');
                const notCompletedUser =
                    !renderCompleted && (!progressList[userId] || (!!progressList[userId] && !progressList[userId].hasOwnProperty('completed')));

                if (completedUser || notCompletedUser) {
                    const name = group.students[userId].name;
                    const firstName = name.includes(' ') ? name.split(' ')[0] : name;
                    const lastName = name.includes(' ') ? name.split(' ')[name.split(' ').length - 1] : '';
                    const imageUrl = group.students[userId].imageUrl;
                    const progress = progressList[userId] ? progressList[userId].progress : undefined;

                    return {
                        userId,
                        firstName,
                        lastName,
                        imageUrl,
                        progress,
                        duration,
                    };
                }

                return undefined;
            })
            .filter(Boolean)
            .sort((a, b) => {
                if (b.progress && a.progress) {
                    return b.progress - a.progress;
                }
                if (b.progress) {
                    return b.progress;
                }
                if (a.progress) {
                    return -1;
                }
                return 0;
            });

        return completedList.map((user) => {
            return renderCompleted ? this.renderCompletedCell(user) : this.renderNotCompletedCell(user);
        });
    }

    private renderCompletedCell = ({ firstName, lastName, imageUrl, userId }) => {
        return (
            <Grid container={true} item={true} style={{ width: 57, height: 86 }} direction="column" key={userId} alignItems="center">
                <Grid item={true} style={{ display: 'flex' }}>
                    {this.renderAvatar(`${firstName} ${lastName}`, imageUrl)}
                </Grid>
                <Grid item={true}>
                    <Text fontSize={10} light={true} style={{ width: 57, textAlign: 'center', overflow: 'hidden' }}>
                        {firstName}
                    </Text>
                </Grid>
                <Grid item={true}>
                    <Text fontSize={10} light={true} style={{ width: 57, textAlign: 'center', overflow: 'hidden' }}>
                        {lastName}
                    </Text>
                </Grid>
            </Grid>
        );
    };

    private renderNotCompletedCell = ({ firstName, lastName, imageUrl, progress, userId, duration }) => {
        let studentProgress = progress ? Math.round((progress / 3600) * 4) / 4 : 0;
        let requiredStudentDuration = Math.round((duration / 3600) * 4) / 4;
        let timeTypeString = 'HOUR';

        if (requiredStudentDuration < 1) {
            requiredStudentDuration = Math.round(duration / 60);
            studentProgress = progress ? Math.round(progress / 60) : 0;
            timeTypeString = 'MIN';
        }

        return (
            <Grid
                container={true}
                key={userId}
                alignItems="center"
                style={{ border: '1px solid #ebebeb', borderRadius: '8px 8px 0px 0px', backgroundColor: '#fff', marginBottom: 16 }}
            >
                <Grid container={true} item={true} alignItems="center">
                    <Grid item={true} style={{ padding: '8px 8px 0px 8px' }}>
                        {this.renderAvatar(`${firstName} ${lastName}`, imageUrl)}
                    </Grid>
                    <Grid item={true} style={{ paddingTop: 8 }}>
                        <Text fontSize={14}>{`${firstName.slice(0, 16)} ${lastName.slice(0, 16)}`}</Text>
                    </Grid>
                    <Grid item={true} style={{ marginLeft: 'auto', marginRight: 16, paddingTop: 8 }}>
                        <Text fontSize={12} extraLight={true}>
                            {`${studentProgress}/${requiredStudentDuration} ${timeTypeString}${requiredStudentDuration === 1 ? '' : 'S'}`}
                        </Text>
                    </Grid>
                </Grid>
                <Grid container={true} item={true}>
                    <Grid item={true} xs={12}>
                        <Line
                            percent={progress ? (progress / duration) * 100 : 0}
                            strokeWidth={0.75}
                            strokeColor="#00D793"
                            strokeLinecap="butt"
                            style={{ marginBottom: -4 }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    private renderAvatar = (name, imageUrl) => {
        const style = { width: 40, height: 40, border: '1px solid #ececec' };

        if (imageUrl) {
            return (
                <div style={{ width: 57, height: 56, padding: 8, display: 'flex' }}>
                    <Avatar alt="Remy Sharp" src={imageUrl} style={style} />
                </div>
            );
        }

        return (
            <div style={{ width: 57, height: 56, padding: 8, display: 'flex' }}>
                <BlankAvatar name={name} style={style} />
            </div>
        );
    };
}
