import { Button, Dialog, DialogContent, Grid, Input } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import * as yup from 'yup';
import CloseButton from '../../components/CloseButton';
import Text from '../../components/Text';
import appStyle from '../App.style';

interface IProps {
    classes: any;
    actions: any;
    modal?: boolean;
    type: 'group' | 'incentive' | 'download' | 'classwide';
}

interface IState {
    mobileNumber: string;
    mobileValid: boolean;
    sizeValid: boolean;
}

class Explainers extends React.PureComponent<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            mobileNumber: '',
            mobileValid: false,
            sizeValid: false,
        };
    }

    public render() {
        const { modal } = this.props;

        if (modal) {
            return (
                <Dialog fullScreen={isMobile} open={true} onClose={() => null} maxWidth="sm" fullWidth={true}>
                    <DialogContent style={{ padding: 0, position: 'relative' }}>{this.renderContent()}</DialogContent>
                </Dialog>
            );
        }

        return this.renderContent();
    }

    private renderContent = () => {
        const { type } = this.props;

        return (
            <React.Fragment>
                <Grid container={true} item={true} xs={12} style={{ padding: 16, paddingBottom: 0 }}>
                    {type === 'download' && <CloseButton onClick={() => this.handleClose()} />}
                    {type === 'group' && this.renderGroup()}
                    {type === 'incentive' && this.renderIncentive()}
                    {type === 'download' && this.renderDownload()}
                    {type === 'classwide' && this.renderClasswide()}
                </Grid>
                {type === 'download' && this.renderDownloadFooter()}
            </React.Fragment>
        );
    };

    private renderGroup = () => {
        const { classes } = this.props;

        return (
            <Grid container={true} justifyContent="center" alignItems="center">
                <Grid container={true} item={true} xs={12}>
                    <Grid
                        style={{
                            width: '100%',
                            height: isMobile ? 340 : 400,
                            backgroundImage: 'url(/images/reward-explainer.gif)',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            backgroundSize: '85%',
                            marginTop: 10,
                        }}
                    />
                </Grid>
                <Grid item={true} xs={12} style={{ marginTop: 12 }}>
                    <Text light={true} fontSize={22} align="center">
                        Set a goal for phone-free time and the reward for completing that goal.
                    </Text>
                </Grid>
                <div className={classes.buttonLoaderWrapper}>
                    <Button onClick={() => this.handleClose()} variant="contained" color="primary" className={classes.primaryButton}>
                        Got it
                    </Button>
                    <Button
                        variant="text"
                        color="primary"
                        target="_blank"
                        href="https://intercom.help/portal-c4c52318881b/en/articles/2420864-reward-setup"
                        style={{ textTransform: 'none', marginTop: 8, width: '100%', marginBottom: 10 }}
                        onClick={this.handleLearnMoreReward}
                    >
                        Learn More About How Rewards Work
                    </Button>
                </div>
            </Grid>
        );
    };

    private renderIncentive = () => {
        const { classes } = this.props;

        return (
            <Grid container={true} justifyContent="center" alignItems="center" className={classes.pageHeader} style={{ padding: 16 }}>
                <Grid container={true} item={true} xs={12}>
                    <Grid
                        style={{
                            width: '100%',
                            height: isMobile ? 340 : 400,
                            backgroundImage: 'url(/images/student-explainer.gif)',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            backgroundSize: 'contain',
                        }}
                    />
                </Grid>
                <Grid item={true} xs={12} style={{ marginTop: 12 }}>
                    <Text light={true} fontSize={22} align="center">
                        Students will input your unique 5-digit class code in their app to join the class.
                    </Text>
                </Grid>
                <div className={classes.buttonLoaderWrapper}>
                    <Button onClick={() => this.handleClose()} variant="contained" color="primary" className={classes.primaryButton}>
                        Got it
                    </Button>
                    <Button
                        variant="text"
                        color="primary"
                        target="_blank"
                        href="https://intercom.help/portal-c4c52318881b/en/articles/2667555-how-pocket-points-works-for-students"
                        style={{ textTransform: 'none', marginTop: 8 }}
                        onClick={this.handleLearnMoreStudent}
                    >
                        Learn More About How It Works For Students
                    </Button>
                </div>
            </Grid>
        );
    };

    private renderDownload = () => {
        const { classes } = this.props;
        const wrapStyle = isMobile ? { paddingTop: 32, paddingBottom: 32, height: 280 } : { paddingRight: 12, height: 340 };

        return (
            <Grid container={true} justifyContent="center" alignItems="center" className={classes.pageHeader} style={{ padding: 16, paddingBottom: 0 }}>
                <Grid item={true} xs={12} style={{ marginTop: 8 }}>
                    <Text bold={true} fontSize={28} align="left">
                        Great, now download the app!
                    </Text>
                </Grid>

                <Grid item={true} xs={12}>
                    <Text light={true} fontSize={20} align="left">
                        See your classes from a student's perspective.
                    </Text>
                    <Text color="primary" italic={true} fontWeight={500} fontSize={20} align="left">
                        Reminder: this is not a teacher version of the app.
                    </Text>
                    <Text light={true} fontSize={20} align="left">
                        You will sign up as if you are a student.
                    </Text>
                </Grid>
                <Grid container={true} style={{ paddingTop: 40 }} direction="row-reverse">
                    <Grid item={true} xs={12} sm={6}>
                        <Grid
                            style={{
                                width: '100%',
                                height: 340,
                                backgroundImage: 'url(/images/app-view.png)',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center top',
                                backgroundSize: 'contain',
                            }}
                        />
                    </Grid>
                    <Grid container={true} item={true} xs={12} sm={6} justifyContent="space-between" style={wrapStyle}>
                        <Grid item={true}>
                            <Text bold={true} fontSize={18} align="left" style={{ paddingBottom: 10 }}>
                                Preview local & online deals
                            </Text>
                            <Text light={true} fontSize={14} align="left">
                                Visit the gift page to see the deals that local/online businesses offer
                            </Text>
                        </Grid>

                        <Grid item={true}>
                            <Text bold={true} fontSize={18} align="left" style={{ paddingBottom: 10 }}>
                                Test your classes
                            </Text>
                            <Text light={true} fontSize={14} align="left">
                                Enter your class codes and try earning your rewards at school
                            </Text>
                        </Grid>

                        <Grid item={true}>
                            <Text bold={true} fontSize={18} align="left" style={{ paddingBottom: 10 }}>
                                Compete with students
                            </Text>
                            <Text light={true} fontSize={14} align="left">
                                Join your classes and compete alongside with your students
                            </Text>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    private renderDownloadFooter = () => {
        const { classes } = this.props;
        const { mobileNumber, mobileValid } = this.state;

        return (
            <div className={`${classes.buttonLoaderWrapper} ${classes.explainerFooter}`}>
                <Grid container={true} justifyContent="center" alignItems="flex-end" style={{ padding: '0, 30px' }}>
                    <Grid item={true} xs={12} sm={6}>
                        <Input
                            id="phoneInput"
                            placeholder="Enter your phone number"
                            onChange={this.handleNumberChanged}
                            onKeyUp={this.handleKeyUp}
                            value={mobileNumber}
                            autoFocus={true}
                            fullWidth={true}
                        />
                    </Grid>
                    <Grid item={true} xs={12} sm={6}>
                        <Button disabled={!mobileValid} onClick={this.sendSms} variant="contained" color="primary" className={classes.primaryButton}>
                            Download Now
                        </Button>
                    </Grid>
                </Grid>
                <Grid container={true} justifyContent="center" alignItems="center">
                    <Button variant="text" color="primary" onClick={this.handleNoThanks} style={{ textTransform: 'none', marginTop: 8 }}>
                        No thanks, not right now
                    </Button>
                </Grid>
            </div>
        );
    };

    private renderClasswide = () => {
        const { classes } = this.props;

        return (
            <Grid container={true} justifyContent="center" alignItems="center" className={classes.pageHeader}>
                <Grid container={true} item={true} xs={12}>
                    <Grid
                        style={{
                            width: '100%',
                            height: isMobile ? 340 : 400,
                            backgroundImage: 'url(/images/classwide_explainer.gif)',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            backgroundSize: '100%',
                        }}
                    />
                </Grid>
                <div className={classes.buttonLoaderWrapper}>
                    <Button onClick={() => this.handleClose()} variant="contained" color="primary" className={classes.primaryButton}>
                        Got it
                    </Button>
                    <Button
                        variant="text"
                        color="primary"
                        target="_blank"
                        href="https://intercom.help/portal-c4c52318881b/en/articles/3160848-class-wide-rewards"
                        style={{ textTransform: 'none', marginTop: 16 }}
                        onClick={this.handleLearnMoreClasswide}
                    >
                        Learn More About How Class Wide Rewards Work
                    </Button>
                </div>
            </Grid>
        );
    };

    private handleLearnMoreReward = () => {
        const { actions } = this.props;

        actions.mixpanel.sendEvent('Clicked Learn More - Reward Explainer');
    };

    private handleLearnMoreClasswide = () => {
        const { actions } = this.props;

        actions.mixpanel.sendEvent('Clicked Learn More - Classwide Explainer');
    };

    private handleLearnMoreStudent = () => {
        const { actions } = this.props;

        actions.mixpanel.sendEvent('Clicked Learn More - Student Explainer');
    };

    private handleKeyUp = (event: any) => {
        if (event.keyCode === 13) {
            this.sendSms();
        }
    };

    private handleNumberChanged = async (event: any) => {
        const mobileNumber = event.target.value;
        const schema = yup
            .string()
            .required()
            .min(10);
        const mobileValid = await schema.isValid(mobileNumber);

        this.setState({ mobileNumber, mobileValid });
    };

    private sendSms = () => {
        const { actions } = this.props;
        const { mobileNumber } = this.state;
        const linkData = {
            tags: [],
            channel: 'Website',
            feature: 'TextMeTheApp',
        };

        window.branch.sendSMS(mobileNumber, linkData, () => {
            actions.mixpanel.sendEvent('Clicked Download Button');
            this.handleClose();
        });
    };

    private handleNoThanks = () => {
        const { actions } = this.props;

        actions.mixpanel.sendEvent('Clicked Download No Thanks');
        this.handleClose();
    };

    private handleClose = () => {
        const { actions, type } = this.props;

        if (type === 'group') {
            actions.mixpanel.sendEvent('Viewed Onboarding - Reward Explainer');
            actions.user.updateHelperModals({ showGroupExplainer: false, showIncentiveExplainer: true, showClasswideExplainer: true });
        }

        if (type === 'incentive') {
            actions.mixpanel.sendEvent('Viewed Onboarding - Student Explainer');
            actions.user.updateHelperModals({ showIncentiveExplainer: false, showDownloadExplainer: true });
        }

        if (type === 'download') {
            actions.mixpanel.sendEvent('Viewed Download Prompt');
            actions.user.updateHelperModals({ showDownloadExplainer: false });
        }

        if (type === 'classwide') {
            actions.mixpanel.sendEvent('Viewed Class Wide Explainer');
            actions.user.updateHelperModals({ showClasswideExplainer: false });
        }
    };
}

const mapStoreToProps = (state: any) => {
    return {
        actions: {
            mixpanel: state.mixpanel.actions,
            user: state.user.actions,
        },
    };
};

export default connect(
    mapStoreToProps,
    null
)(withStyles(appStyle)(Explainers));
