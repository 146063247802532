import { CircularProgress, createStyles, List, ListItem, ListItemSecondaryAction, ListItemText, Paper, Theme, Typography, withStyles } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import ErrorIcon from '@material-ui/icons/Error';
import React from 'react';
import { connect } from 'react-redux';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3),
        },
    });

interface IProps {
    classes: any;
    check: any;
    user: any;
}

interface IState {
    domains: any;
    loading: boolean;
}

class Check extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            domains: {
                getFirestore: {
                    url: 'https://firestore.googleapis.com',
                    status: -1,
                },
                getRealtime: {
                    url: 'https://pocketpoints-mobile-prod.firebaseio.com',
                    status: -1,
                },
                getMixpanel: {
                    url: 'https://api.mixpanel.com',
                    status: -1,
                },
                getGoogleMaps: {
                    url: 'https://maps.googleapis.com',
                    status: -1,
                },
            },
            loading: true,
        };

        window.addEventListener('error', (event) => {
            console.info('onerror', event);
        });
    }

    public componentDidMount() {
        this.runDomainsTest();
    }

    public render() {
        const { classes } = this.props;
        const { domains } = this.state;

        return (
            <Paper elevation={1} classes={{ root: classes.root }}>
                <Typography variant="h6" gutterBottom={true}>
                    Network Check
                </Typography>
                <Typography gutterBottom={true}>
                    Below is a list of the domains required to run this website properly. Some school networks block web domains for security reasons.
                </Typography>
                <Typography gutterBottom={true}>
                    If any of these domains are checked red, the site will not run properly and you will need to bring this to the attention of your IT staff.
                </Typography>
                <Typography gutterBottom={true}>
                    All of these domains are secure and safe. If anyone would like to talk to a member of our team, please reach out to us at
                    educators@pocketpoints.com.
                </Typography>
                <List>
                    {Object.keys(domains).map((key: any, index) => {
                        return (
                            <ListItem key={index} divider={true}>
                                <ListItemText>{domains[key].url}</ListItemText>
                                <ListItemSecondaryAction>
                                    {domains[key].status === -1 && <CircularProgress size={20} />}
                                    {domains[key].status === 0 && <ErrorIcon style={{ color: 'red' }} />}
                                    {domains[key].status === 1 && <DoneIcon style={{ color: 'green' }} />}
                                </ListItemSecondaryAction>
                            </ListItem>
                        );
                    })}
                </List>
                <div id="testmap" />
            </Paper>
        );
    }

    private runDomainsTest = () => {
        const { check } = this.props;
        const { domains } = this.state;
        const requests: any[] = [];

        Object.keys(domains).forEach((key: string) => {
            const request = check.actions[key]();

            requests.push(request);
        });

        return Promise.all(requests)
            .then((results) => {
                Object.keys(domains).forEach((key: string, index: number) => {
                    domains[key].status = results[index];
                });
            })
            .catch(() => {
                Object.keys(domains).forEach((key: string) => {
                    domains[key].status = 0;
                });
            })
            .then(() => {
                this.setState({ domains });
            });
    };
}

const mapStoreToProps = (state: any) => {
    return {
        check: state.check,
        user: state.user,
    };
};

export default connect(
    mapStoreToProps,
    null
)(withStyles(styles)(Check));
