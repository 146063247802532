import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'
import 'firebase/compat/storage'
import 'firebase/compat/database';
import 'firebase/compat/functions';
if (!firebase.apps.length) {
    firebase.initializeApp({
        projectId: `${window.REACT_APP_FIREBASE_projectId || process.env.REACT_APP_FIREBASE_projectId}`,
        apiKey: `${window.REACT_APP_FIREBASE_apiKey || process.env.REACT_APP_FIREBASE_apiKey}`,
        authDomain: `${window.REACT_APP_FIREBASE_authDomain || process.env.REACT_APP_FIREBASE_authDomain}`,
        databaseURL: `${window.REACT_APP_FIREBASE_databaseURL || process.env.REACT_APP_FIREBASE_databaseURL}`,
        storageBucket: `${window.REACT_APP_FIREBASE_storageBucket || process.env.REACT_APP_FIREBASE_storageBucket}`,
    });
}

export default firebase;