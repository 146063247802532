import { Button, Dialog, DialogContent, Grid } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import moment from 'moment';
import { Line } from 'rc-progress';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import EarningWindow from 'shared-library-js';
import CloseButton from '../../../components/CloseButton';
import Text from '../../../components/Text';
import { IGroup, IGroupIncentive, IIteration } from '../../../utils/types';
import appStyle from '../../App.style';
import NewIncentiveModal from '../../Incentives/NewIncentiveModal';
import GroupIncentiveReceipts from '../Cells/GroupIncentiveReceipts';
import GroupProgressUserList from '../Cells/GroupProgressUserList';
import SendReminderModal from './SendReminderModal';

interface IProps {
    classes: any;
    actions: {
        groups: any;
        groupProgresses: any;
        mixpanel: any;
    };
    data: {
        groups: any;
        groupProgresses: any;
        earningWindow: any;
    };
    open: boolean;
    iteration?: IIteration;
    groupIncentive?: IGroupIncentive;
    toggleClasswideModal: (shouldShow: boolean, modalIteration: IIteration, modalGroupIncentive: IGroupIncentive) => void;
}

interface IState {
    showEditIncentive: boolean;
    showReminder: boolean;
    showClasswideReward: boolean;
    percentClassTime: number;
    iteration: IIteration;
    showReceiptList: boolean;
    group: IGroup;
    groupReward: IGroupIncentive;
}

class ClasswideModal extends React.PureComponent<IProps, IState> {
    private earningWindow;

    constructor(props: IProps) {
        super(props);

        const iteration = this.props.iteration ? this.props.iteration : this.getMostRecentIteration(this.props.groupIncentive.iterations);
        const { groupProgresses, groups } = this.props.data;
        const { groupProgressesId } = iteration;
        const groupProgress = groupProgresses[groupProgressesId];
        const { classroomId } = groupProgress;
        const group = groups[classroomId];
        this.earningWindow = new EarningWindow(this.props.data.earningWindow, group);
        const groupReward = group.groupIncentives && groupProgress.groupIncentiveId ? group.groupIncentives[groupProgress.groupIncentiveId] : undefined;

        this.state = {
            iteration,
            showEditIncentive: false,
            showReminder: false,
            showClasswideReward: true,
            percentClassTime: Math.round((this.getInitialDurationSeconds(iteration) / this.earningWindow.getAverageDuration()) * 100),
            showReceiptList: this.props.groupIncentive ? true : false,
            group,
            groupReward,
        };
    }

    public componentDidMount() {
        const { actions } = this.props;
        const { group, groupReward, iteration } = this.state;

        actions.mixpanel.sendEventWithProps('Viewed Reward - Class Wide', {
            'Class Name': group.attributes.name,
            'Reward Name': groupReward ? groupReward.name : 'Error: no group or name',
            'Iteration Start': moment.unix(iteration.start).format(),
            'Iteration End': moment.unix(iteration.expiration).format(),
        });
    }

    public componentDidUpdate(prevProps) {
        if (this.props.iteration !== prevProps.iteration) {
            this.setState({ iteration: this.props.iteration });
        }
    }

    public render() {
        const { open, toggleClasswideModal } = this.props;
        const { showEditIncentive, showReminder, showClasswideReward, iteration } = this.state;
        const { groupProgresses, groups } = this.props.data;
        const { groupProgressesId } = iteration;
        const groupProgress = groupProgresses[groupProgressesId];
        const { classroomId, groupIncentiveId } = groupProgress;
        const group = groups[classroomId];
        const incentive = group.groupIncentives[groupIncentiveId];
        const { name } = incentive;
        const { inviteCode } = group.attributes;

        if (showEditIncentive) {
            return <NewIncentiveModal open={showEditIncentive} onClose={this.handleShowClasswide} groupId={classroomId} groupIncentiveId={groupIncentiveId} />;
        }

        if (showClasswideReward || showReminder) {
            return (
                <Dialog fullScreen={isMobile} open={open} onClose={() => toggleClasswideModal(false, undefined, undefined)} maxWidth="sm" fullWidth={true}>
                    <DialogContent style={{ padding: 0, position: 'relative' }}>
                        {showClasswideReward && this.renderClasswideReward()}
                        {showReminder && <SendReminderModal rewardName={name} inviteCode={inviteCode} handleShowClasswide={this.handleShowClasswide} />}
                    </DialogContent>
                </Dialog>
            );
        }
    }

    private renderClasswideReward = () => {
        const { toggleClasswideModal } = this.props;
        const { percentClassTime, iteration, showReceiptList } = this.state;
        const { duration, expiration, start, groupProgressesId } = iteration;
        const { groupProgresses, groups } = this.props.data;
        const groupProgress = groupProgresses[groupProgressesId];
        const { classroomId, groupIncentiveId, appUsers } = groupProgress;
        const group = groups[classroomId];
        const groupIncentive = group.groupIncentives[groupIncentiveId];
        const { required } = groupIncentive;
        const startDate = moment.unix(start).format('ddd, M/D');
        const endDate = moment.unix(expiration).format('ddd, M/D');
        const { progress } = groupProgress;
        const uniqueCompleted = this.uniqueCompletedCount(progress);
        const notCompletedCount = appUsers.length - uniqueCompleted;
        const { inviteCode } = group.attributes;
        const moreStudentsNeeded = group.students && Object.keys(group.students).length < required ? true : false;

        return (
            <React.Fragment>
                <Helmet>
                    <script src="https://apis.google.com/js/platform.js" async={true} defer={true} />
                </Helmet>
                <Grid container={true}>
                    <Grid container={true} justifyContent="space-between" alignItems="center" style={{ padding: 16 }}>
                        <Grid item={true}>
                            <CloseButton onClick={() => toggleClasswideModal(false, undefined, undefined)} />
                        </Grid>
                        <Grid item={true}>
                            <Button
                                onClick={this.handleEditIncentiveModal}
                                variant="text"
                                size="small"
                                style={{ textTransform: 'none', fontSize: 14, fontWeight: 400 }}
                            >
                                Edit Reward
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container={true} direction="row" justifyContent="space-between" style={{ borderBottom: '1px solid #ebebeb', padding: '0px 32px 8px 32px' }}>
                        <Grid item={true} xs={12} style={{ paddingBottom: 4 }}>
                            <Text fontSize={28} bold={true}>
                                {groupIncentive.name}
                            </Text>
                        </Grid>
                        <Grid item={true} xs={12} style={{ paddingBottom: 24 }}>
                            <Text fontSize={18}>{group.attributes.name}</Text>
                        </Grid>
                        <Grid item={true} xs={12}>
                            <Text light={true}>{`${startDate} - ${endDate}`}</Text>
                        </Grid>
                        <Grid item={true} xs={12}>
                            <Line percent={(uniqueCompleted / groupIncentive.required) * 100} strokeWidth={0.75} strokeColor="#00D793" strokeLinecap="butt" />
                        </Grid>
                        <Grid item={true} style={{ paddingTop: 4 }}>
                            <Text fontSize={11} semiBold={true} extraLight={true}>{`${uniqueCompleted}/${groupIncentive.required} STUDENT${
                                groupIncentive.required === 1 ? '' : 'S'
                            } COMPLETED`}</Text>
                        </Grid>
                        <Grid item={true} style={{ paddingTop: 4 }}>
                            <Text fontSize={11} semiBold={true} extraLight={true}>
                                {percentClassTime}% OF CLASS TIME
                            </Text>
                        </Grid>
                        {moreStudentsNeeded && (
                            <Grid item={true} xs={12} style={{ paddingTop: 32 }}>
                                <Grid
                                    container={true}
                                    justifyContent="center"
                                    alignItems="center"
                                    style={{ backgroundColor: 'rgba(208, 2, 27, .15)', borderRadius: 4 }}
                                >
                                    <Grid item={true} xs={12}>
                                        <Text
                                            fontSize={12}
                                            error={true}
                                            bold={true}
                                            style={{ padding: '16px 16px 0px 16px', opacity: 0.75, textAlign: 'center' }}
                                        >
                                            Not enough students have joined your class to complete this reward
                                        </Text>
                                    </Grid>
                                    <Grid item={true} style={{ backgroundColor: '#fff', margin: 16, borderRadius: 4 }}>
                                        <Button
                                            onClick={() => this.handleSendAReminder(groupIncentive.name, inviteCode)}
                                            variant="text"
                                            size="small"
                                            style={{ textTransform: 'none', fontSize: 12, opacity: 0.5, paddingRight: 16, paddingLeft: 16 }}
                                        >
                                            Send a Reminder
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                        <Grid
                            container={true}
                            item={true}
                            xs={12}
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                            style={{ padding: '16px 0px 32px 0px' }}
                        >
                            <GroupProgressUserList group={group} progressList={progress} renderCompleted={true} />
                        </Grid>
                        <Grid item={true} xs={12}>
                            <Text light={true} fontSize={11}>
                                {`${notCompletedCount} STUDENT${notCompletedCount === 1 ? '' : 'S'} NOT COMPLETED`}
                            </Text>
                        </Grid>
                    </Grid>
                    <Grid
                        container={true}
                        direction="column"
                        justifyContent="center"
                        alignItems="stretch"
                        style={{ backgroundColor: '#FCFCFC', padding: '24px 24px 16px 24px' }}
                    >
                        <Grid item={true} xs={12}>
                            <GroupProgressUserList group={group} progressList={progress} renderCompleted={false} duration={duration} />
                        </Grid>
                    </Grid>
                    {showReceiptList && (
                        <Grid container={true} item={true} xs={12} direction="row" justifyContent="center" style={{ backgroundColor: '#fff' }}>
                            <Grid container={true} item={true} xs={12}>
                                <GroupIncentiveReceipts groupIncentive={groupIncentive} toggleClasswideModal={toggleClasswideModal} iteration={iteration} />
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </React.Fragment>
        );
    };

    private handleEditIncentiveModal = () => {
        this.setState({
            showReminder: false,
            showClasswideReward: false,
            showEditIncentive: true,
        });
    };

    private handleShowClasswide = () => {
        this.setState({
            showReminder: false,
            showClasswideReward: true,
            showEditIncentive: false,
        });
    };

    private handleSendAReminder = (name, inviteCode) => {
        const { actions } = this.props;

        actions.mixpanel.viewedClasswideReminder();

        this.setState({
            showReminder: true,
            showClasswideReward: false,
            showEditIncentive: false,
        });
    };

    private getInitialDurationSeconds = (iteration: IIteration): number => {
        const days = Math.ceil((iteration.expiration - iteration.start) / EarningWindow.day);

        return iteration.duration / days;
    };

    private getMostRecentIteration = (iterations: IIteration[]): IIteration => {
        const currentDate = moment();

        if (iterations.length < 1) {
            return undefined;
        }

        let iteration: IIteration = iterations.find((iter) => {
            return currentDate > moment.unix(iter.start) && currentDate < moment.unix(iter.expiration);
        });

        if (iteration === undefined) {
            iteration = iterations[0];
            iterations.forEach((iter, index) => {
                if (moment.unix(iter.expiration) < currentDate) {
                    iteration = iterations[index];
                }
            });
        }

        return iteration;
    };

    private uniqueCompletedCount = (progress: any) => {
        let uniqueCompletedCount = 0;

        if (progress) {
            Object.keys(progress).forEach((key: any) => {
                if (progress[key].completion) {
                    uniqueCompletedCount++;
                }
            });
        }

        return uniqueCompletedCount;
    };
}

const mapStoreToProps = (state: any) => {
    return {
        actions: {
            groups: state.groups.actions,
            groupProgresses: state.groupProgresses.actions,
            mixpanel: state.mixpanel.actions,
        },
        data: {
            groups: state.groups.data,
            groupProgresses: state.groupProgresses.data,
            earningWindow: state.earningWindow.data,
        },
    };
};

export default connect(
    mapStoreToProps,
    null
)(withStyles(appStyle)(ClasswideModal));
