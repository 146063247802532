import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import React from 'react';

const env = `${process.env.REACT_APP_ENV}`;
let releaseStage;
if (env === 'local') {
    releaseStage = 'local';
} else if (env === 'dev') {
    releaseStage = 'development';
} else if (env === 'prod' || env === 'beta') {
    releaseStage = 'production';
}

const bugsnagClient = Bugsnag.start({
    apiKey: 'f01c5262fa7a1fd204fdc246cf53ec1b',
    plugins: [new BugsnagPluginReact()],
    // notifyReleaseStages: ['development', 'production'],
    releaseStage: 'production',
    // enabledReleaseStages: ['development','production'],

});


// const ErrorBoundary = bugsnagClient.getPlugin('react');
const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

export { bugsnagClient, ErrorBoundary };
