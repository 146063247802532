import { FormControl, FormHelperText, Grid, Input, InputLabel } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import moment from 'moment';
import React from 'react';
import { isMobile } from 'react-device-detect';
import MaskedInput from 'react-text-mask';
import appStyle from '../app/App.style';
import Tooltip from './Tooltip';

interface IProps {
    classes: any;
    endKey: string;
    endLabel?: string;
    endTime: string;
    handleTimeChanged: any;
    page: string;
    hideTooltip?: boolean;
    noPadding?: boolean;
    startKey: string;
    startLabel?: string;
    startTime: string;
    error: boolean;
    errorMessage: string;
    startId: string;
    endId: string;
}

interface IState {
    endTime?: string;
    endTimeError?: string | null;
    startTime?: string;
    startTimeError?: string | null;
}

class Time extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            endTime: moment(props.endTime, 'HH:mm').format('hh:mm A'),
            endTimeError: null,
            startTime: moment(props.startTime, 'HH:mm').format('hh:mm A'),
            startTimeError: null,
        };
    }

    public componentDidUpdate(prevProps: IProps) {
        const { endTime, startTime } = this.props;

        if (prevProps.endTime !== endTime || prevProps.startTime !== startTime) {
            this.setState({
                endTime: moment(endTime, 'HH:mm').format('hh:mm A'),
                endTimeError: null,
                startTime: moment(startTime, 'HH:mm').format('hh:mm A'),
                startTimeError: null,
            });
        }
    }

    public render() {
        const { hideTooltip, noPadding, page, error, errorMessage } = this.props;
        const { endTime, endTimeError, startTime, startTimeError } = this.state;
        const startLabel = this.props.startLabel ? this.props.startLabel : 'Start time';
        const endLabel = this.props.endLabel ? this.props.endLabel : 'End time';
        const parentError = !(endTimeError || startTimeError) && error;
        return (
            <React.Fragment>
                <Grid container={true} justifyContent="center" alignItems="center" style={{ paddingTop: noPadding ? 0 : 24 }}>
                    <Grid item={true} xs={6}>
                        <FormControl margin="normal" style={{ width: '95%' }} error={parentError}>
                            <InputLabel htmlFor={this.props.startId}>{startLabel}</InputLabel>
                            <Input
                                value={isMobile ? this.props.startTime : startTime}
                                onBlur={this.handleBlur('startTime')}
                                onChange={this.handleChange('startTime')}
                                onKeyPress={this.preventEnter}
                                onKeyUp={this.handleKeyUp(this.props.startId)}
                                onClick={this.handleClick(this.props.startId)}
                                id={this.props.startId}
                                name="start-time"
                                inputComponent={isMobile ? undefined : this.renderMaskedInput}
                                inputProps={{ 'data-hj-whitelist': true }}
                                type={isMobile ? 'time' : 'text'}
                            />
                            {!parentError && <FormHelperText error={false}>{startTimeError}</FormHelperText>}
                        </FormControl>
                    </Grid>
                    <Grid item={true} xs={6}>
                        <FormControl margin="normal" fullWidth={true} error={parentError}>
                            <React.Fragment>
                                <InputLabel htmlFor={this.props.endId}>{endLabel}</InputLabel>
                                <Input
                                    value={isMobile ? this.props.endTime : endTime}
                                    onBlur={this.handleBlur('endTime')}
                                    onChange={this.handleChange('endTime')}
                                    onKeyPress={this.preventEnter}
                                    onKeyUp={this.handleKeyUp(this.props.endId)}
                                    onClick={this.handleClick(this.props.endId)}
                                    id={this.props.endId}
                                    name="end-time"
                                    inputComponent={isMobile ? undefined : this.renderMaskedInput}
                                    inputProps={{ 'data-hj-whitelist': true }}
                                    type={isMobile ? 'time' : 'text'}
                                />
                                {!parentError && <FormHelperText error={false}>{endTimeError}</FormHelperText>}
                                {!hideTooltip && (
                                    <Tooltip isInput={true} page={page} id="classTime">
                                        Students will only be able to progress towards your rewards during this time.
                                    </Tooltip>
                                )}
                            </React.Fragment>
                        </FormControl>
                    </Grid>
                    {parentError && (
                        <Grid container={true} justifyContent="flex-start">
                            <FormHelperText error={parentError}>{errorMessage}</FormHelperText>
                        </Grid>
                    )}
                </Grid>
            </React.Fragment>
        );
    }

    private renderMaskedInput = (props) => {
        const { inputRef, ...other } = props;

        return (
            <MaskedInput
                {...other}
                ref={(ref) => inputRef(ref ? ref.inputElement : null)}
                mask={[/[0-9]/, /[0-9]/, ':', /[0-5]/, /[0-9]/, ' ', /a|p/i, /m/i]}
                guide={true}
                showMask={true}
                keepCharPositions={true}
                pipe={this.handleMaskedInput}
            />
        );
    };

    private handleMaskedInput = (conformedValue) => {
        let value = conformedValue;
        let indexesOfPipedChars = [];

        if (conformedValue.match(/^[2-9]_/)) {
            value = `0${conformedValue[0]}${conformedValue.slice(2)}`;
            indexesOfPipedChars = [1, 2];
        }

        return { value, indexesOfPipedChars };
    };

    private handleChange = (id) => (event) => {
        const { target } = event;
        const { endKey, handleTimeChanged, startKey } = this.props;
        const key = id === 'startTime' ? startKey : endKey;
        const regex = isMobile ? /^(\d{2}):(\d{2})/ : /^(\d{2}):(\d{2})\s([a|p])*./i;
        const matches = target.value.match(regex);
        const mask = isMobile ? 'HH:mm' : 'HH:mm A';
        const date = matches ? moment(target.value, mask) : moment();

        const state: IState = {
            [id]: target.value,
            [`${id}Error`]: !matches ? 'Invalid format' : null,
        };

        this.setState(state, () => {
            if (matches) {
                handleTimeChanged({ id: key, value: date.format('HH:mm') });
            }
        });
    };

    private handleBlur = (id) => () => {
        if (this.state[id] !== this.props[id]) {
            this.setState({ [id]: moment(this.props[id], 'HH:mm').format('hh:mm A'), [`${id}Error`]: null });
        }
    };

    private preventEnter = (event) => {
        const { key } = event;

        if (key === 'Enter') {
            event.preventDefault();
        }
    };

    private handleClick = (elementId: string) => () => {
        const inputField = document.getElementById(elementId) as HTMLInputElement;
        const { selectionStart } = inputField;
        if (selectionStart !== null) {
            if (selectionStart < 3) {
                inputField.setSelectionRange(0, 2);
            } else if (selectionStart && selectionStart < 6) {
                inputField.setSelectionRange(3, 5);
            } else if (selectionStart && selectionStart > 5) {
                inputField.setSelectionRange(6, 8);
            }
        }
    };

    private handleKeyUp = (elementId: string) => (event) => {
        const inputField = document.getElementById(elementId) as HTMLInputElement;
        const nextInputField = document.getElementById(`time-${parseInt(elementId[elementId.length - 1]) + 1}`) as HTMLInputElement;
        const { selectionStart } = inputField;
        if (selectionStart !== null && event.key !== 'Backspace' && event.key !== 'ArrowLeft') {
            if (selectionStart === 0) {
                inputField.setSelectionRange(0, 2);
            } else if (selectionStart === 1 && event.key.match(/[^0-9]/)) {
                inputField.setSelectionRange(0, 2);
            } else if (selectionStart === 1) {
                inputField.setSelectionRange(1, 2);
            } else if (selectionStart === 2 || selectionStart === 3) {
                inputField.setSelectionRange(3, 5);
            } else if (selectionStart === 4) {
                inputField.setSelectionRange(4, 5);
            } else if (event.key.match(/[^a-zA-Z]/) && selectionStart > 4) {
                inputField.setSelectionRange(6, 7);
            } else if (selectionStart < 7 && selectionStart > 4) {
                inputField.setSelectionRange(6, 7);
            } else if (selectionStart === 7 && nextInputField) {
                nextInputField.focus();
                nextInputField.setSelectionRange(0, 2);
            }
        }
    };
}

export default withStyles(appStyle)(Time);
