import MomentUtils from '@date-io/moment';
import { Grid, Paper, Typography } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import withStyles from '@material-ui/core/styles/withStyles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { Store } from 'redux';
import styles from './App.style';
import theme from './App.theme';
import AppBar from './AppBar';
import Loader from './AppLoader';
import Routes from './Routes';

declare global {
    /* tslint:disable:interface-name */
    interface Window {
        appVersion: string;
        Intercom: any;
        env: string;
        REACT_APP_ENV: string;
        REACT_APP_FIREBASE_PROJECTID: any;
        REACT_APP_FIREBASE_apiKey: any;
        REACT_APP_FIREBASE_authDomain: any;
        REACT_APP_FIREBASE_databaseURL: any;
        REACT_APP_FIREBASE_projectId: any;
        REACT_APP_FIREBASE_storageBucket: any;
        REACT_APP_MIXPANEL_TOKEN: any;
        googleClassromOnShareComplete: any;
        googleClassroomOnShareStart: any;
        googleClassroomOnShareStartForHome: any;
        googleClassromOnShareCompleteForHome: any;
        remindShared: any;
        branch: any;
    }
    /* tslint:enable:interface-name */
}

interface IProps {
    store: Store;
    classes: any;
    location?: any;
}

window.env = `${window.REACT_APP_ENV || process.env.REACT_APP_ENV}`;

class App extends React.Component<IProps> {
    constructor(props: IProps) {
        super(props);
        window.googleClassroomOnShareStart = () => {
            props.store.getState().mixpanel.actions.clickedGoogleClassroomShare();
        };

        window.googleClassromOnShareComplete = () => {
            props.store.getState().mixpanel.actions.sharedGoogleClassrooms();
        };

        window.googleClassroomOnShareStartForHome = () => {
            props.store.getState().mixpanel.actions.clickedGoogleClassroomShareForHome();
        };

        window.googleClassromOnShareCompleteForHome = () => {
            props.store.getState().mixpanel.actions.sharedGoogleClassroomsForHome();
        };
    }

    public componentDidMount() {
        console.info(`app:componentDidMount:before:`);
        const { location, store } = this.props;
        console.info(`app:componentDidMount:location: ${location}`);
        store.getState().user.actions.getGoogleRedirect(location);
    }

    public render() {
        const { store, classes } = this.props;

        return (
            <Router basename="/">
                <Provider store={store}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <MuiThemeProvider theme={theme}>
                            <Loader />
                            {this.renderBaseline()}
                            <AppBar />
                            <main className={classes.root}>
                                <Paper className={classes.rootPaper} elevation={isMobile ? 0 : 1}>
                                    <Routes />
                                </Paper>
                            </main>
                            <Grid container={true} style={{ paddingBottom: 22 }}>
                                <Grid item={true} xs={12}>
                                    <Typography component="p" className={classes.copyright} style={{ textAlign: 'center' }}>
                                        &copy; {moment().year()} Pocket Points Inc.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </MuiThemeProvider>
                    </MuiPickersUtilsProvider>
                </Provider>
            </Router>
        );
    }

    private renderBaseline = () => {
        if (!isMobile) {
            return <CssBaseline />;
        }

        return null;
    };
}

export default withStyles(styles)(App);
