import { FormControl, Grid, Typography } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import React from 'react';
import Tooltip from '../../../components/Tooltip';
import appStyle from '../../App.style';

interface IProps {
    duration: number;
    handleDurationChanged: any;
    page: string;
}

class IncentiveDuration extends React.PureComponent<IProps> {
    public render() {
        const { duration, page } = this.props;
        const readableTime = this.minutesToReadable(duration);
        const sliderValue = duration <= 3600 ? duration / 600 : duration / 3600 + 5;

        return (
            <React.Fragment>
                <FormControl margin="normal" fullWidth={true} style={{ textAlign: 'center', paddingTop: 16 }}>
                    <Grid container={true} wrap="nowrap">
                        <Grid item={true} style={{ flex: 1 }}>
                            <Typography style={{ opacity: 0.5 }} align="left">
                                How much time does the student need to be off their phone in class to earn this reward?
                            </Typography>
                        </Grid>
                        <Grid item={true}>
                            <Tooltip page={page} id="rewardDuration">
                                Set a time goal for how much time your student needs to be off their phone using the Pocket Points app. We suggest setting a few
                                easy rewards to start so your students understand the process.
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <Slider
                        min={1}
                        max={55}
                        step={1}
                        value={sliderValue}
                        defaultValue={sliderValue}
                        onChange={this.handleChange}
                        trackStyle={[{ height: 5, backgroundColor: '#D8D8D8' }]}
                        handleStyle={[{ height: 37, width: 37, marginTop: -15, borderColor: '#979797' }]}
                        style={{ marginTop: 25, width: '95%' }}
                    />
                    <Grid container={true} wrap="nowrap" justifyContent="space-between" style={{ marginTop: 14 }}>
                        <Typography style={{ opacity: 0.5 }}>Easy</Typography>
                        <Typography style={{ opacity: 0.5 }}>Medium</Typography>
                        <Typography style={{ opacity: 0.5 }}>Hard</Typography>
                    </Grid>
                    <Typography style={{ paddingTop: 24 }}>{readableTime}</Typography>
                </FormControl>
            </React.Fragment>
        );
    }

    private minutesToReadable = (secs: number) => {
        const unit = secs < 3600 ? 'minute' : 'hour';

        return `${secs < 3600 ? Math.floor(secs / 60) : Math.floor(secs / 3600)} ${unit}${unit === 'hour' && secs < 7200 ? '' : 's'}`;
    };

    private handleChange = (value) => {
        const duration = value < 7 ? value * 600 : (value - 5) * 3600;

        this.props.handleDurationChanged({ id: 'duration', value: duration });
    };
}

export default withStyles(appStyle)(IncentiveDuration);
