import { Grid } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import React from 'react';
import { connect } from 'react-redux';
import appStyle from '../App.style';
import EarningWindows from '../EarningWindows';
import Gift from './Gift';
import Intro from './Intro';
import Reward from './Reward';
import Setup from './Setup';

interface IProps {
    classes: any;
    user: any;
    mixpanel: {
        actions: any;
    };
    onClose: any;
}

interface IState {
    card: string;
}

class Onboarding extends React.Component<IProps, IState> {
    constructor(props) {
        super(props);
        const {
            user: {
                userInfo: { school },
            },
        } = props;

        this.state = {
            card: school ? 'intro' : 'setup',
        };
    }

    public componentDidMount = () => {
        const { mixpanel } = this.props;
        const { card } = this.state;
        const event = `Viewed Onboarding - ${card.charAt(0).toUpperCase() + card.slice(1)}`;

        mixpanel.actions.sendEvent(event);
    };

    public render() {
        const { card } = this.state;

        return (
            <React.Fragment>
                {card === 'setup' && this.renderSetup()}
                {card === 'intro' && this.renderIntro()}
                {card === 'gift' && this.renderGift()}
                {card === 'reward' && this.renderReward()}
                {card === 'Setup School' && this.renderEarningWindows()}
            </React.Fragment>
        );
    }

    private renderSetup = () => {
        const { classes } = this.props;

        return <Setup classes={classes} onClose={() => this.handleNextCard('intro')} />;
    };

    private renderIntro = () => {
        const {
            classes,
            user: { userInfo },
        } = this.props;
        const firstName = userInfo.displayName.split(' ')[0];

        return <Intro classes={classes} firstName={firstName} onClose={() => this.handleNextCard('gift')} />;
    };

    private renderGift = () => {
        const { classes } = this.props;

        return <Gift classes={classes} onClose={() => this.handleNextCard('reward')} />;
    };

    private renderReward = () => {
        const { classes } = this.props;

        return <Reward classes={classes} onClose={() => this.handleNextCard('Setup School')} onLearnMore={this.handleLearnMore} />;
    };

    private renderEarningWindows = () => {
        const { classes } = this.props;

        return (
            <Grid container={true} justifyContent="center" alignItems="center" className={classes.pageHeader}>
                <EarningWindows onClose={this.handleClose} />
            </Grid>
        );
    };

    private handleNextCard = (card) => {
        const { mixpanel } = this.props;
        const event = `Viewed Onboarding - ${card.charAt(0).toUpperCase() + card.slice(1)}`;

        mixpanel.actions.sendEvent(event);
        this.setState({ card });
    };

    private handleLearnMore = () => {
        const { mixpanel } = this.props;

        mixpanel.actions.sendEvent('Clicked Learn More - Onboarding Reward');
    };

    private handleClose = () => {
        const { onClose, mixpanel } = this.props;

        mixpanel.actions.sendEvent('Setup School - Saved');
        onClose();
    };
}

const mapStoreToProps = (state: any) => {
    return {
        user: state.user.data,
        mixpanel: state.mixpanel,
    };
};

export default connect(
    mapStoreToProps,
    null
)(withStyles(appStyle)(Onboarding));
