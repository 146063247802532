import { Grid } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { Line } from 'rc-progress';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import Text from '../../../components/Text';
import appStyle from '../../App.style';
import ClasswideModal from '../Modals/ClasswideModal';

interface IProps {
    classes: any;
    incentiveName: string;
    uniqueCompleted: number;
    requiredCompleted: number;
    groupProgresses: any;
    durationPerStudent: number;
    groupName: string;
    moreStudentsNeeded: boolean;
    start: any;
    expiration: any;
    iteration: any;
}

interface IState {
    showClasswideModal: boolean;
}

class InProgressClasswideCell extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            showClasswideModal: false,
        };
    }

    public render() {
        const {
            classes,
            incentiveName,
            uniqueCompleted,
            requiredCompleted,
            durationPerStudent,
            groupName,
            moreStudentsNeeded,
            start,
            expiration,
            iteration,
        } = this.props;
        const { showClasswideModal } = this.state;

        return (
            <Grid item={true} sm={12} xs={12} className={classes.pageRow}>
                {showClasswideModal && <ClasswideModal open={showClasswideModal} toggleClasswideModal={this.toggleClasswideModal} iteration={iteration} />}
                <div onClick={() => this.toggleClasswideModal(true)} className={classes.inProgressClasswideIncentiveCellContainer}>
                    <Grid container={true} className={classes.inProgressIncentiveCellTextContainer}>
                        <Grid item={true} xs={12} className={classes.classwideIncentiveCellHeader}>
                            <Text extraLight={true} fontSize={11}>
                                {`${start.format('ddd, M/D')} - ${expiration.format('ddd, M/D')}`}
                            </Text>
                            <Text fontSize={11} error={true} style={{ marginLeft: 'auto' }}>
                                {moreStudentsNeeded ? 'More Students Needed' : ''}
                            </Text>
                        </Grid>
                        <Grid item={true} xs={6}>
                            <Text fontSize={14} style={{ paddingBottom: 6 }}>
                                {incentiveName}
                            </Text>
                            <Text fontSize={12} light={true}>
                                {durationPerStudent ? `${Math.round((durationPerStudent / 3600) * 4) / 4} Hours Per Student` : ''}
                            </Text>
                        </Grid>
                        <Grid item={true} xs={6} className={classes.inProgressIncentiveCellTime}>
                            <Text extraLight={true} fontSize={11} style={{ paddingBottom: 6 }}>
                                {groupName}
                            </Text>
                            <Text semiBold={true} extraLight={true} fontSize={11}>
                                {`${uniqueCompleted}/${requiredCompleted} STUDENT${requiredCompleted === 1 ? '' : 'S'}`}
                            </Text>
                        </Grid>
                    </Grid>
                    <Grid container={true}>
                        <Grid item={true} xs={12} style={{ marginBottom: -4 }}>
                            <Line percent={(uniqueCompleted / requiredCompleted) * 100} strokeWidth={0.75} strokeColor="#00D793" strokeLinecap="butt" />
                        </Grid>
                        {isMobile && (
                            <Grid item={true} xs={12} className={classes.viewStudentProgressContainer}>
                                <Text  fontSize={12}>
                                    View Student Progress
                                    <img src="/images/right-arrow.png" alt="right arrow" style={{ width: 6, height: 10, marginTop: 4, marginLeft: 4 }} />
                                </Text>
                            </Grid>
                        )}
                    </Grid>
                </div>
            </Grid>
        );
    }

    private toggleClasswideModal = (shouldShow) => {
        if (shouldShow) {
            this.setState({
                showClasswideModal: shouldShow,
            });
        } else {
            this.setState({
                showClasswideModal: false,
            });
        }
    };
}

const mapStoreToProps = (state: any) => {
    return {
        groupProgresses: state.groupProgresses,
    };
};

export default connect(
    mapStoreToProps,
    null
)(withStyles(appStyle)(InProgressClasswideCell));
