import { createStyles, Theme } from '@material-ui/core';
import { isMobile } from 'react-device-detect';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            display: 'block', // Fix IE11 issue.
            [theme.breakpoints.up('sm')]: {},
            [theme.breakpoints.up(800 + theme.spacing(3) * 2)]: {
                width: 1000,
                marginLeft: 'auto',
                marginRight: 'auto',
            },
        },
        rootPaper: {
            marginTop: isMobile ? 0 : theme.spacing(8),
            borderTopLeftRadius: 11,
            borderTopRightRadius: 11,
        },
        pagePreHeader: {
            paddingTop: isMobile ? theme.spacing(3) : theme.spacing(6),
            paddingRight: isMobile ? theme.spacing(2) : theme.spacing(8),
            paddingLeft: isMobile ? theme.spacing(2) : theme.spacing(8),
        },
        inviteHeader: {
            backgroundColor: '#FCFCFC',
            borderBottomWidth: 1,
            borderBottomStyle: 'solid',
            borderBottomColor: '#EBEBEB',
            paddingTop: theme.spacing(1.5),
            paddingBottom: theme.spacing(1.5),
            paddingRight: isMobile ? theme.spacing(2) : theme.spacing(8),
            paddingLeft: isMobile ? theme.spacing(2) : theme.spacing(8),
        },
        inviteCode: {
            color: theme.palette.primary.main,
            fontWeight: 800,
            fontSize: 24,
            paddingLeft: theme.spacing(1.5),
        },

        inviteStudentsContainer: {
            width: '100%',
            paddingLeft: theme.spacing(10),
            paddingRight: theme.spacing(10),
            [theme.breakpoints.down('xs')]: {
                paddingLeft: 0,
                paddingRight: 0,
            },
        },

        inviteCodeMobile: {
            color: theme.palette.primary.main,
            fontWeight: 800,
            fontSize: 20,
            paddingLeft: theme.spacing(1.5),
        },
        inviteCodeLabel: {
            opacity: 0.5,
            fontSize: 10,
        },
        modalBody: {
            backgroundColor: '#FFFFFF',
            [theme.breakpoints.down('xs')]: {
                paddingTop: theme.spacing(2),
                paddingBottom: theme.spacing(8),
                paddingRight: theme.spacing(1),
                paddingLeft: theme.spacing(1),
            },
            [theme.breakpoints.up('sm')]: {
                paddingTop: theme.spacing(2),
                paddingBottom: theme.spacing(8),
                paddingRight: theme.spacing(2),
                paddingLeft: theme.spacing(2),
            },
            [theme.breakpoints.up('md')]: {
                paddingTop: theme.spacing(2),
                paddingBottom: theme.spacing(8),
                paddingRight: theme.spacing(8),
                paddingLeft: theme.spacing(8),
            },
        },
        pageHeader: {
            paddingTop: isMobile ? theme.spacing(4) : theme.spacing(6),
            paddingBottom: isMobile ? theme.spacing(2) : theme.spacing(6),
            paddingRight: isMobile ? theme.spacing(2) : theme.spacing(8),
            paddingLeft: isMobile ? theme.spacing(2) : theme.spacing(8),
        },
        pageFooter: {
            paddingTop: theme.spacing(6),
            paddingBottom: theme.spacing(6),
            paddingRight: isMobile ? theme.spacing(4) : theme.spacing(8),
            paddingLeft: isMobile ? theme.spacing(4) : theme.spacing(8),
            backgroundColor: '#FFFFFF',
            borderTopWidth: 1,
            borderTopStyle: 'solid',
            borderTopColor: '#EBEBEB',
            borderBottomLeftRadius: 4,
            borderBottomRightRadius: 4,
        },
        pageBody: {
            backgroundColor: '#FCFCFC',
            borderTopWidth: 1,
            borderTopStyle: 'solid',
            borderTopColor: '#EBEBEB',

            [theme.breakpoints.down('xs')]: {
                paddingTop: theme.spacing(2),
                paddingBottom: theme.spacing(8),
                paddingRight: theme.spacing(1),
                paddingLeft: theme.spacing(1),
            },
            [theme.breakpoints.up('sm')]: {
                paddingTop: theme.spacing(2),
                paddingBottom: theme.spacing(8),
                paddingRight: theme.spacing(2),
                paddingLeft: theme.spacing(2),
            },
            [theme.breakpoints.up('md')]: {
                paddingTop: theme.spacing(2),
                paddingBottom: theme.spacing(8),
                paddingRight: theme.spacing(8),
                paddingLeft: theme.spacing(8),
            },
        },
        pageTitle: {
            fontWeight: 800,
            fontSize: 28,
        },
        pageSubTitle: {
            fontWeight: 300,
            fontSize: 14,
            opacity: 0.5,
        },
        pageRow: {
            marginLeft: isMobile ? theme.spacing(1) : 0,
            marginRight: isMobile ? theme.spacing(1) : 0,
        },
        backTitle: {
            display: 'flex',
            alignItems: 'center',
            fontWeight: 300,
            marginLeft: -6,
        },
        editTitle: {
            fontWeight: 300,
            cursor: 'pointer',
        },
        deleteTitle: {
            fontWeight: 300,
            cursor: 'pointer',
            fontSize: 14,
            paddingRight: theme.spacing(2),
        },
        typeButton: {
            textTransform: 'none',
            fontSize: 14,
            fontWeight: 'normal',
            backgroundColor: '#FFFFFF',
            padding: theme.spacing(1),
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
            borderRadius: 8,
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: '#D0D0D0',
            '&:hover': {
                backgroundColor: '#FFFFFF',
            },
            marginTop: 8,
            marginBottom: 8,
            minHeight: 66,
        },
        typeButtonSelected: {
            textTransform: 'none',
            fontSize: 14,
            fontWeight: 'normal',
            backgroundColor: '#FFFFFF',
            padding: theme.spacing(1),
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
            borderRadius: 8,
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: theme.palette.primary.main,
            color: theme.palette.primary.main,
            '&:hover': {
                backgroundColor: '#FFFFFF',
            },
            marginTop: 8,
            marginBottom: 8,
            minHeight: 66,
        },
        addButton: {
            boxShadow: 'none',
            height: 52,
            textTransform: 'none',
            fontSize: 13,
            fontWeight: 800,
            backgroundColor: '#FFFFFF',
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            borderRadius: 8,
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: '#EBEBEB',
            '&:hover': {
                backgroundColor: '#FFFFFF',
            },
        },
        addIcon: {
            color: theme.palette.primary.main,
            marginRight: 10,
        },
        authContainer: {
            paddingLeft: isMobile ? theme.spacing(2) : 0,
            paddingRight: isMobile ? theme.spacing(2) : 0,
        },
        authTermsContainer: {
            fontWeight: 300,
            fontSize: 12,
            opacity: 0.5,
            paddingTop: 25,
        },
        copyright: {
            fontWeight: 300,
            fontSize: 11,
            opacity: 0.5,
            marginTop: theme.spacing(3),
        },
        authTerms: {
            color: '#000000',
            textDecoration: 'none',
        },
        authLink: {
            paddingTop: 25,
            fontWeight: 300,
        },
        primaryButton: {
            marginTop: theme.spacing(3),
            height: 50,
            width: 226,
            borderRadius: 25,
            boxShadow: 'none',
            textTransform: 'none',
            color: '#FFFFFF',
            fontSize: 17,
        },
        primaryButtonOutlined: {
            marginTop: theme.spacing(3),
            height: 50,
            width: 226,
            borderRadius: 25,
            boxShadow: 'none',
            textTransform: 'none',
            color: theme.palette.primary.main,
            fontSize: 17,
        },
        cancelButton: {
            marginTop: theme.spacing(3),
            height: 50,
            width: 139,
            borderRadius: 25,
            boxShadow: 'none',
            textTransform: 'none',
            color: theme.palette.primary.main,
            backgroundColor: '#FFFFFF',
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: theme.palette.primary.main,
            fontSize: 17,
            '&:hover': {
                backgroundColor: '#FFFFFF',
            },
        },
        saveButton: {
            marginTop: theme.spacing(3),
            height: 50,
            width: 139,
            borderRadius: 25,
            boxShadow: 'none',
            textTransform: 'none',
            color: '#FFFFFF',
            fontSize: 17,
        },
        buttonLoader: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: 0,
            marginLeft: -12,
        },
        buttonLoaderInline: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -10,
            marginLeft: -12,
        },
        buttonLoaderWrapper: {
            textAlign: 'center',
            position: 'relative',
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(1),
        },
        groupCellContainer: {
            paddingTop: theme.spacing(3),
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            borderRadius: theme.spacing(1),
            backgroundColor: '#FFFFFF',
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: '#EBEBEB',
            cursor: 'pointer',
        },
        groupCellTitle: {
            fontSize: 16,
            fontWeight: 800,
        },
        groupCellFooter: {
            fontWeight: 600,
            fontSize: 10,
            opacity: 0.36,
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(2),
            textTransform: 'uppercase',
        },
        groupCellStudent: {
            fontWeight: 600,
            fontSize: 10,
            opacity: 0.36,
            textTransform: 'uppercase',
        },
        groupAddCellTitle: {
            paddingTop: 8,
            fontSize: 13,
            fontWeight: 800,
        },
        groupAddCellContainer: {
            paddingTop: 20,
            paddingBottom: 20,
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            borderRadius: theme.spacing(1),
            backgroundColor: '#FCFCFC',
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: '#EBEBEB',
            cursor: 'pointer',
        },
        noGroupCellContainer: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(9),
            textAlign: 'center',
        },
        noGroupsMainText: {
            paddingTop: theme.spacing(1.5),
            fontSize: 18,
            fontWeight: 700,
        },
        noGroupsSubText: {
            fontSize: 18,
            fontWeight: 300,
            opacity: 0.3,
            paddingTop: theme.spacing(1),
        },
        noIncentivesIcon: {
            fontSize: 56,
            opacity: 0.05,
        },
        noResultsIcon: {
            fontSize: 56,
            opacity: 0.05,
            marginBottom: theme.spacing(3),
            marginTop: theme.spacing(4),
        },
        incentiveCellContainer: {
            borderRadius: theme.spacing(1),
            backgroundColor: '#FFFFFF',
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: '#EBEBEB',
            cursor: 'pointer',
        },
        incentiveCellContainerSelected: {
            borderColor: '#00D793',
        },
        slider: {
            padding: '22px 0px',
        },
        addressSelectContainer: {
            backgroundColor: '#FCFCFC',

            borderLeftWidth: 1,
            borderLeftStyle: 'solid',
            borderLeftColor: '#EBEBEB',

            borderRightWidth: 1,
            borderRightStyle: 'solid',
            borderRightColor: '#EBEBEB',

            borderBottomWidth: 1,
            borderBottomStyle: 'solid',
            borderBottomColor: '#EBEBEB',

            borderBottomLeftRadius: 6,
            borderBottomRightRadius: 6,
        },
        unselectedDayOfWeekButton: {
            width: 59,
            height: 59,
            borderRadius: 29.5,
            boxShadow: 'none',
            textTransform: 'none',
            fontSize: 14,
            color: '#A0A0A0',
            backgroundColor: '#fff',
            borderColor: '#979797',
            borderStyle: 'solid',
            borderWidth: 1,
            '&:hover': {
                backgroundColor: '#fff',
            },
            fontFamily: ['Montserrat', 'sans-serif'].join(','),
            margin: 4,
        },
        selectedDayOfWeekButton: {
            width: 59,
            height: 59,
            borderRadius: 29.5,
            boxShadow: 'none',
            textTransform: 'none',
            fontSize: 14,
            color: '#fff',
            backgroundColor: theme.palette.primary.main,
            borderColor: '#979797',
            borderStyle: 'solid',
            borderWidth: 1,
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
            },
            fontFamily: ['Montserrat', 'sans-serif'].join(','),
            margin: 4,
        },
        groupPageSubheader: {
            fontWeight: 300,
            fontSize: 16,
            opacity: 0.5,
        },
        selectedUserLimit: {
            cursor: 'pointer',
            paddingTop: 8,
            color: theme.palette.primary.main,
        },
        unSelectedUserLimit: {
            cursor: 'pointer',
            paddingTop: 8,
        },
        mobileInviteCodeContainer: {
            marginTop: theme.spacing(4),
            backgroundColor: '#FCFCFC',
            borderTopWidth: 1,
            borderTopStyle: 'solid',
            borderTopColor: '#EBEBEB',
            borderBottomWidth: 1,
            borderBottomStyle: 'solid',
            borderBottomColor: '#EBEBEB',
            height: 66,
            padding: theme.spacing(2),
        },
        bottomAppBar: {
            top: 'auto',
            bottom: 0,
            backgroundColor: '#FCFCFC',
        },
        bottomToolbar: {
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        dashboardTabs: {
            paddingLeft: isMobile ? 0 : theme.spacing(4),
        },
        dashboardHeader: {
            paddingTop: isMobile ? theme.spacing(4) : theme.spacing(6),
            marginBottom: -1,
            zIndex: 1299,
        },
        dashboardHeaderTitle: {
            paddingRight: isMobile ? theme.spacing(2) : theme.spacing(4),
            paddingLeft: isMobile ? theme.spacing(2) : theme.spacing(4),
        },
        dashboardHeaderTiles: {
            paddingTop: 32,
            paddingRight: isMobile ? theme.spacing(2) : theme.spacing(4),
            paddingLeft: isMobile ? theme.spacing(2) : theme.spacing(4),
            paddingBottom: 32,
        },
        dashboardTitle: {
            opacity: 0.3,
            fontSize: 13,
        },
        dashboardValue: {
            fontSize: 64,
            fontWeight: 800,
            color: theme.palette.primary.main,
        },
        dashboardTile: {
            borderLeftWidth: 1,
            borderLeftStyle: 'solid',
            borderLeftColor: '#EBEBEB',
            paddingLeft: 24,
        },
        dashboardToggleUnselected: {
            backgroundColor: '#FCFCFC',
            height: 57,
            cursor: 'pointer',

            borderTopWidth: 1,
            borderTopStyle: 'solid',
            borderTopColor: '#EBEBEB',

            borderLeftWidth: 1,
            borderLeftStyle: 'solid',
            borderLeftColor: '#EBEBEB',

            borderRightWidth: 1,
            borderRightStyle: 'solid',
            borderRightColor: '#EBEBEB',

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',

            borderBottomWidth: 1,
            borderBottomStyle: 'solid',
            borderBottomColor: '#EBEBEB',
            padding: theme.spacing(1),
            maxWidth: '210px', 
        },
        dashboardToggleSelected: {
            backgroundColor: '#FFFFFF',
            height: 57,
            cursor: 'pointer',

            borderTopWidth: 1,
            borderTopStyle: 'solid',
            borderTopColor: '#EBEBEB',

            borderLeftWidth: 1,
            borderLeftStyle: 'solid',
            borderLeftColor: '#EBEBEB',

            borderRightWidth: 1,
            borderRightStyle: 'solid',
            borderRightColor: '#EBEBEB',

            borderBottomWidth: 1,
            borderBottomStyle: 'solid',
            borderBottomColor: '#FFFFFF',

            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: theme.spacing(1),
            maxWidth: '210px',
        },
        dashboardToggleTitle: {
            fontSize: 14,
            fontWeight: 500,
            opacity: 0.5,
        },
        dashboardPageBody: {
            backgroundColor: '#FFFFFF',

            [theme.breakpoints.down('xs')]: {
                paddingTop: theme.spacing(2),
                paddingBottom: theme.spacing(4),
                paddingRight: theme.spacing(1),
                paddingLeft: theme.spacing(1),
            },
            [theme.breakpoints.up('sm')]: {
                paddingTop: theme.spacing(2),
                paddingBottom: theme.spacing(4),
                paddingRight: theme.spacing(2),
                paddingLeft: theme.spacing(2),

                borderTopWidth: 1,
                borderTopStyle: 'solid',
                borderTopColor: '#EBEBEB',
            },
            [theme.breakpoints.up('md')]: {
                paddingTop: theme.spacing(2),
                paddingBottom: theme.spacing(4),
                paddingRight: theme.spacing(4),
                paddingLeft: theme.spacing(4),
            },
        },
        dashboardPageSubBody: {
            overflowY: 'scroll',
            height: 600,
        },
        dashboardDailyDateButton: {
            textTransform: 'none',
            opacity: 0.5,
            minWidth: 'unset',
        },
        dashboardDailyDateButtonMargin: {
            marginRight: theme.spacing(1),
        },
        dashboardDailyHeader: {
            marginBottom: theme.spacing(2),
        },
        dashboardDailyClassroomModalTitle: {
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
        },
        dashboardDailyClassroomModalContent: {
            paddingTop: theme.spacing(2),
            padding: theme.spacing(3),
            backgroundColor: '#fcfcfc',
            borderTop: '1px solid #ebebeb',
        },
        dashboardDailyClassroomGroupExample: {
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            height: '100%',
            zIndex: 200,
        },
        dashboardDailyClassroomGroupClickableMobile: {
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
        },
        dashboardDailyClassroomGroupClickableMobileButton: {
            backgroundColor: '#ffffff',
            borderRadius: 0,
            boxShadow: '0px -35px 40px 0px rgba(255,255,255,1)',
            borderTop: '1px solid #ebebeb',
            textTransform: 'none',
            zIndex: 200,
        },
        dashboardDailyClassroomGroupClickable: {
            display: 'none',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            backgroundColor: '#000000',
            opacity: 0.5,
            zIndex: 100,
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
        },
        dashboardDailyClassroomGroupClickableText: {
            color: '#ffffff',
            fontSize: 18,
            fontWeight: 600,
            padding: '16px 40px',
            border: '2px solid #404040',
            backgroundColor: '#202020',
        },
        dashboardDailyClassroomGroup: {
            '&:hover > div:first-child': {
                display: 'flex',
            },
            position: 'relative',
            borderRadius: theme.spacing(1),
            backgroundColor: '#FCFCFC',
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: '#EBEBEB',
            maxHeight: 350,
            overflow: 'hidden',
            marginBottom: theme.spacing(5),
        },

        dashboardDailyClassroomGroupWrap: {
            padding: theme.spacing(2),
        },
        dashboardDailyClassroomGroupNoClass: {
            padding: theme.spacing(2),
            minHeight: 90,
        },
        dashboardDailyClassroomGroupHeader: {
            marginBottom: theme.spacing(3),
        },
        dashboardDailyClassroomGroupHeading: {
            marginBottom: theme.spacing(1.5),
            fontWeight: 600,
        },
        dashboardDailyClassroomGroupTimeLabel: {
            opacity: 0.5,
            textTransform: 'uppercase',
            fontSize: 10,
            marginBottom: theme.spacing(1) / 2,
        },
        dashboardDailyClassroomGroupTime: {
            fontWeight: 500,
            fontSize: 12,
        },
        dashboardDailyClassroomGroupMessage: {
            borderRadius: theme.spacing(1),
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
            marginBottom: theme.spacing(2),
            backgroundColor: 'rgba(0,215,147, 0.15)',
            maxHeight: 68,
            width: '100%',
        },
        dashboardDailyClassroomGroupMessageText: {
            color: '#0f4e3a',
            fontSize: 12,
            textAlign: 'center',
        },
        dashboardDailyClassroomGroupCell: {
            borderTopLeftRadius: theme.spacing(1),
            borderTopRightRadius: theme.spacing(1),
            backgroundColor: '#FFFFFF',
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: '#EBEBEB',
            height: 70,
        },
        dashboardDailyClassroomGroupCellBlank: {
            backgroundColor: '#f9f9f9',
            height: 70,
            marginBottom: theme.spacing(2),
        },
        dashboardDailyClassroomGroupProgress: {
            marginBottom: theme.spacing(2),
            backgroundColor: '#ebebeb',
        },
        dashboardDailyClassroomGroupCellInfo: {
            paddingLeft: theme.spacing(1.5),
        },
        dashboardDailyClassroomGroupCellInfoLabel: {
            fontWeight: 600,
            opacity: 0.35,
            textTransform: 'uppercase',
            marginRight: theme.spacing(2),
            fontSize: 12,
        },
        dashboardDailyClassroomGroupCellName: {
            fontWeight: 500,
            paddingLeft: theme.spacing(2),
        },
        dashboardDailyClassroomGroupCellTooltip: {
            backgroundColor: '#ffffff',
            border: '1px solid #ececec',
            opacity: 0,
        },
        dashboardDailyClassroomGroupCellProgressBar: {
            '&:hover > div:first-child': {
                display: 'block',
            },
        },
        dashboardDailyClassroomGroupCellProgressBarHover: {
            display: 'none',
            position: 'relative',
            top: -2,
            bottom: 0,
            left: 0,
            right: 0,
            width: '100%',
            height: 8,
        },
        dashboardDailyClassroomGroupCellTooltipArrow: {
            transform: 'rotate(-135deg)',
            borderRight: '1px solid #ececec',
            borderBottom: '1px solid #ececec',
            width: 20,
            height: 20,
            position: 'relative',
            top: -15,
            backgroundColor: '#ffffff',
            marginBottom: -20,
            zIndex: 100,
        },
        dashboardDailyClassroomGroupCellTooltipHeading: { color: '#000000', fontWeight: 500, textTransform: 'uppercase', fontSize: 11, opacity: 0.5 },
        dashboardDailyClassroomGroupCellTooltipText: { color: '#000000', fontWeight: 600, fontSize: 12 },
        completedIncentiveCellContainer: {
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(3),
            borderRadius: theme.spacing(1),
            backgroundColor: '#FFFFFF',
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: '#EBEBEB',
            flexWrap: 'nowrap',
        },
        dashboardCompletedGroup: {
            position: 'relative',
            borderRadius: theme.spacing(1),
            backgroundColor: '#FFFFFF',
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: '#EBEBEB',
            overflow: 'hidden',
            marginBottom: theme.spacing(3),
        },
        dashboardCompletedGroupHeader: {
            padding: `${theme.spacing(3)}px ${theme.spacing(4)}px ${theme.spacing(3)}px ${theme.spacing(2)}px`,
            [theme.breakpoints.down('xs')]: {
                padding: `${theme.spacing(2)}px ${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(1)}px`,
            },
        },
        dashboardCompletedGroupIncentive: {
            padding: theme.spacing(2),
            backgroundColor: '#fcfcfc',
            borderTop: '1px solid #ececec',
            [theme.breakpoints.down('xs')]: {
                padding: theme.spacing(1),
            },
        },
        dashboardCompletedGroupIncentiveClasswide: {
            padding: theme.spacing(2),
            backgroundColor: '#fcfcfc',
            borderTop: '1px solid #ececec',
            [theme.breakpoints.down('xs')]: {
                padding: theme.spacing(1),
            },
            '&:hover': {
                cursor: 'pointer',
                backgroundColor: 'rgba(0, 0, 0, 0.08)',
                transition: 'background 0.2s',
            },
            '&:active': {
                backgroundColor: 'rgba(0, 0, 0, 0.16)',
                backgroundSize: '100%',
                transition: 'background 0.05s',
            },
        },
        dashboardCompletedGroupReceipt: {
            padding: `6px ${theme.spacing(2)}px`,
            borderTop: '1px solid #ececec',
            [theme.breakpoints.down('xs')]: {
                padding: `0 ${theme.spacing(1)}px`,
            },
        },
        dashboardCompletedGroupReceiptClasswide: {
            borderTop: '1px solid #ececec',
            [theme.breakpoints.down('xs')]: {
                padding: `0 ${theme.spacing(1)}px`,
            },
        },
        inProgressIncentiveCellContainer: {
            paddingTop: theme.spacing(3),
            borderRadius: theme.spacing(1),
            backgroundColor: '#FFFFFF',
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: '#EBEBEB',
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
        },
        inProgressClasswideIncentiveCellContainer: {
            paddingTop: theme.spacing(0.5),
            borderRadius: theme.spacing(1),
            backgroundColor: '#FFFFFF',
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: '#EBEBEB',
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            transition: 'background 0.4s',
            '&:hover': {
                cursor: 'pointer',
                backgroundColor: 'rgba(0, 0, 0, 0.08)',
                transition: 'background 0.2s',
            },
            '&:active': {
                backgroundColor: 'rgba(0, 0, 0, 0.16)',
                backgroundSize: '100%',
                transition: 'background 0.05s',
            },
        },
        inProgressIncentiveCellTextContainer: {
            padding: theme.spacing(2),
        },
        completedIncentiveCellTitle: {
            fontSize: 14,
        },
        completedIncentiveCellIncentiveName: {
            fontSize: 12,
            opacity: 0.5,
            paddingTop: 4,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
        completedIncentiveCellDate: {
            fontSize: 12,
            opacity: 0.33,
            fontWeight: 600,
        },
        inProgressIncentiveCellClassroomName: {
            fontSize: 12,
            opacity: 0.35,
            fontWeight: 400,
            paddingBottom: theme.spacing(1),
        },
        completedIncentiveCellTime: {
            // display: 'flex',
            // alignItems: 'center',
            // justifyContent: 'flex-end',
            paddingRight: theme.spacing(2),
            minWidth: 170,
            // flexGrow: 1,
        },
        inProgressIncentiveCellTime: {
            textAlign: 'right',
        },
        profilePicContainer: {
            paddingLeft: theme.spacing(2),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        profilePic: {
            width: 32,
            height: 32,
            borderRadius: 16,
        },
        dashboardFilterContainer: {
            paddingRight: isMobile ? theme.spacing(2) : theme.spacing(8),
            paddingLeft: isMobile ? theme.spacing(2) : theme.spacing(8),
            paddingBottom: 32,
        },
        dashboardSelect: {
            fontFamily: ['Montserrat', 'sans-serif'].join(','),
            fontWeight: 400,
            fontSize: 13,
            width: '100%',
            marginBottom: theme.spacing(1),
        },
        dashboardDateSelect: {
            maxWidth: 145,
            fontFamily: ['Montserrat', 'sans-serif'].join(','),
            fontWeight: 400,
            fontSize: 13,
            width: '100%',
        },
        dashboardLoading: {
            height: '400px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingBottom: '100px',
        },
        appBarCenter: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            // zIndex: 1,
            left: 0,
            right: 0,
            margin: '0 auto',
        },
        tabsIndicator: {
            backgroundColor: theme.palette.primary.main,
            margin: '0 17px 0 17px',
        },
        tabRoot: {
            textTransform: 'initial',
            minWidth: 72,
            height: 64,
            fontweight: theme.typography.fontWeightRegular,
            margin: '0 17px 0 17px',
            fontFamily: ['Montserrat', 'sans-serif'].join(','),
            '&:hover': {
                color: theme.palette.primary.main,
                opacity: 1,
            },
            '&$tabSelected': {
                color: theme.palette.primary.main,
                fontWeight: theme.typography.fontWeightMedium,
            },
            '&:focus': {
                color: theme.palette.primary.main,
            },
        },
        tabSelected: {},
        h5: {
            fontSize: '24px',
        },
        h6: {
            fontSize: '20px',
            fontWeight: 400,
        },
        subtitle1: {
            fontSize: '16px',
            opacity: 0.5,
        },
        subtitle2: {
            fontSize: '12px',
            opacity: 0.5,
        },
        mt20: {
            marginTop: 20,
        },
        mt12: {
            marginTop: 12,
        },
        mb: {
            marginBottom: 20,
        },
        pr5: {
            paddingRight: 5,
        },
        arrow: {
            backgroundColor: '#fff',
            width: 16,
            height: 16,
            margin: -8,
            boxShadow: '-3px 2px 6px -2px rgba(0, 0, 0, 0.2)',
        },
        tooltipButton: {
            '&:hover': {
                backgroundColor: 'transparent',
            },
        },
        mobileStepper: {
            backgroundColor: 'transparent',
            marginTop: 20,
            marginBottom: 20,
        },
        textTitle: {
            fontSize: 24,
            fontWeight: 500,
            textAlign: 'center',
        },
        textBody: {
            fontSize: 16,
            opacity: 0.5,
            textAlign: 'center',
        },
        blankAvatarWrap: {
            width: 40,
            height: 40,
            backgroundColor: theme.palette.primary.main,
            borderRadius: 20,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        blankAvatarSpan: {
            color: '#fff',
            fontFamily: ['Montserrat', 'sans-serif'].join(','),
            fontSize: 20,
            fontWeight: 'bold',
            textTransform: 'uppercase',
        },
        rewardSuggestionCell: {
            textDecoration: 'none',
            height: 40,
            opacity: 0.5,
            display: 'flex',
            alignItems: 'center',
            paddingLeft: 16,
            paddingTop: 12,
        },
        rewardSuggestionMessageContainer: {
            marginTop: theme.spacing(4),
            borderRadius: 4,
            fontSize: 12,
            backgroundColor: 'rgba(0, 215, 147, 0.15)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 12,
            textAlign: 'center',
        },
        newSchoolRequestButton: {
            textDecoration: 'none',
            color: '#00D793',
            height: 46,
            textTransform: 'none',
            borderRadius: 0,
            borderTop: '1px solid #ECECEC',
        },
        onboardingBarAppBar: {
            top: 'auto',
            bottom: 0,
            backgroundColor: '#ffffff',
            height: 96,
            justifyContent: 'center',
        },
        onboardingBarToolBar: {
            // alignItems: 'center',
            // justifyContent: 'space-between',
            alignSelf: 'center',
            [theme.breakpoints.up(800 + theme.spacing(3) * 2)]: {
                width: 1000,
                marginLeft: 'auto',
                marginRight: 'auto',
            },
            // backgroundColor: 'red',
        },
        onboardingBarMobileContainer: {
            marginTop: theme.spacing(3),
            paddingTop: theme.spacing(1.5),
            paddingBottom: theme.spacing(1.5),
            // borderTop: '1px solid #ECECEC',
            // borderBottom: '1px solid #ECECEC',
            boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.15)',
        },
        onboardingBarTitleText: {
            textAlign: 'center',
            fontSize: 14,
            [theme.breakpoints.down('xs')]: {
                fontSize: 12,
                paddingLeft: theme.spacing(4),
                paddingRight: theme.spacing(4),
            },
        },
        onboardingBarItemTitle: {
            fontSize: 12,
            [theme.breakpoints.down('xs')]: {
                fontSize: 10,
            },
        },
        copyIncentiveOptionContainer: {
            border: '1px solid #979797',
            borderRadius: 8,
            paddingLeft: 24,
            paddingRight: 24,
            height: 82,
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            marginTop: 16,
            maxWidth: 380,
        },
        learnMorePrint: {
            height: 46,
            width: 46,
            color: '#248969',
            '&:hover': {
                color: '#3bad89',
            },
        },
        progressDiv: {
            width: '100%',
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
        },
        sessionDivider: {
            textTransform: 'uppercase',
            fontWeight: 500,
            opacity: 0.5,
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            fontSize: 11,
        },
        institutionMapTextWrap: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
            '&> p': {
                fontWeight: 500,
            },
            '&> .lightText': {
                opacity: 0.35,
            },
        },
        geofenceTooltip: {
            display: 'flex',
            justifyContent: 'center',
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(2),
        },
        sharingBanner: {
            height: 40,
            minHeight: 'unset',
            backgroundColor: '#00D793',
            justifyContent: 'center',
            transition: 'background 0.4s',
            '&:hover': {
                cursor: 'pointer',
                backgroundColor: '#00865C',
                transition: 'background 0.2s',
            },
            '&:active': {
                backgroundColor: '#006C4A',
                backgroundSize: '100%',
                transition: 'background 0.05s',
            },
        },
        toggleButtonGroup: {
            border: '1px solid #ECECEC',
            boxShadow: 'none',
            borderRadius: 5,
        },
        toggleButton: {
            textTransform: 'capitalize',
            color: '#000000 !important',
            fontWeight: 400,
            '&:not(:first-child)': {
                borderLeft: '1px solid #ECECEC',
            },
            height: 36,
        },
        toggleButtonSelected: {
            backgroundColor: `${theme.palette.primary.main} !important`,
            color: '#FFFFFF !important',
        },
        badgeRoot: {
            paddingRight: 4,
        },
        badgeBadge: {
            color: '#ffffff',
        },
        badgeBadgeTab: {
            color: '#ffffff',
            backgroundColor: '#484848',
            top: -18,
        },
        receivedChecked: {
            textTransform: 'uppercase',
            color: theme.palette.primary.main,
            fontSize: 11,
            fontWeight: 600,
        },
        receivedNotChecked: {
            textTransform: 'uppercase',
            fontSize: 11,
            fontWeight: 500,
        },
        explainerFooter: {
            border: '1px solid #EBEBEB',
            backgroundColor: '#fcfcfc',
            margin: 0,
            padding: theme.spacing(3),
        },
        exampleScheduleCell: {
            borderWidth: 1,
            borderLeftWidth: 0,
            borderColor: '#D8D8D8',
            borderStyle: 'solid',
            height: 60,
        },
        exampleScheduleCellFirst: {
            borderLeftWidth: 1,
        },
        scheduleCell: {
            borderWidth: 1,
            borderColor: '#D8D8D8',
            borderStyle: 'solid',
            height: 60,
        },
        scheduleCellCurrent: {
            borderWidth: 1,
            borderColor: theme.palette.primary.main,
            borderStyle: 'solid',
            height: 60,
        },
        classwideRewardDateText: {
            opacity: 0.5,
            fontSize: '11px',
        },
        moreStudentsNeededText: {
            color: '#D0021B',
            fontSize: '11px',
            fontWeight: 300,
            marginLeft: 'auto',
        },
        classwideIncentiveCellHeader: {
            paddingBottom: theme.spacing(1.5),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
        },
        viewStudentProgressContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1),
        },
        viewStudentProgressButton: {
            display: 'flex',
            flexGrow: 0,
            maxWidth: '100%',
            flexBasis: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '0px',
            paddingBottom: theme.spacing(1),
            paddingTop: theme.spacing(1),
        },
        inProgressRewardType: {
            margin: theme.spacing(2),
            fontSize: '11px',
            opacity: 0.5,
        },
        individualRewardUnselected: {
            borderWidth: '1px 0px 1px 1px',
            borderColor: 'rgba(0, 0, 0, 0.4)',
            borderRadius: '8px 0px 0px 8px',
        },
        individualRewardSelected: {
            borderWidth: '1px 1px 1px 1px',
            borderColor: '#00D793',
            borderRadius: '8px 0px 0px 8px',
        },
        groupRewardUnselected: {
            borderWidth: '1px 1px 1px 0px',
            borderColor: 'rgba(0, 0, 0, 0.4)',
            borderRadius: '0px 8px 8px 0px',
            textAlign: 'center',
        },
        groupRewardSelected: {
            borderWidth: '1px 1px 1px 1px',
            borderColor: '#00D793',
            borderRadius: '0px 8px 8px 0px',
            textAlign: 'center',
        },
        rewardTypeToggle: {
            borderStyle: 'solid',
            transition: 'background 0.4s',
            '&:hover': {
                cursor: 'pointer',
                backgroundColor: 'rgba(0, 0, 0, 0.08)',
                transition: 'background 0.2s',
            },
            '&:active': {
                backgroundColor: 'rgba(0, 0, 0, 0.16)',
                backgroundSize: '100%',
                transition: 'background 0.05s',
            },
        },
        isPrivateButtonSelected: {
            borderWidth: 1,
            borderColor: '#00D793',
            borderStyle: 'solid',
            textTransform: 'none',
            fontWeight: 400,
        },
        isPrivateButtonUnselected: {
            borderWidth: 1,
            borderColor: 'rgba(0, 0, 0, 0.4)',
            borderStyle: 'solid',
            textTransform: 'none',
            fontWeight: 400,
        },
        disableUnderline: {
            borderBottomStyle: 'solid',
            borderBottomWidth: 1,
        },
        closeButtonWrapper: {
            transition: 'background 0.4s',
            '&:hover': {
                cursor: 'pointer',
                backgroundColor: 'rgba(0, 0, 0, 0.08)',
                transition: 'background 0.2s',
            },
            '&:active': {
                backgroundColor: 'rgba(0, 0, 0, 0.16)',
                backgroundSize: '100%',
                transition: 'background 0.05s',
            },
        },
        googleAuthButton: {
            '&button:first-of-type': {
                maxWidth: 'unset',
            },
        },
        schoolPromoteIconCell: {
            height: isMobile ? 200 : 135,
            width: '100%',
            backgroundColor: '#F4F4F4',
            borderRadius: theme.spacing(1),
            marginBottom: theme.spacing(2),
            border: '1px solid #EBEBEB',
        },
        teacherListCell: {
            marginBottom: 12,
            padding: 12,
            paddingLeft: 24,
            paddingRight: 24,
            backgroundColor: '#ffffff',
            border: '1px solid #ebebeb',
            borderRadius: isMobile ? 0 : 8,
            borderLeftWidth: isMobile ? 0 : 1,
            borderRightWidth: isMobile ? 0 : 1,
            minWidth: 700,
            '&:hover': {
                backgroundColor: '#F6F6F6',
            }
        },
        classroomList: {
            backgroundColor: '#ffffff',
            border: '1px solid #ebebeb',
            borderRadius: theme.spacing(1),
            marginBottom: theme.spacing(2),
        },
        classroomIncentiveHeader: {
            backgroundColor: '#ffffff',
            justifyContent: 'space-between',
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            height: 40,
        },
        classroomIncentiveCell: {
            backgroundColor: '#ffffff',
            borderTop: '1px solid #ebebeb',
            justifyContent: 'space-between',
            height: 40,
        },
    });

export default styles;
