import { Button, ButtonBase, FormControl, FormHelperText, Grid, Input, InputLabel, Typography, withWidth } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { Form, Formik } from 'formik';
import React from 'react';
import { isMobile } from 'react-device-detect';
import compose from 'recompose/compose';
import EarningWindow, { IEarningWindow } from 'shared-library-js';
import Alert from '../../../components/Alert';
import Submit from '../../../components/Submit';
import Text from '../../../components/Text';
import Time from '../../../components/Time';
import Tooltip from '../../../components/Tooltip';
import { WEEKDAYS } from '../../../utils/constants';
import { WeeklyFormSchema } from '../../../utils/validations';
import appStyle from '../../App.style';

interface IProps {
    classes?: any;
    earningWindow: IEarningWindow;
    group?: any;
    initialValues?: any;
    onClose: any;
    onSubmit?: any;
    onEditSchedule: any;
}

interface IState {
    alert: any;
}

class WeeklyForm extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            alert: { showing: false },
        };
    }

    public render() {
        const { classes, group, onClose, onEditSchedule, onSubmit } = this.props;
        const { alert } = this.state;
        const title = group ? 'Edit Class' : 'Create Class';

        return (
            <React.Fragment>
                <Alert alert={alert} onClose={this.handleAlertClose} />
                <Formik enableReinitialize={true} initialValues={this.getInitialValues()} validationSchema={WeeklyFormSchema} onSubmit={onSubmit}>
                    {({ values, errors, touched, handleChange, isSubmitting, setFieldValue }) => (
                        <Form noValidate={true}>
                            <Grid container={true} justifyContent="center" alignItems="center">
                                <Grid item={true} xs={12} className={classes.authContainer}>
                                    <Text isTitle={true}>{title}</Text>
                                </Grid>
                                <Grid item={true} xs={12} className={classes.authContainer} style={{ paddingTop: 32 }}>
                                    <FormControl margin="normal" required={true} fullWidth={true} error={!!errors.name && !!touched.name}>
                                        <InputLabel shrink={true} htmlFor="name">
                                            Class Name
                                        </InputLabel>
                                        <Input
                                            inputProps={{ 'data-hj-whitelist': true }}
                                            placeholder="e.g. First Period, Spanish 2, 3rd Period English"
                                            id="name"
                                            autoFocus={true}
                                            name="name"
                                            value={values.name}
                                            onChange={handleChange}
                                            onKeyPress={this.preventEnter}
                                        />
                                        <Tooltip isInput={true} page={title} id="className">
                                            This is what your class will be labeled in the app. Name your class someting that your students will easily
                                            recognize.
                                        </Tooltip>
                                    </FormControl>
                                    <FormControl
                                        margin="normal"
                                        required={true}
                                        fullWidth={true}
                                        error={!!errors.groupSize && !!touched.groupSize}
                                        style={{ marginTop: 16 }}
                                    >
                                        <InputLabel shrink={true} htmlFor="groupSize">
                                            How many students are in the class?
                                        </InputLabel>
                                        <Input
                                            type={isMobile ? 'number' : 'text'}
                                            inputProps={{ 'data-hj-whitelist': true }}
                                            placeholder="e.g. 24"
                                            id="groupSize"
                                            name="groupSize"
                                            value={values.groupSize}
                                            onChange={handleChange}
                                            onKeyPress={this.preventEnter}
                                        />
                                        <FormHelperText>{errors && errors.groupSize ? errors.groupSize.toString() : ''}</FormHelperText>
                                        <Tooltip isInput={true} page={title} id="groupSizeTooltip">
                                            Enter the total number of students in your class, regardless if they use Pocket Points or not.
                                        </Tooltip>
                                    </FormControl>
                                    <Grid container={true} item={true} xs={12} sm={6} md={12} style={{ paddingTop: 12 }}>
                                        <Button variant="text" fullWidth={true} className={classes.typeButton} onClick={() => onEditSchedule(values)}>
                                            Edit Schedule
                                        </Button>
                                    </Grid>
                                    {this.renderDays(values.earningWindow, errors, setFieldValue)}
                                    {!group && (
                                        <Submit loading={isSubmitting} style={{ marginTop: 24 }} id="submit-button">
                                            Create Class
                                        </Submit>
                                    )}
                                </Grid>
                                {group && (
                                    <Grid container={true} style={{ textAlign: 'center', paddingTop: 24 }}>
                                        <Grid item={true} xs={6}>
                                            <Button variant="contained" color="primary" className={classes.cancelButton} onClick={onClose}>
                                                Cancel
                                            </Button>
                                        </Grid>
                                        <Grid item={true} xs={6}>
                                            <Submit loading={isSubmitting} id="submit-button" className={classes.saveButton}>
                                                Save
                                            </Submit>
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </React.Fragment>
        );
    }

    private renderDays = (window: IEarningWindow, errors, setFieldValue) => {
        const { classes, group } = this.props;
        const earningWindow = new EarningWindow(window);
        const { slots } = earningWindow.window;
        const title = group ? 'Edit Class' : 'Create Class';

        return (
            <FormControl margin="normal" fullWidth={true} style={{ paddingTop: 24 }}>
                <Grid container={true} justifyContent="space-between" wrap="nowrap">
                    <Typography style={{ opacity: 0.5 }}>Day of Week</Typography>
                    <Tooltip page={title} id="daysTip">
                        Set your class times for each day of the week. Students will only be able to progress towards your rewards during these times.
                    </Tooltip>
                </Grid>
                <Grid container={true} justifyContent="center" alignItems="center" style={{ paddingTop: 16 }}>
                    <Grid item={true} xs={12}>
                        {slots.map((slot, index) => {
                            const abbr = WEEKDAYS[index].substring(0, 3);
                            const error =
                                errors && errors.earningWindow && errors.earningWindow.slots && errors.earningWindow.slots[index]
                                    ? errors.earningWindow.slots[index].start
                                    : undefined;

                            return (
                                <Grid key={index} container={true} justifyContent="center" wrap="nowrap" style={{ height: 100 }}>
                                    <Grid item={true} style={{ paddingTop: 6 }}>
                                        <ButtonBase
                                            key={index}
                                            onClick={() => this.handleToggleWeekdays(index, errors, earningWindow, setFieldValue)}
                                            className={slot.enabled ? classes.selectedDayOfWeekButton : classes.unselectedDayOfWeekButton}
                                            disableRipple={true}
                                        >
                                            {abbr.toUpperCase()}
                                        </ButtonBase>
                                    </Grid>
                                    <Grid item={true} style={{ marginLeft: 10, width: '100%' }}>
                                        {slot.enabled && (
                                            <Time
                                                startKey="start"
                                                startLabel="Class start"
                                                startTime={earningWindow.timeToString(slot.start)}
                                                endKey="end"
                                                endLabel="Class end"
                                                endTime={earningWindow.timeToString(slot.end)}
                                                handleTimeChanged={({ id, value }) => this.handleChangeTime(id, value, index, earningWindow, setFieldValue)}
                                                page={`${index}`}
                                                noPadding={true}
                                                hideTooltip={true}
                                                error={!!error}
                                                errorMessage={error}
                                                startId={`time-${index * 2}`}
                                                endId={`time-${index * 2 + 1}`}
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Grid>
            </FormControl>
        );
    };

    private getInitialValues = () => {
        const { group, initialValues } = this.props;
        const earningWindow = new EarningWindow(this.props.earningWindow, group);

        if (initialValues && initialValues.earningWindow) {
            earningWindow.mergeSlots(initialValues.earningWindow.slots);
        }

        return {
            minGroupSize: group && group.appUsers ? group.appUsers.length : 1,
            name: group && group.attributes.name ? group.attributes.name : '',
            groupSize: group && group.attributes.groupSize && group.attributes.groupSize > 0 ? group.attributes.groupSize : '',
            ...initialValues,
            earningWindow: earningWindow.window,
        };
    };

    private handleToggleWeekdays = (slot: number, errors, earningWindow, setFieldValue: any) => {
        let count;

        if (!(errors && errors.earningWindow && errors.earningWindow.slots && errors.earningWindow.slots[slot])) {
            count = earningWindow.window.slots.filter((item, index) => item.enabled && index !== slot).length;

            if (count === 0 && earningWindow.window.slots[slot].enabled) {
                this.setState({
                    alert: {
                        showing: true,
                        message: 'At least one day must have a class time',
                    },
                });
            } else {
                setFieldValue('earningWindow', earningWindow.toggleSlotEnabled(slot));
            }
        }
    };

    private handleChangeTime = (id, time, index, earningWindow, setFieldValue: any) => {
        const window = earningWindow.setSlotTime(id, time, index);

        setFieldValue('earningWindow', window);
    };

    private handleAlertClose = () => {
        this.setState({ alert: { showing: false } });
    };

    private preventEnter = (event) => {
        const { key } = event;
        const submit = document.getElementById('submit-button');

        if (key === 'Enter') {
            event.preventDefault();
            if (submit) {
                submit.focus();
            }
        }
    };
}

export default compose<IProps, IProps>(
    withStyles(appStyle),
    withWidth()
)(WeeklyForm);
