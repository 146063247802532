import moment from 'moment';
import { Store } from 'redux';
import { createAction, handleActions } from 'redux-actions';
import firebase from 'firebase/compat/app'

export interface IGroup {
    inviteCode?: number;
    name: string;
    schoolId: number;
    teacherName: string;
    dow: number;
    timeZone: string;
}

class GroupProgresses {
    public actions = {
        getGroupProgresses: (groupIds) => {
            const requests: any[] = [];
            const groupProgresses: any = {};

            if (groupIds && groupIds.length > 0) {
                groupIds.forEach((id) => {
                    requests.push(
                        this.db
                            .collection('classrooms')
                            .doc(id)
                            .collection('groupProgresses')
                            .get()
                            .then((snapshot) => {
                                snapshot.docs.forEach((doc) => {
                                    groupProgresses[doc.id] = doc.data();
                                });
                            })
                            .catch((error) => {
                                console.info(`Error reactions:GroupProgresses:getGroupProgresses: ${error}`);
                            })
                    );
                });
            }
            return Promise.all(requests)
                .then(() => {
                    this.store.dispatch(this.setGroupProgresses(groupProgresses));
                })
                .catch((error) => {
                    console.info(`Error reactions:GroupProgresses:getGroupProgresses:Promise.all: ${error}`);
                });
        },
        markGroupIncentivesReceived: (items) => {
            const requests: any[] = [];

            items.forEach((item) => {
                const data = { received: !item.received ? moment().unix() : null };

                requests.push(
                    this.db
                        .collection('classrooms')
                        .doc(item.classroomId)
                        .collection('groupProgresses')
                        .doc(item.id)
                        .set(data, { merge: true })
                        .then(() => {
                            this.store.dispatch(this.setGroupIncentiveReceived({ groupProgressId: item.id, received: !item.received }));

                            if (items.length === 1) {
                                const classroom = this.store.getState().groups.data[item.classroomId];
                                const classroomName = classroom.attributes.name;
                                const incentiveName = classroom.groupIncentives[item.groupIncentiveId];

                                this.store
                                    .getState()
                                    .mixpanel.actions.markedAsReceived(
                                        !item.received,
                                        data.received,
                                        item.id,
                                        incentiveName,
                                        item.classroomId,
                                        classroomName,
                                        'Group'
                                    );
                            }
                        })
                        .catch((error) => {
                            console.info(`reactions:incentiveProgress:markIncentiveReceived:error: ${error}`);
                        })
                );
            });

            if (items.length > 1) {
                this.store.getState().mixpanel.actions.markedAllAsReceived(items.length, 'Group');
            }

            return Promise.all(requests);
        },
    };

    public initialState = {
        actions: this.actions,
        data: null,
    };

    public reducer = handleActions<any>(
        {
            CLEAR_GROUP_PROGRESSES: () => {
                return this.initialState;
            },
            SET_GROUP_PROGRESSES: (state, action) => {
                return {
                    ...state,
                    data: action.payload,
                };
            },
            SET_GROUP_INCENTIVE_RECEIVED: (state, action) => {
                const { groupProgressId, received } = action.payload;

                return {
                    ...state,
                    data: {
                        ...state.data,
                        [groupProgressId]: {
                            ...state.data[groupProgressId],
                            received,
                        },
                    },
                };
            },
        },
        this.initialState
    );

    private setGroupProgresses = createAction('SET_GROUP_PROGRESSES');
    private setGroupIncentiveReceived = createAction('SET_GROUP_INCENTIVE_RECEIVED');

    private db: any;
    private store: Store;

    constructor(/**firebase: any*/) {
        this.db = firebase.firestore();
    }

    public setStore = (store) => {
        this.store = store;
    };
}

export default GroupProgresses;
