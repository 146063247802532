import { Button, CircularProgress, Grid, withStyles } from '@material-ui/core';
import { Add, OpenInNew } from '@material-ui/icons';
import numeral from 'numeral';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import InstitutionMap from '../../components/InstitutionMap';
import Text from '../../components/Text';
import { AdminIcon, DownloadIcon, HandoutIcon, PresentationIcon, SchoolIcon } from '../../icons';
import appStyle from '../App.style';
import InviteTeacher from './InviteTeacher';
import NoTeachers from './NoTeachers';
import Teacher from './Teacher';

interface IProps {
    classes: any;
    actions: any;
    initted: any;
    teachers: any;
    user: any;
}

interface IState {
    showRequestDialog: boolean;
    showInviteTeacher: boolean;
    showTeacherDetail: boolean;
    teacher: any;
}

class School extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            showRequestDialog: false,
            showInviteTeacher: false,
            showTeacherDetail: false,
            teacher: undefined,
        };
    }

    public componentDidMount() {
        const {
            actions,
            user: {
                userInfo: { school },
            },
        } = this.props;

        actions.mixpanel.sendEvent('Viewed School View');
        actions.teachers.getTeacherList(school.id);
    }

    public componentDidUpdate(prevProps) {
        const {
            actions,
            user: {
                userInfo: { school },
            },
        } = this.props;

        if (prevProps.user.userInfo.school.id !== school.id) {
            actions.teachers.getTeacherList(school.id, true);
        }
    }

    public render() {
        const { classes, initted } = this.props;
        const { showInviteTeacher, showTeacherDetail, teacher } = this.state;

        return (
            <React.Fragment>
                <Helmet>
                    <title>Pocket Points | School</title>
                </Helmet>
                <Grid container={true}>
                    <Grid
                        container={true}
                        className={classes.pageHeader}
                        style={{
                            paddingLeft: 32,
                            paddingRight: 32,
                        }}
                        alignItems="center"
                        wrap="nowrap"
                    >
                        {this.renderSchoolDetail()}
                    </Grid>
                    <Grid
                        container={true}
                        className={classes.pageBody}
                        justifyContent="center"
                        alignItems={initted.teachers ? 'flex-start' : 'center'}
                        style={{ paddingLeft: isMobile ? 0 : 32, paddingRight: isMobile ? 0 : 32, minHeight: 360 }}
                    >
                        {this.renderTeachers()}
                    </Grid>
                    <Grid container={true} className={classes.pageFooter} alignItems="center" wrap="nowrap">
                        {this.renderFooter()}
                    </Grid>
                </Grid>
                {showInviteTeacher && <InviteTeacher onClose={() => this.toggleInviteTeacher(false)} onEvent={this.handleEvent} />}
                {showTeacherDetail && <Teacher teacher={teacher} onClose={this.handleHideTeacherDetail} />}
            </React.Fragment>
        );
    }

    private renderSchoolDetail = () => {
        const {
            classes,
            user: {
                userInfo: { school },
            },
        } = this.props;

        return (
            <Grid
                container={true}
                item={true}
                xs={12}
                wrap="nowrap"
                justifyContent="space-between"
                alignItems={isMobile ? 'flex-start' : 'center'}
                direction={isMobile ? 'column' : 'row'}
            >
                <Text isTitle={true}>{school.name}</Text>
                <Button
                    variant="text"
                    className={classes.addButton}
                    onClick={() => this.toggleInviteTeacher(true)}
                    disableRipple={true}
                    style={{ minWidth: 180, marginTop: isMobile ? 16 : 0 }}
                >
                    <Add className={classes.addIcon} />
                    Invite Teachers
                </Button>
            </Grid>
        );
    };

    private renderTeachers = () => {
        const {
            initted,
            teachers,
            user: {
                authUser: { uid },
                userInfo: { displayName },
            },
        } = this.props;
        const teacherKeys = !teachers ? [] : Object.keys(teachers);
        const data =
            teacherKeys.length < 1
                ? []
                : teacherKeys
                      .map((teacherId) => {
                          const teacher: any = teachers[teacherId];
                          const hours = Math.floor(teacher.secondsOff / 3600);
                          const minutes = Math.floor((teacher.secondsOff % 3600) / 60);
                          const seconds = teacher.secondsOff - (hours * 3600 + minutes * 60);
                          const time = `${numeral(hours).format('00')}:${numeral(minutes).format('00')}:${numeral(seconds).format('00')}`;

                          return { ...teacher, teacherId, time };
                      })
                      .sort((teachersA, teachersB) => (teachersA.secondsOff < teachersB.secondsOff ? 1 : -1));

        if (!teacherKeys.includes(uid)) {
            data.push({ displayName, classrooms: 0, classroomsDetail: undefined, rewards: 0, secondsOff: 0, students: 0, time: '00:00:00' });
        }

        if (!initted.teachers) {
            return <CircularProgress size={24} />;
        }

        if (isMobile) {
            return (
                <React.Fragment>
                    <div style={{ overflowX: 'scroll' }}>{this.renderTeacherHeader(data)}</div>
                    {data.length === 1 && <NoTeachers onClick={() => this.toggleInviteTeacher(true)} />}
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                {this.renderTeacherHeader(data)}
                {data.length === 1 && <NoTeachers onClick={() => this.toggleInviteTeacher(true)} />}
            </React.Fragment>
        );
    };

    private renderTeacherHeader = (data) => {
        const { initted } = this.props;

        return (
            <React.Fragment>
                <Grid container={true} item={true} wrap="nowrap" style={{ padding: 24, minWidth: 700, }}>
                    <Text fontSize={11} light={true} style={{ marginLeft: 40, width: isMobile ? 300 : '100%' }}>
                        Teacher
                    </Text>
                    <Text fontSize={11} light={true} noWrap={true} style={{ width: 240 }}>
                        Students
                    </Text>
                    <Text fontSize={11} light={true} noWrap={true} style={{ width: 240 }}>
                        Classes
                    </Text>
                    <Text fontSize={11} light={true} noWrap={true} style={{ width: 240 }}>
                        Rewards
                    </Text>
                    <Text fontSize={11} light={true} noWrap={true} style={{ width: 260 }}>
                        Phone-free Time
                    </Text>
                </Grid>
                {initted.teachers && this.renderTeachersList(data)}
            </React.Fragment>
        );
    };

    private renderTeachersList = (teachers) => {
        const { classes } = this.props;
        const length = teachers.length;

        return teachers.map((teacher, index) => {
            return (
                <Grid
                    key={index}
                    container={true}
                    item={true}
                    wrap="nowrap"
                    style={{ cursor: 'pointer' }}
                    className={classes.teacherListCell}
                    onClick={() => this.handleShowTeacherDetail({ ...teacher, index, length })}
                >
                    <OpenInNew color="primary" style={{ width: 40, fontSize: 18, }} />
                    <Text fontSize={12} style={{ width: isMobile ? 300 : '100%' }}>
                        {teacher.displayName}
                    </Text>
                    <Text fontSize={12} noWrap={true} style={{ width: 240 }}>
                        {teacher.students}
                    </Text>
                    <Text fontSize={12} noWrap={true} style={{ width: 240 }}>
                        {teacher.classrooms}
                    </Text>
                    <Text fontSize={12} noWrap={true} style={{ width: 240 }}>
                        {teacher.rewards}
                    </Text>
                    <Text fontSize={12} noWrap={true} style={{ width: 260 }}>
                        {teacher.time}
                    </Text>
                </Grid>
            );
        });
    };

    private renderFooter = () => {
        const {
            classes,
            user: {
                userInfo: { school },
            },
        } = this.props;
        const { showRequestDialog } = this.state;

        return (
            <Grid container={true}>
                <Grid
                    container={true}
                    justifyContent="center"
                    alignItems="center"
                    wrap="nowrap"
                    spacing={5}
                    style={{ marginBottom: 32 }}
                    direction={isMobile ? 'column' : 'row'}
                >
                    <Grid item={true} xs={12} sm={6}>
                        <InstitutionMap
                            schoolId={school.id}
                            hideHeading={true}
                            hideAddress={true}
                            hideRequest={true}
                            creatorID="school-index"
                            showRequestDialog={showRequestDialog}
                            onHideRequest={() => this.toggleRequestDialog(false)}
                        />
                    </Grid>
                    <Grid item={true} xs={12} sm={6}>
                        <Text fontSize={16} gutterBottom={true}>
                            School Geofence Map
                        </Text>
                        <Text fontSize={15} extraLight={true} gutterBottom={true}>
                            Double check your academic buildings are amply covered by the yellow boundary
                        </Text>
                        <Button
                            color="primary"
                            variant="contained"
                            className={classes.primaryButton}
                            style={{ width: '265px' }}
                            onClick={() => this.toggleRequestDialog(true)}
                        >
                            Report Geofence Issue
                        </Button>
                        <Button
                            color="primary"
                            size="small"
                            style={{ marginTop: 24, textTransform: 'none', fontWeight: 500 }}
                            disableRipple={true}
                            target="_blank"
                            href="https://intercom.help/portal-c4c52318881b/en/articles/3348076-geofences"
                        >
                            Learn more about how geofences work
                        </Button>
                    </Grid>
                </Grid>
                <Grid container={true} item={true} xs={12} justifyContent="center" alignItems="center" direction="column">
                    <SchoolIcon />
                    <Text fontSize={16} gutterBottom={true} style={{ marginTop: 24 }}>
                        Tips on getting your school involved
                    </Text>
                    <Text fontSize={15} extraLight={true} gutterBottom={true} style={{ maxWidth: 540, textAlign: 'center' }}>
                        The more teachers partcipating, the more students are successful
                    </Text>
                </Grid>
                <Grid container={true} item={true} spacing={5} style={{ marginTop: 24 }}>
                    <Grid container={true} item={true} xs={12} sm={4}>
                        <Grid container={true} justifyContent="center" alignItems="center" className={classes.schoolPromoteIconCell}>
                            <PresentationIcon style={{ fontSize: 64 }} />
                        </Grid>
                        <Text fontSize={13} gutterBottom={true}>
                            Present during Personal Development
                        </Text>
                        <Text fontSize={11} light={true} gutterBottom={true}>
                            We put together a presentation you can easily share during your next personal development session.
                        </Text>
                        <Button
                            color="primary"
                            size="small"
                            style={{ marginTop: 8, textTransform: 'none', fontSize: 11, fontWeight: 500 }}
                            disableRipple={true}
                            download={true}
                            href="/docs/PocketPointsPresentation.pptx"
                            onClick={this.handleDownloadPresentation}
                        >
                            <DownloadIcon style={{ fontSize: 11, marginRight: 8 }} />
                            Download PowerPoint
                        </Button>
                    </Grid>
                    <Grid container={true} item={true} xs={12} sm={4}>
                        <Grid container={true} justifyContent="center" alignItems="center" className={classes.schoolPromoteIconCell}>
                            <AdminIcon style={{ fontSize: 64 }} />
                        </Grid>
                        <Text fontSize={13} gutterBottom={true}>
                            Get Administrator Support
                        </Text>
                        <Text fontSize={11} light={true} gutterBottom={true}>
                            Getting your school admin to share school wide is a great way to get more teachers involved.
                        </Text>
                        <Button
                            color="primary"
                            size="small"
                            style={{ marginTop: 8, textTransform: 'none', fontSize: 11, fontWeight: 500 }}
                            disableRipple={true}
                            target="_blank"
                            href="https://intercom.help/portal-c4c52318881b/en/articles/3348142-administrative-involvement-in-pocket-points"
                            onClick={this.handleViewedAdminArticle}
                        >
                            Read More
                        </Button>
                    </Grid>
                    <Grid container={true} item={true} xs={12} sm={4}>
                        <Grid container={true} justifyContent="center" alignItems="center" className={classes.schoolPromoteIconCell}>
                            <HandoutIcon style={{ fontSize: 64 }} />
                        </Grid>
                        <Text fontSize={13} gutterBottom={true}>
                            Share handouts with other teachers
                        </Text>
                        <Text fontSize={11} light={true} gutterBottom={true}>
                            Pass out handouts that give a simple overview of Pocket Points and how teachers can get involved.
                        </Text>
                        <Button
                            color="primary"
                            size="small"
                            style={{ marginTop: 8, textTransform: 'none', fontSize: 11, fontWeight: 500 }}
                            disableRipple={true}
                            download={true}
                            href="/docs/TeacherPoster.pdf"
                            onClick={this.handleDownloadHandout}
                        >
                            <DownloadIcon style={{ fontSize: 11, marginRight: 8 }} />
                            Download Handout (PDF)
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    private toggleRequestDialog = (showRequestDialog) => {
        this.setState({ showRequestDialog });
    };

    private toggleInviteTeacher = (showInviteTeacher) => {
        const { actions } = this.props;

        if (showInviteTeacher) {
            actions.mixpanel.sendEvent('Clicked Invite Teacher');
        }

        this.setState({ showInviteTeacher });
    };

    private handleEvent = (event) => {
        const { actions } = this.props;

        actions.mixpanel.sendEvent(event);
    };

    private handleDownloadHandout = () => {
        const { actions } = this.props;

        actions.mixpanel.sendEvent('Downloaded Teacher Handout');
    };

    private handleDownloadPresentation = () => {
        const { actions } = this.props;

        actions.mixpanel.sendEvent('Downloaded Teacher Presentation');
    };

    private handleViewedAdminArticle = () => {
        const { actions } = this.props;

        actions.mixpanel.sendEvent('Viewed Admin Article');
    };

    private handleHideTeacherDetail = () => {
        this.setState({ showTeacherDetail: false });
    };

    private handleShowTeacherDetail = (teacher) => {
        const { actions } = this.props;

        if (teacher && teacher.classroomsDetail) {
            actions.mixpanel.sendEvent('Viewed Teacher Summary');

            this.setState({ showTeacherDetail: true, teacher });
        }
    };
}

const mapStoreToProps = (state: any): any => {
    return {
        actions: {
            mixpanel: state.mixpanel.actions,
            teachers: state.teachers.actions,
        },
        initted: {
            teachers: state.teachers.initted,
        },
        user: state.user.data,
        teachers: state.teachers.data,
    };
};

export default connect(
    mapStoreToProps,
    null
)(withStyles(appStyle)(School));
