import { ButtonBase, FormControl, Grid, Input, InputLabel, Typography, withWidth } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { Form, Formik } from 'formik';
import React from 'react';
import compose from 'recompose/compose';
import EarningWindow, { IEarningWindow } from 'shared-library-js';
import Submit from '../../components/Submit';
import Text from '../../components/Text';
import Time from '../../components/Time';
import Tooltip from '../../components/Tooltip';
import { WEEKDAYS } from '../../utils/constants';
import { ConfirmWeeklyFormSchema } from '../../utils/validations';
import appStyle from '../App.style';

interface IProps {
    classes?: any;
    earningWindow: IEarningWindow;
    group: any;
    groupId: string;
    onClose: any;
    onSubmit: any;
}

class WeeklyForm extends React.Component<IProps> {
    public render() {
        const { classes, onClose } = this.props;
        const initialValues = this.getInitialValues();

        return (
            <Formik enableReinitialize={true} initialValues={initialValues} validationSchema={ConfirmWeeklyFormSchema} onSubmit={this.handleSubmit}>
                {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                    <Form noValidate={true}>
                        <Grid container={true} justifyContent="center" alignItems="center">
                            <Grid item={true} xs={12} className={classes.authContainer} style={{ paddingTop: 32 }}>
                                <FormControl margin="normal" required={true} fullWidth={true} error={!!errors.name && !!touched.name}>
                                    <InputLabel shrink={true} htmlFor="name">
                                        Class Name
                                    </InputLabel>
                                    <Input
                                        inputProps={{ 'data-hj-whitelist': true }}
                                        placeholder="e.g. First Period, Spanish 2, 3rd Period English"
                                        id="name"
                                        autoFocus={true}
                                        name="name"
                                        value={values.name}
                                        readOnly={true}
                                    />
                                    <Tooltip isInput={true} page="Confirm Weekly Form" id="className">
                                        This is what your class will be labeled in the app. Name your class someting that your students will easily recognize.
                                    </Tooltip>
                                </FormControl>
                                {this.renderDays(values.earningWindow, errors, setFieldValue)}
                            </Grid>
                            <Grid container={true} item={true} xs={12} style={{ paddingTop: 24 }} justifyContent="center">
                                <Submit loading={isSubmitting} id="submit-button" className={classes.saveButton}>
                                    Save
                                </Submit>
                            </Grid>
                            <Grid container={true} item={true} xs={12} style={{ paddingTop: 32 }} justifyContent="center">
                                <Text color="primary" fontSize={14} bold={true} onClick={onClose}>
                                    Back to schedule settings
                                </Text>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        );
    }

    private renderDays = (window: IEarningWindow, errors, setFieldValue) => {
        const { classes, group } = this.props;
        const earningWindow = new EarningWindow(window);
        const { slots } = earningWindow.window;
        const title = group ? 'Edit Class' : 'Create Class';

        return (
            <FormControl margin="normal" fullWidth={true} style={{ paddingTop: 24 }}>
                <Grid container={true} justifyContent="space-between" wrap="nowrap">
                    <Typography style={{ opacity: 0.5 }}>Day of Week</Typography>
                    <Tooltip page={title} id="daysTip">
                        Set your class times for each day of the week. Students will only be able to progress towards your rewards during these times.
                    </Tooltip>
                </Grid>
                <Grid container={true} justifyContent="center" alignItems="center" style={{ paddingTop: 16 }}>
                    <Grid item={true} xs={12}>
                        {slots.map((slot, index) => {
                            const abbr = WEEKDAYS[index].substring(0, 3);
                            const error =
                                errors && errors.earningWindow && errors.earningWindow.slots && errors.earningWindow.slots[index]
                                    ? errors.earningWindow.slots[index].start
                                    : undefined;

                            return (
                                <Grid key={index} container={true} justifyContent="center" wrap="nowrap" style={{ height: 100 }}>
                                    <Grid item={true} style={{ paddingTop: 6 }}>
                                        <ButtonBase
                                            key={index}
                                            onClick={() => this.handleToggleWeekdays(index, earningWindow, setFieldValue)}
                                            className={slot.enabled ? classes.selectedDayOfWeekButton : classes.unselectedDayOfWeekButton}
                                            disableRipple={true}
                                        >
                                            {abbr.toUpperCase()}
                                        </ButtonBase>
                                    </Grid>
                                    <Grid item={true} style={{ marginLeft: 10, width: '100%' }}>
                                        {slot.enabled && (
                                            <Time
                                                startKey="start"
                                                startLabel="Class start"
                                                startTime={earningWindow.timeToString(slot.start)}
                                                endKey="end"
                                                endLabel="Class end"
                                                endTime={earningWindow.timeToString(slot.end)}
                                                handleTimeChanged={({ id, value }) => this.handleChangeTime(id, value, index, earningWindow, setFieldValue)}
                                                page={`${index}`}
                                                noPadding={true}
                                                hideTooltip={true}
                                                error={!!error}
                                                errorMessage={error}
                                                startId={`time-${index * 2}`}
                                                endId={`time-${index * 2 + 1}`}
                                            />
                                        )}
                                    </Grid>
                                </Grid>
                            );
                        })}
                    </Grid>
                </Grid>
            </FormControl>
        );
    };

    private getInitialValues = () => {
        const { group } = this.props;
        const earningWindow = new EarningWindow(this.props.earningWindow, group);

        return {
            name: group ? group.attributes.name : '',
            earningWindow: earningWindow.window,
        };
    };

    private handleToggleWeekdays = (slot: number, earningWindow, setFieldValue: any) => {
        setFieldValue('earningWindow', earningWindow.toggleSlotEnabled(slot));
    };

    private handleChangeTime = (id, time, index, earningWindow, setFieldValue: any) => {
        const window = earningWindow.setSlotTime(id, time, index);

        setFieldValue('earningWindow', window);
    };

    private handleSubmit = (values, options) => {
        const { groupId, onSubmit } = this.props;

        onSubmit(groupId, values, options);
    };
}

export default compose<IProps, IProps>(
    withStyles(appStyle),
    withWidth()
)(WeeklyForm);
