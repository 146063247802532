import { Button, Dialog, DialogContent, Grid, withStyles } from '@material-ui/core';
import { Email } from '@material-ui/icons';
import React from 'react';
import { isMobile } from 'react-device-detect';
import CloseButton from '../../components/CloseButton';
import Text from '../../components/Text';
import { InvitePresentationIcon, InvitePrintIcon } from '../../icons';
import appStyle from '../App.style';

interface IProps {
    classes: any;
    onClose: any;
    onEvent: any;
}

interface IState {
    show: boolean;
}

class InviteTeacher extends React.Component<IProps, IState> {
    public render() {
        const { classes, onClose, onEvent } = this.props;
        const emailBody = `Hey! I have been using Pocket Points to keep my students off their phone in class. You have to try it! It’s easy to get started, students love it, and it is free!\n\nHere is the link: https://pocketpoints.com/teachers`;

        return (
            <Dialog fullScreen={isMobile} open={true} maxWidth="sm" fullWidth={true}>
                <DialogContent>
                    <Grid container={true} direction="column">
                        <Grid item={true} style={{ marginBottom: 20 }}>
                            <CloseButton onClick={onClose} />
                        </Grid>
                    </Grid>
                    <Grid container={true} direction="column" alignItems="center">
                        <Text gutterBottom={true} isTitle={true}>
                            Invite Teachers
                        </Text>
                        <Text light={true} fontSize={16} style={{ textAlign: 'center', maxWidth: 560 }}>
                            Students are 50% more likely to stay off their phone when at least two teachers offer rewards
                        </Text>
                    </Grid>
                    <Grid container={true} direction="column" alignItems="center" style={{ paddingTop: 16 }}>
                        <Button
                            color="primary"
                            variant="contained"
                            className={classes.primaryButton}
                            target="_blank"
                            href={`mailto:?subject=Tired of students on their phones in class?&body=${encodeURIComponent(emailBody)}`}
                            onClick={() => onEvent('Invited Teacher')}
                        >
                            <Email style={{ marginRight: 24 }} />
                            Invite by Email
                        </Button>
                    </Grid>
                    <Grid container={true} direction="column" alignItems="center" style={{ paddingTop: 32, paddingBottom: 32 }}>
                        <Grid container={true} item={true} xs={12} sm={8} style={{ padding: '8px 16px' }} justifyContent="space-between">
                            <Grid container={true} item={true} xs={4} justifyContent="center" alignItems="center">
                                <div style={{ borderTop: '1px solid #D8D8D8', width: '100%' }} />
                            </Grid>
                            <Grid container={true} item={true} xs={4} justifyContent="center" alignItems="center">
                                <Text fontSize={14} light={true}>
                                    or
                                </Text>
                            </Grid>
                            <Grid container={true} item={true} xs={4} justifyContent="center" alignItems="center">
                                <div style={{ borderTop: '1px solid #D8D8D8', width: '100%' }} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container={true} direction="column" alignItems="center" style={{ paddingTop: 16, paddingBottom: 64 }}>
                        <Grid container={true} item={true} justifyContent="center" style={{ marginBottom: 24 }}>
                            <Button
                                variant="text"
                                style={{ textTransform: 'none', width: 250, justifyContent: 'flex-start' }}
                                target="_blank"
                                href="/docs/TeacherPoster.pdf"
                                onClick={() => onEvent('Downloaded Teacher Handout')}
                            >
                                <InvitePrintIcon style={{ marginRight: 24 }} />
                                <Text>Print Teacher Flyer</Text>
                            </Button>
                        </Grid>
                        <Grid container={true} item={true} justifyContent="center">
                            <Button
                                variant="text"
                                style={{ textTransform: 'none', width: 250, justifyContent: 'flex-start' }}
                                download={true}
                                href="/docs/PocketPointsPresentation.pptx"
                                onClick={() => onEvent('Downloaded Teacher Presentation')}
                            >
                                <InvitePresentationIcon style={{ marginRight: 24 }} />
                                <Text>Download PowerPoint</Text>
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        );
    }
}

export default withStyles(appStyle)(InviteTeacher);
