import { FormControl, Grid, TextField, Typography } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { DateTimePicker } from '@material-ui/pickers';
import moment from 'moment-timezone';
import React from 'react';
import { isMobile } from 'react-device-detect';
import Tooltip from '../../../components/Tooltip';
import appStyle from '../../App.style';

interface IProps {
    classes: {
        selectedUserLimit: string;
        unSelectedUserLimit: string;
    };
    onChanged: any;
    value: string;
    page: string;
}

interface IState {
    noExpiration: boolean;
}

class IncentiveExpiration extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            noExpiration: !props.value,
        };
    }

    public render() {
        const { classes, page, value } = this.props;
        const { noExpiration } = this.state;

        return (
            <FormControl margin="normal" fullWidth={true} style={{ paddingTop: 16 }}>
                <Grid container={true} style={{ justifyContent: 'space-between' }} wrap="nowrap">
                    <Grid item={true}>
                        <Typography style={{ opacity: 0.5 }}>Do you want this reward to expire?</Typography>
                    </Grid>
                    <Grid item={true}>
                        <Tooltip page={page} id="rewardExpiration">
                            When a reward expires, it can no longer be earned.
                        </Tooltip>
                    </Grid>
                </Grid>
                <Typography
                    style={{ paddingTop: 16 }}
                    className={noExpiration ? classes.selectedUserLimit : classes.unSelectedUserLimit}
                    onClick={this.handleExpirationSelected}
                >
                    No expiration
                </Typography>
                <Typography
                    style={{ paddingBottom: 8 }}
                    className={noExpiration ? classes.unSelectedUserLimit : classes.selectedUserLimit}
                    onClick={this.handleExpirationSelected}
                >
                    Set an expiration date
                </Typography>
                {isMobile && !noExpiration && (
                    <TextField
                        id="expiration"
                        label="When is your next school day?"
                        type="datetime-local"
                        value={moment(value, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DDTHH:mm')}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        onChange={this.handleMobileDateChange}
                    />
                )}
                {!isMobile && !noExpiration && (
                    <DateTimePicker
                        autoOk={true}
                        allowKeyboardControl={true}
                        disablePast={true}
                        format="MM/DD/YYYY hh:mm a"
                        value={moment(value, 'YYYY-MM-DD HH:mm:ss')}
                        onChange={this.handleDateChange}
                        minDate={moment()}
                    />
                )}
            </FormControl>
        );
    }

    private handleExpirationSelected = () => {
        const { onChanged, value } = this.props;
        const date = moment(value, 'YYYY-MM-DD HH:mm:ss').isValid() ? moment(value, 'YYYY-MM-DD HH:mm:ss') : moment().add(1, 'days');

        this.setState(
            {
                noExpiration: !this.state.noExpiration,
            },
            () => {
                const value = this.state.noExpiration ? null : date.format('YYYY-MM-DD HH:mm:00');

                onChanged(value);
            }
        );
    };

    private handleDateChange = (date) => {
        const { onChanged } = this.props;
        const value = date.isValid() ? date.format('YYYY-MM-DD HH:mm:00') : '';

        onChanged(value);
    };

    private handleMobileDateChange = (event) => {
        const {
            target: { value },
        } = event;
        const { onChanged } = this.props;
        const date = moment(value, 'YYYY-MM-DDTHH:mm').format('YYYY-MM-DD HH:mm:00');

        if (value) {
            onChanged(date);
        }
    };
}

export default withStyles(appStyle)(IncentiveExpiration);
