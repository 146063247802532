import { Dialog, DialogContent, DialogTitle, Grid, withMobileDialog } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { Formik } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import Submit from '../../components/Submit';
import Text from '../../components/Text';
import { DAILY_EMAIL_TYPES, SUMMARY_EMAIL_TYPES } from '../../utils/constants';
import appStyle from '../App.style';
import CloseButton from '../../components/CloseButton';

interface IProps {
    classes?: any;
    onClose: any;
    showing: boolean;
    fullScreen: boolean;
    userInfo: any;
    actions: any;
}

class EditEmail extends React.Component<IProps> {
    public render() {
        const { showing, classes, fullScreen } = this.props;
        const userInfo = this.props.userInfo ? this.props.userInfo : {};
        const summaryEmail = userInfo.preferences && userInfo.preferences.summaryEmail ? userInfo.preferences.summaryEmail : SUMMARY_EMAIL_TYPES.weekly;
        const rewardsEmail = userInfo.preferences && userInfo.preferences.rewardsEmail ? userInfo.preferences.rewardsEmail : DAILY_EMAIL_TYPES.daily;
        const emailStatus = userInfo.preferences && userInfo.preferences.emailStatus ? userInfo.preferences.emailStatus : 'disabled';

        return (
            <div>
                <Dialog fullScreen={fullScreen} open={showing} onClose={this.onClose} maxWidth="sm" fullWidth={true}>
                    <Formik
                        initialValues={{
                            summaryEmail,
                            emailStatus,
                            rewardsEmail,
                        }}
                        onSubmit={this.onSubmit}
                    >
                        {({ values, handleSubmit, isSubmitting, setFieldValue }) => (
                            <form className={classes.form} onSubmit={handleSubmit} noValidate={true}>
                                <DialogContent style={{ paddingBottom: 0, position: 'relative' }}>
                                    <CloseButton onClick={this.onClose} />
                                </DialogContent>
                                <DialogTitle id="alert-dialog-title" style={{ fontWeight: 600 }}>
                                    Email Settings
                                </DialogTitle>
                                <DialogContent style={{ overflowY: 'unset', position: 'relative' }}>
                                    <Grid container={true} direction="column" alignItems="flex-start">
                                        <Text fontSize={15} gutterBottom={true}>
                                            Summary Report
                                        </Text>
                                        <Text light={true} gutterBottom={true}>
                                            Get a quick overview of your class activity with Pocket Points.
                                        </Text>
                                    </Grid>
                                    <Grid container={true} alignItems="flex-start" style={{ paddingTop: 16, paddingBottom: 24 }}>
                                        <ToggleButtonGroup
                                            value={values.summaryEmail}
                                            exclusive={true}
                                            onChange={(_, value) => this.onChange('summaryEmail', value, setFieldValue)}
                                            classes={{ root: classes.toggleButtonGroup }}
                                        >
                                            {Object.keys(SUMMARY_EMAIL_TYPES).map((key, index) => {
                                                return (
                                                    <ToggleButton
                                                        key={index}
                                                        classes={{ root: classes.toggleButton, selected: classes.toggleButtonSelected }}
                                                        value={SUMMARY_EMAIL_TYPES[key]}
                                                    >
                                                        {SUMMARY_EMAIL_TYPES[key]}
                                                    </ToggleButton>
                                                );
                                            })}
                                        </ToggleButtonGroup>
                                    </Grid>
                                    <Grid container={true} direction="column" alignItems="flex-start">
                                        <Text fontSize={15} gutterBottom={true}>
                                            Daily Completed Rewards Report
                                        </Text>
                                        <Text light={true} gutterBottom={true}>
                                            Get a report of all of the completed rewards from the last 24 hours that need to be given out.
                                        </Text>
                                    </Grid>
                                    <Grid container={true} alignItems="flex-start" style={{ paddingTop: 16, paddingBottom: 24 }}>
                                        <ToggleButtonGroup
                                            value={values.rewardsEmail}
                                            exclusive={true}
                                            onChange={(_, value) => this.onChange('rewardsEmail', value, setFieldValue)}
                                            classes={{ root: classes.toggleButtonGroup }}
                                        >
                                            {Object.keys(DAILY_EMAIL_TYPES).map((key, index) => {
                                                return (
                                                    <ToggleButton
                                                        key={index}
                                                        classes={{ root: classes.toggleButton, selected: classes.toggleButtonSelected }}
                                                        value={DAILY_EMAIL_TYPES[key]}
                                                    >
                                                        {DAILY_EMAIL_TYPES[key]}
                                                    </ToggleButton>
                                                );
                                            })}
                                        </ToggleButtonGroup>
                                    </Grid>
                                    <Grid container={true} direction="column" alignItems="flex-start">
                                        <Text fontSize={15} gutterBottom={true}>
                                            All Email
                                        </Text>
                                        <Text light={true} gutterBottom={true}>
                                            Annoucements and product updates.
                                        </Text>
                                    </Grid>
                                    <Grid container={true} alignItems="flex-start" style={{ paddingTop: 16, paddingBottom: 24 }}>
                                        <ToggleButtonGroup
                                            value={values.emailStatus}
                                            exclusive={true}
                                            onChange={(_, value) => this.onChange('emailStatus', value, setFieldValue)}
                                            classes={{ root: classes.toggleButtonGroup }}
                                        >
                                            <ToggleButton classes={{ root: classes.toggleButton, selected: classes.toggleButtonSelected }} value="enabled">
                                                on
                                            </ToggleButton>
                                            <ToggleButton classes={{ root: classes.toggleButton, selected: classes.toggleButtonSelected }} value="disabled">
                                                off
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                    </Grid>
                                    <Grid container={true} justifyContent="center">
                                        <Submit loading={isSubmitting}>Done</Submit>
                                    </Grid>
                                </DialogContent>
                            </form>
                        )}
                    </Formik>
                </Dialog>
            </div>
        );
    }

    private onChange = (field, value, setFieldValue) => {
        if (value) {
            setFieldValue(field, value);
        }
    };

    private onSubmit = (values, { setSubmitting }) => {
        const { actions } = this.props;

        actions
            .updatePreferences(values)
            .then(() => {
                setSubmitting(false);
            })
            .catch(() => {
                setSubmitting(false);
            })
            .then(() => this.onClose());
    };

    private onClose = () => {
        this.props.onClose();
    };
}

const mapStoreToProps = (state: any) => {
    return {
        actions: state.user.actions,
        userInfo: state.user.data.userInfo,
    };
};

export default connect(
    mapStoreToProps,
    null
)(withMobileDialog<IProps>()(withStyles(appStyle)(EditEmail)));
