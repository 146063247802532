import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

const InvitePresentation = (props) => {
    return (
        <SvgIcon viewBox="0 0 28 27" {...props}>
            <g id="School-View" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Invite" transform="translate(-614.000000, -666.000000)">
                    <g id="Group-6" transform="translate(410.000000, 203.000000)">
                        <g id="Group-5">
                            <g id="Group-4">
                                <g id="presentation" transform="translate(204.000000, 463.000000)">
                                    <path
                                        d="M25.8181818,2 L2.18181818,2 C1.53181818,2 1,2.51428571 1,3.14285714 L1,20.8571429 C1,21.4857143 1.53181818,22 2.18181818,22 L25.8181818,22 C26.4681818,22 27,21.4857143 27,20.8571429 L27,3.14285714 C27,2.51428571 26.4681818,2 25.8181818,2 Z"
                                        id="Shape"
                                        fill="#248969"
                                        fillRule="nonzero"
                                    />
                                    <path d="M14,22 L14,24" id="Shape" stroke="#248969" strokeWidth="2" />
                                    <circle id="Oval" stroke="#248969" strokeWidth="2" cx="14" cy="25" r="1" />
                                    <path
                                        d="M27.4166667,1.71428571 L15.1666667,1.71428571 L15.1666667,1.14285714 C15.1666667,0.514285714 14.6416667,0 14,0 C13.3583333,0 12.8333333,0.514285714 12.8333333,1.14285714 L12.8333333,1.71428571 L0.583333333,1.71428571 C0.233333333,1.71428571 0,1.94285714 0,2.28571429 L0,3.42857143 C0,3.77142857 0.233333333,4 0.583333333,4 L27.4166667,4 C27.7666667,4 28,3.77142857 28,3.42857143 L28,2.28571429 C28,1.94285714 27.7666667,1.71428571 27.4166667,1.71428571 Z"
                                        id="Shape"
                                        fill="#248969"
                                        fillRule="nonzero"
                                    />
                                    <path
                                        d="M9,16.2568807 L11,16.2568807 L11,18 L13.65,16.2568807 L19,16.2568807 C19.55,16.2568807 20,15.8440367 20,15.3394495 L20,8.91743119 C20,8.41284404 19.55,8 19,8 L9,8 C8.45,8 8,8.41284404 8,8.91743119 L8,15.3394495 C8,15.8440367 8.45,16.2568807 9,16.2568807 Z"
                                        id="Shape"
                                        fill="#FFFFFF"
                                        fillRule="nonzero"
                                    />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
};

export default InvitePresentation;
