import { Button, FormControl, FormHelperText, Grid, Input, InputLabel, Typography } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { Form, Formik } from 'formik';
import React from 'react';
import { isMobile } from 'react-device-detect';
import EarningWindow, { IEarningWindow } from 'shared-library-js';
import Alert from '../../../components/Alert';
import Submit from '../../../components/Submit';
import Text from '../../../components/Text';
import Time from '../../../components/Time';
import Tooltip from '../../../components/Tooltip';
import { ROTATION_TYPES } from '../../../utils/constants';
import { RotatingFormSchema } from '../../../utils/validations';
import appStyle from '../../App.style';

interface IProps {
    classes: any;
    earningWindow: IEarningWindow;
    group?: any;
    initialValues: any;
    onClose?: any;
    onEditSchedule?: any;
    onSubmit?: any;
}

interface IState {
    alert: any;
}

class RotatingForm extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            alert: { showing: false },
        };
    }

    public render() {
        const { classes, group, onSubmit, onEditSchedule } = this.props;
        const { alert } = this.state;
        const groupId = group ? group.groupId : null;
        const title = group ? 'Edit Class' : 'Create Class';

        return (
            <React.Fragment>
                <Alert alert={alert} onClose={this.handleAlertClose} />
                <Formik
                    enableReinitialize={true}
                    initialValues={this.getInitialValues()}
                    validationSchema={RotatingFormSchema}
                    onSubmit={onSubmit}
                    render={({ values, errors, touched, handleChange, isSubmitting, setFieldValue }) => (
                        <Form noValidate={true}>
                            <Grid container={true} justifyContent="center" alignItems="center">
                                <Grid item={true} xs={12} className={classes.authContainer}>
                                    <Text isTitle={true}>{title}</Text>
                                </Grid>
                                <Grid item={true} xs={12} className={classes.authContainer} style={{ paddingTop: 32 }}>
                                    <FormControl margin="normal" required={true} fullWidth={true} error={!!errors.name && !!touched.name}>
                                        <InputLabel shrink={true} htmlFor="name">
                                            Class Name
                                        </InputLabel>
                                        <Input
                                            inputProps={{ 'data-hj-whitelist': true }}
                                            placeholder="e.g. First Period, Spanish 2, 3rd Period English"
                                            id="name"
                                            autoFocus={!groupId && !values.name}
                                            name="name"
                                            value={values.name}
                                            onChange={handleChange}
                                            onKeyPress={this.preventEnter}
                                        />
                                        <Tooltip isInput={true} page={title} id="className">
                                            This is what your class will be labeled in the app. Name your class someting that your students will easily
                                            recognize.
                                        </Tooltip>
                                    </FormControl>
                                    <FormControl
                                        margin="normal"
                                        required={true}
                                        fullWidth={true}
                                        error={!!errors.groupSize && !!touched.groupSize}
                                        style={{ marginTop: 16 }}
                                    >
                                        <InputLabel shrink={true} htmlFor="groupSize">
                                            How many students are in the class?
                                        </InputLabel>
                                        <Input
                                            type={isMobile ? 'number' : 'text'}
                                            inputProps={{ 'data-hj-whitelist': true }}
                                            placeholder="e.g. 24"
                                            id="groupSize"
                                            name="groupSize"
                                            value={values.groupSize}
                                            onChange={handleChange}
                                            onKeyPress={this.preventEnter}
                                        />
                                        <FormHelperText>{errors && errors.groupSize ? errors.groupSize.toString() : ''}</FormHelperText>
                                        <Tooltip isInput={true} page={title} id="groupSizeTooltip">
                                            Enter the total number of students in your class, regardless if they use Pocket Points or not.
                                        </Tooltip>
                                    </FormControl>
                                    <Grid container={true} item={true} xs={12} style={{ paddingTop: 12 }}>
                                        <Button variant="text" fullWidth={true} className={classes.typeButton} onClick={() => onEditSchedule(values)}>
                                            Edit Schedule
                                        </Button>
                                    </Grid>
                                    {this.renderTimes(values, errors, setFieldValue)}
                                    {!group && (
                                        <Submit loading={isSubmitting} style={{ marginTop: 24 }} id="submit-button">
                                            Create Class
                                        </Submit>
                                    )}
                                </Grid>
                                {groupId && this.renderCancelSaveButtons()}
                            </Grid>
                        </Form>
                    )}
                />
            </React.Fragment>
        );
    }

    private renderTimes = (values, errors, setFieldValue) => {
        const { group } = this.props;
        const earningWindow = new EarningWindow(values.earningWindow);
        const { labelType, slots } = values.earningWindow;
        const title = group ? 'Edit Class' : 'Create Class';

        return slots.map((slot, index) => {
            const label = labelType === ROTATION_TYPES.numeric ? `${index + 1}` : String.fromCharCode(index + 65);
            const error =
                errors && errors.earningWindow && errors.earningWindow.slots && errors.earningWindow.slots[index]
                    ? errors.earningWindow.slots[index].start
                    : undefined;

            return (
                <FormControl key={index} margin="normal" fullWidth={true} style={{ paddingTop: 24 }} error={!!error}>
                    <Grid container={true} justifyContent="space-between" alignItems="center" wrap="nowrap">
                        <Typography style={{ fontWeight: 500 }}>Day {label}</Typography>
                        <Button
                            color="primary"
                            size="small"
                            style={{ textTransform: 'none', fontWeight: 500 }}
                            disableRipple={true}
                            onClick={() => this.handleToggleEnabled(index, errors, earningWindow, setFieldValue)}
                        >
                            {slot.enabled ? 'No class?' : 'Add class time'}
                        </Button>
                    </Grid>
                    <Grid container={true} justifyContent="center" alignItems={slot.enabled ? 'center' : 'flex-start'} wrap="nowrap" style={{ height: '100%' }}>
                        {slot.enabled && (
                            <Time
                                startKey="start"
                                startLabel="Class start"
                                startTime={earningWindow.timeToString(slot.start)}
                                endKey="end"
                                endLabel="Class end"
                                endTime={earningWindow.timeToString(slot.end)}
                                handleTimeChanged={({ id, value }) => this.handleChangeTime(id, value, index, earningWindow, setFieldValue)}
                                page={title}
                                noPadding={true}
                                hideTooltip={true}
                                error={!!error}
                                errorMessage={error}
                                startId={`time-${index * 2}`}
                                endId={`time-${index * 2 + 1}`}
                            />
                        )}
                        {!slot.enabled && <Text light={true}>No class on this day in rotation</Text>}
                    </Grid>
                </FormControl>
            );
        });
    };

    private renderCancelSaveButtons = () => {
        const { classes, onClose } = this.props;

        return (
            <Grid container={true} style={{ textAlign: 'center', paddingTop: 24 }}>
                <Grid item={true} xs={6}>
                    <Button variant="contained" color="primary" className={classes.cancelButton} onClick={onClose}>
                        Cancel
                    </Button>
                </Grid>
                <Grid item={true} xs={6}>
                    <Button id="submit-button" type="submit" variant="contained" color="primary" className={classes.saveButton}>
                        Save
                    </Button>
                </Grid>
            </Grid>
        );
    };

    private getInitialValues = () => {
        const { group, initialValues } = this.props;
        const earningWindow = new EarningWindow(this.props.earningWindow, group);

        if (initialValues && initialValues.earningWindow) {
            earningWindow.mergeSlots(initialValues.earningWindow.slots);
        }

        return {
            minGroupSize: group && group.appUsers ? group.appUsers.length : 1,
            name: group && group.attributes.name ? group.attributes.name : '',
            groupSize: group && group.attributes.groupSize && group.attributes.groupSize > 0 ? group.attributes.groupSize : '',
            ...initialValues,
            earningWindow: earningWindow.window,
        };
    };

    private handleChangeTime = (id, time, index, earningWindow, setFieldValue: any) => {
        const window = earningWindow.setSlotTime(id, time, index);

        setFieldValue('earningWindow', window);
    };

    private handleToggleEnabled = (slot: number, errors, earningWindow, setFieldValue: any) => {
        let count;

        if (!(errors && errors.earningWindow && errors.earningWindow.slots && errors.earningWindow.slots[slot])) {
            count = earningWindow.window.slots.filter((item, index) => item.enabled && index !== slot).length;

            if (count === 0 && earningWindow.window.slots[slot].enabled) {
                this.setState({
                    alert: {
                        showing: true,
                        message: 'At least one day must have a class time',
                    },
                });
            } else {
                setFieldValue('earningWindow', earningWindow.toggleSlotEnabled(slot));
            }
        }
    };

    private handleAlertClose = () => {
        this.setState({ alert: { showing: false } });
    };

    private preventEnter = (event) => {
        const { key } = event;
        const submit = document.getElementById('submit-button');

        if (key === 'Enter') {
            event.preventDefault();
            if (submit) {
                submit.focus();
            }
        }
    };
}

export default withStyles(appStyle)(RotatingForm);
