import { Grid } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { Check } from '@material-ui/icons';
import moment from 'moment';
import React from 'react';
import Text from '../../../components/Text';
import { IGroupIncentive, IIteration } from '../../../utils/types';
import appStyle from '../../App.style';

interface IProps {
    classes: any;
    id: string;
    groupIncentive: IGroupIncentive;
    onClick: any;
    fullWidth?: boolean;
    selected?: boolean;
}

class GroupIncentiveCell extends React.PureComponent<IProps> {
    public render() {
        const { classes, groupIncentive, id, fullWidth, selected } = this.props;
        const { iterations } = groupIncentive;
        let hoursPerStudent = iterations[0].duration / 3600;
        let startDate = iterations[0].start;
        let endDate = iterations[0].expiration;
        const currentOrNextIteration = this.getCurrentOrNextIteration(iterations);

        if (currentOrNextIteration) {
            startDate = currentOrNextIteration.start;
            endDate = currentOrNextIteration.expiration;
            hoursPerStudent = currentOrNextIteration.duration / 3600;
        }

        return (
            <Grid container={true} item={true} xs={fullWidth ? 12 : 6} onClick={() => this.props.onClick(id)}>
                <Grid
                    item={true}
                    container={true}
                    xs={12}
                    className={`${classes.incentiveCellContainer} ${selected ? classes.incentiveCellContainerSelected : ''}`}
                >
                    <Grid container={true} item={true} xs={12} justifyContent="space-between">
                        <div style={{ padding: 16 }}>
                            <Text fontSize={16} bold={true}>
                                {groupIncentive.name}
                            </Text>
                        </div>
                        {selected && (
                            <div style={{ width: 24, height: 24, backgroundColor: '#00D793', borderTopRightRadius: 7 }}>
                                <Check style={{ color: '#ffffff' }} />
                            </div>
                        )}
                    </Grid>
                    <Grid container={true} item={true} justifyContent="space-between" alignItems="flex-end" xs={12} style={{ padding: 16, paddingBottom: 8 }}>
                        <Grid container={true} item={true} xs={6} alignContent="flex-end">
                            <img src="/images/people-outline.png" alt="people icon" style={{ width: 18, height: 14, marginRight: 8 }} />
                            <Text fontSize={10} semiBold={true} extraLight={true}>
                                {`${Math.round(hoursPerStudent * 4) / 4} HOURS EACH`}
                            </Text>
                        </Grid>
                        <Grid container={true} item={true} xs={6} justifyContent="flex-end" alignContent="flex-end">
                            <Text fontSize={10} semiBold={true} extraLight={true}>
                                {`${moment.unix(startDate).format('M/D')} - ${moment.unix(endDate).format('M/D')}`}
                            </Text>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    private getCurrentOrNextIteration = (iterations: IIteration[]): IIteration => {
        const currentDate = moment();
        let iteration: IIteration;

        if (iterations.length < 1) {
            return undefined;
        }

        iteration = iterations.find((iter) => {
            return currentDate > moment.unix(iter.start) && currentDate < moment.unix(iter.expiration);
        });

        if (iteration === undefined) {
            iteration = iterations[0];
            iterations.forEach((iter, index) => {
                if (
                    index > 0 &&
                    (moment.unix(iter.start) < currentDate || (moment.unix(iter.start) > currentDate && moment.unix(iteration.start) <= currentDate))
                ) {
                    iteration = iterations[index];
                }
            });
        }

        return iteration;
    };
}

export default withStyles(appStyle)(GroupIncentiveCell);
