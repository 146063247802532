import { createStyles, Theme } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

export default (theme: Theme) => {
    const styles: Record<string, CSSProperties> = {};

    const containerWidth = 900;

    styles.main = {
        maxWidth: containerWidth,
        margin: '0 auto',
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
    };

    styles.title = {
        fontSize: 19,
        color: '#3f3f3f'
    };

    styles.p = {
        paddingTop: 11,
        margin: 0,
    };

    styles.strong = {
        fontSize: 17,
        fontWeight: 700,
        paddingTop: 21,
        color: '#3f3f3f'
    };

    styles.subTitle = {
        fontWeight: 700,
        paddingTop: 11,
    };

    styles.divider = {
        borderBottom: '1px dotted #dfdfdf',
        marginTop: 11,
    };

    styles.box = {
        border: '1px solid #c0c1c1',
        borderBottomColor: '#a8aaab',
        borderRadius: 3,
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 11,
        marginTop: 11,
        boxShadow: '0 1px 0 rgba(0, 0, 0, 0.1)'
    };

    return createStyles(styles);
}
