import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

const Handout = (props) => {
    return (
        <SvgIcon viewBox="0 0 40 50" {...props}>
            <g id="School-View" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="school-view-" transform="translate(-947.000000, -999.000000)">
                    <g id="Group-3-Copy-2" transform="translate(866.000000, 956.000000)">
                        <g id="handout" transform="translate(81.000000, 43.000000)">
                            <path
                                d="M29.5,0.6 L39.5,10.6 L31,12.9 C31,12.9 27.9,7.5 27.9,7.3 C27.9,7.1 29.5,0.6 29.5,0.6 Z"
                                id="Shape"
                                fill="#00895E"
                                fillRule="nonzero"
                            />
                            <path
                                d="M34,14 L28,14 C26.9,14 26,13.1 26,12 L26,6 C26,4.9 25.1,4 24,4 L2,4 C0.9,4 0,4.9 0,6 L0,48 C0,49.1 0.9,50 2,50 L34,50 C35.1,50 36,49.1 36,48 L36,16 C36,14.9 35.1,14 34,14 Z"
                                id="Shape"
                                fill="#03D794"
                                fillRule="nonzero"
                            />
                            <path
                                d="M38,10 L32,10 C30.9,10 30,9.1 30,8 L30,2 C30,0.9 29.1,0 28,0 L6,0 C4.9,0 4,0.9 4,2 L4,44 C4,45.1 4.9,46 6,46 L38,46 C39.1,46 40,45.1 40,44 L40,12 C40,10.9 39.1,10 38,10 Z"
                                id="Shape"
                                fill="#D9DCE1"
                                fillRule="nonzero"
                            />
                            <path d="M22,32 L30,32" id="Shape" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="square" />
                            <path d="M22,18 L30,18" id="Shape" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="square" />
                            <path d="M22,25 L30,25" id="Shape" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="square" />
                            <polyline id="Shape" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="square" points="13.3 17.9 14.6 19.2 16.7 17.1" />
                            <circle id="Oval" fill="#FFFFFF" fillRule="nonzero" cx="15" cy="25" r="2" />
                            <circle id="Oval" fill="#FFFFFF" fillRule="nonzero" cx="15" cy="32" r="2" />
                        </g>
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
};

export default Handout;
