import { withStyles } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import EarningWindow, { IEarningWindow } from 'shared-library-js';
import Alert from '../../../components/Alert';
import { EARNING_WINDOW_TYPES } from '../../../utils/constants';
import appStyle from '../../App.style';
import RotatingForm from './RotatingForm';
import StandardForm from './StandardForm';
import WeeklyForm from './WeeklyForm';

interface IProps {
    classes: any;
    initialValues?: any;
    userInfo: any;
    groups: any;
    groupId?: string;
    setLoading: any;
    handleClose: any;
    earningWindow: IEarningWindow;
    onEditSchedule: any;
}

interface IState {
    alert: any;
    handleSubmit?: any;
}

class GroupForm extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            alert: { showing: false },
            handleSubmit: undefined,
        };
    }

    public render() {
        const {
            earningWindow,
            groupId,
            groups: { data },
            initialValues,
            handleClose,
            onEditSchedule,
        } = this.props;
        const group = groupId ? { ...data[groupId], groupId } : undefined;
        const { alert } = this.state;

        return (
            <React.Fragment>
                <Alert alert={alert} onClose={this.handleAlertClose} />

                {earningWindow.type === EARNING_WINDOW_TYPES.standard && (
                    <StandardForm
                        earningWindow={earningWindow}
                        group={group}
                        initialValues={initialValues}
                        onEditSchedule={onEditSchedule}
                        onSubmit={this.handleSubmit}
                        onClose={handleClose}
                    />
                )}
                {earningWindow.type === EARNING_WINDOW_TYPES.weekly && (
                    <WeeklyForm
                        earningWindow={earningWindow}
                        group={group}
                        initialValues={initialValues}
                        onEditSchedule={onEditSchedule}
                        onSubmit={this.handleSubmit}
                        onClose={handleClose}
                    />
                )}
                {earningWindow.type === EARNING_WINDOW_TYPES.rotating && (
                    <RotatingForm
                        earningWindow={earningWindow}
                        group={group}
                        initialValues={initialValues}
                        onEditSchedule={onEditSchedule}
                        onSubmit={this.handleSubmit}
                        onClose={handleClose}
                    />
                )}
            </React.Fragment>
        );
    }

    private handleSubmit = (form, { setSubmitting }) => {
        const {
            groupId,
            groups: { actions },
            handleClose,
            userInfo,
        } = this.props;
        const earningWindow = new EarningWindow(form.earningWindow);
        const formAction = groupId ? actions.updateGroup : actions.createGroup;
        const values = {
            name: form.name,
            groupSize: parseInt(form.groupSize, 10),
            schoolId: userInfo.school.id,
            teacherName: userInfo.teacherName,
            window: earningWindow.getGroupWindow(),
            windowType: earningWindow.window.type,
        };

        return formAction({ values, groupId })
            .then((id) => {
                setSubmitting(false);
                handleClose(id);
            })
            .catch(() => {
                setSubmitting(false);
                this.setState({
                    alert: {
                        showing: true,
                        title: 'Sorry',
                        message: 'Something went wrong. Try again',
                    },
                });
            });
    };

    private handleAlertClose = () => {
        this.setState({ alert: { showing: false } });
    };
}

const mapStoreToProps = (state: any) => {
    return {
        userInfo: state.user.data.userInfo,
        groups: state.groups,
        earningWindow: state.earningWindow.data,
    };
};

export default connect(
    mapStoreToProps,
    null
)(withStyles(appStyle)(GroupForm));
