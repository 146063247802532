import { Button, Grid } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { Add, CalendarToday } from '@material-ui/icons';
import moment from 'moment';
import React, { Fragment } from 'react';
import { isMobile } from 'react-device-detect';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import EarningWindow from 'shared-library-js';
import OnboardingBar from '../../components/OnboardingBar';
import Text from '../../components/Text';
import Tooltip from '../../components/Tooltip';
import { EARNING_WINDOW_TYPES, ROTATION_TYPES } from '../../utils/constants';
import * as routes from '../../utils/routes';
import appStyle from '../App.style';
import EarningWindows from '../EarningWindows';
import Onboarding from '../Onboarding/index';
import GroupAddCell from './Cells/GroupAddCell';
import GroupCell from './Cells/GroupCell';
import NoGroupCell from './Cells/NoGroupsCell';
import NewGroupModal from './Modals/NewGroupModal';

interface IProps {
    classes: any;
    earningWindow: any;
    groups: any;
    userInfo: any;
    actions: any;
}

interface IState {
    showNewGroupModal: boolean;
    showNewGroupModalNext: boolean;
    newGroupId: string | null;
    showEditSchedule: boolean;
    valuesFromGroupForm: any;
}

class Groups extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            showNewGroupModal: false,
            showNewGroupModalNext: false,
            newGroupId: null,
            showEditSchedule: false,
            valuesFromGroupForm: undefined,
        };
    }

    public componentDidMount() {
        const {
            actions,
            userInfo: { modalStates },
        } = this.props;

        if (!modalStates || !modalStates.showOnboarding) {
            actions.mixpanel.viewedClasses();
        }
    }

    public render() {
        const {
            classes,
            earningWindow,
            userInfo: { modalStates },
        } = this.props;
        const { newGroupId, showNewGroupModal, showEditSchedule, valuesFromGroupForm } = this.state;
        const placement = isMobile ? 'bottom' : 'right';

        if (modalStates && modalStates.showOnboarding) {
            return <Onboarding onClose={this.handleCloseOnboarding} />;
        }

        if (newGroupId) {
            return (
                <Redirect
                    to={{
                        pathname: routes.group.replace(':id', newGroupId),
                    }}
                />
            );
        }

        return (
            <Fragment>
                <Helmet>
                    <title>Pocket Points | Teacher Rewards</title>
                </Helmet>
                {!isMobile && <OnboardingBar mobile={false} />}
                {showNewGroupModal && (
                    <NewGroupModal
                        initialValues={valuesFromGroupForm}
                        open={true}
                        onClose={this.handleNewGroupClose}
                        onEditSchedule={this.handleEditScheduleShow}
                    />
                )}
                {showEditSchedule && <EarningWindows modal={true} onClose={this.handleEditScheduleClose} />}
                <Grid container={true}>
                    {isMobile && <OnboardingBar mobile={true} />}
                    <Grid item={true} xs={12} className={classes.pageHeader}>
                        <Grid container={true} direction="row" wrap="nowrap" justifyContent="space-between" alignItems="center">
                            <Grid container={true} item={true} xs={5} justifyContent="flex-start" alignItems="center" style={{ width: 'unset' }}>
                                <Grid container={true} item={true}>
                                    <Text isTitle={true}>Classes</Text>
                                    <Tooltip placement={placement} style={{ marginLeft: 8, marginBottom: 8 }} page="Classes" id="classesH1">
                                        Classes are used to determine who can access your rewards as well as when and where they can be earned.
                                    </Tooltip>
                                </Grid>
                                {!isMobile && earningWindow && this.renderNextDay()}
                            </Grid>
                            <Grid container={true} item={true} xs={7} justifyContent="flex-end" spacing={1} wrap="nowrap">
                                <Grid item={true}>
                                    <Button
                                        fullWidth={true}
                                        variant="text"
                                        className={classes.addButton}
                                        onClick={() => this.handleEditScheduleShow()}
                                        disableRipple={true}
                                    >
                                        <CalendarToday className={classes.addIcon} style={{ fontSize: 18 }} />
                                        Edit Schedule
                                    </Button>
                                </Grid>
                                {!isMobile && (
                                    <Grid item={true}>
                                        <Button
                                            fullWidth={true}
                                            variant="text"
                                            className={classes.addButton}
                                            onClick={this.handleNewGroupShow}
                                            disableRipple={true}
                                        >
                                            <Add className={classes.addIcon} />
                                            Create New Class
                                        </Button>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                        {isMobile && earningWindow && this.renderNextDay()}
                    </Grid>
                    <Grid item={true} xs={12} className={classes.pageBody}>
                        {this.renderGroups()}
                    </Grid>
                </Grid>
            </Fragment>
        );
    }

    private renderNextDay = () => {
        const earningWindow = new EarningWindow(this.props.earningWindow);
        const nextSlot = earningWindow.getNextSlot(
            moment()
                .startOf('day')
                .unix()
        );
        const nextDay = moment.unix(nextSlot.epoch);
        const isToday = moment()
            .startOf('day')
            .isSame(nextDay);
        const label = earningWindow.window.labelType === ROTATION_TYPES.numeric ? `${nextSlot.slot + 1}` : String.fromCharCode(nextSlot.slot + 65);

        if (earningWindow.window.type === EARNING_WINDOW_TYPES.rotating) {
            return (
                <Grid container={true} item={true} style={{ marginTop: 6 }} justifyContent={isMobile ? 'flex-end' : 'flex-start'}>
                    <Text fontSize={13} light={true}>
                        {`Next School Day: ${isToday ? 'Today' : nextDay.format('M/D')} -`}
                    </Text>
                    <Text fontSize={13} bold={true}>
                        &nbsp;{`DAY ${label}`}
                    </Text>
                </Grid>
            );
        }
    };

    private renderGroups() {
        const { groups } = this.props;

        if (groups) {
            const numGroups = Object.keys(groups).length;
            return (
                <Grid container={true} spacing={2}>
                    {numGroups === 0 && <NoGroupCell onClick={this.handleNewGroupShow} />}
                    {groups && Object.keys(groups).map((id, index) => <GroupCell key={index} group={groups[id]} id={id} />)}
                    {isMobile && <GroupAddCell onClick={this.handleNewGroupShow} />}
                </Grid>
            );
        } else {
            return <NoGroupCell onClick={this.handleNewGroupShow} />;
        }
    }

    private handleNewGroupShow = () => {
        const { earningWindow } = this.props;

        this.setState({ showNewGroupModal: !!earningWindow, showNewGroupModalNext: true, showEditSchedule: !earningWindow });
    };

    private handleNewGroupClose = (id) => {
        this.setState({ showNewGroupModal: false, newGroupId: id, valuesFromGroupForm: undefined });
    };

    private handleEditScheduleClose = () => {
        const { earningWindow } = this.props;
        const { showNewGroupModalNext } = this.state;

        if (showNewGroupModalNext && earningWindow) {
            this.setState({ showEditSchedule: false, showNewGroupModal: true, showNewGroupModalNext: false });
        } else {
            this.setState({ showEditSchedule: false, showNewGroupModalNext: false });
        }
    };

    private handleEditScheduleShow = (values?) => {
        this.setState({ showEditSchedule: true, showNewGroupModalNext: !!values, valuesFromGroupForm: values });
    };

    private handleCloseOnboarding = () => {
        const { actions } = this.props;

        actions.mixpanel.viewedClasses();
        this.setState({ showNewGroupModal: true }, () => {
            actions.user.updateHelperModals({ showOnboarding: false, showGroupExplainer: true });
        });
    };
}

const mapStoreToProps = (state: any) => {
    return {
        actions: {
            user: state.user.actions,
            mixpanel: state.mixpanel.actions,
        },
        groups: state.groups.data,
        earningWindow: state.earningWindow.data,
        userInfo: state.user.data.userInfo,
    };
};

export default connect(
    mapStoreToProps,
    null
)(withStyles(appStyle)(Groups));
