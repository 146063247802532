import { Button, Dialog, DialogContent, Grid, withMobileDialog, withStyles } from '@material-ui/core';
import { Form, Formik } from 'formik';
import React from 'react';
import isEqual from 'react-fast-compare';
import { connect } from 'react-redux';
import EarningWindow, { IEarningWindow, TEarningWindow, Validations } from 'shared-library-js';
import Alert from '../../components/Alert';
import AlertLoader from '../../components/AlertLoader';
import AlertYesNo from '../../components/AlertYesNo';
import CloseButton from '../../components/CloseButton';
import InstitutionMap from '../../components/InstitutionMap';
import Submit from '../../components/Submit';
import Text from '../../components/Text';
import { EARNING_WINDOW_TYPES } from '../../utils/constants';
import appStyle from '../App.style';
import ConfirmRotatingForm from './ConfirmRotatingForm';
import ConfirmStandardForm from './ConfirmStandardForm';
import ConfirmWeeklyForm from './ConfirmWeeklyForm';
import RotatingForm from './RotatingForm';

interface IProps {
    classes: any;
    actions: any;
    earningWindow?: IEarningWindow;
    fullScreen?: boolean;
    groups: any;
    groupId?: string;
    modal?: boolean;
    onClose: any;
    userInfo?: any;
}

interface IState {
    earningWindowType?: TEarningWindow;
    earningWindow?: IEarningWindow;
    showChooesAlert: boolean;
    showDeletePrompt: boolean;
    showGroupAlert: boolean;
    confirmGroups?: string[];
    updateGroups: any[];
}

class EarningWindows extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            earningWindowType: undefined,
            earningWindow: undefined,
            showDeletePrompt: false,
            showGroupAlert: false,
            showChooesAlert: false,
            confirmGroups: undefined,
            updateGroups: [],
        };
    }

    public componentDidMount() {
        const { earningWindow, userInfo, actions } = this.props;
        const { showOnboarding } = userInfo.modalStates;

        if (earningWindow) {
            actions.mixpanel.sendEvent('Viewed Edit Schedule');
            this.setState({ earningWindowType: earningWindow.type });
        } else {
            this.setState({ showChooesAlert: !showOnboarding });
        }
    }

    public componentDidUpdate() {
        const dialog = document.getElementById('dialog-earning-windows');
        const { confirmGroups } = this.state;

        if (dialog && dialog.scrollTo && confirmGroups) {
            dialog.scrollTo(0, 0);
        }
    }

    public render() {
        const { modal } = this.props;
        const { showChooesAlert } = this.state;

        if (showChooesAlert) {
            return (
                <Alert
                    alert={{
                        showing: showChooesAlert,
                        buttonText: 'Continue',
                        title: 'Looks like your classes are on different schedules',
                        message:
                            'All of your classes need to be on the same schedule.  Please take a moment to choose the schedule all of your classes will use',
                    }}
                    onClose={this.handleCloseChooseAlert}
                />
            );
        }

        return (
            <React.Fragment>
                {modal && this.renderModal()}
                {!modal && this.renderContent()}
            </React.Fragment>
        );
    }

    private renderModal = () => {
        const { classes, fullScreen, onClose } = this.props;
        const { confirmGroups } = this.state;

        return (
            <Dialog fullScreen={fullScreen} open={true} onClose={onClose} maxWidth="sm" fullWidth={true}>
                <DialogContent id="dialog-earning-windows" style={{ padding: 0, position: 'relative' }}>
                    <Grid container={true} item={true} xs={12} style={{ padding: 16, paddingBottom: 0 }} justifyContent={confirmGroups ? 'flex-end' : 'flex-start'}>
                        {!confirmGroups && <CloseButton onClick={onClose} />}
                        {confirmGroups && <Text onClick={this.handleDeletePrompt}>Delete Class</Text>}
                    </Grid>
                    <Grid container={true} item={true} xs={12} className={classes.modalBody}>
                        {this.renderContent()}
                    </Grid>
                </DialogContent>
            </Dialog>
        );
    };

    private renderContent = () => {
        const { classes, earningWindow, userInfo } = this.props;
        const { showOnboarding } = userInfo.modalStates;
        const { confirmGroups, earningWindowType, showDeletePrompt, showGroupAlert } = this.state;
        const schoolId = userInfo ? userInfo.school.id : null;
        let title = earningWindow ? 'Edit Schedule' : 'Choose Schedule';

        if (showOnboarding) {
            title = 'Set Up School';
        }

        return (
            <React.Fragment>
                <AlertYesNo
                    showing={showGroupAlert}
                    title="Editing your schedule may effect class times"
                    content="Please take a moment to confirm all of your class times are correct"
                    closeText="Cancel"
                    confirmText="Continue"
                    onClose={this.handleCloseGroupAlert}
                    onConfirm={this.handleConfirmGroupTimes}
                />
                <AlertYesNo showing={showDeletePrompt} onClose={this.handleDeleteCancel} onConfirm={this.handleDeleteConfirm} />
                <Grid container={true} item={true} xs={12} className={classes.authContainer} style={{ marginBottom: 16 }}>
                    {confirmGroups && <Text isTitle={true}>Confirm Class</Text>}
                    {!confirmGroups && <Text isTitle={true}>{title}</Text>}
                </Grid>
                <Grid container={true} className={classes.authContainer}>
                    {showOnboarding && (
                        <Grid item={true} xs={12}>
                            <InstitutionMap schoolId={schoolId} hideAddress={true} creatorID="window-index" />
                        </Grid>
                    )}
                    {!earningWindowType && this.renderTypeSelection()}
                    {earningWindowType && this.renderCards()}
                </Grid>
            </React.Fragment>
        );
    };

    private renderCards = () => {
        const { earningWindowType, confirmGroups } = this.state;

        if (confirmGroups) {
            return this.renderConfirmGroups();
        }

        return (
            <React.Fragment>
                {earningWindowType === EARNING_WINDOW_TYPES.rotating && this.renderRotating()}
                {earningWindowType === EARNING_WINDOW_TYPES.weekly && this.renderWeekly()}
                {earningWindowType === EARNING_WINDOW_TYPES.standard && this.renderStandard()}
            </React.Fragment>
        );
    };

    private renderConfirmGroups = () => {
        const { groups } = this.props;
        const { earningWindow, earningWindowType, confirmGroups } = this.state;
        const groupId = confirmGroups[0];
        const group = groups[groupId];

        return (
            <React.Fragment>
                {confirmGroups.length === 0 && <AlertLoader loading={true} />}
                {earningWindowType === EARNING_WINDOW_TYPES.rotating && (
                    <ConfirmRotatingForm
                        earningWindow={earningWindow}
                        groupId={groupId}
                        group={group}
                        onClose={this.handleCloseConfirmForm}
                        onSubmit={this.handleSubmitConfirmForm}
                    />
                )}
                {earningWindowType === EARNING_WINDOW_TYPES.weekly && (
                    <ConfirmWeeklyForm
                        earningWindow={earningWindow}
                        groupId={groupId}
                        group={group}
                        onClose={this.handleCloseConfirmForm}
                        onSubmit={this.handleSubmitConfirmForm}
                    />
                )}
                {earningWindowType === EARNING_WINDOW_TYPES.standard && (
                    <ConfirmStandardForm
                        earningWindow={earningWindow}
                        groupId={groupId}
                        group={group}
                        onClose={this.handleCloseConfirmForm}
                        onSubmit={this.handleSubmitConfirmForm}
                    />
                )}
            </React.Fragment>
        );
    };

    private renderRotating = () => {
        const { earningWindow } = this.props;
        let initialValues = this.getInitialValues();

        if (this.state.earningWindow && this.state.earningWindow.type === EARNING_WINDOW_TYPES.rotating) {
            initialValues = {
                ...initialValues,
                ...this.state.earningWindow,
            };
        }

        return (
            <React.Fragment>
                <Formik
                    initialValues={initialValues}
                    validationSchema={Validations.rotating}
                    onSubmit={() => null}
                    render={({ values, setValues }) => (
                        <Form noValidate={true}>
                            {!earningWindow && this.renderTypeSelection()}
                            <RotatingForm edit={!!earningWindow} values={values} setValues={setValues} />
                            {earningWindow && this.renderTypeSelection()}
                            {this.renderButtons(values)}
                        </Form>
                    )}
                />
            </React.Fragment>
        );
    };

    private renderWeekly = () => {
        return (
            <React.Fragment>
                {this.renderTypeSelection()}
                {this.renderButtons(EarningWindow.weeklyWindow())}
            </React.Fragment>
        );
    };

    private renderStandard = () => {
        return (
            <React.Fragment>
                {this.renderTypeSelection()}
                {this.renderButtons(EarningWindow.standardWindow())}
            </React.Fragment>
        );
    };

    private renderTypeSelection = () => {
        const { classes } = this.props;
        const { earningWindowType } = this.state;

        return (
            <React.Fragment>
                <Grid item={true} xs={12} style={{ marginTop: 20, marginBottom: 10 }}>
                    <Text bold={true} fontSize={16}>
                        What type of schedule are you on?
                    </Text>
                </Grid>
                <Grid container={true}>
                    <Grid item={true} xs={12} sm={6} md={12}>
                        <Button
                            variant="text"
                            fullWidth={true}
                            className={earningWindowType === EARNING_WINDOW_TYPES.rotating ? classes.typeButtonSelected : classes.typeButton}
                            onClick={() => this.handleSetEarningWindowType(EARNING_WINDOW_TYPES.rotating)}
                        >
                            Are you on a rotating or block schedule?
                        </Button>
                    </Grid>
                    <Grid item={true} xs={12} sm={6} md={12}>
                        <Button
                            variant="text"
                            fullWidth={true}
                            className={earningWindowType === EARNING_WINDOW_TYPES.weekly ? classes.typeButtonSelected : classes.typeButton}
                            onClick={() => this.handleSetEarningWindowType(EARNING_WINDOW_TYPES.weekly)}
                        >
                            Are your classes at different times during the week?
                        </Button>
                    </Grid>
                    <Grid item={true} xs={12} sm={6} md={12}>
                        <Button
                            variant="text"
                            fullWidth={true}
                            className={earningWindowType === EARNING_WINDOW_TYPES.standard ? classes.typeButtonSelected : classes.typeButton}
                            onClick={() => this.handleSetEarningWindowType(EARNING_WINDOW_TYPES.standard)}
                        >
                            Are your classes at the same time every day each week?
                        </Button>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    };

    private renderButtons = (window: IEarningWindow) => {
        const { earningWindow, userInfo } = this.props;
        const { showOnboarding } = userInfo.modalStates;

        if (!earningWindow || showOnboarding) {
            return this.renderContinueButton(window);
        }

        return this.renderEditButtons(window);
    };

    private renderContinueButton = (earningWindow: IEarningWindow) => {
        return (
            <Grid container={true} justifyContent="center" alignItems="center">
                <Grid item={true} xs={12}>
                    <Submit loading={false} style={{ marginTop: 24 }} id="submit-button" onClick={() => this.handleSubmit(earningWindow)}>
                        Continue
                    </Submit>
                </Grid>
            </Grid>
        );
    };

    private renderEditButtons = (earningWindow: IEarningWindow) => {
        const { classes, onClose } = this.props;

        return (
            <Grid container={true} style={{ textAlign: 'center', paddingTop: 24 }}>
                <Grid item={true} xs={6}>
                    <Button variant="contained" color="primary" className={classes.cancelButton} onClick={onClose}>
                        Cancel
                    </Button>
                </Grid>
                <Grid item={true} xs={6}>
                    <Button
                        id="submit-button"
                        type="submit"
                        variant="contained"
                        color="primary"
                        className={classes.saveButton}
                        onClick={() => this.handleSubmit(earningWindow)}
                    >
                        Save
                    </Button>
                </Grid>
            </Grid>
        );
    };

    private getInitialValues = () => {
        const values =
            this.props.earningWindow && this.props.earningWindow.type === EARNING_WINDOW_TYPES.rotating
                ? this.props.earningWindow
                : EarningWindow.rotatingWindow();
        const earningWindow = new EarningWindow(values);

        return earningWindow.window;
    };

    private handleCloseConfirmForm = () => {
        this.setState({ confirmGroups: undefined });
    };

    private handleSetEarningWindowType = (earningWindowType) => {
        this.setState({ earningWindowType });
    };

    private handleCloseGroupAlert = () => {
        this.setState({ showGroupAlert: false });
    };

    private handleCloseChooseAlert = () => {
        this.setState({ showChooesAlert: false });
    };

    private handleConfirmGroupTimes = () => {
        const { groups, groupId } = this.props;
        const confirmGroups = Object.keys(groups).filter((id) => id !== groupId);

        this.setState({ confirmGroups, showGroupAlert: false });
    };

    private handleDeletePrompt = () => {
        this.setState({ showDeletePrompt: true });
    };

    private handleDeleteCancel = () => {
        this.setState({ showDeletePrompt: false });
    };

    private handleDeleteConfirm = () => {
        const { actions } = this.props;
        const groupId = this.state.confirmGroups[0];
        const confirmGroups = this.state.confirmGroups.filter((id) => id !== groupId);

        actions.groups.deleteGroup(groupId).then(() => {
            this.setState({ confirmGroups, showDeletePrompt: false }, () => {
                if (this.state.confirmGroups.length === 0) {
                    this.onSubmit();
                }
            });
        });
    };

    private handleSubmitConfirmForm = (groupId, { earningWindow }, { resetForm }) => {
        const { actions, groups } = this.props;
        const confirmGroups = this.state.confirmGroups.filter((id) => id !== groupId);
        const editWindow = new EarningWindow(earningWindow);

        const updateGroup = {
            values: {
                ...groups[groupId].attributes,
                window: editWindow.getGroupWindow(),
                windowType: editWindow.window.type,
            },
            groupId,
        };

        this.setState({ updateGroups: [...this.state.updateGroups, updateGroup], confirmGroups }, () => {
            actions.mixpanel.sendEvent('Viewed Confirm Class');

            if (this.state.confirmGroups.length > 0) {
                resetForm();
            } else {
                this.onSubmit();
            }
        });
    };

    private handleSubmit = (window: IEarningWindow) => {
        const { groupId, groups, earningWindow } = this.props;
        const confirmGroups = Object.keys(groups).filter((id) => id !== groupId);
        const updateGroups = [];
        let editWindow;

        if (groupId) {
            editWindow = new EarningWindow(window, groups[groupId]);

            updateGroups.push({
                values: {
                    ...groups[groupId].attributes,
                    window: editWindow.getGroupWindow(),
                    windowType: editWindow.window.type,
                },
                groupId,
            });
        }

        if (confirmGroups.length > 0 && !isEqual(earningWindow, window)) {
            this.setState({ earningWindow: window, showGroupAlert: true, updateGroups });
        } else {
            confirmGroups.forEach((confirmGroupId) => {
                const confirmGroupWindow = new EarningWindow(window, groups[confirmGroupId]);

                updateGroups.push({
                    values: {
                        ...groups[confirmGroupId].attributes,
                        window: confirmGroupWindow.getGroupWindow(),
                        windowType: confirmGroupWindow.window.type,
                    },
                    groupId: confirmGroupId,
                });
            });

            this.setState({ earningWindow: window, updateGroups }, () => {
                this.onSubmit();
            });
        }
    };

    private onSubmit = () => {
        const { actions, onClose } = this.props;
        const { updateGroups, earningWindow } = this.state;
        const action = this.props.earningWindow ? actions.earningWindow.updateEarningWindow : actions.earningWindow.createEarningWindow;

        return action(earningWindow).then(() => {
            let updates = Promise.resolve();

            updateGroups.forEach((update) => {
                updates = updates.then(() => actions.groups.updateGroup(update));
            });

            return updates.then(() => {
                onClose();
            });
        });
    };
}

const mapStoreToProps = (state: any) => {
    return {
        actions: {
            earningWindow: state.earningWindow.actions,
            groups: state.groups.actions,
            mixpanel: state.mixpanel.actions,
        },
        groups: state.groups.data,
        earningWindow: state.earningWindow.data,
        userInfo: state.user.data.userInfo,
    };
};

export default connect(
    mapStoreToProps,
    null
)(withStyles(appStyle)(withMobileDialog<IProps>()(EarningWindows)));
