import { Avatar, Button, Grid, Link, TextField } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { Formik } from 'formik';
import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import AlertDialog from '../../components/Alert';
import Submit from '../../components/Submit';
import Text from '../../components/Text';
import routes from '../../utils/routes';
import { LoginSchema } from '../../utils/validations';
import appStyle from '../App.style';

interface IProps {
    classes: any;
    actions: any;
}

interface IState {
    alert: any;
    loading: boolean;
}

class Login extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            alert: {
                showing: false,
            },
            loading: false,
        };
    }

    public render() {
        const { classes } = this.props;
        const { alert } = this.state;

        return (
            <React.Fragment>
                <Helmet>
                    <title>Pocket Points | Teacher Login</title>
                </Helmet>
                <Grid
                    container={true}
                    className={classes.pageBody}
                    style={{ backgroundColor: '#ffffff' }}
                    justifyContent="center"
                    direction="column"
                    alignItems="center"
                >
                    <Grid container={true} item={true} xs={12} sm={6} style={{ padding: 16, paddingTop: 32 }}>
                        <Text isTitle={true}>Login</Text>
                    </Grid>
                    <Grid container={true} item={true} xs={12} sm={6} style={{ padding: 16 }}>
                        <AlertDialog alert={alert} onClose={this.alertClosed} />
                        <Formik
                            initialValues={{
                                email: '',
                                password: '',
                            }}
                            validationSchema={LoginSchema}
                            onSubmit={this.onSubmit}
                        >
                            {({ values, errors, touched, handleChange, handleSubmit, isSubmitting }) => (
                                <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                                    <TextField
                                        margin="normal"
                                        required={true}
                                        fullWidth={true}
                                        error={!!errors.email && !!touched.email}
                                        label="Email"
                                        placeholder="Enter email"
                                        id="email"
                                        name="email"
                                        autoFocus={true}
                                        value={values.email}
                                        onChange={handleChange}
                                    />
                                    <TextField
                                        margin="normal"
                                        required={true}
                                        fullWidth={true}
                                        error={!!errors.password && !!touched.password}
                                        label="Password"
                                        placeholder="Enter password"
                                        id="password"
                                        name="password"
                                        type="password"
                                        value={values.password}
                                        onChange={handleChange}
                                    />
                                    <Link component={RouterLink} to={routes.forgot} underline="none">
                                        <Text color="primary" fontSize={16}>
                                            Forgot your password?
                                        </Text>
                                    </Link>
                                    <Submit loading={isSubmitting}>Login</Submit>
                                </form>
                            )}
                        </Formik>
                    </Grid>
                    {this.renderSeparator()}
                    {this.renderGoogleButton()}
                    {this.renderSignUpLink()}
                </Grid>
            </React.Fragment>
        );
    }

    private renderGoogleButton = () => (
        <Grid container={true} item={true} xs={12} sm={6} style={{ padding: 16, paddingTop: 32 }}>
            <Button
                variant="contained"
                color="secondary"
                size="large"
                fullWidth={true}
                style={{ justifyContent: 'space-between', height: 54 }}
                onClick={this.handleSignInWithGoogle}
            >
                <Avatar src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg" style={{ width: 23, height: 23, marginLeft: 16 }} />
                <Text fontSize={14} light={true}>
                    Log in with Google
                </Text>
                <div style={{ width: 23, height: 23, marginRight: 16 }} />
            </Button>
        </Grid>
    );

    private renderSeparator = () => (
        <Grid container={true} item={true} xs={12} sm={6} style={{ padding: '8px 16px' }} justifyContent="space-between">
            <Grid container={true} item={true} xs={4} justifyContent="center" alignItems="center">
                <div style={{ borderTop: '1px solid #D8D8D8', width: '100%' }} />
            </Grid>
            <Grid container={true} item={true} xs={4} justifyContent="center" alignItems="center">
                <Text fontSize={14} light={true}>
                    or
                </Text>
            </Grid>
            <Grid container={true} item={true} xs={4} justifyContent="center" alignItems="center">
                <div style={{ borderTop: '1px solid #D8D8D8', width: '100%' }} />
            </Grid>
        </Grid>
    );

    private renderSignUpLink = () => (
        <Grid container={true} item={true} xs={12} sm={6} style={{ padding: 16, paddingTop: 32 }} justifyContent="center">
            <Link component={RouterLink} to={routes.signUp} underline="none">
                <Text color="primary" fontSize={16}>
                    Want to create an account? Sign up
                </Text>
            </Link>
        </Grid>
    );

    private alertClosed = () => {
        this.setState({ alert: { showing: false } });
    };

    private handleSignInWithGoogle = () => {
        const { actions } = this.props;

        actions.user.signInWithGoogle();
    };

    private onSubmit = (values, { setSubmitting }) => {
        const { actions } = this.props;
        const { email, password } = values;

        actions.user
            .loginEmail({ email, password })
            .then(() => setSubmitting(false))
            .catch(() => {
                setSubmitting(false);
                this.setState({
                    loading: false,
                    alert: {
                        showing: true,
                        title: 'Sorry',
                        message: 'You entered an invalid email or password.',
                    },
                });
            });
    };
}

const mapStoreToProps = (state: any) => {
    return {
        actions: {
            user: state.user.actions,
        },
    };
};

export default connect(
    mapStoreToProps,
    null
)(withStyles(appStyle)(Login));
