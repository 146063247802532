import moment from 'moment-timezone';

export const scrubObject = (data) => {
    const results = {};

    Object.keys(data).forEach((key) => {
        if (data[key] !== '' && data[key] !== null && data[key] !== undefined) {
            results[key] = data[key];
        }
    });

    return results;
};

export const calculateDistance = (from, to) => {
    const radFromLat = (Math.PI * from.lat) / 180;
    const radToLat = (Math.PI * to.lat) / 180;
    const theta = from.lon - to.lon;
    const radTheta = (Math.PI * theta) / 180;
    let dist = Math.sin(radFromLat) * Math.sin(radToLat) + Math.cos(radFromLat) * Math.cos(radToLat) * Math.cos(radTheta);

    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;

    return dist;
};

export const readableTime = (secs: number) => {
    const unit = secs < 3600 ? 'minute' : 'hour';

    return `${secs < 3600 ? Math.floor(secs / 60) : Math.floor(secs / 3600)} ${unit}${unit === 'hour' && secs < 7200 ? '' : 's'}`;
};

export const formatDate = (dateString: string) => {
    const expirationDate = moment(dateString, 'YYYY-MM-DD HH:mm:ss');
    const minutes = expirationDate.diff(moment(), 'minutes');
    let expiration = { type: 'minute', value: minutes };

    if (minutes <= 0) {
        return 'Expired';
    }

    if (expirationDate.diff(moment(), 'hours') > 0) {
        expiration = { type: 'hour', value: expirationDate.diff(moment(), 'hours') };
    }
    if (expirationDate.diff(moment(), 'days') > 0) {
        expiration = { type: 'day', value: expirationDate.diff(moment(), 'days') };
    }
    if (expirationDate.diff(moment(), 'months') > 0) {
        expiration = { type: 'month', value: expirationDate.diff(moment(), 'months') };
    }
    if (expirationDate.diff(moment(), 'years') > 0) {
        expiration = { type: 'year', value: expirationDate.diff(moment(), 'years') };
    }

    return `Expires in ${expiration.value} ${expiration.type}${expiration.value > 1 ? 's' : ''}`;
};

export const hasCreatedAnIncentive = (groups: any) => {
    let hasIncentives = false;

    Object.keys(groups.data).forEach((groupId) => {
        const incentives = groups.data[groupId].incentives;
        const groupIncentives = groups.data[groupId].groupIncentives;
        if ((incentives && !(Object.keys(incentives).length === 0 && incentives.constructor === Object)) || (groupIncentives && !(Object.keys(groupIncentives).length === 0 && groupIncentives.constructor === Object))) {
            hasIncentives = true;
        }
    });

    return hasIncentives;
};
