import React from 'react';
import appStyle from '../../App.style';
import withStyles from '@material-ui/core/styles/withStyles';
import { Typography, Grid, Button } from '@material-ui/core';
import { People } from '@material-ui/icons';

interface IProps {
    classes: any;
    onClick: any;
}

class NoStudentsCell extends React.PureComponent<IProps> {
    public render() {
        const { classes } = this.props;

        return (
            <Grid item={true} xs={12} className={classes.pageRow}>
                <div className={classes.noGroupCellContainer}>
                    <Grid container={true} justifyContent="center" alignItems="center" direction="column">
                        <Grid item={true} xs={12}>
                            <People className={classes.noIncentivesIcon} />
                            <Typography component="p" className={classes.noGroupsMainText}>
                                No students have joined this class yet
                            </Typography>
                            <Typography component="p" className={classes.noGroupsSubText}>
                                Invite your students to your class
                            </Typography>
                            <Button color="primary" variant="contained" className={classes.primaryButton} onClick={this.props.onClick}>
                                Invite Students
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
        );
    }
}

export default withStyles(appStyle)(NoStudentsCell);
