import withStyles from '@material-ui/core/styles/withStyles';
import React from 'react';
import { connect } from 'react-redux';
import appStyle from '../app/App.style';
import { hasCreatedAnIncentive } from '../utils/helper';

// import {Line} from 'rc-progress';
import { AppBar, Grid, Typography } from '@material-ui/core';

interface ItemProps {
    classes: any;
    index: number;
    progress: number;
    title: string;
}

const Item = (props: ItemProps) => {
    const { classes, progress, title, index } = props;
    const opacity = progress === 0 ? 0.3 : 1;

    return (
        <Grid item={true} style={{ paddingLeft: index === 0 ? 0 : 4, paddingRight: index === 2 ? 0 : 4 }} xs={4}>
            <Grid container={true} direction="column" alignItems="center">
                <Grid item={true} style={{ width: '100%', height: 3, backgroundColor: progress === 0 ? '#D9D9D9' : '#00D793' }} />
                <Grid item={true} style={{ marginTop: 8 }}>
                    <Typography style={{ opacity }} className={classes.onboardingBarItemTitle}>
                        {title}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
};

interface IProps {
    classes: any;
    groups: any;
    mobile: boolean;
}

class OnboardingBar extends React.PureComponent<IProps> {
    public render() {
        const { classes, groups, mobile } = this.props;

        // let showCloseButton = false;
        let step1 = 0;
        let step2 = 0;
        let step3 = 0;

        if (this.hasClosedBar(groups)) {
            return null;
        }

        if (this.hasCreatedAClass(groups)) {
            if (hasCreatedAnIncentive(groups)) {
                if (this.hasStudentsJoined(groups)) {
                    step1 = 100;
                    step2 = 100;
                    step3 = 100;
                    // showCloseButton = true;
                } else {
                    step1 = 100;
                    step2 = 100;
                    step3 = 0;
                }
            } else {
                step1 = 100;
                step2 = 0;
                step3 = 0;
            }
        }

        let title = '';
        if (step1 === 0) {
            title = 'Add your first class and rewards to get started!';
        } else if (step2 === 0) {
            title = 'Almost done! Create a reward for your class and add students to setup your class';
        } else if (step3 === 0) {
            title = 'Almost done! Get your students to join your class to finish setup';
        } else if (step3 === 100) {
            return null;
        }
        if (mobile) {
            return this.renderMobile(step1, step2, step3, classes, title);
        }

        return this.renderDesktop(step1, step2, step3, classes, title);
    }

    private renderDesktop(step1: number, step2: number, step3: number, classes: any, title: string) {
        return (
            <AppBar position="fixed" color="primary" className={classes.onboardingBarAppBar}>
                <div className={classes.onboardingBarToolBar}>{this.renderContent(step1, step2, step3, classes, title)}</div>
            </AppBar>
        );
    }

    private renderMobile(step1: number, step2: number, step3: number, classes: any, title: string) {
        return (
            <Grid item={true} xs={12} className={classes.onboardingBarMobileContainer}>
                {this.renderContent(step1, step2, step3, classes, title)}
            </Grid>
        );
    }

    private renderContent(step1: number, step2: number, step3: number, classes: any, title: string) {
        return (
            <Grid container={true} direction="column" alignItems="center" style={{ width: '100%' }}>
                <Grid item={true}>
                    <Typography className={classes.onboardingBarTitleText}>{title}</Typography>
                </Grid>
                <Grid item={true} style={{ marginTop: 12, width: '100%' }}>
                    <Grid container={true}>
                        <Item index={0} classes={classes} progress={step1} title="1. Add Class" />
                        <Item index={1} classes={classes} progress={step2} title="2. Create Reward" />
                        <Item index={2} classes={classes} progress={step3} title="3. Add Students" />
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    private hasCreatedAClass(groups: any) {
        if (groups.data) {
            return !(Object.keys(groups.data).length === 0 && groups.data.constructor === Object);
        }
        return false;
    }

    private hasStudentsJoined(groups: any) {
        let hasAppUsers = false;
        Object.keys(groups.data).forEach((groupId) => {
            const appUsers = groups.data[groupId].appUsers;
            if (appUsers && !(Object.keys(appUsers).length === 0 && appUsers.constructor === Object)) {
                hasAppUsers = true;
            }
        });

        return hasAppUsers;
    }

    private hasClosedBar(groups: any) {
        return false;
    }
}

const mapStoreToProps = (state: any) => {
    return {
        groups: state.groups,
    };
};

export default connect(
    mapStoreToProps,
    null
)(withStyles(appStyle)(OnboardingBar));
