import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import * as routes from '../../utils/routes';

interface IProps {
    user: any;
}

class NoMatch extends React.Component<IProps> {
    public render() {
        const { authUser, userInfo } = this.props.user.data;
        const modalStates = userInfo && userInfo.modalStates ? userInfo.modalStates : null;

        if (modalStates && modalStates.showOnboarding) {
            return <Redirect to={routes.groups} />;
        }

        if (authUser) {
            return <Redirect to={routes.dashboard} />;
        }

        return <Redirect to={routes.login} />;
    }
}

const mapStoreToProps = (state: any) => {
    return {
        user: state.user,
    };
};

export default connect(
    mapStoreToProps,
    null
)(NoMatch);
