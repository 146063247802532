import { Avatar, Button, FormControl, FormHelperText, Grid, Input, InputLabel, Link, Typography, withMobileDialog, withStyles } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import { Form, Formik } from 'formik';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import AlertDialog from '../../components/Alert';
import Submit from '../../components/Submit';
import Text from '../../components/Text';
import * as routes from '../../utils/routes';
import { SignUpSchema } from '../../utils/validations';
import appStyle from '../App.style';

interface IProps {
    actions: any;
    classes: any;
    user: any;
    theme: any;
    location: any;
}

interface IState {
    showEmailSignUp: boolean;
    alert: any;
}

class SignUp extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            showEmailSignUp: false,
            alert: {
                showing: false,
            },
        };
    }

    public render() {
        const { showEmailSignUp } = this.state;

        return (
            <React.Fragment>
                <Helmet>
                    <title>Pocket Points | Teacher Signup</title>
                </Helmet>
                {!showEmailSignUp && this.renderOptions()}
                {showEmailSignUp && this.renderEmailSignUp()}
            </React.Fragment>
        );
    }

    private renderOptions = () => {
        const { classes } = this.props;

        return (
            <Grid
                container={true}
                className={classes.pageBody}
                style={isMobile ? {} : { backgroundColor: '#ffffff' }}
                justifyContent="center"
                direction="column"
                alignItems="center"
            >
                <Grid container={true} item={true} xs={12} sm={6} style={{ padding: 16, paddingTop: 32 }}>
                    <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        fullWidth={true}
                        style={{ justifyContent: 'space-between', height: 54 }}
                        onClick={this.handleSignInWithGoogle}
                    >
                        <Avatar src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg" style={{ width: 23, height: 23, marginLeft: 16 }} />
                        <Text fontSize={14} light={true}>
                            Sign up with Google
                        </Text>
                        <div style={{ width: 23, height: 23, marginRight: 16 }} />
                    </Button>
                </Grid>
                {this.renderSeparator()}
                <Grid container={true} item={true} xs={12} sm={6} style={{ padding: 16 }}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        fullWidth={true}
                        style={{ justifyContent: 'space-between', height: 54 }}
                        onClick={this.handleShowEmailSignUp}
                    >
                        <EmailIcon color="secondary" style={{ marginLeft: 16 }} />
                        <Text color="secondary" fontSize={14}>
                            Sign up with email
                        </Text>
                        <div style={{ width: 23, height: 23, marginRight: 16 }} />
                    </Button>
                </Grid>
                {this.renderLoginLink()}
            </Grid>
        );
    };

    private renderEmailSignUp = () => {
        const { classes } = this.props;
        const { alert } = this.state;

        return (
            <Grid container={true} className={classes.pageBody} style={{ backgroundColor: '#ffffff' }} justifyContent="center" direction="column" alignItems="center">
                <Grid container={true} item={true} xs={12} sm={6} style={{ padding: 16, paddingTop: 32 }}>
                    <Button fullWidth={true} size="large" onClick={this.handleSignInWithGoogle}>
                        <Text light={true} fontSize={16}>
                            Sign up with
                        </Text>
                        <Text color="primary" fontSize={16} style={{ paddingLeft: 3 }}>
                            Google
                        </Text>
                    </Button>
                </Grid>
                {this.renderSeparator()}
                <Grid container={true} item={true} xs={12} sm={6} style={{ padding: 16, paddingTop: 0 }}>
                    <AlertDialog alert={alert} onClose={this.alertClosed} />
                    <Formik
                        initialValues={{
                            displayName: '',
                            email: '',
                            password: '',
                        }}
                        validationSchema={SignUpSchema}
                        onSubmit={this.onSubmit}
                    >
                        {({ values, errors, touched, handleChange, isSubmitting }) => (
                            <Form noValidate={true} autoComplete="off">
                                <Grid container={true} item={true} xs={12} justifyContent="center">
                                    <FormControl margin="normal" required={true} fullWidth={true} error={!!errors.email && !!touched.email}>
                                        <InputLabel htmlFor="email">Email</InputLabel>
                                        <Input
                                            autoComplete="off"
                                            placeholder="Enter email"
                                            id="email"
                                            name="email"
                                            value={values.email}
                                            onChange={handleChange}
                                            style={{ marginTop: 22 }}
                                        />
                                    </FormControl>
                                    <FormControl margin="normal" required={true} fullWidth={true} error={!!errors.displayName && !!touched.displayName}>
                                        <InputLabel htmlFor="displayName">Name</InputLabel>
                                        <Input
                                            autoComplete="off"
                                            placeholder="First and Last Name"
                                            id="displayName"
                                            name="displayName"
                                            autoFocus={true}
                                            onChange={handleChange}
                                            value={values.displayName}
                                            style={{ marginTop: 22 }}
                                        />
                                    </FormControl>
                                    <FormControl margin="normal" required={true} fullWidth={true} error={!!errors.password && !!touched.password}>
                                        <InputLabel htmlFor="password">Password</InputLabel>
                                        <Input
                                            autoComplete="off"
                                            placeholder="Enter password"
                                            id="password"
                                            name="password"
                                            type="password"
                                            value={values.password}
                                            onChange={handleChange}
                                            style={{ marginTop: 22 }}
                                        />
                                        <FormHelperText style={{ opacity: 0.3 }}>(min length of 6 characters)</FormHelperText>
                                    </FormControl>
                                    <Typography component="p" variant="body1" className={classes.authTermsContainer} align="center">
                                        By signing up you agree to our{' '}
                                        <a className={classes.authTerms} href="https://pocketpoints.com/terms" target="_blank" rel="noopener noreferrer">
                                            Terms of Service
                                        </a>{' '}
                                        and{' '}
                                        <a className={classes.authTerms} href="https://rewards.pocketpoints.com/privacy" target="_blank" rel="noopener noreferrer">
                                            Privacy Policy
                                        </a>
                                    </Typography>
                                    <Submit loading={isSubmitting}>Continue</Submit>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Grid>
                {this.renderLoginLink()}
            </Grid>
        );
    };

    private renderSeparator = () => (
        <Grid container={true} item={true} xs={12} sm={6} style={{ padding: '8px 16px' }} justifyContent="space-between">
            <Grid container={true} item={true} xs={4} justifyContent="center" alignItems="center">
                <div style={{ borderTop: '1px solid #D8D8D8', width: '100%' }} />
            </Grid>
            <Grid container={true} item={true} xs={4} justifyContent="center" alignItems="center">
                <Text fontSize={14} light={true}>
                    or
                </Text>
            </Grid>
            <Grid container={true} item={true} xs={4} justifyContent="center" alignItems="center">
                <div style={{ borderTop: '1px solid #D8D8D8', width: '100%' }} />
            </Grid>
        </Grid>
    );

    private renderLoginLink = () => (
        <Grid container={true} item={true} xs={12} sm={6} style={{ padding: 16 }} justifyContent="center">
            <Link component={RouterLink} to={routes.login} underline="none">
                <Text color="primary" fontSize={16}>
                    Already have an account? Login
                </Text>
            </Link>
        </Grid>
    );

    private handleSignInWithGoogle = () => {
        const { actions } = this.props;

        actions.user.signInWithGoogle();
    };

    private handleShowEmailSignUp = () => {
        this.setState({ showEmailSignUp: true });
    };

    private onSubmit = (values, { setSubmitting }) => {
        const { actions, location } = this.props;
        const { email, password, displayName } = values;

        actions.user
            .signUpEmail({ displayName, email, password, location })
            .then(() => setSubmitting(false))
            .catch((error: Error) => {
                setSubmitting(false);
                this.setState({
                    alert: {
                        showing: true,
                        title: 'Sorry',
                        message: error.message,
                    },
                });
            });
    };

    private alertClosed = () => {
        this.setState({ alert: { showing: false } });
    };
}

const mapStoreToProps = (state: any) => {
    return {
        actions: {
            user: state.user.actions,
        },
    };
};

export default connect(
    mapStoreToProps,
    null
)(withStyles(appStyle)(withMobileDialog<IProps>()(SignUp)));
