import { ClickAwayListener, DialogContent, DialogContentText, Grid, IconButton, Paper, Popper } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { InfoRounded } from '@material-ui/icons';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import appStyle from '../app/App.style';

interface IProps {
    classes: any;
    children: any;
    placement?: 'left' | 'right' | 'bottom';
    align?: 'center' | undefined;
    style?: any;
    isInput?: boolean;
    id: string;
    page: string;
    imageSrc?: string;
    mixpanel: {
        actions: any;
    };
}

interface IState {
    anchorRef: any;
    arrowRef: any;
    showTooltip: boolean;
}

class Tooltip extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            anchorRef: null,
            arrowRef: null,
            showTooltip: false,
        };
    }

    public render() {
        const { classes, isInput } = this.props;
        const inputStyle = isInput ? { position: 'absolute', right: 0, top: 0 } : this.props.style;

        return (
            <div style={inputStyle} onMouseEnter={this.onShowTooltip} onMouseLeave={() => this.setState({ showTooltip: false })}>
                <IconButton className={classes.tooltipButton} onClick={this.onShowTooltip} style={{ padding: 0 }}>
                    <InfoRounded fontSize="small" style={{ opacity: 0.2 }} />
                </IconButton>
                {this.renderTooltip()}
            </div>
        );
    }

    private renderTooltip = () => {
        const { align, classes, children, imageSrc } = this.props;
        const { anchorRef, arrowRef, showTooltip } = this.state;
        const { arrowStyle, containerStyle, popperStyle } = this.getLayout();
        const placement = this.props.placement ? this.props.placement : 'left';

        return (
            <Popper
                onClick={this.dismissOnClick}
                open={showTooltip}
                anchorEl={anchorRef}
                placement={placement}
                disablePortal={false}
                className={classes.popper}
                modifiers={{
                    flip: {
                        enabled: false,
                    },
                    arrow: {
                        enabled: true,
                        element: arrowRef,
                    },
                    preventOverflow: {
                        enabled: placement === 'bottom',
                        boundariesElement: 'scrollParent',
                    },
                    hide: {
                        enabled: false,
                    },
                }}
                style={popperStyle}
            >
                <ClickAwayListener onClickAway={() => this.setState({ showTooltip: false })}>
                    <Grid container={true} style={containerStyle}>
                        <Grid item={true}>
                            <Paper className={classes.paper}>
                                <DialogContent>
                                    {Array.isArray(children) &&
                                        children.map((child, index) => (
                                            <DialogContentText key={index} component="div" align={align}>
                                                {child}
                                            </DialogContentText>
                                        ))}
                                    {!Array.isArray(children) && (
                                        <DialogContentText component="div" align={align}>
                                            {children}
                                        </DialogContentText>
                                    )}
                                    {imageSrc && (
                                        <DialogContentText component="div" align={align}>
                                            <div className={classes.geofenceTooltip}>
                                                <img alt="geofence" src="/images/geofence-tooltip.png" />
                                            </div>
                                        </DialogContentText>
                                    )}
                                </DialogContent>
                            </Paper>
                        </Grid>
                        <Grid item={true} style={{ alignSelf: 'center' }}>
                            <div ref={this.setArrowRef} className={classes.arrow} style={arrowStyle} />
                        </Grid>
                    </Grid>
                </ClickAwayListener>
            </Popper>
        );
    };

    private dismissOnClick = () => {
        if (isMobile) {
            this.setState({ showTooltip: false });
        }
    };

    private setArrowRef = (node) => {
        this.setState({ arrowRef: node });
    };

    private onShowTooltip = (event: any) => {
        const { currentTarget } = event;
        const { children, page, id, mixpanel } = this.props;
        const text = Array.isArray(children) ? page : children;

        this.setState({ showTooltip: true, anchorRef: currentTarget });
        mixpanel.actions.clickedTooltip(id, page, text);
    };

    private getLayout = () => {
        const placement = this.props.placement ? this.props.placement : 'left';
        let popperStyle: any = {
            zIndex: 5000,
            maxWidth: isMobile ? '75%' : '400px',
            paddingRight: 12,
            paddingLeft: 0,
        };
        let containerStyle: any = {
            flexWrap: 'unset',
            flexDirection: 'row',
        };
        let arrowStyle: any = {
            transform: 'rotate(-135deg)',
        };

        if (placement === 'right') {
            popperStyle = {
                ...popperStyle,
                paddingRight: 0,
                paddingLeft: 12,
            };
            containerStyle = {
                ...containerStyle,
                flexDirection: 'row-reverse',
            };
            arrowStyle = {
                ...arrowStyle,
                transform: 'rotate(45deg)',
            };
        }

        if (placement === 'bottom') {
            popperStyle = {
                ...popperStyle,
                maxWidth: '97%',
                paddingTop: 12,
                paddingRight: 0,
                paddingLeft: 0,
            };
            containerStyle = {
                ...containerStyle,
                flexDirection: 'column-reverse',
            };
            arrowStyle = {
                ...arrowStyle,
                transform: 'rotate(135deg)',
            };
        }

        return {
            popperStyle,
            containerStyle,
            arrowStyle,
        };
    };
}

const mapStoreToProps = (state: any) => {
    return {
        mixpanel: state.mixpanel,
    };
};

export default connect(
    mapStoreToProps,
    null
)(withStyles(appStyle)(Tooltip));
