import MarkerWithLabel from '@google/markerwithlabel';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography, withStyles } from '@material-ui/core';
import { Check, Clear } from '@material-ui/icons';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import appStyle from '../app/App.style';
import Text from './Text';
import Tooltip from './Tooltip';

interface IProps {
    classes: any;
    institutions: any;
    userInfo: any;
    style?: object;
    className?: any;
    hideHeading?: boolean;
    hideAddress?: boolean;
    hideRequest?: boolean;
    page: string;
    mixpanel: any;
    schoolId: number;
    showRequestDialog?: boolean;
    onHideRequest?: any;
    creatorID: string;
}

interface IState {
    institution: any;
    request: string;
    showRequestDialog: boolean;
    showConfirmDialog: boolean;
}

class InstitutionMap extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            institution: null,
            request: '',
            showRequestDialog: false,
            showConfirmDialog: false,
        };
    }

    public componentDidMount() {
        const { institutions, schoolId } = this.props;

        if (schoolId) {
            institutions.actions.getInstitution(schoolId).then((institution) => {
                this.setState({ institution }, () => this.createMap());
            });
        }
    }

    public componentDidUpdate(prevProps: IProps) {
        const { institutions, showRequestDialog, schoolId } = this.props;

        if (showRequestDialog !== prevProps.showRequestDialog) {
            this.setState({ showRequestDialog });
        }

        if (schoolId !== prevProps.schoolId) {
            institutions.actions.getInstitution(schoolId).then((institution) => {
                this.setState({ institution }, () => this.createMap());
            });
        }
    }

    public render() {
        const { classes, className, hideAddress, hideHeading, hideRequest, page, style } = this.props;
        const { institution } = this.state;

        return (
            <Grid container={true} className={className} style={style} direction="column">
                {institution && (
                    <Grid container={true} className={classes.institutionMapTextWrap} direction="column" style={{ marginTop: hideHeading ? 0 : 16 }}>
                        {!hideHeading && (
                            <React.Fragment>
                                <Grid container={true} item={true} justifyContent="space-between" wrap="nowrap">
                                    <Text fontSize={16} semiBold={true}>
                                        Confirm your academic buildings are covered by the yellow boundary
                                    </Text>
                                    <Tooltip page={page} id="schoolName" align="center">
                                        <p>
                                            We use a “geofence” to make sure students are on campus so. This is to prevent students from earning your rewards
                                            while off campus.
                                        </p>
                                        <p>The app will not work for your students if the geofence does not cover your school.</p>
                                    </Tooltip>
                                </Grid>
                                <Grid container={true} item={true} justifyContent="center">
                                    <Grid container={true} item={true} xs={12} sm={6} justifyContent="space-between" style={{ margin: 24 }}>
                                        <div style={{ width: 122, textAlign: 'center' }}>
                                            <Check style={{ color: '#44DA55', fontSize: 30 }} />
                                        </div>
                                        <div style={{ width: 122, textAlign: 'center' }}>
                                            <Clear style={{ color: '#D63535', fontSize: 30 }} />
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid container={true} item={true} justifyContent="center">
                                    <Grid container={true} item={true} xs={12} sm={6} justifyContent="space-between" style={{ margin: 16, marginTop: 0 }}>
                                        <img alt="geofence good" src="/images/geofence-tooltip-good.png" height={109} width={122} />
                                        <img alt="geofence bad" src="/images/geofence-tooltip-bad.png" height={109} width={122} />
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        )}
                        {!hideAddress && (
                            <Typography
                                gutterBottom={true}
                                className="lightText"
                            >{`${institution.address.line_1}, ${institution.address.city}, ${institution.address.state} ${institution.address.zip}`}</Typography>
                        )}
                    </Grid>
                )}
                <Grid container={true} item={true} xs={12}>
                    {isMobile && <div style={{ height: isMobile ? 307 : 400 }} />}
                    <div
                        id={`institution-map-${this.props.creatorID}`}
                        style={{ position: isMobile ? 'absolute' : 'relative', height: isMobile ? 307 : 400, left: 0, right: 0, width: '100%' }}
                    />
                </Grid>
                {!hideRequest && (
                    <Grid container={true} className={classes.institutionMapTextWrap}>
                        <Typography color="primary" onClick={() => this.toggleRequestDialog(true)} style={{ cursor: 'pointer', fontWeight: 500, marginTop: 6 }}>
                            Click here if your boundary is off
                        </Typography>
                    </Grid>
                )}
                {this.renderDialog()}
            </Grid>
        );
    }

    private renderDialog = () => {
        const { request, showRequestDialog, showConfirmDialog } = this.state;

        return (
            <Dialog open={showRequestDialog || showConfirmDialog} maxWidth="xs" fullWidth={true} PaperProps={{ style: { margin: 10 } }}>
                <DialogTitle style={{ textAlign: 'center' }}>
                    {showRequestDialog
                        ? 'Please describe the issue with your school boundary'
                        : 'Thank you. We will email you within 3 - 5 business days once your boundary is updated.'}
                </DialogTitle>
                <DialogContent>
                    {showRequestDialog && (
                        <TextField
                            fullWidth={true}
                            placeholder="e.g. missing academic buildings"
                            autoFocus={true}
                            value={request}
                            onChange={this.onRequestChange}
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.toggleRequestDialog(false)} color="primary">
                        Cancel
                    </Button>
                    {showRequestDialog && (
                        <Button onClick={this.requestUpdateSchool} color="primary" autoFocus={true}>
                            Submit
                        </Button>
                    )}
                </DialogActions>
            </Dialog>
        );
    };

    private toggleRequestDialog = (show) => {
        const { onHideRequest } = this.props;

        this.setState({ showRequestDialog: show, showConfirmDialog: false }, () => {
            if (!this.state.showRequestDialog && onHideRequest) {
                onHideRequest();
            }
        });
    };

    private onRequestChange = (event) => {
        const {
            target: { value },
        } = event;

        this.setState({ request: value });
    };

    private createMap = () => {
        const { point, fences, title } = this.state.institution;
        const center = { lat: point._lat, lng: point._long };
        const element = document.getElementById(`institution-map-${this.props.creatorID}`);
        const map = new google.maps.Map(element, {
            center,
            zoom: 15,
            disableDefaultUI: true,
            zoomControl: true,
            zoomControlOptions: {
                position: google.maps.ControlPosition.LEFT_TOP,
            },
        });
        const marker = new MarkerWithLabel({
            icon: {
                path:
                    'M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z',
                fillColor: '#E8443C',
                fillOpacity: 1,
                anchor: new google.maps.Point(12, 22),
                strokeWeight: 0,
            },
            position: center,
            // labelContent: title,
            labelAnchor: new google.maps.Point(-16, 18),
            labelStyle: { color: '#E8443C', 'font-size': '14px' },
        });

        marker.setMap(map);
        map.setMapTypeId('satellite');
        map.set('styles', [
            {
                featureType: 'poi',
                stylers: [{ visibility: 'off' }],
            },
            {
                featureType: 'transit',
                stylers: [{ visibility: 'off' }],
            },
            {
                featureType: 'administrative',
                stylers: [{ visibility: 'off' }],
            },
        ]);

        if (fences) {
            Object.keys(fences)
                .filter((fenceKey) => fences[fenceKey].enabled === 1)
                .forEach((fenceKey) => {
                    const { vertices } = fences[fenceKey];
                    const paths = vertices.map((verticy) => {
                        const { _lat, _long } = verticy;

                        return { lat: _lat, lng: _long };
                    });
                    const polygon = new google.maps.Polygon({
                        paths,
                        fillColor: '#E3E46C',
                        fillOpacity: 0.7,
                        strokeWeight: 0,
                    });

                    polygon.setMap(map);
                });
        }
    };

    private requestUpdateSchool = () => {
        const { institutions, mixpanel, schoolId } = this.props;
        const { institution, request } = this.state;

        this.setState({ showRequestDialog: false, showConfirmDialog: true }, () => {
            institutions.actions
                .createUpdateSchoolRequest({
                    request,
                    schoolId,
                    schoolName: institution.title,
                    institutionId: institution.id,
                })
                .then(() => {
                    this.setState({ request: '' }, () => {
                        mixpanel.sentGeofenceFeedback();
                    });
                });
        });
    };
}

const mapStoreToProps = (state: any) => {
    return {
        institutions: state.institutions,
        userInfo: state.user.data.userInfo,
        mixpanel: state.mixpanel.actions,
    };
};

export default compose<IProps, any>(
    connect(
        mapStoreToProps,
        null
    ),
    withStyles(appStyle)
)(InstitutionMap);
