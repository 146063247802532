import { Button, Grid, IconButton, Menu, MenuItem, Tab, Tabs, Toolbar, Typography } from '@material-ui/core';
import MaterialAppBar from '@material-ui/core/AppBar';
import withStyles from '@material-ui/core/styles/withStyles';
import { AccountCircle, ChevronRight } from '@material-ui/icons';
import queryString from 'query-string';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Text from '../components/Text';
import * as routes from '../utils/routes';
import appStyle from './App.style';
import AppShareModal from './AppShareModal';
import EditAccount from './Auth/EditAccount';
import EditEmail from './Auth/EditEmail';

interface IProps extends RouteComponentProps {
    classes: any;
    user?: any;
    mixpanel?: any;
    groups?: any;
}

interface IState {
    auth: boolean;
    anchorEl: null | any;
    editAccount: boolean;
    editEmail: boolean;
    tabValue: string;
    showShare: boolean;
}

class AppBar extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        console.info(this.getTab(props));

        this.state = {
            auth: true,
            anchorEl: null,
            editAccount: false,
            editEmail: false,
            tabValue: this.getTab(props),
            showShare: false,
        };
    }

    public componentDidMount() {
        const {
            history: { location },
        } = this.props;
        const params = queryString.parse(location.search);

        if (params && params.email_settings) {
            this.setState({ editEmail: true });
        }

        if (params && params.home_share) {
            this.setState({ showShare: true });
        }
    }

    public render() {
        const { classes, user } = this.props;
        const authed = !!user.data.authUser;
        const showOnboarding = user.data.userInfo && user.data.userInfo.modalStates.showOnboarding;
        const { anchorEl, editAccount, editEmail, showShare } = this.state;
        const open = Boolean(anchorEl);
        const logo = `/images/${process.env.REACT_APP_LOGO}`;

        return (
            <React.Fragment>
                <AppShareModal open={showShare} onClose={() => this.toggleShareModal(false)} onSharedWith={this.onSharedWith} />
                {authed && (
                    <Toolbar variant="dense" className={classes.sharingBanner}>
                        <EditAccount fullScreen={isMobile} showing={editAccount} onClose={this.alertClosed} />
                        <EditEmail fullScreen={isMobile} showing={editEmail} onClose={this.alertClosed} />
                        <Grid container={true} justifyContent="center" wrap="nowrap" alignItems="center" onClick={() => this.toggleShareModal(true)}>
                            <Text bold={true} color="secondary" fontSize={13}>
                                {!isMobile && 'NEW! mobile app - easily manage your rewards on the go'}
                                {isMobile && 'NEW! mobile app'}
                                {!isMobile && <span style={{ paddingLeft: 15, fontWeight: 400 }}>Get the new teacher app</span>}
                            </Text>
                            <ChevronRight style={{ color: '#ffffff' }} />
                        </Grid>
                    </Toolbar>
                )}
                <MaterialAppBar position="static" style={{ boxShadow: 'none', backgroundColor: '#F3F3F3', borderBottom: '1px solid #EBEBEB' }}>
                    <Toolbar style={{ alignItems: 'center', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <img alt="logo" src={logo} style={{ height: 25 }} />
                            {!isMobile && (
                                <Typography variant="h6" color="inherit" style={{ fontWeight: 'bold', paddingLeft: 8 }}>
                                    Pocket Points
                                </Typography>
                            )}
                        </div>
                        {!isMobile && this.renderTabs()}
                        {authed && (
                            <div>
                                <Button size="small" onClick={() => this.openHelpCenter()} style={{ textTransform: 'none' }}>
                                    Help Center
                                </Button>
                                <IconButton aria-owns={open ? 'menu-appbar' : undefined} aria-haspopup="true" onClick={this.handleMenu} color="inherit">
                                    <AccountCircle />
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={open}
                                    onClose={this.handleClose}
                                >
                                    {!showOnboarding && <MenuItem onClick={this.handleAccount}>My account</MenuItem>}
                                    {!showOnboarding && <MenuItem onClick={this.handleEmail}>Email settings</MenuItem>}
                                    <MenuItem onClick={this.handleLogout}>Logout</MenuItem>
                                    <MenuItem onClick={this.handleTerms}>Terms of Use</MenuItem>
                                    <MenuItem onClick={this.handlePrivacy}>Privacy Policy</MenuItem>
                                </Menu>
                            </div>
                        )}
                    </Toolbar>
                </MaterialAppBar>
                {isMobile && (
                    <Grid container={true} justifyContent="center" style={{ borderBottom: '1px solid #EBEBEB', backgroundColor: '#fff' }}>
                        <Grid item={true}>{this.renderTabs()}</Grid>
                    </Grid>
                )}
            </React.Fragment>
        );
    }

    private renderTabs = () => {
        const { user, classes } = this.props;
        const showOnboarding = user.data.userInfo && user.data.userInfo.modalStates ? user.data.userInfo.modalStates.showOnboarding : false;
        const { tabValue } = this.state;

        if (!user.data.authUser || showOnboarding) {
            return null;
        }

        return (
            <Tabs value={tabValue} onChange={this.handleTabChange} indicatorColor="primary" textColor="primary" centered={true}>
                <Tab value="dashboard" disableRipple={true} label="Dashboard" classes={{ root: classes.tabRoot }} />
                <Tab value="groups" disableRipple={true} label="Classes" classes={{ root: classes.tabRoot }} />
                <Tab
                    value="school"
                    disableRipple={true}
                    label={"School"}
                    classes={{ root: classes.tabRoot }}
                    style={{ paddingRight: 20 }}
                />
            </Tabs>
        );
    };

    private handleClose = () => {
        this.setState({ anchorEl: null });
    };

    private handleMenu = (event: any) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    private handleAccount = () => {
        this.setState({ editAccount: true, editEmail: false, anchorEl: null });
    };

    private handleEmail = () => {
        this.setState({ editAccount: false, editEmail: true, anchorEl: null });
    };

    private handleLogout = () => {
        this.handleClose();
        const { user } = this.props;
        user.actions.logout();
    };

    private handleTabChange = (event: any, value: any) => {
        const { history } = this.props;

        this.setState({ tabValue: value }, () => {
            history.replace(routes[value]);
        });
    };

    private alertClosed = () => {
        this.setState({ editAccount: false, editEmail: false });
    };

    private handleTerms = () => {
        window.open('https://pocketpoints.com/terms', '_blank');
    };

    private handlePrivacy = () => {
        window.open('https://rewards.pocketpoints.com/privacy', '_blank');
    };

    private openHelpCenter = () => {
        const { mixpanel } = this.props;
        if (mixpanel) {
            mixpanel.actions.clickedHelpCenter();
        }
        window.open('https://intercom.help/portal-c4c52318881b/help-articles', '_blank');
    };

    private toggleShareModal = (showShare) => {
        const { mixpanel } = this.props;

        this.setState({ showShare }, () => {
            if (showShare) {
                mixpanel.actions.clickedFamilyShare();
            }
        });
    };

    private onSharedWith = (channel) => {
        const { mixpanel } = this.props;

        return Promise.resolve().then(() => {
            mixpanel.actions.shared(channel);
        });
    };

    private getTab = ({ location }: any) => {
        const path = location && location.pathname ? location.pathname : 'dashboard';

        if (path.includes('group')) {
            return 'groups';
        }

        if (path.includes('school')) {
            return 'school';
        }

        return 'dashboard';
    };
}

const mapStoreToProps = (state: any): any => {
    return {
        user: state.user,
        mixpanel: state.mixpanel,
        groups: state.groups,
    };
};

export default withRouter(
    connect(
        mapStoreToProps,
        null
    )(withStyles(appStyle)(AppBar))
);
