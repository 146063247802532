import { combineReducers, Store } from 'redux';
import firebase from '../libraries/firestore';
import Check from './Check';
import Dashboard from './Dashboard';
import EarningWindow from './EarningWindow';
import GroupProgresses from './GroupProgresses';
import Groups from './Groups';
import IncentiveProgress from './IncentiveProgress';
import Institutions from './Institutions';
import Mixpanel from './Mixpanel';
import Requests from './Requests';
import Searches from './Searches';
import Teachers from './Teachers';
import User from './User';

export default class Reactions {
    private user: any;
    private groups: any;
    private incentiveProgress: any;
    private searches: any;
    private mixpanel: any;
    private dashboard: any;
    private institutions: any;
    private check: any;
    private requests: any;
    private earningWindow: any;
    private groupProgresses: any;
    private teachers: any;

    constructor() {
        this.user = new User(/**firebase*/);
        this.groups = new Groups(/**firebase*/);// breakpoint 1. working code. TODO test below implementation when data exists. Sept 2022
        this.incentiveProgress = new IncentiveProgress(/**firebase*/)
        this.searches = new Searches(/**firebase*/)
        this.mixpanel = new Mixpanel();
        this.dashboard = new Dashboard(/**firebase*/)
        this.institutions = new Institutions(/**firebase*/)
        this.check = new Check(/**firebase*/)
        this.requests = new Requests(/**firebase*/)
        this.earningWindow = new EarningWindow(/**firebase*/)
        this.groupProgresses = new GroupProgresses(/**firebase*/)
        this.teachers = new Teachers(firebase);
    }

    public getReducers = () =>
        combineReducers({
            user: this.user.reducer,
            groups: this.groups.reducer,
            incentiveProgress: this.incentiveProgress.reducer,
            searches: this.searches.reducer,
            mixpanel: this.mixpanel.reducer,
            dashboard: this.dashboard.reducer,
            institutions: this.institutions.reducer,
            check: this.check.reducer,
            requests: this.requests.reducer,
            earningWindow: this.earningWindow.reducer,
            groupProgresses: this.groupProgresses.reducer,
            teachers: this.teachers.reducer,
        });

    public setStore = (store: Store) => {
        this.user.setStore(store);
        this.groups.setStore(store);
        this.incentiveProgress.setStore(store);
        this.searches.setStore(store);
        this.mixpanel.setStore(store);
        this.institutions.setStore(store);
        this.requests.setStore(store);
        this.earningWindow.setStore(store);
        this.groupProgresses.setStore(store);
        this.teachers.setStore(store);
    };

    public init = () => {
        return Promise.all([this.user.init()]);
    };
}
