export const IncentiveSuggestionsData = [
    { title: 'Extra Credit', subTitle: '15 hours off the phone in class', duration: 54000 },
    { title: 'Free Snack', subTitle: '5 hours off the phone in class', duration: 18000 },
    { title: 'Homework Pass', subTitle: '10 hours off the phone in class', duration: 36000 },
    { title: 'Drop Lowest Quiz Score', subTitle: '30 hours off the phone in class', duration: 108000 },
    { title: 'Candy', subTitle: '2 hours off the phone in class', duration: 7200 },
    { title: 'Tardy Pass', subTitle: '8 hours off the phone in class', duration: 28800 },
    { title: 'Skip Warmup', subTitle: '4 hours off the phone in class', duration: 14400 },
    { title: 'Extra Hall Pass', subTitle: '3 hours off the phone in class', duration: 10800 },
    { title: 'Bag of Chips', subTitle: '6 hours off the phone in class', duration: 21600 },
    { title: 'Bathroom Pass', subTitle: '5 hours off the phone in class', duration: 18000 },
    { title: 'Movie Day (Each Student Must Earn)', subTitle: '10 hours off the phone in class', duration: 36000 },
    { title: 'Ask a Yes or No Question On A Test', subTitle: '8 hours off the phone in class', duration: 28800 },
    { title: 'Crazy Science Demonstration ', subTitle: '40 hours off the phone in class', duration: 144000 },
    { title: 'Late Homework Pass', subTitle: '3 hours off the phone in class', duration: 10800 },
    { title: 'Funny Youtube Video', subTitle: '5 hours off the phone in class', duration: 18000 },
    { title: 'Retake A Quiz', subTitle: '15 hours off the phone in class', duration: 54000 },
    { title: 'Gum', subTitle: '3 hours off the phone in class', duration: 10800 },
    { title: 'Choose Your Seat', subTitle: '1 hours off the phone in class', duration: 3600 },
    { title: 'Add 10% to a Test Grade', subTitle: '20 hours off the phone in class', duration: 72000 },
    { title: 'Spirit Buck', subTitle: '8 hours off the phone in class', duration: 28800 },
    { title: 'Eat In Class', subTitle: '3 hours off the phone in class', duration: 10800 },
    { title: 'Drop A Tardy', subTitle: '7 hours off the phone in class', duration: 25200 },
    { title: 'Redo Homework', subTitle: '5 hours off the phone in class', duration: 18000 },
    { title: 'Coffee In Class', subTitle: '3 hours off the phone in class', duration: 10800 },
    { title: 'Phone Time At End Of Class', subTitle: '50 minutes hours off the phone in class', duration: 3000 },
    { title: 'Cookies', subTitle: '12 hours off the phone in class', duration: 43200 },
    { title: 'Participation Points', subTitle: '15 hours off the phone in class', duration: 54000 },
    { title: '10 Minute Uninterrupted Nap', subTitle: '5 hours off the phone in class', duration: 18000 },
    { title: 'Reading Day', subTitle: '10 hours off the phone in class', duration: 36000 },
    { title: 'Free Time', subTitle: '5 hours off the phone in class', duration: 18000 },
    { title: 'Pizza Party (Every Student Must Earn)', subTitle: '20 hours off the phone in class', duration: 72000 },
    { title: 'Sit In Comfy Chair', subTitle: '10 hours off the phone in class', duration: 36000 },
    { title: '-200 Word Count On Essay', subTitle: '12 hours off the phone in class', duration: 43200 },
    { title: 'Test Correction', subTitle: '20 hours off the phone in class', duration: 72000 },
    { title: 'Extra Essay Revision', subTitle: '10 hours off the phone in class', duration: 36000 },
    { title: 'Wear Hat In Class', subTitle: '5 hours off the phone in class', duration: 18000 },
    { title: 'Leave Early', subTitle: '18 hours off the phone in class', duration: 64800 },
    { title: '3 Twizzlers', subTitle: '4 hours off the phone in class', duration: 14400 },
    { title: 'Couch For A Day', subTitle: '5 hours off the phone in class', duration: 18000 },
    { title: 'Add A + Or Take Away A -', subTitle: '25 hours off the phone in class', duration: 90000 },
    { title: '1 PBIS Point', subTitle: '5 hours off the phone in class', duration: 18000 },
    { title: 'Trade Your Essay Prompt', subTitle: '30 hours off the phone in class', duration: 108000 },
    { title: 'Donuts (Every Student Must Earn)', subTitle: '25 hours off the phone in class', duration: 90000 },
];
