import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { CardGiftcard, ChevronLeft, ChevronRight, } from '@material-ui/icons';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import AlertDialog from '../../../components/Alert';
import CloseButton from '../../../components/CloseButton';
import Submit from '../../../components/Submit';
import Text from '../../../components/Text';
import appStyle from '../../App.style';
import GroupIncentiveCell from '../Cells/GroupIncentiveCell';
import IncentiveCell from '../Cells/IncentiveCell';

interface IProps {
    classes: any;
    actions: any;
    groups: any;
    showing: boolean;
    onClose: any;
    onCreateRewardsOption: any;
    showListDefault: boolean;
    groupId: string;
}

interface IState {
    showList: boolean;
    formLoading: boolean;
    incentives: any[];
    alert: any;
}

class CopyIncentivesModal extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            showList: props.showListDefault,
            incentives: this.getIncentiveData(),
            formLoading: false,
            alert: {
                showing: false,
            },
        };
    }

    public componentDidUpdate(prevProps: IProps) {
        if (this.props.showing !== prevProps.showing) {
            this.setState({
                showList: this.props.showListDefault,
            });
        }
    }

    public render() {
        const { showing } = this.props;
        const { alert, incentives, showList } = this.state;
        const incentivesToCopy = incentives.length > 0;

        return (
            <div>
                <AlertDialog alert={alert} onClose={this.alertClosed} />
                <Dialog
                    fullScreen={isMobile}
                    open={showing}
                    onClose={() => {
                        this.handleClose(0);
                    }}
                    aria-labelledby="responsive-dialog-title"
                    maxWidth="sm"
                    fullWidth={true}
                >
                    <DialogTitle style={{ paddingBottom: 2 }}>{this.renderCloseButton()}</DialogTitle>
                    <DialogContent>
                        {!incentivesToCopy && this.renderEmptyState()}
                        {incentivesToCopy && this.renderContent()}
                    </DialogContent>
                    {showList && incentives.length > 3 && (
                        <div
                            style={{
                                width: '100%',
                                height: 60,
                                position: 'absolute',
                                bottom: 80,
                                backgroundImage: 'linear-gradient(to bottom, transparent, white)',
                            }}
                        />
                    )}
                    <DialogActions>{showList && this.renderCopyList()}</DialogActions>
                </Dialog>
            </div>
        );
    }

    private renderEmptyState() {
        const { classes, onCreateRewardsOption } = this.props;
        const style = {
            width: '100%',
            paddingTop: 32,
            backgroundImage: isMobile ? 'none' : 'linear-gradient(to bottom, transparent, white)',
        };

        return (
            <Grid container={true} direction="column" justifyContent="center" alignItems="center" style={{ paddingLeft: 28, paddingRight: 28, height: '100%' }}>
                <Grid item={true}>
                    <CardGiftcard style={{ opacity: 0.04, width: 56, height: 56 }} />
                </Grid>
                <Grid item={true}>
                    <Typography className={classes.pageTitle} style={{ textAlign: 'center', paddingTop: 32 }}>
                        You do not have any rewards to copy
                    </Typography>
                </Grid>
                <Grid item={true}>
                    <Typography style={{ opacity: 0.5, fontSize: 14, fontWeight: 300, textAlign: 'center', paddingTop: 28 }}>
                        Create a few rewards so you can copy them for other classes.
                    </Typography>
                </Grid>
                <div style={style}>
                    <Submit loading={false} style={{ margin: 0 }} buttonStyle={{ marginTop: 0 }} onClick={onCreateRewardsOption}>
                        Create Reward
                    </Submit>
                </div>
            </Grid>
        );
    }

    private renderContent() {
        const { classes } = this.props;
        const { showList } = this.state;
        const title = showList ? 'Choose which rewards you want to copy' : 'Do you want to use the same rewards you already created?';
        const subTitle = showList
            ? 'Uncheck any rewards you do not want to copy over to your new class'
            : 'Most teachers use the same rewards for all their classes.';
        const style = isMobile ? { paddingBottom: 0 } : { paddingBottom: showList ? 42 : 0 };

        return (
            <Grid container={true} justifyContent="center" alignItems="center" style={style}>
                <Grid item={true} xs={12} className={classes.authContainer}>
                    <Typography component="h1" className={classes.pageTitle}>
                        {title}
                    </Typography>
                </Grid>
                <Grid item={true} xs={12} className={classes.authContainer} style={{ paddingTop: 16 }}>
                    <Typography component="h1" className={classes.pageSubTitle}>
                        {subTitle}
                    </Typography>
                </Grid>
                <Grid container={true} direction="column" style={{ paddingTop: 16, paddingBottom: 32 }} justifyContent="flex-start">
                    {showList && this.renderIncentiveList()}
                    {!showList && this.renderOptions()}
                </Grid>
            </Grid>
        );
    }

    private renderCloseButton() {
        const { showListDefault } = this.props;
        const { showList } = this.state;
        if (showListDefault || !showList) {
            return (
                <CloseButton
                    onClick={() => {
                        this.handleClose(0);
                    }}
                />
            );
        } else {
            return (
                <ChevronLeft
                    onClick={() => {
                        this.setState({ showList: false });
                    }}
                    style={{ cursor: 'pointer' }}
                />
            );
        }
    }

    private renderOptions() {
        const { onCreateRewardsOption } = this.props;

        return (
            <React.Fragment>
                <Grid style={{ paddingBottom: 16 }} item={true} xs={12} onClick={this.handleOnClickList}>
                    <Button
                        variant="outlined"
                        fullWidth={true}
                        onClick={this.handleOnClickList}
                        style={{ marginTop: 16, justifyContent: 'space-between', textTransform: 'none', height: 82 }}
                    >
                        <Text fontSize={16} light={true}>
                            Yes, copy my rewards
                        </Text>
                        <ChevronRight style={{ color: '#979797' }} />
                    </Button>
                    <Button
                        variant="outlined"
                        fullWidth={true}
                        onClick={onCreateRewardsOption}
                        style={{ marginTop: 16, justifyContent: 'space-between', textTransform: 'none', height: 82 }}
                    >
                        <Text fontSize={16} light={true}>
                            No, I'll create new rewards
                        </Text>
                        <ChevronRight style={{ color: '#979797' }} />
                    </Button>
                </Grid>
            </React.Fragment>
        );
    }

    private renderCopyList() {
        const { formLoading } = this.state;
        const style = { width: '100%', paddingTop: isMobile ? 24 : 0, paddingBottom: 16 };

        return (
            <div style={style}>
                <Submit
                    loading={formLoading}
                    style={{ margin: 0 }}
                    buttonStyle={{ marginTop: 0 }}
                    loadingButtonStyle={{ backgroundColor: '#E0E0E0' }}
                    onClick={this.handleSubmit}
                >
                    Continue
                </Submit>
            </div>
        );
    }

    private renderIncentiveList() {
        const { incentives } = this.state;

        return incentives.map((incentive, index) => {
            const { id, selected, type } = incentive;

            return (
                <div key={index} style={{ marginTop: 16 }}>
                    {type === 'incentive' && (
                        <IncentiveCell id={id} incentive={incentive} fullWidth={true} selected={selected} onClick={() => this.handleSelectIncentive(index)} />
                    )}
                    {type === 'groupIncentive' && (
                        <GroupIncentiveCell
                            id={id}
                            groupIncentive={incentive}
                            fullWidth={true}
                            selected={selected}
                            onClick={() => this.handleSelectIncentive(index)}
                        />
                    )}
                </div>
            );
        });
    }

    private getIncentiveData = () => {
        const { groups } = this.props;
        const incentives = Object.keys(groups).reduce((accumulator: any, groupKey: string) => {
            const group = groups[groupKey];

            if (group.incentives) {
                Object.keys(group.incentives).forEach((incentiveKey) => {
                    accumulator.push({ type: 'incentive', selected: false, id: incentiveKey, ...group.incentives[incentiveKey] });
                });
            }

            if (group.groupIncentives) {
                Object.keys(group.groupIncentives).forEach((groupIncentiveKey) => {
                    accumulator.push({ type: 'groupIncentive', selected: false, id: groupIncentiveKey, ...group.groupIncentives[groupIncentiveKey] });
                });
            }

            return accumulator;
        }, []);

        return incentives;
    };

    private handleSelectIncentive = (index) => {
        const incentives = [...this.state.incentives];

        incentives[index].selected = !incentives[index].selected;

        this.setState({ incentives });
    };

    private handleOnClickList = () => {
        this.setState({
            showList: true,
        });
    };

    private handleClose = (numCreated: number) => {
        const { showList } = this.state;
        this.props.onClose(false, numCreated, showList);
    };

    private alertClosed = () => {
        this.setState({ alert: { showing: false } });
    };

    private handleSubmit = () => {
        const { incentives } = this.state;
        const incentivesToCopy = incentives
            .filter((incentive) => incentive.selected)
            .map((incentive) => {
                delete incentive.selected;

                return incentive;
            });

        if (incentivesToCopy.length === 0) {
            this.setState({
                alert: {
                    showing: true,
                    title: 'Sorry',
                    message: 'You must select at least one reward to copy.',
                },
            });

            return;
        }

        this.setState({ formLoading: true }, () => {
            const { actions, groupId } = this.props;

            actions.groups
                .createMultipleIncentives({
                    incentives: incentivesToCopy,
                    groupId,
                })
                .then(() => {
                    this.handleClose(incentivesToCopy.length);
                })
                .catch(() => {
                    this.setState({
                        formLoading: false,
                        alert: {
                            showing: true,
                            title: 'Sorry',
                            message: 'Something went wrong. Try again',
                        },
                    });
                });
        });
    };
}

const mapStoreToProps = (state: any) => {
    return {
        actions: {
            groups: state.groups.actions,
        },
        groups: state.groups.data,
    };
};

export default connect(
    mapStoreToProps,
    null
)(withStyles(appStyle)(withMobileDialog<IProps>()(CopyIncentivesModal)));
