import { FormControl, Grid, Typography } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import React from 'react';
import Tooltip from '../../../components/Tooltip';
import appStyle from '../../App.style';

interface IProps {
    requiredStudents: number;
    totalStudents: number;
    handleRequiredStudentsChanged: any;
    page: string;
    handleHighPercentageAlert: any;
}

interface IState {
    initialPercentage: number;
    initialValue: number;
}

class ClasswideStudentsSlider extends React.PureComponent<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        const { requiredStudents, totalStudents } = this.props;

        this.state = {
            initialPercentage: Math.round((requiredStudents / totalStudents) * 100),
            initialValue: requiredStudents,
        };
    }

    public render() {
        const { requiredStudents, handleRequiredStudentsChanged, page, totalStudents, handleHighPercentageAlert } = this.props;
        const { initialPercentage, initialValue } = this.state;
        const percentOf = Math.round((requiredStudents / totalStudents) * 100);

        return (
            <React.Fragment>
                <FormControl margin="normal" fullWidth={true} style={{ textAlign: 'center', paddingTop: 16 }}>
                    <Grid container={true} wrap="nowrap">
                        <Grid item={true} style={{ flex: 1 }}>
                            <Typography style={{ opacity: 0.5 }} align="left">
                                How many students in your class need to reach the goal?
                            </Typography>
                        </Grid>
                        <Grid item={true}>
                            <Tooltip page={page} id="rewardDuration">
                                Choose the percentage of class that needs to participate. The percentage is based on the total amount of students in your class,
                                not only students using Pocket Points.
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <Slider
                        min={1}
                        max={totalStudents > 1 ? totalStudents : 1}
                        step={1}
                        value={requiredStudents}
                        defaultValue={requiredStudents}
                        onChange={handleRequiredStudentsChanged}
                        trackStyle={[{ height: 5, backgroundColor: '#D8D8D8' }]}
                        handleStyle={[{ height: 37, width: 37, marginTop: -15, borderColor: '#979797' }]}
                        style={{ marginTop: 25, width: '95%' }}
                        onAfterChange={() => handleHighPercentageAlert({ initialPercentage, newPercentage: percentOf, initialValue }, 'studentsSlider')}
                    />
                    <Grid container={true} wrap="nowrap" justifyContent="space-between" style={{ marginTop: 14 }}>
                        <Typography style={{ opacity: 0.5 }}>0%</Typography>
                        <Typography style={{ opacity: 0.5 }}>50%</Typography>
                        <Typography style={{ opacity: 0.5 }}>100%</Typography>
                    </Grid>
                    <Typography style={{ paddingTop: 24 }}>{`${percentOf}% of Class - ${requiredStudents} Students`}</Typography>
                </FormControl>
            </React.Fragment>
        );
    }
}

export default withStyles(appStyle)(ClasswideStudentsSlider);
