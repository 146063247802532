import moment from 'moment';
import { Store } from 'redux';
import { createAction, handleActions } from 'redux-actions';
import EarningWindow from 'shared-library-js';
// import uuid from 'uuid/v1';
import {v4 as uuidv4} from 'uuid';
import { scrubObject } from '../utils/helper';
import firebase from 'firebase/compat/app';
import firestore from  'firebase/compat/firestore';
import 'firebase/compat/storage';

export interface IGroup {
    inviteCode?: number;
    name: string;
    schoolId: number;
    teacherName: string;
    dow: number;
    timeZone: string;
}

class Groups {
    public actions = {
        getGroups: (uid) => {
            return this.db
                .collection('classrooms')
                .where('portalUsers', 'array-contains', uid)
                .get()
                .then((snapshot) => {
                    const groups = {};
                    const urlRequests: any = [];

                    snapshot.docs.forEach((doc) => {
                        const { appUsers, attributes, incentives, groupIncentives, blockedStudents, students } = doc.data();

                        groups[doc.id] = {
                            appUsers,
                            attributes,
                            incentives,
                            groupIncentives,
                            blockedStudents,
                            students,
                        };

                        Object.keys(students)
                            .filter((key) => {
                                return students[key].pic && students[key].pic.length > 0;
                            })
                            .forEach((key) => {
                                const imageRef = `userProfilePics/${decodeURI(students[key].pic)}`;

                                urlRequests.push(
                                    this.storage
                                        .ref(imageRef)
                                        .getDownloadURL()
                                        .then((result) => (students[key].imageUrl = result))
                                        .catch(() => console.info(`reactions:groups:getGroups:error: missing image ${imageRef}`))
                                );
                            });
                    });

                    return Promise.all(urlRequests).then(() => {
                        this.store.dispatch(this.setGroups(groups));
                        this.store.getState().incentiveProgress.actions.getIncentiveProgress();
                        this.store.getState().groupProgresses.actions.getGroupProgresses(groups ? Object.keys(groups) : null);

                        return groups;
                    });
                })
                .catch((error) => {
                    console.info(`reactions:groups:getGroups:error: ${error}`);
                });
        },
        clearGroups: () => {
            Object.keys(this.activeListeners).forEach((key) => {
                this.activeListeners[key]();
                delete this.activeListeners[key];
            });

            this.store.dispatch(this.clearGroups());
        },
        createGroup: ({ values }) => {
            const { name, groupSize, schoolId, teacherName, window, windowType } = values;
            const {
                user: {
                    data: {
                        authUser: { uid },
                    },
                },
            } = this.store.getState();

            const group = {
                appUsers: [],
                attributes: {
                    name,
                    groupSize: groupSize ? groupSize : 0,
                    schoolId,
                    teacherName,
                    window,
                    windowType,
                },
                groupIncentives: {},
                incentives: {},
                modalStates: {
                    showNewIncentive: true,
                },
                portalUsers: [uid],
                blockedStudents: {},
                students: {},
            };

            return this.db
                .collection('classrooms')
                .add(group)
                .then((doc) => {
                    this.store.getState().mixpanel.actions.createdClass(group, doc.id);

                    return new Promise((resolve) => {
                        const unsubscribe = this.db
                            .collection('classrooms')
                            .doc(doc.id)
                            .onSnapshot((snapshot) => {
                                const data = snapshot.data();
                                const { inviteCode } = data.attributes;

                                if (inviteCode) {
                                    this.store.dispatch(this.setGroup({ [doc.id]: { ...data } }));
                                    unsubscribe();
                                    resolve(doc.id);
                                }
                            });
                    });
                })
                .catch((error) => {
                    console.info(`reactions:groups:createGroup:error: ${error}`);
                });
        },
        updateGroup: ({ values, groupId }) => {
            const {
                user: {
                    data: {
                        authUser: { uid },
                    },
                },
                earningWindow,
            } = this.store.getState();
            const oldGroup = this.store.getState().groups.data[groupId];
            const { name, groupSize, schoolId, teacherName, window, windowType } = values;
            const group = {
                attributes: {
                    name,
                    groupSize: groupSize ? groupSize : 0,
                    schoolId,
                    teacherName,
                    window,
                    windowType,
                },
                groupIncentives: oldGroup.groupIncentives,
            };
            const oldEarningWindow = new EarningWindow(earningWindow.data, oldGroup);
            const newEarningWindow = new EarningWindow(earningWindow.data, group);

            if (oldGroup.groupIncentives) {
                Object.keys(oldGroup.groupIncentives).forEach((key) => {
                    const groupIncentive = oldGroup.groupIncentives[key];
                    let percent;

                    if (oldGroup.attributes.groupSize !== groupSize) {
                        percent = groupIncentive.required / oldGroup.attributes.groupSize;
                        group.groupIncentives[key].required = Math.round(groupSize * percent);
                    }

                    groupIncentive.iterations.forEach((iteration, index) => {
                        const oldDays = oldEarningWindow.getDaysInWindow(iteration.start, iteration.expiration, true);
                        const oldDurationSeconds = iteration.duration / oldDays;
                        const oldTotalSeconds = oldEarningWindow.getAverageDuration();
                        const oldDurationPercent = oldDurationSeconds / oldTotalSeconds;
                        const newDays = newEarningWindow.getDaysInWindow(iteration.start, iteration.expiration, true);
                        const newTotalSeconds = newEarningWindow.getAverageDuration();
                        const duration = Math.round(newTotalSeconds * newDays * oldDurationPercent);

                        if (!isNaN(duration)) {
                            group.groupIncentives[key].iterations[index].duration = duration;
                        }
                    });
                });
            }

            return this.db
                .collection('classrooms')
                .doc(groupId)
                .set(group, { merge: true })
                .then(() => {
                    return this.db
                        .collection(`classrooms/${groupId}/groupProgresses`)
                        .get()
                        .then((groupProgressesSnapshot) => {
                            const batch = this.db.batch();

                            groupProgressesSnapshot.docs.forEach((doc) => {
                                const { groupIncentiveId, progress } = doc.data();
                                const groupIncentive = group.groupIncentives[groupIncentiveId];
                                const iteration = groupIncentive.iterations.find((iterationItem) => {
                                    return iterationItem.groupProgressesId === doc.id;
                                });

                                if (progress && iteration) {
                                    Object.keys(progress).forEach((key) => {
                                        if (iteration.duration <= progress[key].progress) {
                                            progress[key].duration = iteration.duration;
                                            progress[key].completion = moment().unix();
                                            progress[key].hasViewedCompletion = false;
                                        }
                                    });
                                }

                                batch.update(doc.ref, { required: groupIncentive.required, progress });
                            });

                            return batch.commit().then(() => {
                                this.store.getState().mixpanel.actions.editedClass(oldGroup, group, groupId);

                                return this.actions.getGroups(uid);
                            });
                        });
                })
                .catch((error) => {
                    console.info(`reactions:groups:updateGroup:error: ${error}`);
                });
        },
        updateGroupModalState: ({ modalStates, groupId }) => {
            const {
                user: {
                    data: {
                        authUser: { uid },
                    },
                },
            } = this.store.getState();

            return this.db
                .collection('classrooms')
                .doc(groupId)
                .set({ modalStates }, { merge: true })
                .then(() => {
                    return this.actions.getGroups(uid);
                })
                .catch((error) => {
                    console.info(`reactions:groups:updateGroupModalState:error: ${error}`);
                });
        },
        blockStudentsFromGroup: ({ studentsToBlock, groupId }) => {
            const {
                groups,
                user: {
                    data: {
                        authUser: { uid },
                    },
                },
            } = this.store.getState();
            const oldGroup = groups.data[groupId];
            const appUsers = !oldGroup.appUsers ? [] : oldGroup.appUsers.filter((appUserId) => !studentsToBlock.includes(appUserId));
            const blockedStudents = { ...oldGroup.blockedStudents };
            const students = {};
            const studentsBlocked = [];

            if (oldGroup.students) {
                Object.keys(oldGroup.students).forEach((key) => {
                    if (studentsToBlock.includes(key)) {
                        blockedStudents[key] = oldGroup.students[key];
                        studentsBlocked.push({ name: oldGroup.students[key].name, id: key });
                    } else {
                        students[key] = oldGroup.students[key];
                    }
                });
            }

            return this.db
                .collection('classrooms')
                .doc(groupId)
                .update({
                    appUsers,
                    students,
                    blockedStudents,
                })
                .then(() => {
                    this.store.getState().mixpanel.actions.blockStudentsFromClass(studentsBlocked, groupId);

                    return this.actions.getGroups(uid);
                })
                .catch((error) => {
                    console.info(`reactions:groups:blockStudentsFromGroup:error: ${error}`);
                });
        },
        restoreStudentsFromGroup: ({ studentsToRestore, groupId }) => {
            const {
                groups,
                user: {
                    data: {
                        authUser: { uid },
                    },
                },
            } = this.store.getState();
            const oldGroup = groups.data[groupId];
            const appUsers = [...oldGroup.appUsers, ...studentsToRestore];
            const blockedStudents = {};
            const students = { ...oldGroup.students };
            const studentsRestored = [];

            if (oldGroup.blockedStudents) {
                Object.keys(oldGroup.blockedStudents).forEach((key) => {
                    if (studentsToRestore.includes(key)) {
                        students[key] = oldGroup.blockedStudents[key];
                        studentsRestored.push({ name: oldGroup.blockedStudents[key].name, id: key });
                    } else {
                        blockedStudents[key] = oldGroup.blockedStudents[key];
                    }
                });
            }

            return this.db
                .collection('classrooms')
                .doc(groupId)
                .update({
                    appUsers,
                    students,
                    blockedStudents,
                })
                .then(() => {
                    this.store.getState().mixpanel.actions.restoreStudentsFromClass(studentsRestored, groupId);

                    return this.actions.getGroups(uid);
                })
                .catch((error) => {
                    console.info(`reactions:groups:restoreStudentsFromGroup:error: ${error}`);
                });
        },
        deleteGroup: (groupId) => {
            const {
                groups,
                user: {
                    data: {
                        authUser: { uid },
                    },
                },
            } = this.store.getState();
            const group = groups.data[groupId];

            return this.db
                .collection('classrooms')
                .doc(groupId)
                .delete()
                .then(() => {
                    return this.db
                        .collection(`classrooms/${groupId}/groupProgresses`)
                        .get()
                        .then((groupProgressesSnapshot) => {
                            const batch = this.db.batch();

                            groupProgressesSnapshot.docs.forEach((doc) => {
                                batch.delete(doc.ref);
                            });

                            batch.commit().then(() => {
                                this.store.getState().mixpanel.actions.deletedClass(group, groupId);

                                return this.actions.getGroups(uid);
                            });
                        });
                })
                .catch((error) => {
                    console.info(`reactions:groups:deleteGroup:error: ${error}`);
                });
        },
        createIncentive: ({ values, groupId }, getGroups = true, isCopy = false) => {
            const {
                user: {
                    data: {
                        authUser: { uid },
                    },
                },
            } = this.store.getState();
            const data = scrubObject(values);
            const rewardId = uuidv4();
            const group = {
                incentives: {
                    [rewardId]: { ...data },
                },
            };

            return this.db
                .collection('classrooms')
                .doc(groupId)
                .set(group, { merge: true })
                .then(() => {
                    this.store.getState().mixpanel.actions.createdReward(data, rewardId, groupId, isCopy);

                    if (getGroups) {
                        return this.actions.getGroups(uid);
                    }
                })
                .catch((error) => {
                    console.info(`reactions:groups:createIncentive:error: ${error}`);
                });
        },
        createMultipleIncentives: ({ incentives, groupId }) => {
            const {
                user: {
                    data: {
                        authUser: { uid },
                    },
                },
            } = this.store.getState();
            let creates = Promise.resolve();

            incentives.forEach((incentive) => {
                const values = { ...incentive };

                delete values.id;
                delete values.type;

                if (incentive.type === 'groupIncentive') {
                    creates = creates.then(() => {
                        return this.actions.createGroupIncentive({ values, groupId });
                    });
                } else {
                    creates = creates.then(() => {
                        return this.actions.createIncentive({ values, groupId });
                    });
                }
            });

            return creates
                .then(() => {
                    this.store.getState().mixpanel.actions.copiedRewards(incentives.length);

                    return this.actions.getGroups(uid);
                })
                .catch((error) => {
                    console.info(`reactions:groups:createMultipleIncentive:error: ${error}`);
                });
        },
        updateIncentive: ({ values, groupId, incentiveId }) => {
            const {
                groups,
                user: {
                    data: {
                        authUser: { uid },
                    },
                },
            } = this.store.getState();
            const { incentives } = groups.data[groupId];
            const data = scrubObject(values);
            const group = {
                incentives: {
                    ...incentives,
                    [incentiveId]: { ...data },
                },
            };
            const oldIncentive = incentives[incentiveId];

            return this.db
                .collection('classrooms')
                .doc(groupId)
                .update(group)
                .then(() => {
                    this.store.getState().mixpanel.actions.editedReward(oldIncentive, data, incentiveId, groupId);

                    return this.actions.getGroups(uid);
                })
                .catch((error) => {
                    console.info(`reactions:groups:updateIncentive:error: ${error}`);
                });
        },
        deleteIncentive: (groupId, incentiveId) => {
            const {
                user: {
                    data: {
                        authUser: { uid },
                    },
                },
            } = this.store.getState();
            const group = this.store.getState().groups.data[groupId];
            const incentive = group.incentives[incentiveId];
            const incentives = {};

            Object.keys(group.incentives).forEach((key) => {
                if (key !== incentiveId) {
                    incentives[key] = group.incentives[key];
                }
            });

            return this.db
                .collection('classrooms')
                .doc(groupId)
                .update({ incentives })
                .then(() => {
                    this.store.getState().mixpanel.actions.deletedReward(incentive, incentiveId, groupId);

                    return this.actions.getGroups(uid);
                })
                .catch((error) => {
                    console.info(`reactions:groups:deleteIncentive:error: ${error}`);
                });
        },
        createGroupIncentive: ({ values, groupId }, getGroups = true, isCopy = false) => {
            const {
                user: {
                    data: {
                        authUser: { uid },
                    },
                },
            } = this.store.getState();
            const data = scrubObject(values);
            const groupIncentiveId = uuidv4();
            const group: any = {
                groupIncentives: {
                    [groupIncentiveId]: {
                        id: groupIncentiveId,
                        ...data,
                    },
                },
            };

            group.groupIncentives[groupIncentiveId].iterations.forEach((iteration: any) => {
                const groupProgressesRef = this.db.collection(`classrooms/${groupId}/groupProgresses`).doc();

                iteration.groupProgressesId = groupProgressesRef.id;
            });

            return this.db
                .collection('classrooms')
                .doc(groupId)
                .set(group, { merge: true })
                .then(() => {
                    const batch = this.db.batch();

                    group.groupIncentives[groupIncentiveId].iterations.forEach((iteration: any) => {
                        const { groupProgressesId } = iteration;
                        const groupProgressesRef = this.db.collection(`classrooms/${groupId}/groupProgresses`).doc(groupProgressesId);

                        const groupProgress = {
                            classroomId: groupId,
                            completion: null,
                            groupIncentiveId,
                            id: groupProgressesId,
                            received: null,
                            required: values.required,
                            requiredType: values.requiredType,
                        };

                        batch.set(groupProgressesRef, groupProgress);
                    });

                    return batch.commit().then(() => {
                        this.store.getState().mixpanel.actions.createdClassWideReward(data, groupIncentiveId, groupId, isCopy);

                        if (getGroups) {
                            return this.actions.getGroups(uid);
                        }
                    });
                })
                .catch((error) => {
                    console.info(`reactions:groups:createGroupIncentive:error: ${error}`);
                });
        },
        updateGroupIncentive: ({ values, groupId, groupIncentiveId }) => {
            const {
                groups,
                user: {
                    data: {
                        authUser: { uid },
                    },
                },
            } = this.store.getState();
            const { groupIncentives } = groups.data[groupId];
            const data = scrubObject(values);
            const group = {
                groupIncentives: {
                    ...groupIncentives,
                    [groupIncentiveId]: {
                        ...data,
                    },
                },
            };

            group.groupIncentives[groupIncentiveId].iterations.forEach((iteration: any) => {
                let groupProgressesRef;

                if (!iteration.groupProgressesId) {
                    groupProgressesRef = this.db.collection(`classrooms/${groupId}/groupProgresses`).doc();

                    iteration.groupProgressesId = groupProgressesRef.id;
                }
            });

            return this.db
                .collection('classrooms')
                .doc(groupId)
                .set(group, { merge: true })
                .then(() => {
                    return this.db
                        .collection(`classrooms/${groupId}/groupProgresses`)
                        .where('groupIncentiveId', '==', groupIncentiveId)
                        .get()
                        .then((groupProgressesSnapshot) => {
                            const groupProgresses = [];
                            const batch = this.db.batch();

                            groupProgressesSnapshot.docs.forEach((doc) => {
                                const found = group.groupIncentives[groupIncentiveId].iterations.find((iteration) => {
                                    return iteration.groupProgressesId === doc.id;
                                });

                                if (!found) {
                                    batch.delete(doc.ref);
                                }

                                groupProgresses.push({ id: doc.id, data: doc.data() });
                            });

                            group.groupIncentives[groupIncentiveId].iterations.forEach((iteration) => {
                                const found = groupProgresses.find((progress) => {
                                    return iteration.groupProgressesId === progress.id;
                                });
                                const groupProgressesRef = this.db.collection(`classrooms/${groupId}/groupProgresses`).doc(iteration.groupProgressesId);
                                let groupProgress;

                                if (found && found.data) {
                                    groupProgress = {
                                        ...found.data,
                                        required: values.required,
                                        requiredType: values.requiredType,
                                    };

                                    if (found.data.progress) {
                                        Object.keys(found.data.progress).forEach((key) => {
                                            const { progress } = found.data.progress[key];

                                            if (iteration.duration <= progress) {
                                                groupProgress.progress[key].duration = iteration.duration;
                                                groupProgress.progress[key].completion = moment().unix();
                                                groupProgress.progress[key].hasViewedCompletion = false;
                                            }
                                        });
                                    }

                                    batch.update(groupProgressesRef, groupProgress);
                                } else {
                                    groupProgress = {
                                        classroomId: groupId,
                                        completion: null,
                                        groupIncentiveId,
                                        id: iteration.groupProgressesId,
                                        received: null,
                                        required: values.required,
                                        requiredType: values.requiredType,
                                    };

                                    batch.set(groupProgressesRef, groupProgress);
                                }
                            });

                            return batch
                                .commit()
                                .then(() => {
                                    return this.actions.getGroups(uid);
                                })
                                .catch((error) => {
                                    console.info(`reactions:groups:updateGroupIncentive:error: ${error}`);
                                });
                        });
                })
                .catch((error) => {
                    console.info(`reactions:groups:updateGroupIncentive:error: ${error}`);
                });
        },
        deleteGroupIncentive: (groupId, groupIncentiveId) => {
            const {
                user: {
                    data: {
                        authUser: { uid },
                    },
                },
            } = this.store.getState();
            const group = this.store.getState().groups.data[groupId];
            const groupIncentives = {};

            Object.keys(group.groupIncentives).forEach((key) => {
                if (key !== groupIncentiveId) {
                    groupIncentives[key] = group.groupIncentives[key];
                }
            });

            return this.db
                .collection('classrooms')
                .doc(groupId)
                .update({ groupIncentives })
                .then(() => {
                    return this.db
                        .collection(`classrooms/${groupId}/groupProgresses`)
                        .where('groupIncentiveId', '==', groupIncentiveId)
                        .get()
                        .then((snapshot) => {
                            const batch = this.db.batch();

                            snapshot.docs.forEach((doc) => {
                                batch.delete(doc.ref);
                            });

                            return batch.commit().then(() => {
                                return this.actions.getGroups(uid);
                            });
                        });
                })
                .catch((error) => {
                    console.info(`reactions:groups:deleteIncentive:error: ${error}`);
                });
        },
    };

    public initialState = {
        actions: this.actions,
        data: null,
    };

    public reducer = handleActions<any>(
        {
            CLEAR_GROUPS: () => {
                return this.initialState;
            },
            SET_GROUPS: (state, action) => {
                return {
                    ...state,
                    data: action.payload,
                };
            },
            SET_GROUP: (state, action) => {
                return {
                    ...state,
                    data: {
                        ...state.data,
                        ...action.payload,
                    },
                };
            },
        },
        this.initialState
    );

    private clearGroups = createAction('CLEAR_GROUPS');
    private setGroups = createAction('SET_GROUPS');
    private setGroup = createAction('SET_GROUP');

    private db: any;
    private storage: any;
    private store: Store;
    private activeListeners: any = {};

    constructor(/**firebase: any*/) {
        this.storage = firebase.storage();
        this.db = firebase.firestore();
    }

    public setStore = (store) => {
        this.store = store;
    };
}

export default Groups;
