import { Store } from 'redux';
import { createAction, handleActions } from 'redux-actions';
import firebase from 'firebase/compat/app'

class Check {
    public actions = {
        getNewRewards: (classroomId: string): any => {
            const classroomRef = this.firebase
                .firestore()
                .collection('classrooms')
                .doc(classroomId);

            return this.firebase
                .firestore()
                .collection('requests')
                .doc('appUser')
                .collection('newReward')
                .where('classroomRef', '==', classroomRef)
                .get()
                .then((snapshot) => {
                    const newRewards = {};

                    snapshot.docs.forEach((doc) => {
                        newRewards[doc.id] = doc.data();
                    });

                    this.store.dispatch(this.setNewRewards(newRewards));
                })
                .catch((error) => console.info(`reactions:requests:getNewRewards:error: ${error}`));
        },
        getNotWorking: (classroomId: string): any => {
            const classroomRef = this.firebase
                .firestore()
                .collection('classrooms')
                .doc(classroomId);

            return this.firebase
                .firestore()
                .collection('requests')
                .doc('appUser')
                .collection('notWorking')
                .where('classroomRef', '==', classroomRef)
                .get()
                .then((snapshot) => {
                    const notWorking = {};

                    snapshot.docs.forEach((doc) => {
                        notWorking[doc.id] = doc.data();
                    });

                    this.store.dispatch(this.setNotWorking(notWorking));
                })
                .catch((error) => console.info(`reactions:requests:getNotWorking:error: ${error}`));
        },
        setNewRewardsViewed: (classroomId: string) => {
            const { newRewards } = this.store.getState().requests.data;
            const requests: any[] = [];

            Object.keys(newRewards).forEach((key) => {
                const request = this.firebase
                    .firestore()
                    .collection('requests')
                    .doc('appUser')
                    .collection('newReward')
                    .doc(key)
                    .update({ viewed: true });

                requests.push(request);
            });

            return Promise.all(requests).then(() => {
                return this.actions.getNewRewards(classroomId);
            });
        },
        setNotWorkingViewed: (classroomId: string) => {
            const { notWorking } = this.store.getState().requests.data;
            const requests: any[] = [];

            Object.keys(notWorking).forEach((key) => {
                const request = this.firebase
                    .firestore()
                    .collection('requests')
                    .doc('appUser')
                    .collection('notWorking')
                    .doc(key)
                    .update({ viewed: true });

                requests.push(request);
            });

            return Promise.all(requests).then(() => {
                return this.actions.getNotWorking(classroomId);
            });
        },
    };

    public initialState = {
        actions: this.actions,
        data: {
            newRewards: null,
            notWorking: null,
        },
    };

    public reducer = handleActions<any>(
        {
            SET_NEW_REWARDS: (state, action) => ({
                ...state,
                data: {
                    ...state.data,
                    newRewards: action.payload,
                },
            }),
            SET_NOT_WORKING: (state, action) => ({
                ...state,
                data: {
                    ...state.data,
                    notWorking: action.payload,
                },
            }),
        },
        this.initialState
    );

    private setNewRewards = createAction('SET_NEW_REWARDS');
    private setNotWorking = createAction('SET_NOT_WORKING');

    private firebase: any;
    private store: Store;

    constructor(/**firebase: any*/) {
        this.firebase = firebase;
    }

    public setStore = (store) => {
        this.store = store;
    };
}

export default Check;
