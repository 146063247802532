import { Dialog, DialogContent, Grid, Typography } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import React from 'react';
import { connect } from 'react-redux';
import AlertLoader from '../../components/AlertLoader';
import AlertYesNo from '../../components/AlertYesNo';
import CloseButton from '../../components/CloseButton';
import styles from '../App.style';
import Explainers from '../Explainers';
import ClasswideForm from './Form/ClasswideForm';
import IndividualForm from './Form/IncentiveForm';
import StudentCountForm from './Form/StudentCountForm';

interface IProps {
    classes?: any;
    actions: any;
    groups: any;
    groupId: string;
    incentiveId?: string | null;
    onClose: any;
    open: boolean;
    fullScreen?: boolean;
    width?: any;
    copyIncentives?: any;
    showAdvanced?: boolean;
    userInfo: any;
    groupIncentiveId?: string | null;
}

interface IState {
    loading: boolean;
    deleteView: boolean;
    showIndividual: boolean;
    showClasswide: boolean;
}

class NewIncentiveModal extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            loading: false,
            deleteView: false,
            showIndividual: this.props.groupIncentiveId ? false : true,
            showClasswide: this.props.groupIncentiveId ? true : false,
        };
    }

    public render() {
        const { fullScreen } = this.props;
        const { loading, deleteView } = this.state;

        return (
            <div>
                <Dialog
                    fullScreen={fullScreen}
                    open={this.props.open}
                    onClose={() => this.handleClose(false)}
                    aria-labelledby="responsive-dialog-title"
                    maxWidth="sm"
                    fullWidth={true}
                >
                    <AlertYesNo
                        showing={deleteView}
                        title={'Are you sure you want to delete this reward?'}
                        content={'All student progress will be lost and cannot be reverted.'}
                        onClose={this.handleOnDeleteCancel}
                        onConfirm={this.handleOnDeleteConfirm}
                    />
                    <AlertLoader loading={loading} />
                    <DialogContent style={{ padding: 0, position: 'relative' }}>{this.renderContent()}</DialogContent>
                </Dialog>
            </div>
        );
    }

    private renderContent = () => {
        const {
            classes,
            incentiveId,
            groupIncentiveId,
            groupId,
            showAdvanced,
            userInfo: { modalStates },
        } = this.props;
        const { showIndividual, showClasswide } = this.state;

        console.info(incentiveId, groupIncentiveId, modalStates.showClasswideExplainer);

        return (
            <React.Fragment>
                <Grid container={true} style={{ paddingLeft: 16, paddingTop: 16 }}>
                    <Grid item={true} xs={6}>
                        <CloseButton onClick={() => this.handleClose(false)} />
                    </Grid>
                    {(incentiveId || groupIncentiveId) && (
                        <Grid item={true} xs={6} style={{ textAlign: 'right', paddingRight: 16 }}>
                            <Typography>
                                <span className={classes.deleteTitle} onClick={this.handleOnDelete}>
                                    Delete
                                </span>
                            </Typography>
                        </Grid>
                    )}
                    {!incentiveId && showIndividual && (
                        <Grid item={true} xs={6} style={{ textAlign: 'right', paddingRight: 16 }}>
                            <Typography>
                                <span className={classes.deleteTitle} onClick={this.handleOnCopy}>
                                    Copy existing rewards
                                </span>
                            </Typography>
                        </Grid>
                    )}
                </Grid>

                <Grid item={true} xs={12} className={classes.pageBody} style={{ backgroundColor: '#FFFFFF', borderTopWidth: 0 }}>
                    {showIndividual && (
                        <IndividualForm
                            groupId={groupId}
                            incentiveId={incentiveId}
                            setLoading={this.handleLoading}
                            handleClose={this.handleClose}
                            fullScreen={false}
                            showAdvanced={showAdvanced}
                            toggleRewardType={this.toggleRewardType}
                        />
                    )}
                    {showClasswide && this.renderClasswide()}
                </Grid>
            </React.Fragment>
        );
    };

    private renderClasswide = () => {
        const {
            groupIncentiveId,
            groupId,
            groups,
            userInfo: { modalStates },
        } = this.props;
        const group = groups[groupId];
        const showStudentCountForm = !group.attributes.groupSize || group.attributes.groupSize === 0;

        if (modalStates.showClasswideExplainer) {
            return <Explainers type="classwide" />;
        }

        if (showStudentCountForm) {
            return <StudentCountForm group={group} onSubmit={this.handleUpdategroupSize} />;
        }

        return (
            <ClasswideForm
                groupId={groupId}
                groupIncentiveId={groupIncentiveId}
                setLoading={this.handleLoading}
                handleClose={this.handleClose}
                fullScreen={false}
                toggleRewardType={this.toggleRewardType}
            />
        );
    };

    private handleLoading = (loading: boolean, finished: any) => {
        this.setState({ loading }, () => finished());
    };

    private handleClose = (created: boolean, onCompleted?: any) => {
        const { loading } = this.state;
        if (!loading) {
            this.props.onClose(created, onCompleted);
        }
    };

    private handleOnCopy = () => {
        this.handleClose(false, () => {
            this.props.copyIncentives(true, 0, true);
        });
    };

    private handleOnDelete = () => {
        this.setState({ deleteView: true });
    };

    private handleOnDeleteCancel = () => {
        this.setState({ deleteView: false });
    };

    private handleOnDeleteConfirm = () => {
        const { actions, groupId, incentiveId, groupIncentiveId, onClose } = this.props;

        this.setState({ deleteView: false, loading: true }, () => {
            if (incentiveId) {
                actions.group
                    .deleteIncentive(groupId, incentiveId)
                    .then(() => {
                        this.setState({ loading: false }, () => onClose());
                    })
                    .catch(() => {
                        this.setState({ loading: false });
                    });
            }
            if (groupIncentiveId) {
                actions.group
                    .deleteGroupIncentive(groupId, groupIncentiveId)
                    .then(() => {
                        this.setState({ loading: false }, () => onClose());
                    })
                    .catch(() => {
                        this.setState({ loading: false });
                    });
            }
        });
    };

    private handleUpdategroupSize = ({ groupSize }) => {
        const { actions, groups, groupId } = this.props;
        const group = groups[groupId];
        const size = parseInt(groupSize, 10);
        const values = {
            ...group.attributes,
            groupSize: isNaN(size) ? 0 : size,
        };

        actions.group.updateGroup({ values, groupId });
        actions.mixpanel.classRosterEntered();
    };

    private toggleRewardType = () => {
        const { showIndividual, showClasswide } = this.state;
        if (showIndividual) {
            this.setState({ showIndividual: false, showClasswide: true });
        }
        if (showClasswide) {
            this.setState({ showIndividual: true, showClasswide: false });
        }
    };
}

const mapStoreToProps = (state: any) => {
    return {
        actions: { group: state.groups.actions, mixpanel: state.mixpanel.actions },
        groups: state.groups.data,
        userInfo: state.user.data.userInfo,
    };
};

export default connect(
    mapStoreToProps,
    null
)(withStyles(styles)(withMobileDialog<IProps>()(NewIncentiveModal)));
