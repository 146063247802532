import { Grid, TextField } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { Form, Formik } from 'formik';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import Submit from '../../../components/Submit';
import Text from '../../../components/Text';
import { StudentCountFormSchema } from '../../../utils/validations';
import appStyle from '../../App.style';

interface IProps {
    classes: any;
    group: any;
    onSubmit: any;
}

class StudentCountForm extends React.PureComponent<IProps> {
    public render() {
        const { group, onSubmit } = this.props;
        const initialValues = {
            minGroupSize: group && group.appUsers ? group.appUsers.length : 1,
            groupSize: '',
        };
        const label = `Students in ${group.attributes.name}`;

        return (
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={StudentCountFormSchema}
                onSubmit={onSubmit}
                render={({ values, errors, touched, handleChange, isSubmitting }) => (
                    <Form noValidate={true}>
                        <Grid container={true} justifyContent="center" alignItems="center">
                            <Grid item={true} xs={12} style={{ paddingBottom: 30 }}>
                                <Text fontSize={24} bold={true} style={{ paddingBottom: 16 }}>
                                    How many total students are in this class?
                                </Text>
                                <Text fontSize={14} light={true}>
                                    Class wide rewards are based off of the total amount of students in your class
                                </Text>
                            </Grid>
                            <Grid item={true} xs={12} style={{ paddingBottom: 38 }}>
                                <TextField
                                    type={isMobile ? 'number' : 'text'}
                                    id="groupSize"
                                    label={label}
                                    placeholder="e.g. 24"
                                    value={values.groupSize}
                                    onChange={handleChange}
                                    autoFocus={true}
                                    fullWidth={true}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error={!!(touched && errors && errors.groupSize)}
                                    helperText={errors && errors.groupSize ? errors.groupSize.toString() : ''}
                                />
                            </Grid>
                            <Grid container={true} justifyContent="center" alignItems="center" style={{ paddingBottom: 24 }}>
                                <Submit loading={isSubmitting} style={{ marginTop: 24 }} id="submit-button">
                                    Continue
                                </Submit>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            />
        );
    }
}

const mapStoreToProps = (state: any) => {
    return {
        actions: {
            mixpanel: state.mixpanel.actions,
            user: state.user.actions,
        },
    };
};

export default connect(
    mapStoreToProps,
    null
)(withStyles(appStyle)(StudentCountForm));
