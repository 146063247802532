import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import styles from './Terms.style';
import withStyles from '@material-ui/core/styles/withStyles';
import { Helmet } from "react-helmet";

interface IProps {
    classes: any
}

class Privacy extends React.Component<IProps> {

    public render() {
        const { classes } = this.props;

        return (
            <React.Fragment>
                <Helmet>
                    <title>Privacy Policy | Pocket Points: Teacher</title>
                    <script src="https://cdn.iubenda.com/iubenda.js" />
                </Helmet>
                <CssBaseline />
                <main className={classes.main}>
                    <a href="https://www.iubenda.com/privacy-policy/51345008" className="iubenda-white no-brand iubenda-embed iub-body-embed" title="Privacy Policy">Privacy Policy</a>
                </main>
            </React.Fragment>
        )
    }

}

export default withStyles(styles)(Privacy);