import { Button, CircularProgress, Grid, Typography, withStyles } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment-timezone';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import EarningWindow, { IEarningWindow, IEarningWindowSlot } from 'shared-library-js';
import { ClassroomData } from '../../data/examples';
import appStyle from '../App.style';
import NoInProgressCell from './Cells/NoInProgressCell';
import StudentSessions from './Cells/StudentSessions';
import DailyClassroomModal from './DailyClassroomModal';
import DateSelector from './DateSelector';

interface IClassroomProgress {
    id: string;
    name: string;
    onDate: boolean;
    students: any[];
    window: IEarningWindow;
    totalEarned: number;
    duration: number;
    durationHours: number;
    durationMinutes: number;
    currentSlot?: IEarningWindowSlot;
    startTime?: number;
    endTime?: number;
}

interface IProps {
    classes: any;
    earningWindow: any;
    groups: any;
    incentiveProgress: any;
    actions: any;
    mixpanel: { actions: any };
}

interface IState {
    loading: boolean;
    filterDate: any;
    progress: any[];
    classroomId: string | null;
    showDate: boolean;
}

class DailyTab extends React.Component<IProps, IState> {
    private datePickerRef: any;

    constructor(props: IProps) {
        super(props);
        let filterDate = moment();

        if (props.earningWindow && props.earningWindow.timeZone) {
            filterDate = moment().tz(props.earningWindow.timeZone);
        }

        this.state = {
            loading: false,
            filterDate: filterDate.startOf('day'),
            progress: [],
            classroomId: null,
            showDate: false,
        };
    }

    public componentDidMount() {
        const { filterDate } = this.state;
        this.getData(filterDate);
    }

    public componentDidUpdate(prevProps: IProps) {
        const { filterDate } = this.state;
        if (this.props.incentiveProgress !== prevProps.incentiveProgress) {
            this.getData(filterDate);
        }
    }

    public render() {
        const { classes } = this.props;
        const { filterDate, loading, classroomId, progress, showDate } = this.state;
        const isToday = Math.floor(filterDate.unix() / 86400) >= Math.floor(moment().unix() / 86400);
        const currentClassroom = progress.find((classroom) => classroom.id === classroomId);

        return (
            <Grid container={true} direction="column">
                {classroomId && (
                    <DailyClassroomModal
                        classroom={currentClassroom}
                        onClose={() => this.setClassroomIndex(null)}
                        date={filterDate}
                        onAddDay={this.onAddDay}
                        onOpenDate={this.onOpenDate}
                        onSubtractDay={this.onSubtractDay}
                    />
                )}
                <DateSelector date={filterDate} onAddDay={this.onAddDay} onOpenDate={this.onOpenDate} onSubtractDay={this.onSubtractDay} />
                <Grid container={true} item={true} className={isMobile ? undefined : classes.dashboardPageSubBody} alignContent="flex-start">
                    {loading && (
                        <div className={classes.progressDiv}>
                            <CircularProgress />
                        </div>
                    )}
                    {!loading && isToday && progress.length > 0 && (
                        <Grid className={classes.dashboardDailyClassroomGroupMessage}>
                            <Typography className={classes.dashboardDailyClassroomGroupMessageText}>
                                <Typography
                                    component="span"
                                    className={classes.dashboardDailyClassroomGroupMessageText}
                                    style={{ fontWeight: 600, display: 'inline' }}
                                >
                                    Your data is not complete for the day.
                                </Typography>
                                {'  Some student progress will not be available until they unlock their phone at the end of the school day.'}
                            </Typography>
                        </Grid>
                    )}
                    {!loading && progress.length === 0 && (
                        <NoInProgressCell
                            title="No Student Activity Yet"
                            body="Once students join your classes and start using Pocket Points, you will see a daily breakdown of their phone usage in class."
                        />
                    )}
                    {!loading && this.renderClassrooms()}
                </Grid>
                <DatePicker open={showDate} autoOk={true} value={filterDate} onChange={this.onChangeDate} style={{ display: 'none' }} />
            </Grid>
        );
    }

    private renderClassrooms = () => {
        const { classes } = this.props;
        const { progress } = this.state;
        const data = progress.length === 0 ? ClassroomData : progress;

        return data
            .sort((classroomA, classroomB) => {
                if (classroomA.currentSlot && classroomB.currentSlot) {
                    return classroomA.currentSlot.start >= classroomB.currentSlot.start ? 1 : -1;
                }

                return 1;
            })
            .map((classroom, index) => {
                const hoursText = classroom.durationHours > 0 ? classroom.durationHours + ' hr' : '';
                const minutesText = classroom.durationMinutes > 0 ? classroom.durationMinutes + ' min' : '';
                const { timeZone } = classroom.window ? classroom.window : "American/LosAngeles";

                if (!classroom.onDate) {
                    return (
                        <Grid container={true} key={index} className={classes.dashboardDailyClassroomGroup}>
                            <Grid
                                container={true}
                                item={true}
                                justifyContent="space-between"
                                alignItems="center"
                                className={classes.dashboardDailyClassroomGroupNoClass}
                            >
                                <Typography noWrap={true} className={classes.dashboardDailyClassroomGroupHeading} style={{ margin: 0 }}>
                                    {classroom.name}
                                </Typography>
                                <Typography className={classes.dashboardDailyClassroomGroupTimeLabel} style={{ fontSize: 11, fontWeight: 500, margin: 0 }}>
                                    no class on this date
                                </Typography>
                            </Grid>
                        </Grid>
                    );
                }

                return (
                    <Grid
                        container={true}
                        key={index}
                        className={classes.dashboardDailyClassroomGroup}
                        style={isMobile ? { borderBottomLeftRadius: 0, borderBottomRightRadius: 0, minHeight: 140 } : undefined}
                    >
                        {classroom.example && <div className={classes.dashboardDailyClassroomGroupExample} />}
                        {!isMobile && classroom.students.length > 0 && !classroom.example && (
                            <div className={classes.dashboardDailyClassroomGroupClickable} onClick={() => this.setClassroomIndex(classroom.id)}>
                                <Typography className={classes.dashboardDailyClassroomGroupClickableText}>View Full Class</Typography>
                            </div>
                        )}
                        {isMobile && classroom.students.length > 0 && !classroom.example && (
                            <div className={classes.dashboardDailyClassroomGroupClickableMobile}>
                                <div style={{ boxShadow: '0px -39px 54px 0px rgba(255,255,255,1)' }} />
                                <Button
                                    variant="contained"
                                    size="large"
                                    fullWidth={true}
                                    className={classes.dashboardDailyClassroomGroupClickableMobileButton}
                                    onClick={() => this.setClassroomIndex(classroom.id)}
                                >
                                    View Full Class
                                    <Icons.ChevronRight />
                                </Button>
                            </div>
                        )}
                        <Grid container={true} className={classes.dashboardDailyClassroomGroupWrap}>
                            <Grid container={true} item={true} justifyContent="space-between" className={classes.dashboardDailyClassroomGroupHeader}>
                                <Grid container={true} item={true} xs={6} direction="column">
                                    <Typography noWrap={true} gutterBottom={true} className={classes.dashboardDailyClassroomGroupHeading}>
                                        {classroom.name}
                                    </Typography>
                                    <Typography className={classes.dashboardDailyClassroomGroupTimeLabel}>class start</Typography>
                                    <Typography className={classes.dashboardDailyClassroomGroupTime}>
                                        {moment
                                            .unix(classroom.startTime)
                                            .tz(timeZone)
                                            .format('h:mmA')}
                                    </Typography>
                                </Grid>
                                <Grid container={true} item={true} xs={6} direction="column" alignItems="flex-end">
                                    <Typography gutterBottom={true} className={classes.dashboardDailyClassroomGroupHeading}>
                                        {`${hoursText} ${minutesText}`} class
                                    </Typography>
                                    <Typography className={classes.dashboardDailyClassroomGroupTimeLabel}>class end</Typography>
                                    <Typography className={classes.dashboardDailyClassroomGroupTime}>
                                        {moment
                                            .unix(classroom.endTime)
                                            .tz(timeZone)
                                            .format('h:mmA')}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container={true} item={true}>
                                <StudentSessions classroom={classroom} shortList={true} />
                            </Grid>
                        </Grid>
                    </Grid>
                );
            });
    };

    private onOpenDate = () => {
        this.setState({ showDate: true });
    };

    private onAddDay = () => {
        const { mixpanel } = this.props;
        const { filterDate } = this.state;
        const date = moment(filterDate).add(1, 'days');

        this.getData(date);
        mixpanel.actions.changedDate();
    };

    private onSubtractDay = () => {
        const { mixpanel } = this.props;
        const { filterDate } = this.state;
        const date = moment(filterDate).subtract(1, 'days');

        this.getData(date);
        mixpanel.actions.changedDate();
    };

    private onChangeDate = (date) => {
        const { mixpanel } = this.props;

        this.getData(date);
        this.setState({ showDate: false }, () => {
            mixpanel.actions.changedDate();
        });
    };

    private getData = (date) => {
        const { actions, earningWindow, groups } = this.props;
        const { loading } = this.state;

        if (!loading) {
            this.setState({ loading: true, filterDate: date }, () => {
                const progressRequests: any[] = [];

                Object.keys(groups).forEach((classroomKey) => {
                    const group = groups[classroomKey];
                    const { attributes, students } = group;
                    const offset = moment(date).tz(attributes.window.timeZone).utcOffset()
                    const startOfDay = moment(date).utcOffset(offset).startOf('day').unix();
                    
                    const progressRequest = actions.progressEarned({ date, offset, classroomId: classroomKey }).then((progressEarned) => {   
                        let slot;   
                        let progressWindow     
                        if ( earningWindow && group ) {
                            progressWindow = new EarningWindow(earningWindow, group);
                            slot = progressWindow.getSlot(date.unix(), true);
                        } else {
                            progressWindow = null;
                            slot = -1;
                        }       

                        const classroom: IClassroomProgress = {
                            id: classroomKey,
                            name: attributes.name,
                            onDate: false,
                            students: [],
                            totalEarned: 0,
                            duration: 0,
                            durationHours: 0,
                            durationMinutes: 0,
                            currentSlot: slot > -1 ? progressWindow.window.slots[slot] : undefined,
                            window: progressWindow ? progressWindow.window : null,
                        };



                        if (classroom.currentSlot) {
                            classroom.onDate = classroom.currentSlot.enabled;
                            classroom.duration = classroom.currentSlot.end - classroom.currentSlot.start;
                            classroom.durationHours = Math.floor(classroom.duration / 3600);
                            classroom.durationMinutes = Math.floor((classroom.duration % 3600) / 60);
                            classroom.startTime = startOfDay + classroom.currentSlot.start;
                            classroom.endTime = startOfDay + classroom.currentSlot.end;

                            if (students) {
                                classroom.students = Object.keys(students).map((studentKey) => {
                                    const sessions =
                                        progressEarned[classroomKey] && progressEarned[classroomKey].students[studentKey]
                                            ? progressEarned[classroomKey].students[studentKey].sessions
                                            : [];
                                    const student: any = {
                                        ...students[studentKey],
                                        id: studentKey,
                                        totalDuration: 0,
                                    };

                                    student.sessions = this.getSessions(sessions, classroom);

                                    student.totalDuration = student.sessions.reduce((accumulator, session) => {
                                        return session.gap ? accumulator : accumulator + session.duration;
                                    }, 0);

                                    classroom.totalEarned = classroom.totalEarned + student.totalDuration;

                                    return student;
                                });
                            }
                        }
                        return classroom;
                    });
                    progressRequests.push(progressRequest);
                });

                return Promise.all(progressRequests).then((progress) => {
                    this.setState({ loading: false, progress });
                });
            });
        }
    };

    private getSessions = (sessions, classroom) => {
        const filteredSessions = sessions
            .filter((session) => {
                return session.start < classroom.endTime && session.end > classroom.startTime;
            })
            .sort((sessionA, sessionB) => {
                return sessionA.start > sessionB.start ? 1 : -1;
            });

        return filteredSessions
            .reduce((accumulator, session, index) => {
                const last = filteredSessions[index - 1] ? filteredSessions[index - 1] : { start: 0, end: classroom.startTime};

                if (session.start >= last.end) {
                    accumulator.push({
                        gap: true,
                        start: last.end,
                        end: session.start,
                    });
                }

                accumulator.push({
                    gap: false,
                    start: session.start < classroom.startTime ? classroom.startTime : session.start,
                    end: session.end > classroom.endTime ? classroom.endTime : session.end,
                });

                if (index === filteredSessions.length - 1 && session.end < classroom.endTime) {
                    accumulator.push({
                        gap: true,
                        start: session.end,
                        end: classroom.endTime,
                    });
                }

                return accumulator;
            }, [])
            .map((session) => {
                return { ...session, duration: session.end - session.start };
            });
    };

    private setClassroomIndex = (classroomId) => {
        const { mixpanel } = this.props;

        this.setState({ classroomId }, () => {
            if (this.state.classroomId) {
                mixpanel.actions.viewedClassDetails();
            }
        });
    };
}

const mapStoreToProps = (state: any) => {
    return {
        actions: {
            ...state.dashboard.actions,
        },
        earningWindow: state.earningWindow.data,
        groups: state.groups.data,
        incentiveProgress: state.incentiveProgress.data,
        mixpanel: state.mixpanel,
    };
};

export default compose<IProps, any>(
    connect(
        mapStoreToProps,
        null
    ),
    withStyles(appStyle)
)(DailyTab);
