import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import React from 'react';

interface IProps {
    showing: boolean;
    onClose: any;
    onConfirm: any;
    closeText?: string;
    confirmText?: string;
    title?: string;
    content?: string;
}

class AlertYesNo extends React.Component<IProps> {
    public render() {
        const { closeText, confirmText, title, content } = this.props;

        return (
            <div>
                <Dialog
                    open={this.props.showing}
                    onClose={this.props.onClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth="xs"
                    fullWidth={true}
                >
                    <DialogTitle id="alert-dialog-title">{title ? title : 'Are you sure?'}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>{content ? content : ''}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.props.onClose} color="primary">
                            {closeText ? closeText : 'No'}
                        </Button>
                        <Button onClick={this.props.onConfirm} color="primary" autoFocus={true}>
                            {confirmText ? confirmText : 'Yes'}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default AlertYesNo;
