import React from 'react'
import appStyle from '../../App.style'
import withStyles from '@material-ui/core/styles/withStyles';
import {Typography, Grid} from "@material-ui/core";
import {Add} from '@material-ui/icons'


interface IProps {
    classes: {
        groupAddCellContainer: string
        addIcon: string
        groupAddCellTitle: string
        pageRow: string
    }
    onClick: any
}

class GroupAddCell extends React.Component<IProps> {

    public render() {
        const {classes} = this.props;

        return (
            <Grid item={true} xs={12} onClick={this.props.onClick} className={classes.pageRow}>
                <div className={classes.groupAddCellContainer} style={{cursor: 'pointer'}}>
                    <Grid container={true} justifyContent="center" alignItems="center" direction="column">
                        <Grid item={true} xs={12}>
                            <Add className={classes.addIcon} />
                        </Grid>
                        <Grid item={true} xs={12}>
                            <Typography component="p" className={classes.groupAddCellTitle}>
                                Create New Class
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
        )
    }
}

export default withStyles(appStyle)(GroupAddCell);

