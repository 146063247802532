import { ButtonBase, FormControl, Grid, Input, InputLabel, Typography, withWidth } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { Form, Formik } from 'formik';
import React from 'react';
import compose from 'recompose/compose';
import EarningWindow, { IEarningWindow } from 'shared-library-js';
import Submit from '../../components/Submit';
import Text from '../../components/Text';
import Time from '../../components/Time';
import Tooltip from '../../components/Tooltip';
import { WEEKDAYS } from '../../utils/constants';
import { ConfirmStandardFormSchema } from '../../utils/validations';
import appStyle from '../App.style';

interface IProps {
    classes?: any;
    earningWindow: IEarningWindow;
    group: any;
    groupId: string;
    onClose: any;
    onSubmit?: any;
}

class StandardForm extends React.Component<IProps> {
    public render() {
        const { classes, group, onClose } = this.props;
        const initialValues = this.getInitialValues();
        const title = group ? 'Edit Class' : 'Create Class';

        return (
            <Formik enableReinitialize={true} initialValues={initialValues} validationSchema={ConfirmStandardFormSchema} onSubmit={this.handleSubmit}>
                {({ values, errors, touched, isSubmitting, setFieldValue }) => (
                    <Form noValidate={true}>
                        <Grid container={true} justifyContent="center" alignItems="center">
                            <Grid className={classes.authContainer} style={{ paddingTop: 32 }}>
                                <FormControl margin="normal" required={true} fullWidth={true} error={!!errors.name && !!touched.name}>
                                    <InputLabel shrink={true} htmlFor="name">
                                        Class Name
                                    </InputLabel>
                                    <Input
                                        inputProps={{ 'data-hj-whitelist': true }}
                                        placeholder="e.g. First Period, Spanish 2, 3rd Period English"
                                        id="name"
                                        autoFocus={true}
                                        name="name"
                                        value={values.name}
                                        readOnly={true}
                                    />
                                    <Tooltip isInput={true} page="Confirm Standard Form" id="className">
                                        This is what your class will be labeled in the app. Name your class someting that your students will easily recognize.
                                    </Tooltip>
                                </FormControl>
                                <Time
                                    startKey="start"
                                    startTime={values.start}
                                    endKey="end"
                                    endTime={values.end}
                                    handleTimeChanged={({ id, value }) => this.handleChangeTime(id, value, values.earningWindow, setFieldValue)}
                                    page={title}
                                    error={!!(errors.start || errors.end)}
                                    errorMessage={errors.start ? errors.start.toString() : ''}
                                    startId="start"
                                    endId="end"
                                />
                                {this.renderWeekdays(values.earningWindow, setFieldValue)}
                            </Grid>
                            <Grid container={true} item={true} xs={12} style={{ paddingTop: 24 }} justifyContent="center">
                                <Submit loading={isSubmitting} id="submit-button" className={classes.saveButton}>
                                    Save
                                </Submit>
                            </Grid>
                            <Grid container={true} item={true} xs={12} style={{ paddingTop: 32 }} justifyContent="center">
                                <Text color="primary" fontSize={14} bold={true} onClick={onClose}>
                                    Back to schedule settings
                                </Text>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        );
    }

    private renderWeekdays = (earningWindow: IEarningWindow, setFieldValue: any) => {
        const { classes } = this.props;

        return (
            <FormControl margin="normal" fullWidth={true}>
                <Typography style={{ opacity: 0.5 }}>Day of week</Typography>
                <Grid container={true} justifyContent="center" alignItems="center" style={{ paddingTop: 16 }}>
                    <Grid item={true} xs={12}>
                        {earningWindow.slots.map((slot, index) => {
                            const abbr = WEEKDAYS[index].substring(0, 3);

                            return (
                                <ButtonBase
                                    key={index}
                                    id={abbr}
                                    onClick={() => this.handleToggleWeekdays(index, earningWindow, setFieldValue)}
                                    className={slot.enabled ? classes.selectedDayOfWeekButton : classes.unselectedDayOfWeekButton}
                                    disableRipple={true}
                                >
                                    {abbr.toUpperCase()}
                                </ButtonBase>
                            );
                        })}
                    </Grid>
                </Grid>
            </FormControl>
        );
    };

    private getInitialValues = () => {
        const { group } = this.props;
        const earningWindow = new EarningWindow(this.props.earningWindow, group);

        return {
            name: group ? group.attributes.name : '',
            start: earningWindow.getStandardTimeString('start'),
            end: earningWindow.getStandardTimeString('end'),
            earningWindow: earningWindow.window,
        };
    };

    private handleToggleWeekdays = (slot: number, values: IEarningWindow, setFieldValue: any) => {
        const earningWindow = new EarningWindow(values);
        const window = earningWindow.toggleSlotEnabled(slot);

        setFieldValue('earningWindow', window);
    };

    private handleChangeTime = (id, time, values: IEarningWindow, setFieldValue: any) => {
        const earningWindow = new EarningWindow(values);
        const window = earningWindow.setSlotTime(id, time, 0, true);

        setFieldValue(id, time);
        setFieldValue('earningWindow', window);
    };

    private handleSubmit = (values, options) => {
        const { groupId, onSubmit } = this.props;

        onSubmit(groupId, values, options);
    };
}

export default compose<IProps, IProps>(
    withStyles(appStyle),
    withWidth()
)(StandardForm);
