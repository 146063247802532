import { Button, Grid } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { People } from '@material-ui/icons';
import React from 'react';
import Text from '../../../components/Text';
import appStyle from '../../App.style';

interface IProps {
    classes: any;
    onClick: any;
}

class NoGroupCell extends React.Component<IProps> {
    public render() {
        const { classes } = this.props;

        return (
            <Grid container={true} item={true} xs={12} justifyContent="center" alignItems="center" direction="column">
                <People className={classes.noResultsIcon} />
                <Text fontSize={18} bold={true} gutterBottom={true}>
                    You do not have any classes yet
                </Text>
                <Text fontSize={18} extraLight={true} gutterBottom={true} style={{ maxWidth: 540, textAlign: 'center' }}>
                    Create a class to organize your rewards
                </Text>
                <Button color="primary" variant="contained" className={classes.primaryButton} onClick={this.props.onClick}>
                    Create Class
                </Button>
            </Grid>
        );
    }
}

export default withStyles(appStyle)(NoGroupCell);
