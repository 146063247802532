import { Grid, List, ListItem } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { ChevronLeft, MessageRounded } from '@material-ui/icons';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import Submit from '../../components/Submit';
import Text from '../../components/Text';
import appStyle from '../App.style';

interface IProps {
    classes?: any;
    actions: any;
    showNewReward?: boolean;
    showNotWorking?: boolean;
    onClose: any;
    classroomId: string;
    groups: any;
    requests: any;
}

interface IState {
    loading: boolean;
    deleteView: boolean;
    newGroupRedirect: any;
}

class RequestsList extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            loading: false,
            deleteView: false,
            newGroupRedirect: null,
        };
    }

    public componentDidMount() {
        const { actions, classroomId, showNewReward, showNotWorking } = this.props;

        if (showNewReward) {
            actions.requests.setNewRewardsViewed(classroomId);
        }

        if (showNotWorking) {
            actions.requests.setNotWorkingViewed(classroomId);
        }
    }

    public render() {
        const {
            classes,
            classroomId,
            groups,
            requests: { newRewards, notWorking },
            onClose,
            showNewReward,
            showNotWorking,
        } = this.props;
        const { attributes } = groups[classroomId];

        return (
            <React.Fragment>
                <Grid container={true} item={true} xs={12} style={{ paddingLeft: 16, paddingRight: 16, paddingTop: 16 }} direction="row">
                    <div onClick={onClose} style={{ cursor: 'pointer' }}>
                        <Grid container={true}>
                            <ChevronLeft />
                            <Text fontSize={14}>{`Back to ${attributes.name}`}</Text>
                        </Grid>
                    </div>
                </Grid>
                <Grid container={true} className={classes.pageBody} style={{ backgroundColor: '#FFFFFF', borderTopWidth: 0 }}>
                    <Grid item={true} xs={12}>
                        <Text isTitle={true} justifyContent="flex-start">
                            {showNewReward ? 'Requested Rewards' : ''}
                            {showNotWorking ? 'Reported Issues' : ''}
                        </Text>
                    </Grid>
                    <Grid item={true} xs={12}>
                        <List>
                            {showNewReward && this.renderRequests(newRewards)}
                            {showNotWorking && this.renderRequests(notWorking)}
                        </List>
                    </Grid>
                </Grid>
            </React.Fragment>
        );
    }

    private renderRequests = (requests) => {
        const { classroomId, groups, showNotWorking, showNewReward } = this.props;
        const { students } = groups[classroomId];
        const keys = requests ? Object.keys(requests) : [];

        if (keys.length === 0) {
            return this.renderNoRequests();
        }

        return keys.map((key: any, index) => {
            const { created, studentId } = requests[key];
            const { name } = !!students[studentId] ? students[studentId] : '';

            return (
                <ListItem key={index} divider={true} style={{ paddingLeft: 0, paddingRight: 0, alignItems: 'flex-end' }}>
                    <Grid item={true} xs={6}>
                        <Text bold={true} justifyContent="flex-start">
                            {showNewReward ? requests[key].rewardRequested : ''}
                            {showNotWorking ? requests[key].issueReported : ''}
                        </Text>
                        <Text justifyContent="flex-start" light={true}>
                            {name}
                        </Text>
                    </Grid>
                    <Grid item={true} xs={6}>
                        <Text justifyContent="flex-end" light={true}>
                            {moment.unix(created.seconds).format('MM/DD/YY hh:mmA')}
                        </Text>
                    </Grid>
                </ListItem>
            );
        });
    };

    private renderNoRequests = () => {
        const { onClose, showNewReward } = this.props;

        return (
            <ListItem style={{ padding: '100px 0', alignItems: 'center' }}>
                <Grid container={true} justifyContent="center" alignItems="center" direction="column">
                    <MessageRounded style={{ fontSize: 56, opacity: 0.05, marginBottom: 32 }} />
                    <Text fontSize={18} fontWeight={700} style={{ marginBottom: 16, textAlign: 'center' }}>
                        {showNewReward ? 'You do not have any requested rewards yet' : 'You do not have any reported issues'}
                    </Text>
                    <Text fontSize={18} light={true} style={{ marginBottom: 32, textAlign: 'center' }}>
                        {showNewReward ? 'Students can request rewards from within the app. Encourage them to make some suggestions!' : ''}
                        {!showNewReward ? 'Students can report issues such as incorrect class times from within the app.' : ''}
                    </Text>
                    <Submit loading={false} onClick={onClose}>
                        Go Back
                    </Submit>
                </Grid>
            </ListItem>
        );
    };
}

const mapStoreToProps = (state: any) => {
    return {
        actions: {
            requests: state.requests.actions,
        },
        groups: state.groups.data,
        requests: state.requests.data,
    };
};

export default compose<IProps, any>(
    connect(
        mapStoreToProps,
        null
    ),
    withStyles(appStyle)
)(RequestsList);
