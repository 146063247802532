import { Button, Dialog, DialogContent, Grid, MobileStepper, Typography } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { ChevronLeft, Print } from '@material-ui/icons';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import CloseButton from '../../../components/CloseButton';
import styles from '../../App.style';
import theme from '../../App.theme';

declare let gapi: any;
declare let remind: any;

interface IProps {
    classes?: any;
    group: any;
    handleClose: any;
    open: boolean;
    inviteCode: string;
    mixpanel: any;
    user: any;
}

interface IState {
    step: number;
    learnMore: boolean;
    glowActive: boolean;
}

class LearnMoreModal extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            step: 0,
            learnMore: false,
            glowActive: false,
        };
    }

    public componentDidUpdate(prevProps: IProps) {
        if (this.props.open && !prevProps.open) {
            this.setState({ step: 0 });
        }
        if (prevProps !== this.props && this.state.step === 0) {
            this.renderClassroom(this.props.inviteCode);
        }
    }

    public render() {
        const { open, inviteCode } = this.props;
        const { learnMore } = this.state;

        return (
            <div>
                <Helmet>
                    <script src="https://apis.google.com/js/platform.js" async={true} defer={true} />
                </Helmet>
                <Dialog
                    fullScreen={isMobile}
                    open={open}
                    onClose={this.handleClose}
                    maxWidth="sm"
                    fullWidth={true}
                    // onEntered={() => this.renderClassroom(inviteCode)}// old 
                    TransitionProps={{
                        onEntered: () => { this.renderClassroom(inviteCode)}
                    }}
                    onExited={this.alertClosed}
                >
                    <DialogContent>
                        {learnMore && this.renderLearnMore()}
                        {!learnMore && this.renderShare()}
                    </DialogContent>
                </Dialog>
            </div>
        );
    }

    private renderClassroom(inviteCode: string) {
        if (gapi) {
            gapi.sharetoclassroom.render('gShareWidget', {
                url: 'https://pckt.app.link/e2wr2RwEXT',
                size: 46,
                body: `Download the Pocket Points app to earn rewards for staying off your phone in class! Join my class with invite code: ${inviteCode} and open the app during class to get started.`,
                onsharecomplete: 'googleClassromOnShareComplete',
                onsharestart: 'googleClassroomOnShareStart',
            });
        }

        remind.init({
            name: 'Teacher Rewards',
            id: '597920dc-3807-4040-b393-4053832e29e8',
        });
    }

    private renderShare() {
        const { classes, inviteCode } = this.props;
        const body = `Download the Pocket Points app to earn rewards for staying off your phone! Join my class with invite code: ${inviteCode}`;

        return (
            <React.Fragment>
                <Grid container={true} direction="column">
                    <Grid item={true} style={{ marginBottom: 20 }}>
                        <CloseButton onClick={this.handleClose} />
                    </Grid>

                    <Grid item={true}>
                        <Grid container={true} direction="column" alignItems="center">
                            <Grid item={true}>
                                <Typography style={{ fontSize: 32, fontWeight: 700 }}>Invite Students</Typography>
                            </Grid>
                            <Grid item={true} style={{ marginTop: 28 }}>
                                <Typography style={{ fontSize: 16, fontWeight: 300, textAlign: 'center' }}>
                                    Share your invite code to give access to your rewards
                                </Typography>
                            </Grid>
                            <Grid item={true} style={{ marginTop: 12, cursor: 'pointer' }}>
                                <Typography onClick={this.handleLearnMore} style={{ fontSize: 12, color: '#24DDA3' }}>
                                    Learn More
                                </Typography>
                            </Grid>

                            <Grid item={true} style={{ marginTop: 24 }}>
                                <Typography onClick={this.handleLearnMore} style={{ fontSize: 36, fontWeight: 800, color: '#24DDA3' }}>
                                    {inviteCode}
                                </Typography>
                            </Grid>

                            <Grid container={true} className={classes.inviteStudentsContainer} direction="column">
                                <Grid item={true} style={{ paddingTop: 32, paddingBottom: 32 }}>
                                    <Grid container={true}>
                                        <Grid item={true} xs={5} style={{ display: 'flex', alignItems: 'center' }}>
                                            <div style={{ height: 1, width: '100%', backgroundColor: '#979797', opacity: 0.4 }} />
                                        </Grid>
                                        <Grid item={true} xs={2} style={{ textAlign: 'center' }}>
                                            <Typography style={{ fontSize: 16, opacity: 0.5, fontWeight: 300 }}>or</Typography>
                                        </Grid>
                                        <Grid item={true} xs={5} style={{ display: 'flex', alignItems: 'center' }}>
                                            <div style={{ height: 1, width: '100%', backgroundColor: '#979797', opacity: 0.4 }} />
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item={true} onClick={() => this.glowOnce()}>
                                    <Grid container={true} alignItems="center" justifyContent="center">
                                        <Grid item={true} style={{ height: 46, width: 46, ...this.glowStyle() }}>
                                            <div id="gShareWidget" />
                                        </Grid>
                                        <Grid item={true}>
                                            <Typography style={{ fontSize: 16, fontWeight: 300, marginLeft: 20, width: 216 }}>
                                                Share on Google Classroom
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item={true} style={{ marginTop: 28 }} onClick={() => this.glowOnce()}>
                                    <Grid container={true} alignItems="center" justifyContent="center">
                                        <Grid item={true} style={{ height: 46, width: 46, cursor: 'pointer', ...this.glowStyle() }}>
                                            <div
                                                className="remind-composer-button"
                                                data-body={body}
                                                data-body-url="https://pckt.app.link/e2wr2RwEXT"
                                                data-text="Share on Remind"
                                                data-theme="dark"
                                                data-size="medium"
                                                data-no-text="true"
                                                data-button-type="square"
                                                onClick={this.handleRemind}
                                            />
                                        </Grid>
                                        <Grid item={true}>
                                            <Typography style={{ fontSize: 16, fontWeight: 300, marginLeft: 20, width: 216 }}>Share on Remind</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item={true} style={{ marginTop: 28 }} onClick={() => this.glowOnce()}>
                                    <Grid container={true} alignItems="center" justifyContent="center">
                                        <Grid
                                            item={true}
                                            style={{ height: 46, width: 46, cursor: 'pointer', ...this.glowStyle() }}
                                            onClick={this.handlePrintableHandout}
                                        >
                                            <Print className={classes.learnMorePrint} />
                                        </Grid>
                                        <Grid item={true}>
                                            <Typography style={{ fontSize: 16, fontWeight: 300, marginLeft: 20, width: 216 }}>Print Student Handout</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <div className={classes.buttonLoaderWrapper} style={{ marginTop: 48 }}>
                    <Button autoFocus={true} onClick={this.handleClose} variant="contained" color="primary" className={classes.primaryButton}>
                        Done
                    </Button>
                </div>
            </React.Fragment>
        );
    }

    private renderLearnMore() {
        const { classes, inviteCode } = this.props;
        const { step } = this.state;
        const slides = [
            {
                src: '/images/learn1.png',
                title: 'Share your invite code',
                body: 'Put your invite code in a public place like a class whiteboard',
                button: 'Next',
            },
            {
                src: '/images/learn2.png',
                title: 'Code is entered in the app',
                body: 'Anyone with the code can enter it in the Pocket Points app for access to all of your rewards',
                button: 'Done',
            },
        ];
        const { imageStyle, teacherStyle, screenStyle, codeStyle } = this.getStyle(slides[step]);

        return (
            <React.Fragment>
                <Grid container={true} direction="column">
                    <Grid item={true} style={{ marginBottom: 20 }}>
                        <ChevronLeft
                            onClick={this.handleBack}
                            style={{
                                cursor: 'pointer',
                            }}
                        />
                    </Grid>
                    <Grid item={true} style={imageStyle}>
                        <Typography component="div" style={step === 0 ? teacherStyle : screenStyle}>
                            {step === 0 && <div>Pocket Points Code</div>}
                            <div style={codeStyle}>{inviteCode}</div>
                        </Typography>
                    </Grid>
                    <Grid item={true}>
                        <MobileStepper
                            steps={slides.length}
                            position="static"
                            activeStep={step}
                            className={classes.mobileStepper}
                            nextButton={<div />}
                            backButton={<div />}
                        />
                    </Grid>
                    <Grid item={true} style={{ alignSelf: 'center' }}>
                        <Typography component="p" className={classes.textTitle}>
                            {slides[step].title}
                        </Typography>
                    </Grid>
                    <Grid item={true} style={{ alignSelf: 'center', height: 60, marginBottom: 10 }}>
                        <Typography component="p" className={classes.textBody}>
                            {slides[step].body}
                        </Typography>
                    </Grid>
                </Grid>
                <div className={classes.buttonLoaderWrapper}>
                    <Button autoFocus={true} onClick={this.onChangeStep} variant="outlined" color="primary" className={classes.primaryButtonOutlined}>
                        {slides[step].button}
                    </Button>
                </div>
            </React.Fragment>
        );
    }

    private getStyle = (slide) => {
        const { step } = this.state;
        const imageStyle = {
            alignSelf: 'center',
            width: '100%',
            height: isMobile ? 300 : 400,
            backgroundImage: `url(${slide.src})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
        };
        const teacherStyle: any = {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            color: '#245293',
            fontFamily: 'Dekko',
            height: '50%',
            paddingRight: '21%',
            fontSize: isMobile ? '20px' : '26px',
        };
        const screenStyle: any = {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            color: theme.palette.primary.main,
            fontWeight: 600,
            fontSize: isMobile ? '29px' : '32px',
        };
        const codeStyle = step === 0 ? { fontSize: isMobile ? '40px' : '46px' } : { paddingBottom: '50px' };

        return { imageStyle, teacherStyle, screenStyle, codeStyle };
    };

    private onChangeStep = () => {
        const { step } = this.state;

        if (step === 1) {
            this.handleBack();
        } else {
            this.setState({ step: this.state.step + 1 });
        }
    };

    private handleLearnMore = () => {
        this.setState({
            learnMore: true,
        });
        const { mixpanel } = this.props;
        mixpanel.actions.clickedLearnMore();
    };

    private handleBack = () => {
        this.setState({
            learnMore: false,
            step: 0,
        });
    };

    private handleClose = () => {
        this.props.handleClose();
    };

    private alertClosed = () => {
        this.setState({
            step: 0,
            learnMore: false,
        });
    };

    private handlePrintableHandout = () => {
        const { inviteCode, group, user, mixpanel } = this.props;
        const teacherName = user.data.userInfo.teacherName;
        const classroomName = group.attributes.name;
        const url = `${process.env.REACT_APP_API}/handout/student?inviteCode=${inviteCode}&classroomName=${classroomName}&teacherName=${teacherName}`;

        mixpanel.actions.clickedPrintHandout();

        window.open(url, '_blank');
    };

    private glowStyle = () => {
        if (this.state.glowActive) {
            return {
                transition: 'box-shadow .4s ease-out',
                boxShadow: '0 0 6px black',
            };
        }
        return {
            transition: 'box-shadow .4s ease-in-out',
        };
    };

    private glowOnce = () => {
        this.setState({ glowActive: true });
        setTimeout(() => this.setState({ glowActive: false }), 400);
    };

    private handleRemind = () => {
        const { mixpanel } = this.props;

        mixpanel.actions.sendEvent('Clicked Remind Share');
    };
}

const mapStoreToProps = (state: any) => {
    return {
        mixpanel: state.mixpanel,
        user: state.user,
    };
};

export default connect(
    mapStoreToProps,
    null
)(withStyles(styles)(withMobileDialog<IProps>()(LearnMoreModal)));
