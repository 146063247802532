import { Grid, Typography, withStyles } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import Text from '../../components/Text';
import appStyle from '../App.style';
import InProgressClasswideCell from './Cells/InProgressClasswideCell';
import InProgressIncentiveCell from './Cells/InProgressIncentiveCell';
import NoInProgressCell from './Cells/NoInProgressCell';
import Filters from './Filters';

interface IProps {
    classes: any;
    getIncentiveName: any;
    getUserName: any;
    getUserPic: any;
    filteredData: any;
    filterInputs: any;
    onFilterChanged: any;
    data: any;
}

interface IState {
    showClasswideModal: boolean;
    groupProgressId: string;
    groupIncentiveId: string;
}

class ProgressTab extends React.Component<IProps, IState> {
    public render() {
        const { classes, filterInputs, onFilterChanged } = this.props;

        return (
            <Grid container={true} direction="column">
                <Grid container={true} item={true} justifyContent="flex-end" style={{ paddingLeft: 6, paddingRight: 6, marginBottom: 6 }}>
                    <Filters filterInputs={filterInputs} onFilterChanged={onFilterChanged} hideDate={true} />
                </Grid>
                <Grid container={true} item={true} className={isMobile ? undefined : classes.dashboardPageSubBody} alignContent="flex-start">
                    {this.renderClasswideIncentives()}
                    {this.renderIndividualIncentives()}
                </Grid>
            </Grid>
        );
    }

    public renderIndividualIncentives() {
        const {
            filteredData: { inProgress },
            getIncentiveName,
            getUserName,
            getUserPic,
            classes,
        } = this.props;

        if (inProgress && inProgress.length > 0) {
            return (
                <Grid container={true} item={true} spacing={2}>
                    <Typography className={classes.inProgressRewardType}>INDIVIDUAL STUDENT REWARDS</Typography>
                    {typeof inProgress === 'object' &&
                        Object.keys(inProgress).map((id, index) => {
                            const progress = inProgress[id];

                            return (
                                <InProgressIncentiveCell
                                    key={index}
                                    progress={progress}
                                    name={getUserName(progress)}
                                    pic={getUserPic(progress)}
                                    incentiveName={getIncentiveName(progress)}
                                />
                            );
                        })}
                </Grid>
            );
        } else if (this.showClasswideHeader()) {
            return '';
        } else {
            return this.renderInProgressEmptyCell();
        }
    }

    private renderInProgressEmptyCell() {
        return (
            <NoInProgressCell
                title="You do not have any in progress rewards yet"
                body="Make sure you have shared your invite code and your rewards settings are correct"
            />
        );
    }

    private renderClasswideIncentives = () => {
        const {
            filteredData: { groupsFiltered },
        } = this.props;
        const { groupProgresses } = this.props.data;

        if (!!groupsFiltered && !!groupProgresses && this.showClasswideHeader()) {
            return (
                <Grid container={true} spacing={2} style={{ marginBottom: '8px' }}>
                    <Grid container={true} item={true} xs={12}>
                        <img src="/images/people-outline.png" alt="people icon" style={{ width: 18, height: 14, marginRight: 8 }} />
                        <Text fontSize={11} light={true}>
                            CLASSWIDE REWARDS
                        </Text>
                    </Grid>
                    {this.renderClasswideIncentiveCards()}
                </Grid>
            );
        }

        return '';
    };

    private renderClasswideIncentiveCards = () => {
        const { groupProgresses } = this.props.data;
        const {
            filteredData: { groupsFiltered },
        } = this.props;

        return Object.keys(groupsFiltered).map((groupKey: string) => {
            const group = groupsFiltered[groupKey];
            const { groupIncentives } = group;

            if (groupIncentives) {
                return Object.keys(groupIncentives).map((groupIncentiveKey: string) => {
                    const { iterations, name, required } = groupIncentives[groupIncentiveKey];

                    return iterations.map((iteration: any) => {
                        const { duration, expiration, groupProgressesId, start } = iteration;
                        if (groupProgresses[groupProgressesId]) {
                            const { completion, progress } = groupProgresses[groupProgressesId];
                            const moreStudentsNeeded = group.students && Object.keys(group.students).length < required ? true : false;
                            const groupName = group.attributes.name ? group.attributes.name : '';

                            if (this.isInWindow(start, expiration, completion)) {
                                return (
                                    <InProgressClasswideCell
                                        key={groupProgressesId}
                                        uniqueCompleted={this.uniqueCompletedCount(progress)}
                                        requiredCompleted={required}
                                        incentiveName={name}
                                        durationPerStudent={duration}
                                        groupName={groupName}
                                        moreStudentsNeeded={moreStudentsNeeded}
                                        start={moment.unix(start)}
                                        expiration={moment.unix(expiration)}
                                        iteration={iteration}
                                    />
                                );
                            }

                            return undefined;
                        }

                        return undefined;
                    });
                });
            }

            return undefined;
        });
    };

    private showClasswideHeader = () => {
        const { groupProgresses } = this.props.data;
        const {
            filteredData: { groupsFiltered },
        } = this.props;
        let showClasswideHeader = false;

        if (Object.keys(groupProgresses).length !== 0 && !!groupsFiltered) {
            Object.keys(groupsFiltered).forEach((groupKey) => {
                const group = groupsFiltered[groupKey];
                const { groupIncentives } = group;

                if (groupIncentives) {
                    Object.keys(groupIncentives).forEach((groupIncentiveKey: string) => {
                        const { iterations } = groupIncentives[groupIncentiveKey];

                        iterations.forEach((iteration: any) => {
                            const { expiration, groupProgressesId, start } = iteration;
                            if (groupProgresses[groupProgressesId]) {
                                const { completion } = groupProgresses[groupProgressesId];
                                const currentDate = moment();
                                const withinWindow = currentDate > moment.unix(start) && currentDate < moment.unix(expiration);

                                if (!completion && withinWindow) {
                                    showClasswideHeader = true;
                                }
                            }
                        });
                    });
                }
            });
        }

        return showClasswideHeader;
    };

    private isInWindow = (start, expiration, completion) => {
        const currentDate = moment();
        const withinWindow = currentDate > moment.unix(start) && currentDate < moment.unix(expiration);

        if (!completion && withinWindow) {
            return true;
        }

        return false;
    };

    private uniqueCompletedCount = (progress: any) => {
        let uniqueCompletedCount = 0;

        if (progress) {
            Object.keys(progress).forEach((key: any) => {
                if (progress[key].completion) {
                    uniqueCompletedCount++;
                }
            });
        }

        return uniqueCompletedCount;
    };
}

const mapStoreToProps = (state: any) => {
    return {
        data: {
            groupProgresses: state.groupProgresses.data,
        },
    };
};

export default connect(
    mapStoreToProps,
    null
)(withStyles(appStyle)(ProgressTab));
