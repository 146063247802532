import { Badge, Dialog, DialogContent, Grid, Typography } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import AlertLoader from '../../../components/AlertLoader';
import AlertYesNo from '../../../components/AlertYesNo';
import appStyle from '../../App.style';
import GroupForm from '../Form/GroupForm';
import RequestsList from '../RequestsList';
import CloseButton from '../../../components/CloseButton';

interface IProps {
    classes?: any;
    initialValues?: any;
    requests: any;
    groups: any;
    groupId?: string;
    fullScreen?: boolean;
    onClose: any;
    onEditSchedule: any;
    open: any;
    width?: any;
}

interface IState {
    loading: boolean;
    deleteView: boolean;
    showNewReward: boolean;
    showNotWorking: boolean;
}

class NewGroupModal extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            loading: false,
            deleteView: false,
            showNewReward: false,
            showNotWorking: false,
        };
    }

    public render() {
        const { fullScreen } = this.props;
        const { loading, deleteView } = this.state;

        return (
            <Dialog fullScreen={fullScreen} open={this.props.open} onClose={this.handleClose} maxWidth="sm" fullWidth={true}>
                <AlertYesNo showing={deleteView} onClose={this.handleOnDeleteCancel} onConfirm={this.handleOnDeleteConfirm} />
                <AlertLoader loading={loading} />
                <DialogContent style={{ padding: 0, position: 'relative' }}>{this.renderContent()}</DialogContent>
            </Dialog>
        );
    }

    private renderContent = () => {
        const { classes, initialValues, groupId, onEditSchedule } = this.props;
        const { showNewReward, showNotWorking } = this.state;

        if (groupId && showNewReward) {
            return <RequestsList showNewReward={showNewReward} classroomId={groupId} onClose={this.toogleNewReward} />;
        }

        if (groupId && showNotWorking) {
            return <RequestsList showNotWorking={showNotWorking} classroomId={groupId} onClose={this.toogleNotWorking} />;
        }

        return (
            <React.Fragment>
                <Grid container={true} item={true} xs={12} style={{ paddingLeft: 16, paddingRight: 16, paddingTop: 16 }} justifyContent="space-between">
                    <Grid container={true} item={true} xs={1}>
                        <CloseButton onClick={() => this.handleClose(groupId)} />
                    </Grid>
                    {groupId && (
                        <Grid container={true} item={true} xs={11} direction="row" justifyContent="flex-end" alignContent="center">
                            {!isMobile && this.renderRequestButtons()}
                            <Typography className={classes.deleteTitle} onClick={this.handleOnDelete}>
                                Delete
                            </Typography>
                        </Grid>
                    )}
                </Grid>
                {groupId && isMobile && (
                    <Grid container={true} item={true} xs={12} style={{ paddingLeft: 24, paddingTop: 16 }} direction="row">
                        {this.renderRequestButtons()}
                    </Grid>
                )}
                <Grid container={true} item={true} xs={12} className={classes.modalBody}>
                    <GroupForm
                        groupId={groupId}
                        initialValues={initialValues}
                        setLoading={this.handleLoading}
                        handleClose={this.handleClose}
                        onEditSchedule={onEditSchedule}
                    />
                </Grid>
            </React.Fragment>
        );
    };

    private renderRequestButtons = () => {
        const {
            classes,
            requests: { newRewards, notWorking },
        } = this.props;
        const newRewardsCount = newRewards ? Object.keys(newRewards).filter((key: string) => !newRewards[key].viewed).length : 0;
        const notWorkingCount = notWorking ? Object.keys(notWorking).filter((key: any) => !notWorking[key].viewed).length : 0;

        return (
            <React.Fragment>
                <div onClick={this.toogleNewReward} style={{ marginRight: 24 }}>
                    <Badge badgeContent={newRewardsCount} color="primary" classes={{ root: classes.badgeRoot, badge: classes.badgeBadge }}>
                        <Typography component="p" className={classes.editTitle}>
                            Requested Rewards
                        </Typography>
                    </Badge>
                </div>
                <div onClick={this.toogleNotWorking} style={{ marginRight: 24 }}>
                    <Badge badgeContent={notWorkingCount} color="primary" classes={{ root: classes.badgeRoot, badge: classes.badgeBadge }}>
                        <Typography component="p" className={classes.editTitle}>
                            View Issue Reports
                        </Typography>
                    </Badge>
                </div>
            </React.Fragment>
        );
    };

    private handleLoading = (loading: boolean, finished: any) => {
        this.setState({ loading }, () => finished());
    };

    private handleClose = (id) => {
        const { loading } = this.state;

        if (loading) {
            return;
        }
        this.props.onClose(id);
    };

    private handleOnDelete = () => {
        this.setState({ deleteView: true });
    };

    private handleOnDeleteCancel = () => {
        this.setState({ deleteView: false });
    };

    private handleOnDeleteConfirm = () => {
        const { groups, groupId, onClose } = this.props;

        this.setState({ deleteView: false, loading: true }, () => {
            groups.actions
                .deleteGroup(groupId)
                .then(() => {
                    this.setState({ loading: false }, () => onClose());
                })
                .catch(() => {
                    this.setState({ loading: false });
                });
        });
    };

    private toogleNewReward = () => {
        this.setState({ showNewReward: !this.state.showNewReward });
    };

    private toogleNotWorking = () => {
        this.setState({ showNotWorking: !this.state.showNotWorking });
    };
}

const mapStoreToProps = (state: any) => {
    return {
        groups: state.groups,
        requests: state.requests.data,
    };
};

export default connect(
    mapStoreToProps,
    null
)(withStyles(appStyle)(withMobileDialog<IProps>()(NewGroupModal)));
