import React from 'react';
import LoadingScreen from 'react-loading-screen';
import { connect } from 'react-redux';

interface IProps {
    loaded: boolean;
}

class AppLoader extends React.Component<IProps> {
    public render() {
        const { loaded } = this.props;
        return (
            <LoadingScreen loading={!loaded} bgColor="#f1f1f1" spinnerColor="#00D793" textColor="#676767">
                <div />
            </LoadingScreen>
        );
    }
}

const mapStoreToProps = (state) => {
    return {
        loaded: state.user.data.loaded,
    };
};

export default connect(
    mapStoreToProps,
    null
)(AppLoader);
