import { Grid } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { Check } from '@material-ui/icons';
import React from 'react';
import Text from '../../../components/Text';
import { formatDate, readableTime } from '../../../utils/helper';
import appStyle from '../../App.style';

interface IProps {
    classes: any;
    id: string;
    incentive: any;
    onClick: any;
    fullWidth?: boolean;
    selected?: boolean;
}

class IncentiveCell extends React.PureComponent<IProps> {
    public render() {
        const { classes, fullWidth, incentive, id, selected } = this.props;

        return (
            <Grid container={true} item={true} xs={fullWidth ? 12 : 6} onClick={() => this.props.onClick(id)}>
                <Grid
                    container={true}
                    item={true}
                    xs={12}
                    className={`${classes.incentiveCellContainer} ${selected ? classes.incentiveCellContainerSelected : ''}`}
                >
                    <Grid container={true} item={true} xs={12} justifyContent="space-between">
                        <div style={{ padding: 16 }}>
                            <Text fontSize={16} bold={true}>
                                {incentive.name}
                            </Text>
                        </div>
                        {selected && (
                            <div style={{ width: 24, height: 24, backgroundColor: '#00D793', borderTopRightRadius: 7 }}>
                                <Check style={{ color: '#ffffff' }} />
                            </div>
                        )}
                    </Grid>
                    <Grid container={true} item={true} justifyContent="space-between" alignItems="flex-end" xs={12} style={{ padding: 16, paddingBottom: 8 }}>
                        <Text fontSize={10} semiBold={true} extraLight={true}>
                            {readableTime(incentive.duration).toUpperCase()}
                        </Text>
                        <Text fontSize={10} semiBold={true} extraLight={true}>
                            {incentive.expiration ? formatDate(incentive.expiration).toUpperCase() : 'NEVER EXPIRES'}
                        </Text>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(appStyle)(IncentiveCell);
