import axios from 'axios';
import { handleActions } from 'redux-actions';
import { TIMEOUT } from '../utils/constants';
import firebase from 'firebase/compat/app'

class Check {
    public actions = {
        getFirestore: (): any => {
            return new Promise((resolve) => {
                const timeout = setTimeout(() => {
                    resolve(0);
                }, TIMEOUT);

                return this.firebase
                    .firestore()
                    .collection('searches')
                    .doc('schools')
                    .collection('indexes')
                    .where('id', '==', 4)
                    .get()
                    .then((results) => {
                        clearTimeout(timeout);
                        resolve(results.docs.length > 0 ? 1 : 0);
                    })
                    .catch(() => {
                        clearTimeout(timeout);
                        resolve(0);
                    });
            });
        },
        getRealtime: (): any => {
            return new Promise((resolve) => {
                const timeout = setTimeout(() => {
                    resolve(0);
                }, TIMEOUT);

                return this.firebase
                    .database()
                    .ref('appVersions')
                    .once('value')
                    .then((snapshot) => {
                        clearTimeout(timeout);
                        resolve(snapshot.val() ? 1 : 0);
                    })
                    .catch(() => {
                        clearTimeout(timeout);
                        resolve(0);
                    });
            });
        },
        getMixpanel: (): any => {
            return new Promise((resolve) => {
                const timeout = setTimeout(() => {
                    resolve(0);
                }, TIMEOUT);

                return axios({
                    method: 'head',
                    url: 'https://api.mixpanel.com/track',
                })
                    .then(() => {
                        clearTimeout(timeout);
                        resolve(1);
                    })
                    .catch(() => {
                        clearTimeout(timeout);
                        resolve(0);
                    });
            });
        },
        getGoogleMaps: (): any => {
            return new Promise((resolve) => {
                const timeout = setTimeout(() => {
                    resolve(0);
                }, TIMEOUT);

                return axios({
                    method: 'head',
                    url: `https://maps.googleapis.com/maps/api/geocode/json?address=95928&key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}`,
                })
                    .then(() => {
                        clearTimeout(timeout);
                        resolve(1);
                    })
                    .catch(() => {
                        clearTimeout(timeout);
                        resolve(0);
                    });
            });
        },
    };

    public initialState = {
        actions: this.actions,
        data: {
            location: null,
            schools: [],
        },
        loading: false,
    };

    public reducer = handleActions<any>({}, this.initialState);

    private firebase: any;

    constructor(/**firebase: any*/) {
        this.firebase = firebase;
    }
}

export default Check;
