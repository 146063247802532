import { Avatar, Grid, Tooltip, Typography, withStyles } from '@material-ui/core';
import moment from 'moment-timezone';
import React from 'react';
import { isMobile } from 'react-device-detect';
import BlankAvatar from '../../../components/BlankAvatar';
import appStyle from '../../App.style';

interface IProps {
    classes: any;
    classroom: any;
    shortList?: boolean;
}

class StudentSessions extends React.PureComponent<IProps> {
    public render() {
        const {
            classes,
            classroom: { students, duration },
            shortList,
        } = this.props;
        const earned = students
            .filter((student) => student.totalDuration > 0)
            .sort((studentA, studentB) => (studentA.totalDuration > studentB.totalDuration ? -1 : 1));
        const notEarned = students.filter((student) => student.totalDuration === 0);

        if (shortList) {
            return (
                <React.Fragment>
                    {earned.map((student, index) => {
                        return this.renderStudentRow(duration, student, index);
                    })}
                    {earned.length > 0 && notEarned.length > 0 && <Typography className={classes.sessionDivider}>did not use pocket points</Typography>}
                    {earned.length > 0 &&
                        notEarned.length > 0 &&
                        notEarned.map((student, index) => {
                            return this.renderStudentRow(duration, student, index);
                        })}
                    {earned.length === 0 && this.renderBlankRows(notEarned.length > 0)}
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                {earned.map((student, index) => {
                    return this.renderStudentRow(duration, student, index);
                })}
                {notEarned.length > 0 && <Typography className={classes.sessionDivider}>did not use pocket points</Typography>}
                {notEarned.length > 0 &&
                    notEarned.map((student, index) => {
                        return this.renderStudentRow(duration, student, index);
                    })}
            </React.Fragment>
        );
    }

    private renderStudentRow = (duration, student, studentIndex) => {
        const { classes } = this.props;
        const { imageUrl, sessions, totalDuration } = student;
        const avatarStyle = { border: '1px solid #ececec', width: 30, height: 30, fontSize: 16 };
        const hours = Math.floor(totalDuration / 3600);
        const minutes = Math.floor((totalDuration % 3600) / 60);
        const hoursText = hours > 0 ? hours + ' hr' : '';
        const minutesText = minutes > 0 ? minutes + ' mins' : '';
        const earned = hours > 0 || minutes > 0 ? `${hoursText} ${minutesText}` : '0 mins';
        const percent = totalDuration > 0 ? Math.round((totalDuration / duration) * 100) : 0;

        return (
            <React.Fragment key={studentIndex}>
                <Grid container={true} item={true} className={classes.dashboardDailyClassroomGroupCell}>
                    <Grid
                        container={true}
                        item={true}
                        justifyContent="space-between"
                        alignItems="center"
                        className={classes.dashboardDailyClassroomGroupCellInfo}
                        wrap="nowrap"
                    >
                        <Grid container={true} item={true} alignItems="center" wrap="nowrap">
                            {imageUrl && <Avatar alt="Remy Sharp" src={imageUrl} style={avatarStyle} />}
                            {!imageUrl && <BlankAvatar name={student.name} style={avatarStyle} />}
                            <Typography className={classes.dashboardDailyClassroomGroupCellName} noWrap={true}>
                                {student.name}
                            </Typography>
                        </Grid>
                        <Grid container={true} item={true} justifyContent="flex-end" alignItems="center" wrap="nowrap">
                            <Typography className={classes.dashboardDailyClassroomGroupCellInfoLabel}>{earned} earned</Typography>
                            {!isMobile && <Typography className={classes.dashboardDailyClassroomGroupCellInfoLabel}>{percent}% of class</Typography>}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container={true} alignItems="center" className={classes.dashboardDailyClassroomGroupProgress}>
                    {this.renderStudentProgressBar(sessions)}
                </Grid>
            </React.Fragment>
        );
    };

    private renderStudentProgressBar = (sessions) => {
        const {
            classes,
            classroom: { duration },
        } = this.props;
        let totalPercent = 0;

        if (sessions.length === 0) {
            return <div style={{ width: '100%', height: 3, backgroundColor: '#ebebeb' }} />;
        }

        return sessions.map((session, index) => {
            const percent = (session.duration / duration) * 100;
            const width = index === sessions.length - 1 ? 100 - totalPercent : percent;
            const style = {
                width: `${width}%`,
                backgroundColor: session.gap ? '#ebebeb' : '#00d793',
                height: 4,
            };
            const startText = moment.unix(session.start).format('h:mmA');
            const endText = moment.unix(session.end).format('h:mmA');

            totalPercent += percent;

            if (isMobile) {
                return <div key={index} style={style} />;
            }

            return (
                <Tooltip
                    key={index}
                    title={
                        <Grid container={true} direction="column" alignItems="center">
                            <div className={classes.dashboardDailyClassroomGroupCellTooltipArrow} />
                            <Grid container={true} wrap="nowrap">
                                <Grid container={true} item={true} direction="column" style={{ padding: 5 }}>
                                    <Typography className={classes.dashboardDailyClassroomGroupCellTooltipHeading}>start</Typography>
                                    <Typography className={classes.dashboardDailyClassroomGroupCellTooltipText}>{startText}</Typography>
                                </Grid>
                                <Grid container={true} item={true} direction="column" style={{ padding: 5 }}>
                                    <Typography className={classes.dashboardDailyClassroomGroupCellTooltipHeading}>end</Typography>
                                    <Typography className={classes.dashboardDailyClassroomGroupCellTooltipText}>{endText}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                    classes={{
                        tooltip: classes.dashboardDailyClassroomGroupCellTooltip,
                    }}
                >
                    <div style={style} className={classes.dashboardDailyClassroomGroupCellProgressBar}>
                        <div
                            className={classes.dashboardDailyClassroomGroupCellProgressBarHover}
                            style={{ backgroundColor: session.gap ? '#a9a9a9' : '#03aa75' }}
                        />
                    </div>
                </Tooltip>
            );
        });
    };

    private renderBlankRows = (hasStudents) => {
        const { classes } = this.props;

        return (
            <Grid container={true}>
                <Grid container={true} item={true} className={classes.dashboardDailyClassroomGroupCellBlank} />
                <Grid container={true} item={true} className={classes.dashboardDailyClassroomGroupCellBlank} justifyContent="center" alignItems="center">
                    <Typography className={classes.sessionDivider}>{hasStudents ? 'no student progress' : 'no students have joined'}</Typography>
                </Grid>
                <Grid container={true} item={true} className={classes.dashboardDailyClassroomGroupCellBlank} />
            </Grid>
        );
    };
}

export default withStyles(appStyle)(StudentSessions);
