import { MenuItem, Typography } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import debounce from 'lodash.debounce';
import React from 'react';
import appStyle from '../app/App.style';

interface IProps {
    debounce?: any;
    inputValue: string;
    highlightedIndex: number;
    selectedItem: any;
    getItemProps: any;
}

interface IState {
    addressSuggestions: any[];
    autocompleteService: any;
    autocompleteOK: any;
    loadingPredictions: boolean;
}

class AddressPredictionList extends React.Component<IProps, IState> {
    private debouncedFetchSuggestions: any;

    constructor(props: IProps) {
        super(props);

        this.state = {
            addressSuggestions: [],
            loadingPredictions: false,
            autocompleteService: new (window as any).google.maps.places.AutocompleteService(),
            autocompleteOK: (window as any).google.maps.places.PlacesServiceStatus.OK,
        };

        this.debouncedFetchSuggestions = debounce(this.fetchSuggestions, 150);
    }

    public componentDidMount() {
        this.debouncedFetchSuggestions(this.props.inputValue);
    }

    public componentDidUpdate(prevProps: IProps) {
        if (this.props.inputValue !== prevProps.inputValue) {
            this.debouncedFetchSuggestions(this.props.inputValue);
        }
    }

    public render() {
        const { highlightedIndex, selectedItem, getItemProps } = this.props;

        if (this.state.loadingPredictions) {
            return (
                <React.Fragment>
                    <MenuItem {...getItemProps({ item: { description: this.props.inputValue } })} key={0} component="div">
                        {this.props.inputValue}
                    </MenuItem>
                    <MenuItem disabled={true}>
                        <Typography align="center" style={{ fontStyle: 'italic' }}>
                            Loading...
                        </Typography>
                    </MenuItem>
                </React.Fragment>
            );
        }

        if (this.state.addressSuggestions.length === 0) {
            return (
                <React.Fragment>
                    <MenuItem {...getItemProps({ item: { description: this.props.inputValue } })} key={0} component="div">
                        {this.props.inputValue}
                    </MenuItem>
                    <MenuItem disabled={true}>
                        <Typography style={{ fontStyle: 'italic' }}>No results found</Typography>
                    </MenuItem>
                </React.Fragment>
            );
        }

        return this.state.addressSuggestions.map((suggestion: any, index) => {
            const isHighlighted = highlightedIndex === index;
            const isSelected = selectedItem ? suggestion === selectedItem.name : false;

            return (
                <MenuItem
                    {...getItemProps({ item: suggestion })}
                >
                    {suggestion.name}
                </MenuItem>
            );
        });
    }

    private autocompleteCallback = (predictions, status) => {
        if (status) {
            this.setState({ loadingPredictions: false });
        }
        if (status !== this.state.autocompleteOK) {
            this.setState({
                addressSuggestions: [],
            });
        }
        if (status === this.state.autocompleteOK) {
            this.setState({
                addressSuggestions: predictions
                    .map((p, idx) => ({
                        id: p.id,
                        description: p.description,
                        placeId: p.place_id,
                        active: false,
                        index: idx,
                        formattedSuggestion: p.structured_formatting,
                        matchedSubstrings: p.matched_substrings,
                        terms: p.terms,
                        types: p.types,
                    }))
                    .concat({ description: this.props.inputValue }),
            });
        }
    };

    private fetchSuggestions = (address) => {
        if (address && this.state.autocompleteOK && !!address.length) {
            this.setState({ loadingPredictions: true });
            this.state.autocompleteService.getPlacePredictions({ input: address }, this.autocompleteCallback);
        }
    };
}

export default withStyles(appStyle)(AddressPredictionList);
