import { Grid, TextField, withStyles } from '@material-ui/core';
import { Form, Formik } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import Alert from '../../components/Alert';
import Referrer from '../../components/Referrer';
import SchoolPicker from '../../components/SchoolPicker';
import Submit from '../../components/Submit';
import Text from '../../components/Text';
import { SetupSchema } from '../../utils/validations';
import appStyle from '../App.style';

interface IProps {
    classes: any;
    actions: any;
    onClose: any;
    user: any;
}

interface IState {
    alert: any;
}

class Setup extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            alert: {
                showing: false,
            },
        };
    }

    public render() {
        const { alert } = this.state;
        const initialValues = {
            teacherName: '',
            school: undefined,
            referrer: '',
        };

        return (
            <Grid container={true} direction="column" wrap="nowrap" justifyContent="center" alignItems="center" style={{ padding: '64px 16px' }}>
                <Alert alert={alert} onClose={this.alertClosed} />
                <Grid container={true} item={true} xs={12} sm={6} style={{ paddingBottom: 16 }}>
                    <Text isTitle={true} fontSize={24}>
                        Complete your account
                    </Text>
                </Grid>
                <Grid container={true} item={true} xs={12} sm={6}>
                    <Formik initialValues={initialValues} validationSchema={SetupSchema} onSubmit={this.handleSubmit}>
                        {({ values, errors, touched, handleChange, isSubmitting, setFieldValue }) => (
                            <Form noValidate={true} autoComplete="off">
                                <Grid container={true} item={true} xs={12} justifyContent="center">
                                    <TextField
                                        id="teacherName"
                                        label="What do students call you?"
                                        placeholder="e.g. Mrs. Smith, Coach Robertson, etc."
                                        margin="normal"
                                        required={true}
                                        onChange={handleChange}
                                        value={values.teacherName}
                                        error={!!errors.teacherName && !!touched.teacherName}
                                        fullWidth={true}
                                    />
                                    <div style={{ marginTop: 16, width: '100%' }}>
                                        <SchoolPicker error={!!errors.school && !!touched.school} onChange={(school) => setFieldValue('school', school)} />
                                    </div>
                                    <Referrer value={values.referrer} onChange={(referrer) => setFieldValue('referrer', referrer)} />
                                    <Submit loading={isSubmitting}>Continue</Submit>
                                </Grid>
                            </Form>
                        )}
                    </Formik>
                </Grid>
            </Grid>
        );
    }

    private alertClosed = () => {
        this.setState({ alert: { showing: false } });
    };

    private handleSubmit = (values, { setSubmitting }) => {
        const { actions, onClose, user } = this.props;
        const {
            authUser: { uid },
        } = user;

        actions.user
            .setupUser(uid, values)
            .then(() => {
                setSubmitting(false);
                onClose();
            })
            .catch(() => {
                setSubmitting(false);
                this.setState({
                    alert: {
                        showing: true,
                        title: 'Sorry',
                        message: 'Unable to complete account, please try again.',
                    },
                });
            });
    };
}

const mapStoreToProps = (state: any) => {
    return {
        actions: {
            user: state.user.actions,
        },
        user: state.user.data,
        mixpanel: state.mixpanel,
    };
};

export default connect(
    mapStoreToProps,
    null
)(withStyles(appStyle)(Setup));