import moment from 'moment';
import { Store } from 'redux';
import { handleActions } from 'redux-actions';
import firebase from 'firebase/compat/app'
export default class Institutions {
    public actions = {
        getInstitution: (schoolId) => {
            console.info(`reactions:institutions:getInstitution:schoolId-1: ${schoolId}`);
            return this.firestore
                .collection('institutions')
                .where('pp.schools.id', '==', schoolId)
                .get()
                .then((snapshot) => {
                    const data = snapshot.docs[0].data();
                    const {
                        address,
                        geo: { fences, point, hash },
                        title,
                    } = data;
                    console.info(`reactions:institutions:getInstitution:schoolId-2: ${schoolId}`);
                    return { address, fences, point, title, id: snapshot.docs[0].id };
                })
                .catch((error) => {
                    console.info(`reactions:institutions:getInstitution:error: ${error}`);
                });
        },
        createUpdateSchoolRequest: ({ request, schoolId, schoolName, institutionId }) => {
            const requestRef = this.firestore
                .collection('requests')
                .doc('portalUser')
                .collection('updateSchool')
                .doc();
            const { authUser } = this.store.getState().user.data;

            return requestRef
                .set({
                    request,
                    portalUserEmail: authUser.email,
                    portalUserId: authUser.uid,
                    schoolId,
                    schoolName,
                    institutionId,
                    completed: false,
                    createdAt: moment().toISOString(),
                })
                .then(() => {
                    this.store.getState().mixpanel.actions.sendEventWithProps('Update School Requested', {
                        Email: authUser.email,
                        'School Id': schoolId,
                        'School Name': schoolName,
                    });
                });
        },
        createNewSchoolRequest: ({ newSchoolName, newSchoolAddress }) => {
            const requestRef = this.firestore
                .collection('requests')
                .doc('portalUser')
                .collection('newSchool')
                .doc();
            const { authUser } = this.store.getState().user.data;

            return requestRef
                .set({
                    name: newSchoolName,
                    address: newSchoolAddress,
                    email: authUser.email,
                    emailedUser: false,
                    completed: false,
                    createdAt: moment().toISOString(),
                })
                .then(() => {
                    this.store.getState().mixpanel.actions.sendEventWithProps('School Requested', {
                        Email: authUser.email,
                        'School Name': newSchoolName,
                        'School Address': newSchoolAddress,
                    });
                });
        },
    };

    public initialState = {
        actions: this.actions,
        data: null,
    };

    public reducer = handleActions<any>({}, this.initialState);

    private database: any;
    private firestore: any;
    private store: Store;

    constructor(/**firebase: any*/) {
        this.firestore = firebase.firestore();
        this.database = firebase.database();
    }

    public setStore = (store) => {
        this.store = store;
    };
}
