import { Grid, Link, Typography } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Text from '../../../components/Text';
import appStyle from '../../App.style';

interface IProps {
    classes: {
        pageRow: string;
        groupCellContainer: string;
        groupCellTitle: string;
        groupCellFooter: string;
    };
    group: any;
    id: string;
    onClick?: any;
}

class GroupCell extends React.Component<IProps> {
    public render() {
        const { classes, group, id } = this.props;
        const studentCount = group.students ? Object.keys(group.students).length : 0;
        let incentiveLength = group.incentives ? Object.keys(group.incentives).length : 0;
        incentiveLength += group.groupIncentives ? Object.keys(group.groupIncentives).length : 0;
        const incentiveText = group.incentives || group.groupIncentives ? `${incentiveLength} Reward${incentiveLength !== 1 ? 's' : ''}` : 'No Rewards';
        const link = `/group/${id}`;

        return (
            <Grid item={true} sm={6} xs={12} className={classes.pageRow} onClick={this.props.onClick}>
                <Link color="inherit" component={RouterLink} to={link} underline="none">
                    <div className={classes.groupCellContainer}>
                        <Grid container={true}>
                            <Grid item={true} xs={12}>
                                <Text fontSize={16} bold={true}>
                                    {group.attributes.name}
                                </Text>
                            </Grid>
                            <Grid item={true} xs={6}>
                                <Typography component="p" className={classes.groupCellFooter}>
                                    {incentiveText}
                                </Typography>
                            </Grid>
                            <Grid item={true} xs={6} style={{ textAlign: 'right' }}>
                                <Typography component="p" className={classes.groupCellFooter}>
                                    {`${studentCount} Student${studentCount !== 1 ? 's' : ''}`}
                                </Typography>
                            </Grid>
                        </Grid>
                    </div>
                </Link>
            </Grid>
        );
    }
}

export default withStyles(appStyle)(GroupCell);
