export const WEEKDAY_MASK = {
    monday: 32,
    tuesday: 16,
    wednesday: 8,
    thursday: 4,
    friday: 2,
    saturday: 1,
    sunday: 64,
};
export const WEEKDAYS = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
export const EARNING_WINDOW_TYPES = {
    standard: 'standard',
    weekly: 'weekly',
    rotating: 'rotating',
};
export const ROTATION_TYPES = {
    alphabet: 'alphabet',
    numeric: 'numeric',
};
export const FILTER_DATE_OPTIONS = [
    { value: 0, label: 'Current Day' },
    { value: 1, label: 'Previous Day' },
    { value: 7, label: 'Last Week' },
    { value: 30, label: 'Last 30 Days' },
    { value: 90, label: 'Last 90 Days' },
    { value: 360, label: 'Last 12 Months' },
    { value: -1, label: 'All Time' },
];
export const TIMEOUT = 10000;
export const SUMMARY_EMAIL_TYPES = {
    weekly: 'weekly',
    disabled: 'disabled',
};
export const DAILY_EMAIL_TYPES = {
    daily: 'daily',
    disabled: 'disabled',
};
