import { Dialog, DialogContent, DialogTitle, Grid, Typography, withStyles } from '@material-ui/core';
import moment, { Moment } from 'moment';
import React from 'react';
import { isMobile } from 'react-device-detect';
import CloseButton from '../../components/CloseButton';
import appStyle from '../App.style';
import StudentSessions from './Cells/StudentSessions';
import DateSelector from './DateSelector';

interface IProps {
    classes: any;
    classroom: any;
    date: Moment;
    onClose: any;
    onAddDay: any;
    onOpenDate: any;
    onSubtractDay: any;
}

class DailyClassroomModal extends React.PureComponent<IProps> {
    public render() {
        const { classes, onClose, classroom, date, onAddDay, onOpenDate, onSubtractDay } = this.props;
        const { name, onDate, students } = classroom;
        const duration = onDate ? classroom.duration : 1;
        const withSessions = students.filter((student) => {
            return student.totalDuration > 59;
        });
        const totalWithSession = withSessions.reduce((total, student) => {
            return total + student.totalDuration / duration;
        }, 0);
        const percentOfClass = withSessions.length > 0 ? Math.round((totalWithSession / withSessions.length) * 100) : 0;
        const totalSeconds = withSessions.reduce((count, student) => {
            return (count += student.totalDuration);
        }, 0);
        const hours = Math.floor(moment.duration(totalSeconds / withSessions.length, 'seconds').asHours());
        const minutes = Math.round(moment.duration(totalSeconds / withSessions.length, 'seconds').asMinutes()) % 60;
        const hoursText = hours > 0 ? hours + ' hr' : '';
        const minutesText = minutes > 0 ? minutes + ' mins' : '';
        const averageTimeText = hours > 0 || minutes > 0 ? `${hoursText} ${minutesText}` : '0 mins';

        return (
            <Dialog fullScreen={isMobile} open={onDate} onClose={onClose} maxWidth="sm" fullWidth={true} scroll="paper">
                <DialogTitle style={{ paddingBottom: 0 }}>
                    <Grid container={true} item={true} justifyContent="space-between" style={{ height: 40 }} wrap="nowrap">
                        <CloseButton onClick={onClose} />
                        <DateSelector date={date} onAddDay={onAddDay} onOpenDate={onOpenDate} onSubtractDay={onSubtractDay} />
                    </Grid>
                    <Grid className={classes.dashboardDailyClassroomModalTitle}>
                        <Typography style={{ fontWeight: 800, fontSize: 26, marginBottom: 32 }}>{name}</Typography>
                        <Grid container={true} item={true} justifyContent="space-between" className={classes.dashboardDailyClassroomGroupHeader}>
                            <Grid container={true} item={true} direction="column">
                                <Typography style={{ fontWeight: 500, fontSize: 14, marginBottom: 4, opacity: 0.5 }}>Students used Pocket Points</Typography>
                                <Typography color="primary" style={{ fontWeight: 600, fontSize: 24, marginBottom: 20 }}>
                                    {percentOfClass}% of class
                                </Typography>
                                <Typography style={{ fontWeight: 500, fontSize: 14, marginBottom: 4, opacity: 0.5 }}>Avg time on Pocket Points</Typography>
                                <Typography color="primary" style={{ fontWeight: 600, fontSize: 24 }}>
                                    {averageTimeText}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent className={classes.dashboardDailyClassroomModalContent} style={{ position: 'relative' }}>
                    <Grid container={true} item={true} justifyContent="space-between" className={classes.dashboardDailyClassroomGroupHeader}>
                        <Grid container={true} item={true} xs={6} direction="column">
                            <Typography className={classes.dashboardDailyClassroomGroupTimeLabel}>class start</Typography>
                            <Typography className={classes.dashboardDailyClassroomGroupTime}>{moment.unix(classroom.startTime).format('h:mmA')}</Typography>
                        </Grid>
                        <Grid container={true} item={true} xs={6} direction="column" alignItems="flex-end">
                            <Typography className={classes.dashboardDailyClassroomGroupTimeLabel}>class end</Typography>
                            <Typography className={classes.dashboardDailyClassroomGroupTime}>{moment.unix(classroom.endTime).format('h:mmA')}</Typography>
                        </Grid>
                    </Grid>

                    <Grid container={true} item={true}>
                        {classroom.onDate && <StudentSessions classroom={classroom} />}
                    </Grid>
                </DialogContent>
            </Dialog>
        );
    }
}

export default withStyles(appStyle)(DailyClassroomModal);
