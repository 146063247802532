import { Avatar, Badge, Button, Grid, Link, Typography } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import { ChevronLeft, PersonAdd } from '@material-ui/icons';
import moment from 'moment';
import React, { Fragment } from 'react';
import { isMobile } from 'react-device-detect';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { Link as RouterLink, Redirect } from 'react-router-dom';
import EarningWindow from 'shared-library-js';
import BlankAvatar from '../../components/BlankAvatar';
import OnboardingBar from '../../components/OnboardingBar';
import Text from '../../components/Text';
import { EARNING_WINDOW_TYPES, WEEKDAYS } from '../../utils/constants';
import { hasCreatedAnIncentive } from '../../utils/helper';
import * as routes from '../../utils/routes';
import appStyle from '../App.style';
import EarningWindows from '../EarningWindows';
import Explainers from '../Explainers';
import GroupIncentiveCell from '../Incentives/Cells/GroupIncentiveCell';
import IncentiveAddCell from '../Incentives/Cells/IncentiveAddCell';
import IncentiveCell from '../Incentives/Cells/IncentiveCell';
import NoIncentivesCell from '../Incentives/Cells/NoIncentivesCell';
import NoStudentsCell from '../Incentives/Cells/NoStudentsCell';
import CopyIncentivesModal from '../Incentives/Form/CopyIncentivesModal';
import NewIncentiveModal from '../Incentives/NewIncentiveModal';
import LearnMoreModal from './Modals/LearnMoreModal';
import NewGroupModal from './Modals/NewGroupModal';
import StudentsModal from './Modals/StudentsModal';

interface IProps {
    classes: any;
    actions: any;
    earningWindow: any;
    groups: any;
    mixpanel: any;
    location: any;
    match: any;
    requests: any;
    userInfo: any;
}

interface IState {
    newIncentiveModal: boolean;
    editIncentiveModal: boolean;
    editIncentiveId: string | null;
    editGroupIncentiveModal: boolean;
    editGroupIncentiveId: string | null;
    showEditGroupModal: boolean;
    showEditGroupModalNext: boolean;
    showLearnMore: boolean;
    showStudents: boolean;
    showEditSchedule: boolean;
    copyIncentivesModal: boolean;
    copyIncentivesShowListDefault: boolean;
    valuesFromGroupForm: any;
}

class Group extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        const { match, groups } = props;
        const groupId: string = match.params.id;
        const group = groups[groupId];
        const newIncentiveModal = !!(group && group.modalStates && group.modalStates.showNewIncentive);
        const copyIncentivesModal = hasCreatedAnIncentive({ data: groups });

        this.state = {
            newIncentiveModal: newIncentiveModal && !copyIncentivesModal,
            editIncentiveModal: false,
            editIncentiveId: null,
            editGroupIncentiveModal: false,
            editGroupIncentiveId: null,
            showEditGroupModal: false,
            showEditGroupModalNext: false,
            showLearnMore: false,
            showStudents: false,
            showEditSchedule: false,
            copyIncentivesShowListDefault: false,
            copyIncentivesModal: newIncentiveModal && copyIncentivesModal,
            valuesFromGroupForm: undefined,
        };
    }

    public componentDidMount() {
        const { actions, match, groups } = this.props;
        const groupId: string = match.params.id;
        const group = groups[groupId];

        actions.mixpanel.viewedClass(group, groupId);
        actions.requests.getNewRewards(groupId);
        actions.requests.getNotWorking(groupId);
    }

    public render() {
        const { match, groups } = this.props;
        const groupId: string = match.params.id;
        const group = groups[groupId];

        if (!group) {
            return <Redirect to={routes.groups} />;
        }

        return this.renderGroup();
    }

    private renderGroup = () => {
        const { classes, earningWindow, match, groups } = this.props;
        const groupId: string = match.params.id;
        const group = groups[groupId];

        return (
            <Fragment>
                <Helmet>
                    <title>Pocket Points | {group.attributes.name}</title>
                </Helmet>
                {!isMobile && <OnboardingBar mobile={false} />}
                {this.renderModal()}
                <Grid container={true}>
                    <Grid item={true} xs={12} className={classes.pagePreHeader}>
                        <Grid container={true}>
                            <Grid item={true} xs={6}>
                                <Typography component="div">
                                    <div className={classes.backTitle}>
                                        <ChevronLeft />
                                        <Link color="inherit" component={RouterLink} to={routes.groups} underline="none">
                                            Back to Classes
                                        </Link>
                                    </div>
                                </Typography>
                            </Grid>
                            {!isMobile && this.renderDesktopEditBlock(group)}
                            {isMobile && this.renderMobileEditBlock()}
                        </Grid>
                    </Grid>
                    {isMobile && <OnboardingBar mobile={true} />}
                    <Grid item={true} xs={12} className={classes.pageHeader}>
                        <Grid container={true}>
                            <Grid item={true} sm={6} xs={12}>
                                <Typography component="h1" className={classes.pageTitle}>
                                    {group.attributes.name}
                                </Typography>
                                {earningWindow && this.renderSubHeader()}
                            </Grid>
                            {!isMobile && this.renderDesktopInvite()}
                            {isMobile && this.renderMobileInvite(group)}
                        </Grid>
                        {this.renderStudents()}
                    </Grid>
                    <Grid item={true} xs={12} className={classes.pageBody}>
                        {this.renderIncentives()}
                    </Grid>
                </Grid>
            </Fragment>
        );
    };

    private renderModal = () => {
        const {
            groups,
            match,
            userInfo: { modalStates },
        } = this.props;
        const {
            newIncentiveModal,
            showEditGroupModal,
            editIncentiveModal,
            editIncentiveId,
            editGroupIncentiveModal,
            editGroupIncentiveId,
            showLearnMore,
            showEditSchedule,
            copyIncentivesModal,
            copyIncentivesShowListDefault,
            valuesFromGroupForm,
        } = this.state;
        const groupId: string = match.params.id;
        const group = groups[groupId];
        const { inviteCode } = group.attributes;
        const incentiveCount = group.incentives ? Object.keys(group.incentives).length : 0;
        const showStudents = this.state.showStudents && Object.keys(group.students).length > 0;
        const showGroupExplainer = modalStates && modalStates.showGroupExplainer;
        const showAdvanced = modalStates && modalStates.showIncentiveExplainer;
        const showIncentiveExplainer = modalStates && modalStates.showIncentiveExplainer && incentiveCount > 0;
        const showDownloadExplainer = modalStates && modalStates.showDownloadExplainer && incentiveCount > 0;

        if (showEditSchedule) {
            return <EarningWindows groupId={groupId} modal={true} onClose={this.handleEditScheduleClose} />;
        }

        if (showEditGroupModal) {
            return (
                <NewGroupModal
                    groupId={groupId}
                    initialValues={valuesFromGroupForm}
                    open={showEditGroupModal}
                    onClose={this.handleEditGroupClose}
                    onEditSchedule={this.handleEditScheduleShow}
                />
            );
        }

        if (showGroupExplainer) {
            return <Explainers modal={true} type="group" />;
        }

        if (copyIncentivesModal) {
            return (
                <CopyIncentivesModal
                    groupId={groupId}
                    showing={copyIncentivesModal}
                    onClose={this.handleOpenCopyIncentives}
                    onCreateRewardsOption={this.handleCopyIncentivesCreateRewardOption}
                    showListDefault={copyIncentivesShowListDefault}
                />
            );
        }

        if (newIncentiveModal) {
            return (
                <NewIncentiveModal
                    open={newIncentiveModal}
                    onClose={this.handleNewIncentiveClose}
                    groupId={groupId}
                    copyIncentives={this.handleOpenCopyIncentives}
                    showAdvanced={showAdvanced}
                />
            );
        }

        if (editIncentiveModal) {
            return (
                <NewIncentiveModal
                    open={editIncentiveModal}
                    onClose={this.handleEditIncentiveClose}
                    groupId={groupId}
                    incentiveId={editIncentiveId}
                    copyIncentives={this.handleOpenCopyIncentives}
                />
            );
        }

        if (editGroupIncentiveModal) {
            return (
                <NewIncentiveModal
                    open={editGroupIncentiveModal}
                    onClose={this.handleEditGroupIncentiveClose}
                    groupId={groupId}
                    groupIncentiveId={editGroupIncentiveId}
                />
            );
        }

        if (showIncentiveExplainer) {
            return <Explainers modal={true} type="incentive" />;
        }

        if (showLearnMore) {
            return <LearnMoreModal open={showLearnMore} handleClose={this.handleCloseLearnMore} inviteCode={inviteCode} group={group} />;
        }

        if (showDownloadExplainer) {
            return <Explainers modal={true} type="download" />;
        }

        if (showStudents) {
            return (
                <StudentsModal
                    open={showStudents}
                    onClose={this.handleCloseStudents}
                    group={group}
                    blockStudents={this.blockStudents}
                    restoreStudents={this.restoreStudents}
                />
            );
        }

        return null;
    };

    private renderMobileEditBlock() {
        const {
            classes,
            requests: { newRewards, notWorking },
        } = this.props;
        const newRewardsCount = newRewards ? Object.keys(newRewards).filter((key: string) => !newRewards[key].viewed).length : 0;
        const notWorkingCount = notWorking ? Object.keys(notWorking).filter((key: any) => !notWorking[key].viewed).length : 0;

        return (
            <Grid item={true} xs={6} style={{ textAlign: 'right' }}>
                <div onClick={this.handleEditGroupShow}>
                    <Badge badgeContent={newRewardsCount + notWorkingCount} color="primary" classes={{ root: classes.badgeRoot, badge: classes.badgeBadge }}>
                        <Typography component="p" className={classes.editTitle}>
                            Edit Class
                        </Typography>
                    </Badge>
                </div>
            </Grid>
        );
    }

    private renderDesktopEditBlock(group) {
        const {
            classes,
            requests: { newRewards, notWorking },
        } = this.props;
        const newRewardsCount = newRewards ? Object.keys(newRewards).filter((key: string) => !newRewards[key].viewed).length : 0;
        const notWorkingCount = notWorking ? Object.keys(notWorking).filter((key: any) => !notWorking[key].viewed).length : 0;
        const inviteCode = group.attributes.inviteCode;

        return (
            <Grid item={true} xs={6} style={{ textAlign: 'right' }}>
                <Grid container={true} alignItems="center">
                    <Grid item={true} xs={8}>
                        <Grid container={true} justifyContent="center" alignItems="center" style={{ paddingLeft: 16 }}>
                            <Grid item={true}>
                                <Typography className={classes.inviteCodeLabel}>INVITE CODE</Typography>
                            </Grid>
                            <Grid item={true}>
                                <Typography className={classes.inviteCode}>{inviteCode}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item={true} xs={4}>
                        <div onClick={this.handleEditGroupShow}>
                            <Badge
                                badgeContent={newRewardsCount + notWorkingCount}
                                color="primary"
                                classes={{ root: classes.badgeRoot, badge: classes.badgeBadge }}
                            >
                                <Typography component="p" className={classes.editTitle}>
                                    Edit Class
                                </Typography>
                            </Badge>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    private renderDesktopInvite() {
        const { classes } = this.props;
        return (
            <Grid item={true} sm={6} xs={12} style={{ textAlign: 'right' }}>
                <Button variant="text" className={classes.addButton} onClick={this.handleShowLearnMore} disableRipple={true}>
                    <PersonAdd className={classes.addIcon} />
                    Invite Students
                </Button>
            </Grid>
        );
    }

    private renderMobileInvite(group: any) {
        const { classes } = this.props;
        const inviteCode = group.attributes.inviteCode;
        return (
            <Grid item={true} xs={12} style={{ backgroundColor: 'white', marginTop: 16, marginBottom: 16 }}>
                <Grid container={true}>
                    <Grid item={true} xs={6}>
                        <Button variant="text" className={classes.addButton} onClick={this.handleShowLearnMore} disableRipple={true}>
                            <PersonAdd className={classes.addIcon} />
                            Invite Students
                        </Button>
                    </Grid>
                    <Grid item={true} xs={6} style={{ display: 'flex' }}>
                        <Grid container={true} justifyContent="center" alignItems="center">
                            <Grid item={true}>
                                <Typography className={classes.inviteCodeLabel}>INVITE CODE</Typography>
                            </Grid>
                            <Grid item={true}>
                                <Typography className={classes.inviteCodeMobile}>{inviteCode}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    private renderSubHeader = () => {
        const { match, groups } = this.props;
        const groupId: string = match.params.id;
        const group = groups[groupId];
        const earningWindow = new EarningWindow(this.props.earningWindow, group);
        const days = [];
        const start = moment(earningWindow.getStandardTimeString('start'), 'HH:mm').format('h:mma');
        const end = moment(earningWindow.getStandardTimeString('end'), 'HH:mm').format('h:mma');
        let weekdayLabel = ', ';

        if (earningWindow.window.type === EARNING_WINDOW_TYPES.standard) {
            earningWindow.window.slots.forEach((slot, index) => {
                if (slot.enabled) {
                    days.push(WEEKDAYS[index].charAt(0).toUpperCase());
                }
            });
            weekdayLabel += days.join('');
            weekdayLabel = weekdayLabel === ', MTWTF' ? ', Mon - Fri' : weekdayLabel;
            weekdayLabel = weekdayLabel === ', MTWTFSS' ? ', Mon - Sun' : weekdayLabel;

            return (
                <Text fontSize={16} light={true}>
                    {`${start} - ${end}${weekdayLabel}`}
                </Text>
            );
        }
    };

    private renderStudents = () => {
        const { classes, match, groups } = this.props;
        const groupId = match.params.id;
        const studentKeys = Object.keys(groups[groupId].students);
        const students = studentKeys.map((key) => groups[groupId].students[key]).slice(0, 4);
        let stack = 0;

        if (students.length === 0) {
            return null;
        }

        const studentAvatars = students.map((student, index) => {
            stack = -10 * index;
            const style: any = { left: stack, position: 'relative', border: '1px solid #ececec', cursor: 'pointer' };

            if (student.imageUrl) {
                return <Avatar key={index} alt="Remy Sharp" src={student.imageUrl} style={style} onClick={this.handleStudents} />;
            }

            return <BlankAvatar key={index} name={student.name} style={style} onClick={this.handleStudents} />;
        });

        return (
            <Grid container={true} justifyContent="flex-start" alignItems="center" style={{ marginTop: 10 }}>
                {studentAvatars}
                <Typography
                    component="p"
                    className={classes.groupCellStudent}
                    style={{ cursor: 'pointer', marginLeft: students.length > 0 ? stack + 12 : 0 }}
                    onClick={this.handleStudents}
                >
                    {`${studentKeys.length} student${studentKeys.length !== 1 ? 's' : ''}`}
                </Typography>
            </Grid>
        );
    };

    private renderIncentives = () => {
        const { match, groups } = this.props;
        const groupId = match.params.id;
        const incentivesData = groups[groupId].incentives;
        const groupIncentivesData = groups[groupId].groupIncentives;

        const studentKeys = Object.keys(groups[groupId].students);
        const students = studentKeys.map((key) => groups[groupId].students[key]).slice(0, 4);
        const hasStudents = students && students.length > 0;

        if ((incentivesData && Object.keys(incentivesData).length > 0) || (groupIncentivesData && Object.keys(groupIncentivesData).length > 0)) {
            return (
                <Grid container={true} spacing={2}>
                    {!hasStudents && <NoStudentsCell onClick={this.handleShowLearnMore} />}
                    {!isMobile && <IncentiveAddCell mobile={false} onClick={this.handleNewIncentive} />}
                    {groupIncentivesData &&
                        Object.keys(groupIncentivesData).map((id, index) => (
                            <GroupIncentiveCell key={index} groupIncentive={groupIncentivesData[id]} id={id} onClick={this.handleEditGroupIncentive} />
                        ))}
                    {incentivesData &&
                        Object.keys(incentivesData).map((id, index) => (
                            <IncentiveCell key={index} incentive={incentivesData[id]} id={id} onClick={this.handleEditIncentive} />
                        ))}
                    {isMobile && <IncentiveAddCell mobile={true} onClick={this.handleNewIncentive} />}
                </Grid>
            );
        } else {
            return <NoIncentivesCell onClick={this.handleNewIncentive} />;
        }
    };

    private handleStudents = () => {
        const { actions } = this.props;

        this.setState({ showStudents: true }, () => {
            actions.mixpanel.clickedStudents();
        });
    };

    private handleOpenCopyIncentives = (show: boolean, numIncentivesCreated: number = 0, listShowing: boolean = false) => {
        const { match, groups } = this.props;
        const groupId = match.params.id;
        const data = groups[groupId].incentives;

        let showLearnMore = false;
        if (numIncentivesCreated > 0) {
            if (data && Object.keys(data).length === numIncentivesCreated) {
                showLearnMore = true;
            }
        }

        this.setState({
            copyIncentivesModal: show,
            newIncentiveModal: false,
            copyIncentivesShowListDefault: listShowing,
            showLearnMore,
        });
    };

    private handleCopyIncentivesCreateRewardOption = () => {
        this.setState({
            copyIncentivesModal: false,
            newIncentiveModal: true,
            copyIncentivesShowListDefault: false,
        });
    };

    private handleEditScheduleClose = () => {
        const { earningWindow } = this.props;
        const { showEditGroupModalNext } = this.state;

        if (showEditGroupModalNext && earningWindow) {
            this.setState({ showEditSchedule: false, showEditGroupModal: true, showEditGroupModalNext: false });
        } else {
            this.setState({ showEditSchedule: false, showEditGroupModalNext: false });
        }
    };

    private handleEditScheduleShow = (values) => {
        this.setState({ showEditSchedule: true, valuesFromGroupForm: values });
    };

    private handleCloseStudents = () => {
        this.setState({ showStudents: false });
    };

    private handleShowLearnMore = () => {
        const { actions } = this.props;

        this.setState({ showLearnMore: true }, () => {
            actions.mixpanel.clickedInviteStudent();
        });
    };

    private handleCloseLearnMore = () => {
        this.setState({ showLearnMore: false });
    };

    private handleNewIncentive = () => {
        const { earningWindow, groups } = this.props;

        this.setState({
            newIncentiveModal: true,
            copyIncentivesModal: hasCreatedAnIncentive({ data: groups }),
            copyIncentivesShowListDefault: false,
            showEditSchedule: !earningWindow,
        });
    };

    private handleNewIncentiveClose = (created: boolean, onCompleted?: any) => {
        const { actions, match, groups } = this.props;
        const groupId = match.params.id;
        const data = groups[groupId].incentives;

        let showLearnMore = false;
        if (created && data && Object.keys(data).length === 1) {
            showLearnMore = true;
        }

        this.setState({ newIncentiveModal: false, copyIncentivesShowListDefault: !created, showLearnMore }, () => {
            actions.groups.updateGroupModalState({ groupId, modalStates: { showNewIncentive: false } });
            if (onCompleted) {
                onCompleted();
            }
        });
    };

    private handleEditGroupShow = () => {
        const { earningWindow } = this.props;

        this.setState({ showEditGroupModal: !!earningWindow, showEditGroupModalNext: true, showEditSchedule: !earningWindow });
    };

    private handleEditGroupClose = () => {
        this.setState({ showEditGroupModal: false, valuesFromGroupForm: undefined });
    };

    private handleEditIncentive = (id: string) => {
        this.setState({ editIncentiveId: id, editIncentiveModal: true });
    };

    private handleEditGroupIncentive = (id: string) => {
        this.setState({ editGroupIncentiveId: id, editGroupIncentiveModal: true });
    };

    private handleEditIncentiveClose = () => {
        this.setState({ editIncentiveModal: false });
    };

    private handleEditGroupIncentiveClose = () => {
        this.setState({ editGroupIncentiveModal: false });
    };

    private blockStudents = (studentsToBlock) => {
        const { actions, match } = this.props;
        const groupId = match.params.id;

        return actions.groups.blockStudentsFromGroup({ studentsToBlock, groupId });
    };

    private restoreStudents = (studentsToRestore) => {
        const { actions, match } = this.props;
        const groupId = match.params.id;

        return actions.groups.restoreStudentsFromGroup({ studentsToRestore, groupId });
    };
}

const mapStoreToProps = (state: any) => {
    return {
        actions: {
            groups: state.groups.actions,
            mixpanel: state.mixpanel.actions,
            requests: state.requests.actions,
        },
        earningWindow: state.earningWindow.data,
        groups: state.groups.data,
        mixpanel: state.mixpanel.data,
        requests: state.requests.data,
        userInfo: state.user.data.userInfo,
    };
};

export default connect(
    mapStoreToProps,
    null
)(withStyles(appStyle)(Group));
