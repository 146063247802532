import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

const Presentation = (props) => {
    return (
        <SvgIcon viewBox="0 0 65 64" {...props}>
            <g id="School-View" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="school-view-" transform="translate(-443.000000, -992.000000)">
                    <g id="Group-3" transform="translate(376.000000, 956.000000)">
                        <g id="presentation" transform="translate(67.000000, 36.000000)">
                            <path
                                d="M60.2727273,5 L5.72727273,5 C4.22727273,5 3,6.23428571 3,7.74285714 L3,50.2571429 C3,51.7657143 4.22727273,53 5.72727273,53 L60.2727273,53 C61.7727273,53 63,51.7657143 63,50.2571429 L63,7.74285714 C63,6.23428571 61.7727273,5 60.2727273,5 Z"
                                id="Shape"
                                fill="#D9DCE1"
                                fillRule="nonzero"
                            />
                            <path d="M33,54 L33,58" id="Shape" stroke="#D9DCE1" strokeWidth="2" />
                            <circle id="Oval" stroke="#D9DCE1" strokeWidth="2" cx="32.5" cy="61.5" r="2.5" />
                            <path
                                d="M63.6458333,4.28571429 L35.2083333,4.28571429 L35.2083333,2.85714286 C35.2083333,1.28571429 33.9895833,0 32.5,0 C31.0104167,0 29.7916667,1.28571429 29.7916667,2.85714286 L29.7916667,4.28571429 L1.35416667,4.28571429 C0.541666667,4.28571429 0,4.85714286 0,5.71428571 L0,8.57142857 C0,9.42857143 0.541666667,10 1.35416667,10 L63.6458333,10 C64.4583333,10 65,9.42857143 65,8.57142857 L65,5.71428571 C65,4.85714286 64.4583333,4.28571429 63.6458333,4.28571429 Z"
                                id="Shape"
                                fill="#03D794"
                                fillRule="nonzero"
                            />
                            <path
                                d="M18.6666667,42.7706422 L24,42.7706422 L24,48 L31.0666667,42.7706422 L45.3333333,42.7706422 C46.8,42.7706422 48,41.5321101 48,40.0183486 L48,20.7522936 C48,19.2385321 46.8,18 45.3333333,18 L18.6666667,18 C17.2,18 16,19.2385321 16,20.7522936 L16,40.0183486 C16,41.5321101 17.2,42.7706422 18.6666667,42.7706422 Z"
                                id="Shape"
                                fill="#FFFFFF"
                                fillRule="nonzero"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
};

export default Presentation;
