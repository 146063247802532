import React from 'react';
import LoadingScreen from 'react-loading-screen';

interface IProps {
    loading: boolean;
}

class AlertLoader extends React.Component<IProps> {
    public render() {
        const { loading } = this.props;

        return (
            <LoadingScreen loading={loading} bgColor="rgba(255, 255, 255, 0.5)" spinnerColor="#00D793">
                <div />
            </LoadingScreen>
        );
    }
}

export default AlertLoader;
