import { Button, Dialog, DialogActions, DialogContent, FormControl, Input } from '@material-ui/core';
import React from 'react';

interface IProps {
    value: any;
    showing: boolean;
    onClose: any;
}

interface IState {
    inputValue: string;
}

class AlertTextInput extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            inputValue: props.value ? props.value : '',
        };
    }

    public render() {
        const { inputValue } = this.state;
        return (
            <div>
                <Dialog
                    open={this.props.showing}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth="xs"
                    fullWidth={true}
                >
                    <DialogContent>
                        <FormControl margin="normal" required={true} fullWidth={true}>
                            <Input
                                type="number"
                                placeholder="How many?"
                                id="inputValue"
                                autoFocus={true}
                                name="inputValue"
                                value={inputValue}
                                onChange={this.setField}
                            />
                        </FormControl>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCancel} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.handleOkay} color="primary" autoFocus={true}>
                            Okay
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

    private setField = (event: any) => {
        const { value } = event.target;

        this.setState({
            inputValue: value,
        });
    };

    private handleCancel = () => {
        this.props.onClose(-1);
    };

    private handleOkay = () => {
        const inputValue = parseInt(this.state.inputValue, 10);

        if (inputValue > 0) {
            const val = Math.floor(inputValue);
            this.props.onClose(val);
        } else {
            this.props.onClose(0);
        }
    };
}

export default AlertTextInput;
